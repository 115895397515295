export default function ActionRow({
  children,
  colSpan = 1,
  style = "",
}: {
  style?: any;
  children: any;
  colSpan?: number;
}) {
  return (
    <td
      colSpan={colSpan}
      className={`rounded-md min-w-[60px]  border-[1px] border-[#767676] bg-white px-[10px] py-[12px] text-start`}
    >
      <div className={`text-[14px] text-[#767676] ${style}`}>{children}</div>
    </td>
  );
}
