import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const exportToCSV = (data: any, fileName: string) => {
  if (data?.length < 1) return;

  const ws = XLSX.utils.json_to_sheet(data);

  const capitalizedKeys = Object.keys(data[0]).map(
    (key) => key.toUpperCase(),
    // eslint-disable-next-line function-paren-newline
  );

  /* custom headers */
  XLSX.utils.sheet_add_aoa(ws, [capitalizedKeys], {
    origin: "A1",
  });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const exData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(exData, fileName + fileExtension);
};

export const downloadExcelFile = async (blobData: any) => {
  const url = window.URL.createObjectURL(new Blob([blobData]));
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", "Settlement List.xlsx");

  document.body.appendChild(link);
  link.click();
};
