export const BUSINESS_CATEGORIES = [
  "Training and Lessons",
  "Logistics and Movements",
  "Interior Decorations",
  "Catering Services",
  "Gadgets and Accessories",
  "Health and Beauty products/services",
  "Events and Entertainment",
  "Digital and Web Services",
  "Cleaning Services",
  "Clothing and Fashion",
  "Branding and Printing",
];

export const TICKET_TYPES = ["E-Ticket", "Hard Copy", "Both"];

export const FOOD_CATEGORY = ["Food", "Beverage", "Others"];

export const ACCOMMODATION_TYPES = [
  {
    name: "Hostel",
    value: "Hostel",
  },
  {
    name: "Single Room",
    value: "Single Room",
  },
  {
    name: "Room and Palour",
    value: "Room and Palour",
  },
  {
    name: "Self Contain",
    value: "Self Contain",
  },
  {
    name: "Flat",
    value: "Flat",
  },
];

export const ACCOMMODATION_AREAS = [
  {
    name: "Ede Road",
    value: "Ede Road",
  },
  {
    name: "General/OAUTHC",
    value: "General/OAUTHC",
  },
  {
    name: "Hostel",
    value: "Hostel",
  },
  {
    name: "Ibadan Road",
    value: "Ibadan Road",
  },
  {
    name: "Lagere",
    value: "Lagere",
  },
  {
    name: "Mayfair",
    value: "Mayfair",
  },
];
