import Modal from "core/components/Modal";
import Pagination from "core/components/Pagination";
import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import ActionRow from "core/components/table/Actionrow";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import {
  activeModule,
  downloadImg,
  editIcon,
  eyeopen,
} from "core/consts/images";
import { actionbtn, btn } from "core/consts/styling";
import { exportToCSV } from "core/helpers/exportToExcel";
import {
  convertTimeTo12Hour,
  convertTimeTo24Hour,
  formatToFormDate,
  getDateTime,
} from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useLocationStore from "core/services/stores/useLocationStore";
import useTransportationStore from "core/services/stores/useTransportationStore";
import useUserStore from "core/services/stores/useUserStore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const PlanTrips = () => {
  // need state
  const user = useUserStore((store) => store.user);
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const tripList = useTransportationStore((store) => store.plannedTripList);
  const drivers = useTransportationStore((store) => store.drivers);
  const trips = useTransportationStore((store) => store.busTrips);

  // actions
  const getTripsAction = useTransportationStore(
    (store) => store.getPlannedTrips,
  );

  const getTripAction = useTransportationStore((store) => store.getTrips);

  const getDriversAction = useTransportationStore((store) => store.getDrivers);

  const addUpdatePlannedTripAction = useTransportationStore(
    (store) => store.addUpdatePlannedTrip,
  );

  const [selected, setSelected] = useState<any>(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const defaultTrip: NewPlannedTrip = {
    departureDate: "",
    departureTime: "",
    driverId: "",
    tripId: "",
    validityPeriodInDays: 1,
  };

  const [newTrip, setNewTrip] = useState<NewPlannedTrip>({
    ...defaultTrip,
  });

  const defaultErrors = {
    DepartureDate: [],
    DepartureTime: [],
    DriverId: [],
    TripId: [],
    ValidityPeriodInDays: [],
  };

  const [errors, setErrors] = useState({
    ...defaultErrors,
  });

  const [query, setQuery] = useState<TripQuery>({
    search: "",
    status: "",
    startDate: "",
    endDate: "",
    locationFilter: !isSuperAdmin ? user?.location : "",
  });

  const onQueryChange = (e: any) => {
    const { name, value } = e.target;
    setQuery((state) => ({ ...state, [name]: value }));
  };

  const onFormChange = (e: any) => {
    const { name, value } = e.target;
    setNewTrip((state) => ({ ...state, [name]: value }));
  };

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const search = (e: any) => {
    e.preventDefault();
    getTripsAction(query, tripList?.pageNumber, tripList?.pageSize);
  };

  const fetchMore = async (page: number) => {
    await getTripsAction(query, page, tripList?.pageSize);
  };

  // validation
  const validation = (data: NewPlannedTrip) => {
    var isValid = true;

    if (data?.departureDate?.length < 1) {
      isValid = false;
      onErrorChange("DepartureDate", ["Please select a departure date"]);
    }

    if (data?.tripId?.length < 1) {
      isValid = false;
      onErrorChange("TripId", ["Please choose a trip"]);
    }

    if (data?.departureTime?.length < 1) {
      isValid = false;
      onErrorChange("DepartureTime", ["Departure Time is required"]);
    }

    if (data?.driverId?.length < 1) {
      isValid = false;
      onErrorChange("DriverId", ["Please select a driver for the trip"]);
    }

    if (+data?.validityPeriodInDays < 1) {
      isValid = false;
      onErrorChange("ValidityPeriodInDays", [
        "Validity period should be a day or more",
      ]);
    }

    return isValid;
  };

  const addUpdatePlanTrip = async (e: any, isUpdate: boolean = false) => {
    e?.preventDefault();

    if (validation(newTrip)) {
      var res = await addUpdatePlannedTripAction(
        {
          ...newTrip,
          validityPeriodInDays: +newTrip?.validityPeriodInDays,
          departureTime: convertTimeTo12Hour(newTrip?.departureTime),
        },
        isUpdate ? selected?.id : "",
      );

      if (res?.isSuccessful) {
        setNewTrip({ ...defaultTrip });

        if (isUpdate) {
          setSelected(null);
          setShowUpdateModal(false);
        }
      } else {
        setErrors((state) => ({
          ...state,
          ...res?.data?.errors,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();
    drivers?.length < 1 && getDriversAction();

    getTripsAction(query, tripList?.pageNumber, tripList?.pageSize);
    getTripAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mx-auto w-[95%] overflow-x-scroll">
        <header className="mb-[28px] flex flex-col justify-start gap-2 lg:flex-row lg:items-center lg:justify-between">
          <div className="flex items-center gap-3">
            <img src={activeModule} loading="lazy" alt="" />
            <h5 className="text-[18px] font-[500] text-brand">
              Plan Daily Trips
            </h5>
          </div>
          <div className="flex items-center justify-between gap-5">
            <Link
              className="text-brand hover:underline"
              to="/transportation/assign-bus"
            >
              Assign Driver to Bus
            </Link>
            <Link
              className="text-brand hover:underline"
              to="/transportation/manage-trips"
            >
              Manage Trips
            </Link>
            <Link
              className="text-brand hover:underline"
              to="/transportation/driver-schedule"
            >
              Driver Schedule
            </Link>
          </div>
        </header>

        <section>
          <form
            className="mb-[28px] flex items-end justify-between gap-2 overflow-x-scroll rounded-[5px] border border-t-[3px] border-brand bg-white p-3"
            onSubmit={search}
          >
            <InputField
              label="Search"
              boxStyle="min-w-[200px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="search"
              value={query?.search}
              onChange={onQueryChange}
            />

            <SelectField
              label="Select Location"
              boxStyle="min-w-[200px]"
              selectStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              defaultName="Select your Location"
              defaultValue=""
              name="locationFilter"
              disabled={!isSuperAdmin}
              options={
                locations?.length > 0
                  ? [
                      ...locations?.map((location) => ({
                        name: location?.name,
                        value: location?.name,
                      })),
                    ]
                  : []
              }
              value={query?.locationFilter}
              onChange={onQueryChange}
            />

            <InputField
              label="Start Date"
              type="date"
              boxStyle="min-w-[200px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="startDate"
              value={query?.startDate}
              onChange={onQueryChange}
            />

            <InputField
              label="End  Date"
              type="date"
              boxStyle="min-w-[200px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="endDate"
              value={query?.endDate}
              onChange={onQueryChange}
            />

            <div className="flex min-w-[400px] items-center gap-3">
              <button className="flex h-[50px] items-center justify-center gap-3 rounded-[5px] border border-brand bg-[#DFA1F4] p-3 px-8 text-white disabled:cursor-not-allowed">
                <span className="text-[14px text-brand">Search</span>
              </button>

              <button
                type="button"
                disabled={
                  tripList == null || tripList?.plannedTrips?.length < 1
                }
                className="flex h-[50px] items-center justify-between gap-3 rounded-[5px] bg-brand p-3 text-white disabled:cursor-not-allowed"
                onClick={() => {
                  exportToCSV(tripList?.plannedTrips, "Planned Trips");

                  notification({
                    message: "You have successfully exported the data to excel",
                    type: "success",
                  });
                }}
              >
                <span className="text-[14px]">Download</span>
                <img src={downloadImg} alt="" loading="lazy" />
              </button>
            </div>
          </form>
        </section>
        <section className="mb-[28px] flex flex-col gap-5 overflow-x-scroll lg:flex-row">
          <div className="w-full lg:w-4/12">
            <div className="w-full rounded-[5px] bg-white p-5">
              <div className="mb-[28px] text-center">
                <p>Manage Daily Trips</p>
              </div>
              <form onSubmit={(e: any) => addUpdatePlanTrip(e, false)}>
                <InputField
                  boxStyle="mb-[24px]"
                  placeholder="Departure Date"
                  label="Departure Date"
                  type="date"
                  name="departureDate"
                  value={newTrip?.departureDate}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.DepartureDate[0]}
                  onBlur={() => onErrorChange("DepartureDate", [])}
                />

                <SelectField
                  boxStyle="mb-[18px]"
                  label="Select Trip"
                  name="tripId"
                  defaultName="Select Trip"
                  defaultValue=""
                  options={
                    trips?.length > 0
                      ? [
                          ...trips?.map((trip) => ({
                            name: trip?.tripName,
                            value: trip?.id,
                          })),
                        ]
                      : []
                  }
                  value={newTrip?.tripId}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.TripId[0]}
                  onBlur={() => onErrorChange("TripId", [])}
                />

                <InputField
                  boxStyle="mb-[18px]"
                  label="Select Departure Time"
                  name="departureTime"
                  type="time"
                  value={newTrip?.departureTime}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.DepartureTime[0]}
                  onBlur={() => onErrorChange("DepartureTime", [])}
                />

                <SelectField
                  boxStyle="mb-[18px]"
                  label="Select Driver"
                  name="driverId"
                  defaultName="Select Driver"
                  defaultValue=""
                  options={
                    drivers?.length > 0
                      ? [
                          ...drivers?.map((driver) => ({
                            name: driver?.fullName,
                            value: driver?.agentId,
                          })),
                        ]
                      : []
                  }
                  value={newTrip?.driverId}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.DriverId[0]}
                  onBlur={() => onErrorChange("DriverId", [])}
                />

                <InputField
                  boxStyle="mb-[24px]"
                  placeholder="Validity Period"
                  label="Validity Period In Days"
                  type="number"
                  name="validityPeriodInDays"
                  value={newTrip?.validityPeriodInDays}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.ValidityPeriodInDays[0]}
                  onBlur={() => onErrorChange("ValidityPeriodInDays", [])}
                />

                <button className={`${btn} w-full bg-brand text-white`}>
                  Submit
                </button>
              </form>
            </div>
          </div>

          <div className="w-full lg:w-8/12">
            <Table
              headers={[
                "Trip",
                "Date",
                "Day",
                "Trip Start Time",
                "Assigned Driver",
                "Status",
                "Action",
              ]}
            >
              {tripList?.plannedTrips?.length > 0 ? (
                tripList?.plannedTrips?.map((trip) => (
                  <tr key={trip?.id}>
                    <Row value={trip?.tripName} />
                    <Row value={getDateTime(trip?.departureDate)} />
                    <Row value={trip?.departureDay} />
                    <Row value={trip?.departureTime} />
                    <Row value={trip?.assignedDriver} />
                    <Row value={trip?.status} />
                    <ActionRow style={`flex items-center justify-center gap-3`}>
                      <button
                        disabled
                        className="flex w-[35px] items-center justify-center border-[1px] !border-[#6C18A4] !bg-[#DFA1F4] p-2 disabled:cursor-not-allowed"
                        onClick={() => {}}
                      >
                        <img
                          src={eyeopen}
                          loading="lazy"
                          className="h-[16px]"
                          alt=""
                        />
                      </button>
                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] border-[#59960B] bg-[#D9FEAA] p-2 disabled:cursor-not-allowed"
                        onClick={() => {
                          setSelected(trip);

                          setNewTrip({
                            departureDate: formatToFormDate(
                              trip?.departureDate,
                            ),
                            departureTime: convertTimeTo24Hour(
                              trip?.departureTime,
                            ),
                            driverId: drivers?.find(
                              (x) => x.fullName === trip?.assignedDriver,
                            )?.agentId!,
                            tripId: trips?.find(
                              (x) => x?.tripName === trip?.tripName,
                            )?.id!,
                            validityPeriodInDays: 1,
                          });

                          setShowUpdateModal(true);
                        }}
                      >
                        <img
                          src={editIcon}
                          loading="lazy"
                          className="h-[16px]"
                          alt=""
                        />
                      </button>
                    </ActionRow>
                  </tr>
                ))
              ) : (
                <tr>
                  <Row colSpan={7} value="No planned trip yet" />
                </tr>
              )}
            </Table>

            <Pagination
              pageNumber={tripList?.pageNumber}
              pageSize={tripList?.pageSize}
              totalCount={tripList?.totalCount}
              totalPage={tripList?.totalPage}
              onFetch={fetchMore}
            />
          </div>
        </section>
      </div>

      {showUpdateModal && (
        <Modal
          header={`Update "${selected?.tripName}" Trip`}
          onClose={() => {
            setNewTrip({ ...defaultTrip });
            setErrors({ ...defaultErrors });
            setSelected(null);
            setShowUpdateModal(false);
          }}
        >
          <form onSubmit={(e) => addUpdatePlanTrip(e, true)}>
            <InputField
              boxStyle="mb-[24px]"
              placeholder="Departure Date"
              label="Departure Date"
              type="date"
              name="departureDate"
              value={newTrip?.departureDate}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.DepartureDate[0]}
              onBlur={() => onErrorChange("DepartureDate", [])}
            />

            <SelectField
              boxStyle="mb-[18px]"
              label="Select Trip"
              name="tripId"
              defaultName="Select Trip"
              defaultValue=""
              options={
                trips?.length > 0
                  ? [
                      ...trips?.map((trip) => ({
                        name: trip?.tripName,
                        value: trip?.id,
                      })),
                    ]
                  : []
              }
              value={newTrip?.tripId}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.TripId[0]}
              onBlur={() => onErrorChange("TripId", [])}
            />

            <InputField
              boxStyle="mb-[18px]"
              label="Select Departure Time"
              name="departureTime"
              type="time"
              value={newTrip?.departureTime}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.DepartureTime[0]}
              onBlur={() => onErrorChange("DepartureTime", [])}
            />

            <SelectField
              boxStyle="mb-[18px]"
              label="Select Driver"
              name="driverId"
              defaultName="Select Driver"
              defaultValue=""
              options={
                drivers?.length > 0
                  ? [
                      ...drivers?.map((driver) => ({
                        name: driver?.fullName,
                        value: driver?.agentId,
                      })),
                    ]
                  : []
              }
              value={newTrip?.driverId}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.DriverId[0]}
              onBlur={() => onErrorChange("DriverId", [])}
            />

            <InputField
              boxStyle="mb-[24px]"
              placeholder="Validity Period"
              label="Validity Period In Days"
              type="number"
              name="validityPeriodInDays"
              value={newTrip?.validityPeriodInDays}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.ValidityPeriodInDays[0]}
              onBlur={() => onErrorChange("ValidityPeriodInDays", [])}
            />

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setNewTrip({ ...defaultTrip });
                  setErrors({ ...defaultErrors });
                  setSelected(null);
                  setShowUpdateModal(false);
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default PlanTrips;
