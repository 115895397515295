import { useEffect, useState } from "react";
import FileUploadField from "core/components/formfields/FileUploadField";
import InputField from "core/components/formfields/InputField";
import TextField from "core/components/formfields/TextField";
import { activeModule } from "core/consts/images";
import { actionbtn, btn } from "core/consts/styling";
import { useSearchParams } from "react-router-dom";
import notification from "core/helpers/notification";
import useUserStore from "core/services/stores/useUserStore";
import useAccommodationStore from "core/services/stores/useAccommodationStore";
import SelectField from "core/components/formfields/SelectField";
import {
  ACCOMMODATION_AREAS,
  ACCOMMODATION_TYPES,
} from "core/consts/businessCategories";

interface Props {
  isUpdate: boolean;
}

const PropertyForm = ({ isUpdate = false }: Props) => {
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const [searchParams]: any = useSearchParams();
  const [, setAccommodationId] = useState("");

  const property = useAccommodationStore((store) => store.property);
  const getPropertyAction = useAccommodationStore(
    (store) => store.getPropertyById,
  );
  const addUpdatePropertyAction = useAccommodationStore(
    (store) => store.addUpdateProperty,
  );
  const deletePropertyImageAction = useAccommodationStore(
    (store) => store.deletePropertyImage,
  );

  const [files, setFiles] = useState([]);
  const [newProperty, setNewProperty] = useState<NewProperty>({
    agentContact: "",
    agentName: "",
    amenities: "",
    apartmentType: "",
    area: "",
    commission: 0,
    landlordContact: "",
    landlordName: "",
    otherClause: "",
    propertyAddress: "",
    rent: 0,
    images: [],
  });

  const [errors, setErrors] = useState({
    AgentContact: [],
    AgentName: [],
    Amenities: [],
    ApartmentType: [],
    Area: [],
    Commission: [],
    LandlordContact: [],
    LandlordName: [],
    OtherClause: [],
    PropertyAddress: [],
    Rent: [],
    Images: [],
  });

  const removeImage = (index: number) => {
    setFiles([...files.filter((file, i) => i !== index)]);
  };

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const deleteImage = async (url: any) => {
    if (window.confirm(`Click 'OK' to delete this image from the server.`)) {
      await deletePropertyImageAction(
        "accommodation",
        property?.accommodationId,
        url,
      );
    }
  };

  const validation = (data: NewProperty, files: any[]) => {
    var isValid = true;

    // TODO: Add Validations
    return isValid;
  };

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;

    setNewProperty((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const addUpdateProperty = async (e: any) => {
    e.preventDefault();

    if (validation(newProperty, files)) {
      var res = await addUpdatePropertyAction(
        {
          ...newProperty,
          images: files,
        },
        isUpdate ? property?.accommodationId : "",
      );

      if (res?.isSuccessful) {
        if (!isUpdate) {
          setNewProperty({
            agentContact: "",
            agentName: "",
            amenities: "",
            apartmentType: "",
            area: "",
            commission: 0,
            landlordContact: "",
            landlordName: "",
            otherClause: "",
            propertyAddress: "",
            rent: 0,
            images: [],
          });
        }

        setFiles([]);
      } else {
        setErrors((state) => ({
          ...state,
          ...res?.data?.errors,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    var id = searchParams.get("accommodationId");

    if (isUpdate && id?.length > 0) {
      setAccommodationId(id);
      getPropertyAction(id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams && isUpdate]);

  useEffect(() => {
    isUpdate &&
      property != null &&
      setNewProperty({
        agentContact: property?.agentContact,
        agentName: property?.agentName,
        amenities: property?.amenities,
        apartmentType: property?.apartmentType,
        area: property?.area,
        commission: property?.commission,
        landlordContact: property?.landlordContact,
        landlordName: property?.landlordName,
        otherClause: property?.otherClause,
        propertyAddress: property?.propertyAddress,
        rent: property?.rent,
        images: [],
      });
  }, [isUpdate, property]);

  return (
    <section className="mx-auto mb-[28px] w-[95%]">
      <header className="mb-[14px] flex items-center gap-3">
        <img src={activeModule} alt="active module" loading="lazy" />
        <h5 className="text-[18px] font-[500] text-brand">
          {isUpdate && property
            ? `Update ${property && property?.apartmentType}`
            : "Create Property"}
        </h5>
      </header>
      <form className="flex gap-5" onSubmit={addUpdateProperty}>
        <div className="w-3/5">
          <SelectField
            boxStyle="mb-[18px]"
            selectStyle="!border-brand text-[#615353]"
            name="apartmentType"
            isRequired
            defaultName="Select Apartment Type"
            label="Apartment Type"
            defaultValue=""
            options={
              ACCOMMODATION_TYPES?.length > 0
                ? [
                    ...ACCOMMODATION_TYPES?.map((cat) => ({
                      name: cat?.name,
                      value: cat?.value,
                    })),
                  ]
                : []
            }
            value={newProperty?.apartmentType}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.ApartmentType[0]}
            onBlur={() => onErrorChange("ApartmentType", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            label="Property Address"
            placeholder="Property Address"
            name="propertyAddress"
            isRequired
            value={newProperty?.propertyAddress}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.PropertyAddress[0]}
            onBlur={() => onErrorChange("PropertyAddress", [])}
          />

          <SelectField
            boxStyle="mb-[18px]"
            selectStyle="!border-brand text-[#615353]"
            name="area"
            isRequired
            label="Select Area"
            defaultName="Select Area"
            defaultValue=""
            options={
              ACCOMMODATION_AREAS?.length > 0
                ? [
                    ...ACCOMMODATION_AREAS?.map((cat) => ({
                      name: cat?.name,
                      value: cat?.value,
                    })),
                  ]
                : []
            }
            value={newProperty?.area}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.Area[0]}
            onBlur={() => onErrorChange("Area", [])}
          />

          <TextField
            boxStyle="mb-[18px]"
            name="amenities"
            label="Amenities"
            placeholder="Amenities"
            isRequired
            rows={5}
            value={newProperty?.amenities}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.Amenities[0]}
            onBlur={() => onErrorChange("Amenities", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            placeholder="Rent"
            label="Rent"
            type="number"
            name="rent"
            isRequired
            value={newProperty?.rent}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.Rent[0]}
            onBlur={() => onErrorChange("Rent", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            placeholder="Commission"
            label="Commission"
            type="number"
            name="commission"
            isRequired
            value={newProperty?.commission}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.Commission[0]}
            onBlur={() => onErrorChange("Commission", [])}
          />

          <TextField
            boxStyle="mb-[18px]"
            name="otherClause"
            placeholder="Other Clause"
            label="Other Clause"
            isRequired
            rows={5}
            value={newProperty?.otherClause}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.OtherClause[0]}
            onBlur={() => onErrorChange("OtherClause", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            placeholder="Agent Name"
            label="Agent Name"
            name="agentName"
            isRequired
            value={newProperty?.agentName}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.AgentName[0]}
            onBlur={() => onErrorChange("AgentName", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            placeholder="Agent Contact"
            label="Agent Contact"
            name="agentContact"
            isRequired
            value={newProperty?.agentContact}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.AgentContact[0]}
            onBlur={() => onErrorChange("AgentContact", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            placeholder="Landlord Name"
            label="Landlord Name"
            name="landlordName"
            isRequired
            value={newProperty?.landlordName}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.LandlordName[0]}
            onBlur={() => onErrorChange("LandlordName", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            placeholder="Landlord Contact"
            label="Landlord Contact"
            name="landlordContact"
            isRequired
            value={newProperty?.landlordContact}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.LandlordContact[0]}
            onBlur={() => onErrorChange("LandlordContact", [])}
          />

          <button
            disabled={isSuperAdmin}
            className={`${btn} w-full bg-brand text-white`}
          >
            Submit
          </button>
        </div>
        <div className="w-2/6">
          <>
            <FileUploadField
              multiple={true}
              boxStyle="w-3/5"
              label="Upload Pictures"
              name="signature"
              onChange={setFiles}
              showUploadedImages={false}
            />

            <div className="mt-[16px] grid grid-cols-2 gap-3 ">
              {files?.length > 0 &&
                files.map((file: any, index: number) => (
                  <div
                    key={file?.name + index}
                    className="relative overflow-hidden rounded-[5px] border border-brandLight"
                  >
                    <img src={URL.createObjectURL(file)} alt={`logo${index}`} />
                    <button
                      type="button"
                      className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                      onClick={() => removeImage(index)}
                    >
                      Delete
                    </button>
                  </div>
                ))}

              {isUpdate &&
                property?.uploadedImageList?.length > 0 &&
                property?.uploadedImageList?.map(
                  (image: string, index: number) => (
                    <div
                      key={index}
                      className="relative overflow-hidden rounded-[5px] border border-brandLight"
                    >
                      <img src={image} alt={`logo${index}`} />
                      <button
                        type="button"
                        className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                        onClick={() => deleteImage(image)}
                      >
                        Delete
                      </button>
                    </div>
                  ),
                )}
            </div>
          </>
        </div>
      </form>
    </section>
  );
};

export default PropertyForm;
