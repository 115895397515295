import Modal from "core/components/Modal";
import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import { btn } from "core/consts/styling";
import { MARKETPLACE_CATEGORY, USER_ROLES } from "core/consts/systemConst";
import notification from "core/helpers/notification";
import useLocationStore from "core/services/stores/useLocationStore";
import { useEffect, useState } from "react";

interface Props {
  category: string;
  onShow?: any;
  show: boolean;
  selectedArea?: any;
  isUpdate?: boolean;
}

const DeliveryAreaForm = ({
  category = "",
  selectedArea = null,
  isUpdate = false,
  show = false,
  onShow = () => {},
}: Props) => {
  const [area, setArea] = useState<NewDeliveryArea>({
    category: category,
    deliveryArea: "",
    deliveryFee: 0,
    subCategory: "",
  });

  const defaultErrors = {
    Category: [],
    DeliveryArea: [],
    DeliveryFee: [],
    SubCategory: [],
  };

  const [errors, setErrors] = useState(defaultErrors);

  const addUpdateArea = useLocationStore(
    (store) => store.addUpdateDeliveryArea,
  );

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;

    setArea((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const validation = (data: NewDeliveryArea) => {
    var isValid = true;

    if (category === USER_ROLES.marketplace && data?.subCategory?.length < 1) {
      isValid = false;
      onErrorChange("SubCategory", ["Sub category is required"]);
    }

    if (data?.deliveryArea?.length < 1) {
      isValid = false;
      onErrorChange("DeliveryArea", ["Delivery area is required"]);
    }

    return isValid;
  };

  const submitForm = async (e: any) => {
    e.preventDefault();

    if (validation(area)) {
      var res = await addUpdateArea(area, isUpdate ? selectedArea?.areaId : "");

      if (res?.isSuccessful) {
        setArea({
          category: category,
          deliveryArea: "",
          deliveryFee: 0,
          subCategory: "",
        });
        onShow(false);
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    isUpdate &&
      selectedArea != null &&
      setArea({
        category: selectedArea?.category,
        deliveryArea: selectedArea?.deliveryArea,
        deliveryFee: selectedArea?.deliveryFee,
        subCategory: selectedArea?.subCategory,
      });
  }, [isUpdate, selectedArea]);

  return (
    <>
      {show && (
        <Modal
          header={isUpdate ? "Update Delivery Area" : "Add Delivery Area"}
          onClose={() => {
            onShow(false);
            setArea({
              category: category,
              deliveryArea: "",
              deliveryFee: 0,
              subCategory: "",
            });
            setErrors(defaultErrors);
          }}
        >
          <form onSubmit={submitForm}>
            {category === USER_ROLES.marketplace && (
              <SelectField
                boxStyle="mb-[24px]"
                name="subCategory"
                label="Category"
                defaultName="Select Category"
                defaultValue=""
                options={
                  MARKETPLACE_CATEGORY?.length > 0
                    ? [
                        ...MARKETPLACE_CATEGORY?.map((cat) => ({
                          name: cat?.name,
                          value: cat?.value,
                        })),
                      ]
                    : []
                }
                value={area?.subCategory}
                onChange={onFormChange}
                error={errors?.SubCategory && errors?.SubCategory[0]}
                onBlur={() => onErrorChange("SubCategory", [])}
              />
            )}

            <InputField
              boxStyle="mb-[24px]"
              label="Delivery Area"
              placeholder="Delivery Area"
              name="deliveryArea"
              value={area?.deliveryArea}
              onChange={onFormChange}
              error={errors?.DeliveryArea && errors?.DeliveryArea[0]}
              onBlur={() => onErrorChange("DeliveryArea", [])}
            />

            <InputField
              boxStyle="mb-[24px]"
              placeholder="Delivery Fee"
              label="Delivery Fee"
              name="deliveryFee"
              type="number"
              value={area?.deliveryFee}
              onChange={onFormChange}
              error={errors?.DeliveryFee && errors?.DeliveryFee[0]}
              onBlur={() => onErrorChange("DeliveryFee", [])}
            />

            <button className={`${btn} w-full bg-brand text-white`}>
              Submit
            </button>
          </form>
        </Modal>
      )}
    </>
  );
};

export default DeliveryAreaForm;
