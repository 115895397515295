import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import TextField from "core/components/formfields/TextField";
import { btn } from "core/consts/styling";
import { BUSINESS_MODULES, SECURITY_QUESTIONS } from "core/consts/systemConst";
import notification from "core/helpers/notification";
import useLocationStore from "core/services/stores/useLocationStore";

export const BusinessDetails = ({
  onSubmit = () => {},
  onChange = () => {},
  onBack = () => {},
  data,
  errors = [],
  onErrorChange = () => {},
}: {
  onSubmit?: any;
  onBack?: any;
  data: NewUser;
  errors?: any;
  onChange?: any;
  onErrorChange?: any;
}) => {
  const locations = useLocationStore((store) => store.locations);

  const validation = (data: NewUser) => {
    var isValid = true;

    if (data?.businessName?.length < 1) {
      onErrorChange((state: any) => ({
        ...state,
        BusinessName: ["Business Name is required"],
      }));
      isValid = false;
    }

    if (data?.category?.length < 1) {
      onErrorChange((state: any) => ({
        ...state,
        Category: ["Business category is required"],
      }));
      isValid = false;
    }

    if (data?.location?.length < 1) {
      onErrorChange((state: any) => ({
        ...state,
        Location: ["Location is required"],
      }));
      isValid = false;
    }

    if (data?.businessDescription?.length < 1) {
      onErrorChange((state: any) => ({
        ...state,
        BusinessDescription: ["Business description is required"],
      }));
      isValid = false;
    }

    if (data?.securityQuestion?.length < 1) {
      onErrorChange((state: any) => ({
        ...state,
        SecurityQuestion: ["Please choose a security question"],
      }));
      isValid = false;
    }

    if (data?.securityAnswer?.length < 1) {
      onErrorChange((state: any) => ({
        ...state,
        SecurityAnswer: ["Answer to security question is required"],
      }));
      isValid = false;
    }

    if (data?.transactionPin?.length !== 4) {
      onErrorChange((state: any) => ({
        ...state,
        TransactionPin: ["Wallet pin must be equal to 4"],
      }));
      isValid = false;
    }

    return isValid;
  };

  const submitForm = (e: any) => {
    e.preventDefault();

    if (validation(data)) {
      onSubmit();
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  return (
    <form onSubmit={submitForm}>
      <p className="mb-[24px] text-center text-brandgray">
        Your phone number is {data?.phoneNo}
      </p>
      <InputField
        boxStyle="mb-[24px]"
        placeholder="Business Name"
        name="businessName"
        value={data?.businessName}
        onChange={(e: any) => {
          onChange(e);
        }}
        error={errors?.BusinessName[0]}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            BusinessName: [""],
          }));
        }}
      />
      <SelectField
        boxStyle="mb-[24px]"
        name="category"
        defaultName="Select Business Category"
        defaultValue=""
        options={BUSINESS_MODULES?.length > 0 ? [...BUSINESS_MODULES] : []}
        value={data?.category}
        error={errors?.Category[0]}
        onChange={(e: any) => {
          onChange(e);
        }}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            Category: [""],
          }));
        }}
      />
      <SelectField
        boxStyle="mb-[24px]"
        name="location"
        defaultName="Select your Location"
        defaultValue=""
        options={
          locations?.length > 0
            ? [
                ...locations?.map((location) => ({
                  name: location?.name,
                  value: location?.name,
                })),
              ]
            : []
        }
        value={data?.location}
        error={errors?.Location[0]}
        onChange={(e: any) => {
          onChange(e);
        }}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            Location: [""],
          }));
        }}
      />
      <TextField
        boxStyle="mb-[24px]"
        name="businessDescription"
        placeholder="Tell us about your company"
        rows={5}
        ref={null}
        value={data?.businessDescription}
        error={errors?.BusinessDescription[0]}
        onChange={(e: any) => {
          onChange(e);
        }}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            BusinessDescription: [""],
          }));
        }}
      />

      <SelectField
        boxStyle="mb-[24px]"
        name="securityQuestion"
        defaultName="Select Security Question"
        defaultValue=""
        options={
          SECURITY_QUESTIONS?.length > 1
            ? [
                ...SECURITY_QUESTIONS?.map((ques: any) => ({
                  name: ques,
                  value: ques,
                })),
              ]
            : []
        }
        onChange={(e: any) => {
          onChange(e);
        }}
        value={data?.securityQuestion}
        error={errors?.SecurityQuestion[0]}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            SecurityQuestion: [],
          }));
        }}
      />
      <InputField
        boxStyle="mb-[24px]"
        placeholder="Security Question Answer"
        name="securityAnswer"
        type="text"
        value={data?.securityAnswer}
        error={errors?.SecurityAnswer[0]}
        onChange={(e: any) => {
          onChange(e);
        }}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            SecurityAnswer: [""],
          }));
        }}
      />
      <InputField
        boxStyle="mb-[24px]"
        placeholder="Enter Wallet Pin"
        name="transactionPin"
        type="password"
        isNumberOnly
        value={data?.transactionPin}
        error={errors?.TransactionPin[0]}
        onChange={(e: any) => {
          onChange(e);
        }}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            TransactionPin: [""],
          }));
        }}
      />
      <div className="flex gap-3">
        <button
          type="button"
          onClick={() => onBack()}
          className={`${btn} w-full !bg-[#DFA1F4] text-white`}
        >
          Back
        </button>
        <button className={`${btn} w-full bg-brand text-white`}>
          Continue
        </button>
      </div>
    </form>
  );
};
