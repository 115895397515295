import React from "react";
import { minibus } from "core/consts/images";

export default function Timeline({
  stops = [],
  startPoint = "",
  finalPoint = "",
}: {
  stops: any;
  startPoint: string;
  finalPoint: string;
}) {
  return (
    <>
      <div className="relative mx-auto my-0 ml-[-5] flex w-[90%] gap-2 px-0 text-[14px] font-[500] outline-none">
        <img src={minibus} className="h-[18px]" alt="bus" />
        View Route Details
      </div>
      <div className="relative z-10 mx-auto my-0 w-[90%] px-0 py-[10px] text-[14px] outline-none before:absolute before:left-[5px] before:top-0 before:ml-[-1px] before:h-[100%] before:w-[1px] before:bg-brand before:content-['']">
        {stops?.length > 0 &&
          stops.map((stop: any, index: number) => (
            <div className="timeline-block-right flex px-0 py-[10px]">
              <div className="z-[9999] mt-[5px] h-[10px] w-[10px] rounded-full border-[2px] border-brand border-r-[50%] bg-brand" />
              <div className="m-0 ml-[10px] w-[95%] p-0">
                <>
                  {index === 0 && (
                    <p className="flex items-center gap-3">
                      <span>Start Point</span>
                      <span>{stop?.name}</span>
                      <span>{stop?.expectedArrivalTime}</span>
                    </p>
                  )}
                </>

                <>
                  {index === stops?.length - 1 && (
                    <p className="flex items-center gap-3">
                      <span>Final Point</span>
                      <span>{stop?.name}</span>
                      <span>{stop?.expectedArrivalTime}</span>
                    </p>
                  )}
                </>

                <>
                  {index !== stops?.length - 1 && index !== 0 && (
                    <p className="flex items-center gap-3">
                      <span>Point {index + 1}</span>
                      <span>{stop?.name}</span>
                      <span>{stop?.expectedArrivalTime}</span>
                    </p>
                  )}
                </>
              </div>
            </div>
          ))}
        <div></div>
      </div>
    </>
  );
}
