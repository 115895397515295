import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import notification from "core/helpers/notification";
import { updateOrderStatus } from "../api/userapi";
import { getProcessStatus } from "core/helpers/generalHelpers";
import {
  addUpdateEngineeringService,
  deleteEngineeringImage,
  deleteEngineeringService,
  getEngineeringDashboard,
  getEngineeringOrderById,
  getEngineeringOrders,
  getEngineeringService,
  getEngineeringServiceById,
  toggleEngineeringService,
} from "../api/engineering.api";

type StoreState = {
  isLoading: boolean;
  analytics: EngineeringAnalytics;
  serviceList: EngineeringServiceList;
  service: any;
  order: any;
  orderList: {
    orders: EngineeringOrder[];
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPage: number;
  };
  getDashboardAnalytics: (query: DateLocationQuery) => Promise<void>;
  getServices: (
    query: EngineeringQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  getServiceById: (serviceId: string) => Promise<void>;
  addUpdateService: (
    service: NewEngineeringService,
    serviceId: string,
  ) => Promise<UIResponse>;
  toggleService: (serviceId: string, visibility: boolean) => Promise<void>;
  deleteService: (serviceId: string) => Promise<void>;
  deleteServiceImage: (
    category: string,
    serviceId: string,
    image: string,
  ) => Promise<void>;
  getOrders: (
    query: EngineeringQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  updateOrderStatus: (
    orderId: string,
    orderStatus: ManageOrder,
    reason: string,
  ) => Promise<UIResponse>;
  getOrderById: (orderId: string) => Promise<void>;
  resetOrder: () => void;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  analytics: {
    listedServices: 0,
    totalOrders: 0,
    totalViews: 0,
    totalConvenienceFee: 0,
  },
  serviceList: {
    services: [],
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPage: 0,
  },
  orderList: {
    orders: [],
    pageNumber: 1,
    pageSize: 15,
    totalCount: 1,
    totalPage: 1,
  },
  service: null,
  order: null,
};

const useEngineeringStore = create<StoreState>()(
  devtools(
    persist(
      (set, get): StoreState => ({
        ...defaultState,
        getDashboardAnalytics: async (query) => {
          set({ isLoading: true });

          var res: UIResponse = await getEngineeringDashboard(query);
          if (res?.isSuccessful) {
            set({
              analytics: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getOrders: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getEngineeringOrders(
            query,
            pageNumber,
            pageSize,
          );

          if (res?.isSuccessful) {
            set({
              orderList: {
                orders: res?.data?.data?.orders,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: res?.data?.data?.pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
        },
        getOrderById: async (orderId) => {
          set({ isLoading: true });

          var res: UIResponse = await getEngineeringOrderById(orderId);

          if (res?.isSuccessful) {
            set({
              order: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getServices: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getEngineeringService(
            query,
            pageNumber,
            pageSize,
          );

          if (res?.isSuccessful) {
            set({
              serviceList: {
                services: res?.data?.data?.data,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: res?.data?.data?.pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
        },
        getServiceById: async (serviceId) => {
          set({ isLoading: true });

          var res: UIResponse = await getEngineeringServiceById(serviceId);
          if (res?.isSuccessful) {
            set({
              service: res?.data?.data,
              isLoading: false,
            });
          } else {
            set({ service: null, isLoading: false });
          }
        },
        addUpdateService: async (service, serviceId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateEngineeringService(
            service,
            serviceId,
          );

          if (res?.isSuccessful && serviceId?.length > 1) {
            get().getServiceById(serviceId);
          } else {
            set({ isLoading: false });
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        deleteServiceImage: async (category, serviceId, image) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteEngineeringImage(
            category,
            serviceId,
            image,
          );

          if (res?.isSuccessful) {
            get().getServiceById(serviceId);
          }

          notification({
            message: res?.isSuccessful
              ? "Image has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });
        },
        deleteService: async (serviceId) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteEngineeringService(serviceId);

          if (res?.isSuccessful) {
            set((state) => ({
              serviceList: {
                ...state.serviceList,
                services: state?.serviceList?.services?.filter(
                  (service) => service?.serviceId !== serviceId,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Service has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        toggleService: async (serviceId, visibility) => {
          set({ isLoading: true });

          var res: UIResponse = await toggleEngineeringService(
            serviceId,
            visibility,
          );

          if (res?.isSuccessful) {
            set((state) => ({
              serviceList: {
                ...state.serviceList,
                services: state?.serviceList?.services?.map((service) =>
                  service?.serviceId === serviceId
                    ? { ...service, visibility: visibility }
                    : service,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        updateOrderStatus: async (orderId, orderStatus, reason) => {
          set({ isLoading: true });

          var res: UIResponse = await updateOrderStatus(
            orderId,
            orderStatus,
            reason,
          );

          var processStatus = "";

          if (res?.isSuccessful) {
            processStatus = getProcessStatus(orderStatus);

            set((state) => ({
              orderList: {
                ...state.orderList,
                orders: state?.orderList?.orders?.map((order) =>
                  order?.orderId === orderId
                    ? { ...order, status: processStatus }
                    : order,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? `Order status has been updated successfully to ${processStatus}`
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        resetOrder: () => {
          set({ order: null });
        },
        reset: () => {
          set({ ...defaultState });
        },
      }),
      {
        name: "engineeringStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useEngineeringStore;
