import Pagination from "core/components/Pagination";
import DatePicker from "core/components/formfields/DatePicker";
import SelectField from "core/components/formfields/SelectField";
import { profileuser2, searchImg } from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import { MARKETPLACE_CATEGORY } from "core/consts/systemConst";
import { getDateTime } from "core/helpers/generalHelpers";
import useLocationStore from "core/services/stores/useLocationStore";
import useNotificationStore from "core/services/stores/useNotificationStore";
import { useEffect, useState } from "react";

interface Props {
  module: string;
}

const SystemNotifications = ({ module = "" }: Props) => {
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const notificationList = useNotificationStore(
    (store) => store.notificationList,
  );
  const getNotificationsAction = useNotificationStore(
    (store) => store.getNotifications,
  );
 // const markAsReadAction = useNotificationStore((store) => store.markAsRead);
  const deleteNotificationAction = useNotificationStore(
    (store) => store.deleteNotification,
  );

  const [query, setQuery] = useState<NotificationQuery>({
    category: "",
    endDate: "",
    startDate: "",
    module: module,
    userId: "",
  });

  const onQueryChange = (e: any, isAuto: boolean = true) => {
    const { name, value } = e?.target;

    setQuery((state) => ({
      ...state,
      [name]: value,
    }));

    if (isAuto) {
      getNotificationsAction(
        { ...query, [name]: value },
        notificationList?.pageNumber,
        notificationList?.pageSize,
      );
    }
  };

  const fetchMore = async (page: number) => {
    await getNotificationsAction(query, page, notificationList?.pageSize);
  };
  
  const deleteNotification = async (noticeId: string) => {
    if (window.confirm(`Click 'OK' to delete notification.`)) {
      await deleteNotificationAction(module, noticeId);
    }
  };
  
  useEffect(() => {
    getNotificationsAction(query, 1, 20);
    locations?.length < 1 && getLocationAction();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="mx-auto mb-[28px] w-[95%]">
      <div className="mb-[24px] flex items-center justify-between gap-5">
        <div className="w-8/12">
          <header className="mb-[18px] flex h-12 items-center justify-between gap-3">
            <div className="flex items-center gap-3">
              <img src={profileuser2} alt="active module" loading="lazy" />
              <h5 className="text-[18px] font-[500] text-brand">
                Notifications
              </h5>
            </div>
          </header>

          <div className="flex h-12 items-center justify-between gap-3 rounded-l-[25px] rounded-r-[5px] border-[1px] border-brand bg-white px-2 shadow-md">
            <input
              type="text"
              name="searchText"
              disabled
              placeholder="Search"
              value={""}
              onChange={(e: any) => {}}
              className="w-10/12 bg-transparent px-3 py-1 text-[14px] text-brand outline-none disabled:cursor-not-allowed"
            />
            <button
              type="button"
              disabled
              onClick={() => {}}
              className="px-3 py-1 text-black disabled:cursor-not-allowed"
            >
              <img src={searchImg} alt="search" className="h-[25px] w-[25px]" />
            </button>
          </div>
        </div>

        <div className="w-4/12">
          <div className="mb-[18px] grid grid-cols-2 gap-5">
            <DatePicker
              name="startDate"
              label="Start Date"
              value={query?.startDate}
              className={`flex h-12 w-full items-center justify-between rounded-[5px]  border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
              onChange={(e: any) => {
                onQueryChange(e);
              }}
            />

            <DatePicker
              name="endDate"
              label="End Date"
              value={query?.endDate}
              className={`flex h-12 w-full items-center justify-between rounded-[5px]  border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
              onChange={(e: any) => {
                onQueryChange(e);
              }}
            />
          </div>

          <SelectField
            selectStyle="!border-brand !shadow !text-[14px] !bg-brandLight !text-brand"
            name="category"
            defaultName="Select Market Category"
            defaultValue=""
            options={
              MARKETPLACE_CATEGORY?.length > 0
                ? [
                    ...MARKETPLACE_CATEGORY?.map((cat) => ({
                      name: cat?.name,
                      value: cat?.value,
                    })),
                  ]
                : []
            }
            disabled={module !== "marketplace"}
            value={query?.category}
            onChange={(e: any) => {
              onQueryChange(e);
            }}
          />
        </div>
      </div>

      <main className="my-[24px]">
        {notificationList?.notifications?.length > 0 ? (
          <>
            {notificationList?.notifications?.map(
              (notice: SystemNotification) => (
                <div
                  key={notice?.notificationId}
                  className="mb-[24px] flex items-center justify-between gap-5 rounded-[5px] border-[1px] border-brand bg-white px-4 py-3 shadow-md"
                >
                  <div className="flex w-2/3 items-center justify-between">
                    <div className="flex items-center gap-3">
                      <div>
                        <p className="mb-[5px]">{notice?.message}</p>
                        <p className="text-[14px] capitalize text-[#8E8787]">
                          Date: {getDateTime(notice?.createdAt)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-3">
                    <button
                      className={`${actionbtn} !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                      onClick={() => deleteNotification(notice?.notificationId)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ),
            )}

            <Pagination
              pageNumber={notificationList?.pageNumber}
              pageSize={notificationList?.pageSize}
              totalCount={notificationList?.totalCount}
              totalPage={notificationList?.totalPage}
              onFetch={fetchMore}
            />
          </>
        ) : (
          <div className="mb-[24px] flex items-center justify-between gap-5 rounded-[5px] border-[1px] border-brand bg-white px-4 py-3 shadow-md">
            <div>
              <p className="mb-[5px] font-[500] text-[#2E2626]">
                No notifications yet
              </p>
            </div>
          </div>
        )}
      </main>

      <Pagination
        pageNumber={notificationList?.pageNumber}
        pageSize={notificationList?.pageSize}
        totalCount={notificationList?.totalCount}
        totalPage={notificationList?.totalPage}
        onFetch={fetchMore}
      />
    </section>
  );
};

export default SystemNotifications;
