import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
  addLocation,
  addUpdateDeliveryArea,
  deleteDeliveryArea,
  deleteLocationById,
  getDeliveryArea,
  getDeliveryAreaById,
  getLocations,
  toggleLocation,
  updateLocation,
} from "../api/locationapi";
import notification from "core/helpers/notification";

type LocationState = {
  isLoading: boolean;
  locations: CampusLocation[];
  deliveryArea: DeliveryArea | any;
  deliveryAreaList: {
    areas: DeliveryArea[];
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPage: number;
  };
  getLocations: () => Promise<void>;
  addLocation: (location: NewCampusLocation) => Promise<UIResponse>;
  updateLocation: (location: CampusLocation) => Promise<UIResponse>;
  toggleLocation: (id: string, isActive: boolean) => Promise<void>;
  deleteLocation: (id: string) => Promise<void>;
  addUpdateDeliveryArea: (
    area: NewDeliveryArea,
    areaId: string,
  ) => Promise<UIResponse>;
  deleteDeliveryArea: (areaId: string) => Promise<void>;
  getDeliveryAreas: (
    query: DeliveryAreaQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  getDeliveryAreaById: (areaId: string) => Promise<void>;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  locations: [],
  deliveryArea: {},
  deliveryAreaList: {
    areas: [],
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPage: 0,
  },
};

const useLocationStore = create<LocationState>()(
  devtools(
    persist(
      (set, get): LocationState => ({
        ...defaultState,
        getLocations: async () => {
          set({ isLoading: true });
          var res: UIResponse = await getLocations();
          if (res?.data?.length > 0) {
            set({ locations: res?.data });
          }
          set({ isLoading: false });
        },
        addLocation: async (location: NewCampusLocation) => {
          set({ isLoading: true });
          var res = await addLocation(location);

          if (res?.isSuccessful) {
            set((state) => ({
              locations: [
                {
                  ...res?.data?.data,
                },
                ...state.locations,
              ],
            }));
          }

          notification({
            message: res?.isSuccessful ? res?.data?.message : res?.data?.title,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        updateLocation: async (location: CampusLocation) => {
          set({ isLoading: true });
          var res = await updateLocation(location);

          if (res?.isSuccessful) {
            set((state) => ({
              locations: state.locations.map((loc: CampusLocation) =>
                loc.id === location?.id
                  ? {
                      ...res?.data?.data,
                    }
                  : loc,
              ),
            }));
          }

          notification({
            message: res?.isSuccessful ? res?.data?.message : res?.data?.title,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        toggleLocation: async (id: string, isActive: boolean) => {
          set({ isLoading: true });
          var res = await toggleLocation(id, isActive);

          if (res?.isSuccessful) {
            set((state) => ({
              locations: state.locations.map((loc) =>
                loc.id === id
                  ? {
                      ...loc,
                      active: isActive,
                    }
                  : loc,
              ),
            }));
          }

          notification({
            message: res?.isSuccessful
              ? `location has been ${
                  isActive ? "activated" : "deactivated"
                } successfully`
              : `process could not be completed`,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return;
        },
        deleteLocation: async (id: string) => {
          set({ isLoading: true });
          var res = await deleteLocationById(id);

          if (res?.isSuccessful) {
            set((state) => ({
              locations: state.locations.filter((loc) => loc.id !== id),
            }));
          }

          notification({
            message: res?.isSuccessful
              ? `location has been deleted successfully`
              : `process could not be completed`,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return;
        },
        getDeliveryAreas: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getDeliveryArea(
            query,
            pageNumber,
            pageSize,
          );

          if (res?.isSuccessful) {
            set({
              deliveryAreaList: {
                areas: res?.data?.data?.data,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: res?.data?.data?.pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
        },
        getDeliveryAreaById: async (areaId) => {
          set({ isLoading: true });

          var res: UIResponse = await getDeliveryAreaById(areaId);

          if (res?.isSuccessful) {
            set({ deliveryArea: res?.data?.data });
          }

          set({ isLoading: false });
        },
        addUpdateDeliveryArea: async (area, areaId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateDeliveryArea(area, areaId);

          if (res?.isSuccessful) {
            get().getDeliveryAreas(
              {
                category: area?.category,
                endDate: "",
                startDate: "",
                subCategory: "",
                locationFilter: "",
              },
              1,
              15,
            );
          } else {
            set({ isLoading: false });
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        deleteDeliveryArea: async (areaId) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteDeliveryArea(areaId);

          if (res?.isSuccessful) {
            set((state) => ({
              deliveryAreaList: {
                ...state.deliveryAreaList,
                areas: state?.deliveryAreaList.areas?.filter(
                  (area: any) => area?.areaId !== areaId,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Area has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        reset: () => {
          set({ ...defaultState });
        },
      }),
      {
        name: "locationStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useLocationStore;
