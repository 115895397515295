import { ADMIN_ACTION } from "core/consts/systemConst";
import { apicall } from "./apicall";

export const getAdmins = (locationFilter: string) => {
  return apicall({
    endpoint: "/manage/administrators",
    method: "GET",
    pQuery: {
      locationFilter,
    },
    auth: true,
  });
};

export const getAdminById = (id: string) => {
  return apicall({
    endpoint: "/manage/administrator",
    param: id,
    method: "GET",
    auth: true,
  });
};

export const manageAdmin = (id: string, action: ManageActions) => {
  return apicall({
    endpoint: `/manage/administrator/${action}`,
    param: id,
    method: action === ADMIN_ACTION.remove ? "DELETE" : "PATCH",
    auth: true,
  });
};

export const getSettlementLog = (query: LogQueryParam) => {
  return apicall({
    endpoint: `/manage/merchant/settlementlog`,
    pQuery: { ...query },
    method: "GET",
    auth: true,
  });
};

export const merchantExportToExcel = (logs: SettlementLog[]) => {
  return apicall({
    endpoint: `/manage/merchant/export_excel`,
    method: "POST",
    body: [...logs],
    responseType: "blob",
    auth: true,
  });
};

export const markMerchantAsPaid = (logs: SettlementLog[]) => {
  return apicall({
    endpoint: `/manage/merchant/makepayment`,
    method: "POST",
    body: [...logs],
    auth: true,
  });
};

// SUPPORT
export const getSupportPersonnels = () => {
  return apicall({
    endpoint: "/manage/support/getsupportpersonnels",
    method: "GET",
    auth: true,
  });
};

export const getSupportPersonnelById = (id: string) => {
  return apicall({
    endpoint: "/manage/support/getsupportpersonnel",
    param: id,
    method: "GET",
    auth: true,
  });
};

export const addSupportPersonnel = (newSupport: NewSupportPersonnel) => {
  return apicall({
    endpoint: "/manage/support/create_account",
    method: "POST",
    body: { ...newSupport },
    auth: true,
  });
};

export const updateSupportPersonnel = (support: SupportPersonnel) => {
  return apicall({
    endpoint: "/manage/support/update",
    param: support?.supportId,
    method: "PATCH",
    body: {
      email: support?.email,
      lastName: support?.lastName,
      firstName: support?.firstName,
    },
    auth: true,
  });
};

export const deleteSupportUser = (supportId: string) => {
  return apicall({
    endpoint: "/manage/deleteSupportUser",
    param: supportId,
    method: "DELETE",
    auth: true,
  });
};

// FUNCTIONALITY MANAGEMENT
export const getFunctionality = () => {
  return apicall({
    endpoint: "/manage/functionalitymanagement",
    method: "GET",
    auth: true,
  });
};

export const manageFunctionality = (id: string, isActive: boolean) => {
  return apicall({
    endpoint: `/manage/${
      isActive
        ? "activatefunctionalitymanagement"
        : "deactivatefunctionalitymanagement"
    }`,
    param: id,
    method: "PATCH",
    auth: true,
  });
};

// roles
export const getRoles = () => {
  return apicall({
    endpoint: "/manage/roles",
    method: "GET",
    auth: true,
  });
};

//privilege
export const getPrivilege = () => {
  return apicall({
    endpoint: "/manage/privilege",
    method: "GET",
    auth: true,
  });
};

export const addPrivilege = (name: string) => {
  return apicall({
    endpoint: "/manage/privilege",
    method: "POST",
    body: { name },
    auth: true,
  });
};

export const updatePrivilege = (id: string, name: string) => {
  return apicall({
    endpoint: "/manage/privilege",
    param: id,
    method: "PATCH",
    body: { name },
    auth: true,
  });
};

export const deletePrivilege = (id: string) => {
  return apicall({
    endpoint: "/manage/privilege",
    param: id,
    method: "DELETE",
    auth: true,
  });
};

// roles with privileges
export const getRolePrivileges = () => {
  return apicall({
    endpoint: "/manage/rolesprivileges",
    method: "GET",
    auth: true,
  });
};

export const addRolePrivileges = ({
  roleId,
  privilegeId,
}: {
  roleId: string;
  privilegeId: string[];
}) => {
  return apicall({
    endpoint: "/manage/roleprivileges",
    method: "POST",
    body: {
      roleId,
      privilegeId,
    },
    auth: true,
  });
};

export const deleteRolePrivileges = (id: string) => {
  return apicall({
    endpoint: "/manage/roleprivileges",
    param: id,
    method: "DELETE",
    auth: true,
  });
};

export const assignRole = (userId: string, roleId: string) => {
  return apicall({
    endpoint: "/manage/assignrole",
    method: "POST",
    body: {
      userId,
      roleId,
    },
    auth: true,
  });
};
