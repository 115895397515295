import { home, waves } from "core/consts/images";
import { USER_ROLES } from "core/consts/systemConst";
import notification from "core/helpers/notification";
import useIdleTimer from "core/helpers/useIdleTimer";
import useAccommodationStore from "core/services/stores/useAccommodationStore";
import useAdminStore from "core/services/stores/useAdminStore";
import useAdvertStore from "core/services/stores/useAdvertStore";
import useAnalyticStore from "core/services/stores/useAnalyticStore";
import useEngineeringStore from "core/services/stores/useEngineeringStore";
import useFoodStuffStore from "core/services/stores/useFoodStuffStore";
import useLaundryStore from "core/services/stores/useLaundryStore";
import useLocationStore from "core/services/stores/useLocationStore";
import useMarketPlaceStore from "core/services/stores/useMarketPlaceStore";
import useNotificationStore from "core/services/stores/useNotificationStore";
import useReferralStore from "core/services/stores/useReferralStore";
import useSupportStore from "core/services/stores/useSupportStore";
import useTransportationStore from "core/services/stores/useTransportationStore";
import useUserStore from "core/services/stores/useUserStore";
import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import logout from "assets/icons/logout.png";
import { getRoutes } from "core/helpers/getRoutes";
import useSmsVendorStore from "core/services/stores/useSmsVendorStore";

export default function ModuleLayout({
  routes = [],
  defaultRoute = "",
}: {
  routes: RoutesType[];
  defaultRoute: string;
}) {
  const resetAccommodationStore = useAccommodationStore((store) => store.reset);
  const resetAdminStore = useAdminStore((store) => store.reset);
  const resetAdvertStore = useAdvertStore((store) => store.reset);
  const resetAnalyticStore = useAnalyticStore((store) => store.reset);
  const resetEngineeringStore = useEngineeringStore((store) => store.reset);
  const resetFoodStuffStore = useFoodStuffStore((store) => store.reset);
  const resetLaundryStore = useLaundryStore((store) => store.reset);
  const resetLocationStore = useLocationStore((store) => store.reset);
  const resetMarketPlaceStore = useMarketPlaceStore((store) => store.reset);
  const resetNotificationStore = useNotificationStore((store) => store.reset);
  const resetReferralStore = useReferralStore((store) => store.reset);
  const resetSupportStore = useSupportStore((store) => store.reset);
  const resetUserStore = useUserStore((store) => store.reset);
  const resetTransportationStore = useTransportationStore(
    (store) => store.reset,
  );
  const resetSmsVendorStore = useSmsVendorStore((store) => store.reset);

  const user: any = useUserStore((store) => store.user);

  const navigate = useNavigate();

  const signout = (isExpired: boolean = false) => {
    resetUserStore();
    resetAdminStore();
    resetLocationStore();
    resetReferralStore();
    resetSupportStore();
    resetAnalyticStore();
    resetNotificationStore();
    resetLaundryStore();
    resetMarketPlaceStore();
    resetEngineeringStore();
    resetAccommodationStore();
    resetFoodStuffStore();
    resetAdvertStore();
    resetSmsVendorStore();
    resetTransportationStore();

    localStorage.clear();
    sessionStorage.clear();

    if (isExpired) {
      notification({
        title: "SESSION TIMEOUT",
        type: "warning",
        message: "You have been logged out automatically, due to inactivity.",
      });
    }

    navigate("/");
  };

  useIdleTimer({ timeout: 15 * 60 * 1000, onInactive: () => signout(true) });

  return (
    <>
      <div className="relative m-0 w-full">
        <div className="fixed top-0 h-[100px] w-full" style={{ zIndex: 50 }}>
          <img
            src={waves}
            alt=""
            loading="lazy"
            className="mt-0 h-[100px] sm:h-auto md:mt-[-30px] lg:mt-[-60px]"
          />
        </div>
        <div
          style={{
            zIndex: 500,
            minHeight: "100vh",
          }}
          className="flex h-full w-full"
        >
          <div
            className="fixed top-0 h-full w-[20%] overflow-auto  bg-white px-[16px] py-[10%]"
            style={{ zIndex: 200 }}
          >
            {routes?.length > 0 && (
              <>
                {routes
                  .filter((route) => route.name?.length > 1)
                  ?.map((route: any, index: number) => (
                    <Link
                      className="mb-2 flex items-center gap-5  px-2 py-3 font-[400] text-[#7B7777] hover:font-bold hover:text-brand"
                      key={route?.path + index}
                      to={route.layout + "/" + route.path}
                    >
                      <img
                        src={route?.icon}
                        alt=""
                        className="w-auto"
                        loading="lazy"
                      />
                      {route?.name}
                    </Link>
                  ))}

                {routes[0]?.layout !== "/superadmin" &&
                  user?.category === USER_ROLES.super_admin && (
                    <Link
                      className="mb-2 flex items-center gap-5  px-2 py-3 font-[400] text-[#7B7777] hover:font-bold hover:text-brand"
                      key="backToSuperadmin"
                      to="/superadmin/dashboard"
                    >
                      <img
                        src={home}
                        alt=""
                        className="w-auto"
                        loading="lazy"
                      />
                      Back to Superadmin
                    </Link>
                  )}
              </>
            )}

            <button
              onClick={() => signout()}
              className="mb-2 flex items-center gap-5  px-2 py-3 font-[400] text-[#7B7777] hover:font-bold hover:text-brand"
            >
              <img src={logout} alt="" loading="lazy" className="w-auto" />
              Logout
            </button>
          </div>
          <div className="ml-[20%] w-[80%] pt-[10%]" style={{ zIndex: 200 }}>
            <Routes>
              {getRoutes(routes)}
              <Route
                path="/"
                element={<Navigate to={defaultRoute} replace />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}
