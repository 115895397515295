/* eslint-disable eqeqeq */
import Detail from "core/components/Detail";
import { back, viewOrderImg } from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import { ORDER_STATUS } from "core/consts/systemConst";
import {
  formatCurrency,
  getDateTime,
  getStatusBackgroundColor,
} from "core/helpers/generalHelpers";
import useEngineeringStore from "core/services/stores/useEngineeringStore";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ViewOrderDetails = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();

  const order = useEngineeringStore((store) => store.order);
  const getOrderAction = useEngineeringStore((store) => store.getOrderById);
  const resetOrder = useEngineeringStore((store) => store.resetOrder);

  useEffect(() => {
    if (orderId != null && orderId?.length > 1) {
      getOrderAction(orderId!);
    } else {
      resetOrder();
      navigate("/engineering/view-orders");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="mx-auto mb-[28px] w-[95%]">
      <div className="flex items-center justify-between">
        <header className="flex items-center gap-3">
          <img src={viewOrderImg} alt="" loading="lazy" />
          <h5 className="text-[18px] font-[500] text-brand">
            Order from {order?.customerName} - {order?.status}
          </h5>
        </header>

        <button
          onClick={() => {
            resetOrder();
            navigate("/engineering/view-orders");
          }}
          className="flex w-[100px] items-center justify-between px-4 py-2 text-[14px] text-brand"
        >
          <img src={back} loading="lazy" alt="" />
          <span>Back</span>
        </button>
      </div>

      <div className="my-[16px] flex items-center justify-between gap-5">
        <div className="flex justify-start gap-3 rounded-[25px]">
          <div className={`${actionbtn} bg-[#F2EAF8] shadow-md`}>
            Receipt Number: {order?.receiptNumber}
          </div>
        </div>
      </div>

      <section className="mb-[32px]">
        <Detail name="Customer Name" value={order?.customerName} />
        <Detail name="Customer ID" value={order?.customerId} />
        <Detail
          name="Customer Phone number"
          value={order?.customerPhoneNumber}
        />
        <Detail
          name="Customer Email"
          style={`!lowercase`}
          value={order?.customerEmailAddress}
        />
        <Detail name="Order Time" value={getDateTime(order!?.createdAt)} />
        <Detail
          name="Processing Time"
          value={order?.processedAt ? getDateTime(order!?.processedAt) : "N/A"}
        />
      </section>

      <section className="mb-[32px]">
        <div className="flex justify-start gap-3 rounded-[25px]">
          <div className={`${actionbtn} bg-[#F2EAF8] shadow-md`}>
            Module Info
          </div>
        </div>

        <Detail name="Market Category" value={order?.category} />
        <Detail name="Service Provider" value={order?.serviceProvider} />
      </section>

      <section className="my-[32px]">
        <div className="flex justify-start gap-3 rounded-[25px]">
          <div className={`${actionbtn} bg-[#F2EAF8] shadow-md`}>
            Order Breakdown
          </div>
        </div>

        <Detail name="Appliance Type" value={order?.applianceType} />
        <Detail name="Engineer Name" value={order?.engineerName} />
      </section>

      <section className="mb-[32px]">
        <div className="flex justify-start gap-3 rounded-[25px]">
          <div className={`${actionbtn} bg-[#F2EAF8] shadow-md`}>
            Billing Detail
          </div>
        </div>

        <Detail
          name="Transaction Amount"
          value={formatCurrency(order?.transactionAmount)}
        />
        <Detail
          name="Convenience Fee"
          value={formatCurrency(order?.convenienceFee)}
        />
        <Detail
          name="Total Amount"
          value={formatCurrency(order?.totalAmount)}
        />
      </section>

      <section className="mb-[32px]">
        <div className="flex justify-start gap-3 rounded-[25px]">
          <div
            className={`${actionbtn} bg-[#F2EAF8] shadow-md ${
              order?.status && getStatusBackgroundColor(order!?.status)
            }`}
          >
            Status Detail - {order?.status}
          </div>
        </div>
        <Detail
          name="Error Message"
          value={order?.errorMessage ? order?.errorMessage : "N/A"}
        />
        {order?.status?.toLowerCase() ==
          ORDER_STATUS.cancelled?.toLowerCase() && (
          <>
            <Detail name="Cancelled By" value={order?.cancelledBy} />{" "}
            <Detail
              name="Cancelled At"
              value={
                order?.cancelledAt != null
                  ? getDateTime(order?.cancelledAt)
                  : "N/A"
              }
            />{" "}
            <Detail
              name="Reason For Cancelling"
              value={order?.reasonForCancelling}
            />
          </>
        )}
      </section>

      <div className="flex justify-end">
        <button
          onClick={() => {
            resetOrder();
            navigate("/engineering/view-orders");
          }}
          className="flex items-center justify-between px-4 py-2 text-[14px] text-brand"
        >
          <img src={back} loading="lazy" alt="" />
          <span>Back To View Order</span>
        </button>
      </div>
    </section>
  );
};

export default ViewOrderDetails;
