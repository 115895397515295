import SelectField from "core/components/formfields/SelectField";
import { addIcon, fastFoodImg, searchImg } from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import { MARKETPLACE_CATEGORY } from "core/consts/systemConst";
import { formatCurrency, getDateTime } from "core/helpers/generalHelpers";
import useMarketPlaceStore from "core/services/stores/useMarketPlaceStore";
import useUserStore from "core/services/stores/useUserStore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const FastFoodVendors = () => {
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const vendors = useMarketPlaceStore((store) => store.fastFoodVendors);
  const getVendorsAction = useMarketPlaceStore(
    (store) => store.getFastFoodVendors,
  );
  const deleteVendorAction = useMarketPlaceStore(
    (store) => store.deleteFastFoodVendor,
  );
  const [searchText, setSearchText] = useState("");

  const deleteVendor = async (vendor: FastFoodVendor) => {
    if (window.confirm(`Click 'OK' to delete ${vendor?.vendorName}.`)) {
      await deleteVendorAction(vendor?.vendorId);
    }
  };

  useEffect(() => {
    getVendorsAction();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="mx-auto mb-[28px] w-[95%]">
      <div className="mb-[24px] flex items-center justify-between">
        <header className="flex items-center gap-3">
          <img src={fastFoodImg} alt="active module" loading="lazy" />
          <h5 className="text-[18px] font-[500] text-brand">
            Fast Food Vendors
          </h5>
        </header>

        <Link
          to={"/marketplace/fast-food-vendors/create-vendor"}
          className="flex w-[180px] items-center justify-between px-4 py-3 text-[14px] text-brand"
        >
          <img src={addIcon} loading="lazy" alt="" />
          <span>Add New Vendor</span>
        </Link>
      </div>

      <div className="flex items-center justify-between gap-5">
        <div className="flex h-12 w-3/5 items-center justify-between gap-3 rounded-l-[25px] rounded-r-[5px] border-[1px] border-brand bg-white px-2 shadow-md">
          <input
            type="text"
            name="searchText"
            disabled
            placeholder="Search"
            value={searchText}
            onChange={(e: any) => setSearchText(e?.target?.value)}
            className="w-10/12 bg-transparent px-3 py-1 text-[14px] text-brand outline-none disabled:cursor-not-allowed"
          />
          <button
            type="button"
            disabled
            onClick={() => {}}
            className="px-3 py-1 text-black disabled:cursor-not-allowed"
          >
            <img src={searchImg} alt="search" className="h-[25px] w-[25px]" />
          </button>
        </div>

        <div className="w-1/5">
          <SelectField
            selectStyle="!border-brand !shadow !text-[14px] !bg-brandLight !text-brand"
            name="category"
            disabled
            defaultName="Filter by"
            defaultValue=""
            options={[
              {
                name: "Hide",
                value: "Hide",
              },
              {
                name: "Show",
                value: "Show",
              },
            ]}
            value={searchText}
            onChange={(e: any) => {}}
          />
        </div>

        <div className="w-1/5">
          <SelectField
            selectStyle="!border-brand !shadow !text-[14px] !rounded-r-[25px]"
            name="category"
            disabled
            defaultName="Select Market Category"
            defaultValue=""
            options={
              MARKETPLACE_CATEGORY?.length > 0
                ? [
                    ...MARKETPLACE_CATEGORY?.map((cat) => ({
                      name: cat?.name,
                      value: cat?.value,
                    })),
                  ]
                : []
            }
            value={searchText}
            onChange={(e: any) => {}}
          />
        </div>
      </div>

      <main className="my-[24px]">
        {vendors?.length > 0 ? (
          vendors.map((vendor: FastFoodVendor) => (
            <div
              key={vendor?.vendorId}
              className="mb-[24px] flex items-center justify-between gap-5 rounded-[5px] border-[1px] border-brand bg-white px-4 py-3 shadow-md"
            >
              <div className="flex w-2/3 items-center gap-3">
                <img
                  src={vendor?.logo}
                  alt={vendor?.vendorName}
                  className="h-[68px] w-[100px]"
                  loading="lazy"
                />
                <div>
                  <p className="mb-[5px] font-[500] capitalize text-brand">
                    {vendor?.vendorName} ({vendor?.rating}*)
                  </p>
                  <p className="text-[14px] capitalize text-[#8E8787]">
                    Convenience Fee: {formatCurrency(vendor?.convenienceFee)}
                  </p>{" "}
                  <p className="text-[14px] capitalize text-[#8E8787]">
                    Address: {vendor?.address}
                  </p>{" "}
                  <p className="text-[14px] capitalize text-[#8E8787]">
                    Opening Period: {vendor?.operationTime}{" "}
                    {vendor?.operationDays}
                  </p>{" "}
                  <p className="text-[14px] capitalize text-[#8E8787]">
                    Ratings: {vendor?.rating}
                  </p>
                  <p className="text-[14px] capitalize text-[#8E8787]">
                    Added on: {getDateTime(vendor?.createdAt)}
                  </p>
                </div>
              </div>
              <div className="flex gap-3">
                <Link
                  to={`/marketplace/fast-food-vendors/update-vendor?vendorId=${vendor?.vendorId}`}
                  className={`${actionbtn} !border-[#6C18A4] !bg-[#DFA1F4]`}
                  onClick={() => {}}
                >
                  Update
                </Link>
                <button
                  disabled={isSuperAdmin}
                  className={`${actionbtn} !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                  onClick={() => deleteVendor(vendor)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="mb-[24px] flex items-center justify-between gap-5 rounded-[5px] border-[1px] border-brand bg-white px-4 py-3 shadow-md">
            <div>
              <p className="mb-[5px] font-[500] text-[#2E2626]">
                No vendor yet
              </p>
            </div>
          </div>
        )}
      </main>
    </section>
  );
};

export default FastFoodVendors;
