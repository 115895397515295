import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import notification from "core/helpers/notification";
import {
  addUpdateSmsVendor,
  deleteSmsVendor,
  getSmsVendor,
  updateSmsVendorStatus,
} from "../api/smsManagement.api";

type State = {
  isLoading: boolean;
  vendors: SmsVendor[];
  getVendors: () => Promise<void>;
  addUpdateVendor: (name: string, vendorId: string) => Promise<UIResponse>;
  updateVendorStatus: (
    vendorId: string,
    status: boolean,
    commission: boolean,
  ) => Promise<UIResponse>;
  deleteVendor: (id: string) => Promise<void>;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  vendors: [],
};

const useSmsVendorStore = create<State>()(
  devtools(
    persist(
      (set, get): State => ({
        ...defaultState,
        getVendors: async () => {
          set({ isLoading: true });
          var res: UIResponse = await getSmsVendor();
          if (res?.data?.length > 0) {
            set({ vendors: res?.data });
          }
          set({ isLoading: false });
        },
        updateVendorStatus: async (
          vendorId: string,
          status: boolean,
          commission: boolean,
        ) => {
          set({ isLoading: true });
          var res = await updateSmsVendorStatus(vendorId, status, commission);

          if (res?.isSuccessful) {
            set({
              vendors: [...res?.data?.data],
            });
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message ?? res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        deleteVendor: async (vendorId: string) => {
          set({ isLoading: true });
          var res = await deleteSmsVendor(vendorId);

          if (res?.isSuccessful) {
            set((state) => ({
              vendors: state.vendors.filter((vendor) => vendor.id !== vendorId),
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "SMS vendor deleted successfully"
              : res?.data?.error?.message ?? res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return;
        },
        addUpdateVendor: async (name, vendorId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateSmsVendor(name, vendorId);

          if (res?.isSuccessful) {
            set({
              vendors: [...res?.data?.data],
            });
          }

          set({ isLoading: false });

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message ?? res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },

        reset: () => {
          set({ ...defaultState });
        },
      }),
      {
        name: "smsVendorStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useSmsVendorStore;
