import Accordion from "core/components/Accordion";
import Modal from "core/components/Modal";
import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import ActionRow from "core/components/table/Actionrow";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import {
  deleteIcon,
  editIcon,
  eyeclose,
  eyeopen,
  users,
} from "core/consts/images";
import { actionbtn, btn } from "core/consts/styling";
import { REWARD_TYPES } from "core/consts/systemConst";
import {
  addSpaceToCamelCase,
  formatSimpleDate,
  isEmail,
} from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useLocationStore from "core/services/stores/useLocationStore";
import useReferralStore from "core/services/stores/useReferralStore";
import { useEffect, useState } from "react";

const ReferralSettings = () => {
  const locations = useLocationStore((store) => store.locations);
  const rewards = useReferralStore((store) => store.rewards);
  const rewardTypes = useReferralStore((store) => store.rewardTypes);

  const getLocationAction = useLocationStore((store) => store.getLocations);
  const getRewardsAction = useReferralStore(
    (store) => store.getReferralRewardsForUser,
  );
  const getRewardTypesAction = useReferralStore(
    (store) => store.getReferralRewardTypes,
  );
  const createRewardAction = useReferralStore(
    (store) => store.createReferralReward,
  );
  const manageRewardAction = useReferralStore(
    (store) => store.manageReferralReward,
  );
  const deleteRewardAction = useReferralStore(
    (store) => store.deleteReferralReward,
  );
  const updateReferralAction = useReferralStore(
    (store) => store.updateReferralReward,
  );

  const [errors, setErrors] = useState({
    RewardType: [""],
    InitialBonus: [""],
    MainBonus: [""],
    DiscountValue: [""],
    DiscountCount: [""],
    LocationId: [""],
  });

  const resetErrors = () => {
    setErrors({
      RewardType: [""],
      InitialBonus: [""],
      MainBonus: [""],
      DiscountValue: [""],
      DiscountCount: [""],
      LocationId: [""],
    });
  };

  // REWARDS
  const [newReward, setNewReward] = useState<NewReferralReward>({
    rewardType: "",
    initialBonus: 0,
    mainBonus: 0,
    discountValue: 0,
    discountCount: 0,
    locationId: "",
  });
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedReward, setSelectedReward] = useState<any>({});

  const validateReward = (data: NewReferralReward) => {
    var isValid = true;

    if (data?.rewardType?.length < 1) {
      setErrors((state: any) => ({
        ...state,
        RewardType: ["Reward Type is required"],
      }));
      isValid = false;
    }

    if (data?.locationId?.length < 1) {
      setErrors((state: any) => ({
        ...state,
        LocationId: ["Please select a location"],
      }));
      isValid = false;
    }

    return isValid;
  };

  // AGENCIES
  const agencies = useReferralStore((store) => store.agencies);
  const getAgenciesAction = useReferralStore((store) => store.getAgencies);
  const addAgencyAction = useReferralStore((store) => store.addAgency);
  const updateAgencyAction = useReferralStore((store) => store.updateAgency);
  const deleteAgencyAction = useReferralStore((store) => store.deleteAgency);

  const [newAgency, setNewAgency] = useState<NewAgency>({
    agencyName: "",
    email: "",
    fullName: "",
    locationId: "",
    phoneNumber: "",
    referralCode: "",
    rewardAmount: 0,
  });
  const [selectedAgency, setSelectedAgency] = useState<any>();
  const [showUpdateAgency, setShowUpdateAgency] = useState(false);

  const [agencyErrors, setAgencyErrors] = useState<any>({
    AgencyName: [""],
    Email: [""],
    FullName: [""],
    LocationId: [""],
    PhoneNumber: [""],
    ReferralCode: [""],
    RewardAmount: [""],
  });

  const resetAgencyError = () => {
    setAgencyErrors({
      AgencyName: [""],
      Email: [""],
      FullName: [""],
      LocationId: [""],
      PhoneNumber: [""],
      ReferralCode: [""],
      RewardAmount: [""],
    });
  };

  const validateAgency = (data: NewAgency) => {
    var isValid = true;

    if (data?.agencyName?.length < 1) {
      setAgencyErrors((state: any) => ({
        ...state,
        AgencyName: ["Agency name is required"],
      }));
      isValid = false;
    }

    if (!isEmail(data?.email)) {
      setAgencyErrors((state: any) => ({
        ...state,
        Email: ["Please provide a valid email address"],
      }));
      isValid = false;
    }

    if (data?.locationId?.length < 1) {
      setAgencyErrors((state: any) => ({
        ...state,
        LocationId: ["Please choose a location"],
      }));
      isValid = false;
    }

    if (data?.referralCode?.length < 1) {
      setAgencyErrors((state: any) => ({
        ...state,
        ReferralCode: ["Referral Code is required"],
      }));
      isValid = false;
    }

    if (data?.fullName?.length < 1) {
      setAgencyErrors((state: any) => ({
        ...state,
        FullName: ["Full name is required"],
      }));
      isValid = false;
    }

    if (data?.phoneNumber?.length < 1) {
      setAgencyErrors((state: any) => ({
        ...state,
        PhoneNumber: ["Phone Number is required"],
      }));
      isValid = false;
    }

    return isValid;
  };

  const onFormChange = (e: any, action: string = "add") => {
    const { name, value } = e?.target;

    switch (action) {
      case "add":
        setNewReward((state) => ({
          ...state,
          [name]: value,
        }));
        break;
      case "update":
        setSelectedReward((state: any) => ({
          ...state,
          [name]: value,
        }));
        break;
      case "addAgency":
        setNewAgency((state) => ({
          ...state,
          [name]: value,
        }));
        break;
      case "updateAgency":
        setSelectedAgency((state: any) => ({
          ...state,
          [name]: value,
        }));
        break;
      default:
        break;
    }
  };

  const addReward = async (e: any) => {
    e.preventDefault();

    if (validateReward(newReward)) {
      var res = await createRewardAction(
        newReward,
        locations?.find((loc) => loc?.id === newReward?.locationId)!,
      );

      if (res?.isSuccessful) {
        setNewReward({
          rewardType: "",
          initialBonus: 0,
          mainBonus: 0,
          discountValue: 0,
          discountCount: 0,
          locationId: "",
        });
      } else {
        if (res?.data?.errors != null) {
          setErrors(res?.data?.errors);
        }
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const updateReward = async (e: any) => {
    e.preventDefault();

    if (validateReward(selectedReward)) {
      var res = await updateReferralAction(selectedReward?.id, selectedReward);

      if (!res?.isSuccessful && res?.data?.errors != null) {
        setErrors(res?.data?.errors);
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const manageReward = async (reward: ReferralReward) => {
    if (
      window.confirm(
        `Click 'OK' to ${
          reward?.isActive ? "deactivate" : "activate"
        } this referral reward`,
      )
    ) {
      await manageRewardAction(reward?.id, !reward?.isActive);
    }
  };

  const deleteReward = async (reward: ReferralReward) => {
    if (window.confirm(`Click 'OK' to delete this reward`)) {
      await deleteRewardAction(reward?.id);
    }
  };

  const addAgency = async (e: any) => {
    e.preventDefault();

    if (validateAgency(newAgency)) {
      var res = await addAgencyAction(newAgency);

      if (res?.isSuccessful) {
        setNewAgency({
          agencyName: "",
          email: "",
          fullName: "",
          locationId: "",
          phoneNumber: "",
          referralCode: "",
          rewardAmount: 0,
        });
      } else {
        if (res?.data?.errors != null) {
          setAgencyErrors((state: any) => ({ ...state, ...res?.data?.errors }));
        }
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const updateAgency = async (e: any) => {
    e.preventDefault();

    if (validateAgency(selectedAgency)) {
      var res = await updateAgencyAction(selectedAgency?.id, selectedAgency);

      if (!res?.isSuccessful && res?.data?.errors != null) {
        setAgencyErrors((state: any) => ({ ...state, ...res?.data?.errors }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const deleteAgency = async (agency: Agency) => {
    if (window.confirm(`Click 'OK' to delete ${agency?.agencyName}`)) {
      await deleteAgencyAction(agency?.id);
    }
  };
  
  useEffect(() => {
    getAgenciesAction();
    locations?.length < 1 && getLocationAction();
    rewards?.length < 1 && getRewardsAction();
    rewardTypes?.length < 1 && getRewardTypesAction();

    setNewReward((state) => ({
      ...state,
      rewardType: rewardTypes?.length > 0 ? rewardTypes[0]?.rewardType : "",
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mx-auto w-[95%]">
        <header className="mb-[28px] flex items-center gap-3">
          <img src={users} loading="lazy" alt="" />
          <h5 className="text-[18px] font-[500] text-brand">
            Referral Program /<span className="font-[400]">Settings</span>
          </h5>
        </header>

        <section className="mb-[28px] w-full">
          <Accordion id="rewards" title="Manage Rewards">
            <section className="mb-[28px] flex w-full flex-col gap-5 overflow-x-scroll lg:flex-row">
              <div className="w-full lg:w-3/12">
                <form
                  className="rounded-[5px] bg-white p-5 "
                  onSubmit={addReward}
                >
                  <SelectField
                    boxStyle="mb-[24px]"
                    name="locationId"
                    label="Location"
                    defaultName="Select Location"
                    defaultValue=""
                    options={
                      locations?.length > 0
                        ? [
                            ...locations.map((loc) => ({
                              name: loc?.name,
                              value: loc?.id,
                            })),
                          ]
                        : []
                    }
                    value={newReward?.locationId}
                    onChange={(e: any) => onFormChange(e, "add")}
                    error={errors?.LocationId[0]}
                    onBlur={() => {
                      setErrors((state: any) => ({
                        ...state,
                        LocationId: [],
                      }));
                    }}
                  />

                  <SelectField
                    boxStyle="mb-[24px]"
                    name="rewardType"
                    label="Reward Type"
                    defaultName="Select Reward Type"
                    defaultValue=""
                    options={
                      rewardTypes?.length > 0
                        ? [
                            ...rewardTypes.map((type) => ({
                              name: addSpaceToCamelCase(type?.rewardType),
                              value: type?.rewardType,
                            })),
                          ]
                        : []
                    }
                    value={newReward?.rewardType}
                    onChange={(e: any) => onFormChange(e, "add")}
                    error={errors?.RewardType[0]}
                    onBlur={() => {
                      setErrors((state: any) => ({
                        ...state,
                        RewardType: [],
                      }));
                    }}
                  />

                  {newReward?.rewardType.toLowerCase() ===
                    REWARD_TYPES.referrerbonus && (
                    <>
                      <InputField
                        boxStyle="mb-[16px]"
                        label="Initial Bonus"
                        placeholder="Initial Bonus"
                        name="initialBonus"
                        type="number"
                        value={newReward?.initialBonus}
                        onChange={(e: any) => onFormChange(e, "add")}
                        error={errors?.InitialBonus[0]}
                        onBlur={() => {
                          setErrors((state: any) => ({
                            ...state,
                            InitialBonus: [],
                          }));
                        }}
                      />

                      <InputField
                        boxStyle="mb-[16px]"
                        placeholder="Main Bonus"
                        label="Main Bonus"
                        name="mainBonus"
                        value={newReward?.mainBonus}
                        onChange={(e: any) => onFormChange(e, "add")}
                        error={errors?.MainBonus[0]}
                        onBlur={() => {
                          setErrors((state: any) => ({
                            ...state,
                            MainBonus: [],
                          }));
                        }}
                      />
                    </>
                  )}

                  {newReward?.rewardType?.toLowerCase() ===
                    REWARD_TYPES.referreduserdiscount && (
                    <>
                      <InputField
                        boxStyle="mb-[16px]"
                        placeholder="Discount Value"
                        label="Discount Value"
                        name="discountValue"
                        type="number"
                        value={newReward?.discountValue}
                        onChange={(e: any) => onFormChange(e, "add")}
                        error={errors?.DiscountValue[0]}
                        onBlur={() => {
                          setErrors((state: any) => ({
                            ...state,
                            DiscountValue: [],
                          }));
                        }}
                      />

                      <InputField
                        boxStyle="mb-[16px]"
                        label="Count of Prize"
                        placeholder="Count of Prize"
                        name="discountCount"
                        type="number"
                        value={newReward?.discountCount}
                        onChange={(e: any) => onFormChange(e, "add")}
                        error={errors?.DiscountCount[0]}
                        onBlur={() => {
                          setErrors((state: any) => ({
                            ...state,
                            DiscountCount: [],
                          }));
                        }}
                      />
                    </>
                  )}

                  <button className={`${btn} w-full bg-brand text-white`}>
                    Submit
                  </button>
                </form>
              </div>

              <div className="w-full lg:w-9/12">
                <Table
                  headers={[
                    "Reward Type",
                    "initial Bonus",
                    "Main Bonus",
                    "Discount Value",
                    "Discount Count",
                    "Location",
                    "Is Active",
                    "Action",
                  ]}
                >
                  {rewards?.length > 0 ? (
                    rewards?.map((reward) => (
                      <tr key={reward?.id}>
                        <Row
                          value={
                            reward?.rewardType?.length > 0 &&
                            addSpaceToCamelCase(reward?.rewardType)
                          }
                        />
                        <Row value={reward?.initialBonus} />
                        <Row value={reward?.mainBonus} />
                        <Row value={reward?.discountValue} />
                        <Row value={reward?.discountCount} />
                        <Row value={reward?.location} />
                        <Row value={reward?.isActive ? "Active" : "Inactive"} />
                        <ActionRow
                          style={`flex items-center justify-center gap-3`}
                        >
                          <button
                            className="flex w-[35px] items-center justify-center border-[1px] border-brand bg-[#F3CFFF] p-2"
                            onClick={() => manageReward(reward)}
                          >
                            <img
                              src={reward?.isActive ? eyeclose : eyeopen}
                              loading="lazy"
                              className="h-[16px]"
                              alt={reward?.isActive ? "deactivate" : "activate"}
                            />
                          </button>
                          <button
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#59960B] bg-[#D9FEAA] p-2"
                            onClick={() => {
                              resetErrors();
                              setSelectedReward({
                                ...reward,
                                locationId: locations.find(
                                  (x) => x.name === reward?.location,
                                )?.id,
                              });
                              setShowUpdateModal(true);
                            }}
                          >
                            <img
                              src={editIcon}
                              loading="lazy"
                              className="h-[16px]"
                              alt="edit"
                            />
                          </button>
                          <button
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#FF0000] bg-[#FFC8C8] p-2"
                            onClick={() => deleteReward(reward)}
                          >
                            <img
                              src={deleteIcon}
                              className="h-[16px]"
                              loading="lazy"
                              alt="delete"
                            />
                          </button>
                        </ActionRow>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <Row colSpan={6} value="" />
                    </tr>
                  )}
                </Table>
              </div>
            </section>
          </Accordion>
        </section>
        <section className="mb-[28px]">
          <Accordion id="agencies" title="Manage Influencers/Agency">
            <section className="mb-[28px] flex flex-col gap-5 overflow-x-scroll lg:flex-row">
              <div className="w-full lg:w-3/12">
                <form
                  className="rounded-[5px] bg-white p-5 "
                  onSubmit={addAgency}
                >
                  <SelectField
                    boxStyle="mb-[24px]"
                    name="locationId"
                    defaultName="Select Location"
                    defaultValue=""
                    options={
                      locations?.length > 0
                        ? [
                            ...locations.map((loc) => ({
                              name: loc?.name,
                              value: loc?.id,
                            })),
                          ]
                        : []
                    }
                    value={newAgency?.locationId}
                    onChange={(e: any) => onFormChange(e, "addAgency")}
                    error={agencyErrors?.LocationId[0]}
                    onBlur={() => {
                      setAgencyErrors((state: any) => ({
                        ...state,
                        LocationId: [],
                      }));
                    }}
                  />

                  <InputField
                    boxStyle="mb-[16px]"
                    placeholder="Influencer/Agency Name"
                    name="agencyName"
                    value={newAgency?.agencyName}
                    onChange={(e: any) => onFormChange(e, "addAgency")}
                    error={agencyErrors?.AgencyName[0]}
                    onBlur={() => {
                      setAgencyErrors((state: any) => ({
                        ...state,
                        AgencyName: [],
                      }));
                    }}
                  />

                  <InputField
                    boxStyle="mb-[16px]"
                    placeholder="Email address"
                    name="email"
                    value={newAgency?.email}
                    onChange={(e: any) => onFormChange(e, "addAgency")}
                    error={agencyErrors?.Email[0]}
                    onBlur={() => {
                      setAgencyErrors((state: any) => ({
                        ...state,
                        Email: [],
                      }));
                    }}
                  />

                  <InputField
                    boxStyle="mb-[16px]"
                    placeholder="Referral Code"
                    name="referralCode"
                    type="text"
                    value={newAgency?.referralCode}
                    onChange={(e: any) => onFormChange(e, "addAgency")}
                    error={agencyErrors?.ReferralCode[0]}
                    onBlur={() => {
                      setAgencyErrors((state: any) => ({
                        ...state,
                        ReferralCode: [],
                      }));
                    }}
                  />

                  <InputField
                    boxStyle="mb-[16px]"
                    placeholder="Full Name"
                    name="fullName"
                    value={newAgency?.fullName}
                    onChange={(e: any) => onFormChange(e, "addAgency")}
                    error={agencyErrors?.FullName[0]}
                    onBlur={() => {
                      setAgencyErrors((state: any) => ({
                        ...state,
                        FullName: [],
                      }));
                    }}
                  />

                  <InputField
                    boxStyle="mb-[16px]"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    value={newAgency?.phoneNumber}
                    onChange={(e: any) => onFormChange(e, "addAgency")}
                    error={agencyErrors?.PhoneNumber[0]}
                    onBlur={() => {
                      setAgencyErrors((state: any) => ({
                        ...state,
                        PhoneNumber: [],
                      }));
                    }}
                  />

                  <InputField
                    boxStyle="mb-[16px]"
                    placeholder="Onboarding Reward Amount"
                    name="rewardAmount"
                    type="number"
                    value={newAgency?.rewardAmount}
                    onChange={(e: any) => onFormChange(e, "addAgency")}
                    error={agencyErrors?.RewardAmount[0]}
                    onBlur={() => {
                      setAgencyErrors((state: any) => ({
                        ...state,
                        RewardAmount: [],
                      }));
                    }}
                  />

                  <button className={`${btn} w-full bg-brand text-white`}>
                    Submit
                  </button>
                </form>
              </div>
              <div className="w-full lg:w-9/12">
                <Table
                  headers={[
                    "Full Name",
                    "Referral Code",
                    "Location",
                    "Email",
                    "Phone No.",
                    "Date Created",
                    "Action",
                  ]}
                >
                  {agencies?.length > 0 ? (
                    agencies?.map((agency) => (
                      <tr key={agency?.id}>
                        <Row value={agency?.fullName} />
                        <Row value={agency?.referralCode} />
                        <Row value={agency?.location} />
                        <Row value={agency?.email} />
                        <Row value={agency?.phoneNumber} />
                        <Row value={formatSimpleDate(agency?.dateCreated)} />
                        <ActionRow
                          style={`flex items-center justify-center gap-3`}
                        >
                          <button
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#59960B] bg-[#D9FEAA] p-2"
                            onClick={() => {
                              resetAgencyError();
                              setSelectedAgency({
                                ...agency,
                              });
                              setShowUpdateAgency(true);
                            }}
                          >
                            <img
                              src={editIcon}
                              loading="lazy"
                              className="h-[16px]"
                              alt="edit"
                            />
                          </button>
                          <button
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#FF0000] bg-[#FFC8C8] p-2"
                            onClick={() => deleteAgency(agency)}
                          >
                            <img
                              src={deleteIcon}
                              className="h-[16px]"
                              loading="lazy"
                              alt="delete"
                            />
                          </button>
                        </ActionRow>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <Row colSpan={7} value="" />
                    </tr>
                  )}
                </Table>
              </div>
            </section>
          </Accordion>
        </section>
      </div>

      {showUpdateModal && (
        <Modal
          header="Update Referral Bonus"
          onClose={() => {
            setShowUpdateModal(false);
            setSelectedReward({});
            resetErrors();
          }}
        >
          <form onSubmit={updateReward}>
            <SelectField
              boxStyle="mb-[24px]"
              name="locationId"
              defaultName="Select Location"
              defaultValue=""
              disabled
              options={
                locations?.length > 0
                  ? [
                      ...locations.map((loc) => ({
                        name: loc?.name,
                        value: loc?.id,
                      })),
                    ]
                  : []
              }
              value={selectedReward?.locationId}
              onChange={(e: any) => onFormChange(e, "update")}
              error={errors?.LocationId[0]}
              onBlur={() => {
                setErrors((state: any) => ({
                  ...state,
                  LocationId: [],
                }));
              }}
            />
            <SelectField
              boxStyle="mb-[24px]"
              name="rewardType"
              defaultName="Select Reward Type"
              defaultValue=""
              disabled
              options={
                rewardTypes?.length > 0
                  ? [
                      ...rewardTypes.map((type) => ({
                        name: addSpaceToCamelCase(type?.rewardType),
                        value: type?.rewardType,
                      })),
                    ]
                  : []
              }
              value={selectedReward?.rewardType}
              onChange={(e: any) => onFormChange(e, "update")}
              error={errors?.RewardType[0]}
              onBlur={() => {
                setErrors((state: any) => ({
                  ...state,
                  RewardType: [],
                }));
              }}
            />

            {selectedReward?.rewardType.toLowerCase() ===
              REWARD_TYPES.referrerbonus && (
              <>
                <InputField
                  boxStyle="mb-[16px]"
                  label="Initial Bonus"
                  placeholder="Initial Bonus"
                  name="initialBonus"
                  type="number"
                  value={selectedReward?.initialBonus}
                  onChange={(e: any) => onFormChange(e, "update")}
                  error={errors?.InitialBonus[0]}
                  onBlur={() => {
                    setErrors((state: any) => ({
                      ...state,
                      InitialBonus: [],
                    }));
                  }}
                />
                <InputField
                  boxStyle="mb-[16px]"
                  placeholder="Main Bonus"
                  label="Main Bonus"
                  name="mainBonus"
                  value={selectedReward?.mainBonus}
                  onChange={(e: any) => onFormChange(e, "update")}
                  error={errors?.MainBonus[0]}
                  onBlur={() => {
                    setErrors((state: any) => ({
                      ...state,
                      MainBonus: [],
                    }));
                  }}
                />
              </>
            )}

            {selectedReward?.rewardType?.toLowerCase() ===
              REWARD_TYPES.referreduserdiscount && (
              <>
                <InputField
                  boxStyle="mb-[16px]"
                  placeholder="Discount Value"
                  label="Discount Value"
                  name="discountValue"
                  type="number"
                  value={selectedReward?.discountValue}
                  onChange={(e: any) => onFormChange(e, "update")}
                  error={errors?.DiscountValue[0]}
                  onBlur={() => {
                    setErrors((state: any) => ({
                      ...state,
                      DiscountValue: [],
                    }));
                  }}
                />
                <InputField
                  boxStyle="mb-[16px]"
                  label="Count of Prize"
                  placeholder="Count of Prize"
                  name="discountCount"
                  type="number"
                  value={selectedReward?.discountCount}
                  onChange={(e: any) => onFormChange(e, "update")}
                  error={errors?.DiscountCount[0]}
                  onBlur={() => {
                    setErrors((state: any) => ({
                      ...state,
                      DiscountCount: [],
                    }));
                  }}
                />
              </>
            )}

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setShowUpdateModal(false);
                  setSelectedReward({});
                  resetErrors();
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}

      {showUpdateAgency && (
        <Modal
          header="Update Agency Info"
          onClose={() => {
            setShowUpdateAgency(false);
            setSelectedAgency({});
            resetAgencyError();
          }}
        >
          <form onSubmit={updateAgency}>
            <SelectField
              boxStyle="mb-[24px]"
              name="locationId"
              defaultName="Select Location"
              defaultValue=""
              options={
                locations?.length > 0
                  ? [
                      ...locations.map((loc) => ({
                        name: loc?.name,
                        value: loc?.id,
                      })),
                    ]
                  : []
              }
              value={selectedAgency?.locationId}
              onChange={(e: any) => onFormChange(e, "updateAgency")}
              error={agencyErrors?.LocationId[0]}
              onBlur={() => {
                setAgencyErrors((state: any) => ({
                  ...state,
                  LocationId: [],
                }));
              }}
            />

            <InputField
              boxStyle="mb-[16px]"
              placeholder="Influencer/Agency Name"
              name="agencyName"
              value={selectedAgency?.agencyName}
              onChange={(e: any) => onFormChange(e, "updateAgency")}
              error={agencyErrors?.AgencyName[0]}
              onBlur={() => {
                setAgencyErrors((state: any) => ({
                  ...state,
                  AgencyName: [],
                }));
              }}
            />

            <InputField
              boxStyle="mb-[16px]"
              placeholder="Email address"
              name="email"
              value={selectedAgency?.email}
              onChange={(e: any) => onFormChange(e, "updateAgency")}
              error={agencyErrors?.Email[0]}
              onBlur={() => {
                setAgencyErrors((state: any) => ({
                  ...state,
                  Email: [],
                }));
              }}
            />

            <InputField
              boxStyle="mb-[16px]"
              placeholder="Referral Code"
              name="referralCode"
              value={selectedAgency?.referralCode}
              onChange={(e: any) => onFormChange(e, "updateAgency")}
              error={agencyErrors?.ReferralCode[0]}
              onBlur={() => {
                setAgencyErrors((state: any) => ({
                  ...state,
                  ReferralCode: [],
                }));
              }}
            />

            <InputField
              boxStyle="mb-[16px]"
              placeholder="Full Name"
              name="fullName"
              value={selectedAgency?.fullName}
              onChange={(e: any) => onFormChange(e, "updateAgency")}
              error={agencyErrors?.FullName[0]}
              onBlur={() => {
                setAgencyErrors((state: any) => ({
                  ...state,
                  FullName: [],
                }));
              }}
            />

            <InputField
              boxStyle="mb-[16px]"
              placeholder="Phone Number"
              name="phoneNumber"
              value={selectedAgency?.phoneNumber}
              onChange={(e: any) => onFormChange(e, "updateAgency")}
              error={agencyErrors?.PhoneNumber[0]}
              onBlur={() => {
                setAgencyErrors((state: any) => ({
                  ...state,
                  PhoneNumber: [],
                }));
              }}
            />

            <InputField
              boxStyle="mb-[16px]"
              placeholder="Onboarding Reward Amount"
              name="rewardAmount"
              type="number"
              value={selectedAgency?.rewardAmount}
              onChange={(e: any) => onFormChange(e, "updateAgency")}
              error={agencyErrors?.RewardAmount[0]}
              onBlur={() => {
                setAgencyErrors((state: any) => ({
                  ...state,
                  RewardAmount: [],
                }));
              }}
            />

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setShowUpdateAgency(false);
                  setSelectedAgency({});
                  resetAgencyError();
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default ReferralSettings;
