import React, { useEffect } from "react";
import ApexCharts from "apexcharts";

const LineChart = ({
  id,
  categories = [],
  xLabel = "",
  yAxis = [],
  series = [],
  colors = [],
}: {
  id: string;
  series?: any;
  categories?: any;
  colors?: any;
  yAxis?: any;
  yLabel?: string;
  xLabel?: string;
}) => {
  const options = {
    series: [...series],
    stroke: {
      curve: "straight",
      width: [2],
    },
    markers: {
      size: 4,
    },
    chart: {
      type: "line",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: [...colors],
    grid: {
      borderColor: "#6C18A4",
      strokeDashArray: 2,
    },
    xaxis: {
      title: {
        style: {
          color: "#6C18A4",
        },
        text: xLabel,
      },
      categories: [...categories],
    },
    yaxis: [...yAxis],
  };

  useEffect(() => {
    const chart = new ApexCharts(document.getElementById(id), options);
    chart.render();
    return () => chart.destroy();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [series]);

  return <div id={id}></div>;
};

export default LineChart;
