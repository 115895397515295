import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import UploadField from "core/components/formfields/UploadField";
import { btn } from "core/consts/styling";
import { ID_TYPES } from "core/consts/systemConst";
import { isValidFormDate } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";

export const DocumentDetails = ({
  onSubmit = () => {},
  onChange = () => {},
  onBack = () => {},
  onFileUpload = () => {},
  data,
  files,
  errors = [],
  onErrorChange = () => {},
}: {
  onSubmit?: any;
  onBack?: any;
  data: NewUser;
  onChange?: any;
  onFileUpload?: any;
  files: any;
  errors?: any;
  onErrorChange?: any;
}) => {
  const validation = (data: NewUser, files: any) => {
    var isValid = true;

    if (data?.IDCardType?.length < 1) {
      onErrorChange((state: any) => ({
        ...state,
        IDCardType: ["Please select an ID type"],
      }));
      isValid = false;
    }

    if (data?.IDCardNumber?.length < 1) {
      onErrorChange((state: any) => ({
        ...state,
        IDCardNumber: ["ID number is required"],
      }));
      isValid = false;
    }

    if (!isValidFormDate(data?.IDCardIssuedDate)) {
      onErrorChange((state: any) => ({
        ...state,
        IDCardIssuedDate: ["Date ID was issued is required"],
      }));
      isValid = false;
    } else {
      if (new Date(data?.IDCardIssuedDate) > new Date()) {
        onErrorChange((state: any) => ({
          ...state,
          IDCardIssuedDate: [
            "Date ID was issued must not be greater than today",
          ],
        }));
        isValid = false;
      }
    }

    if (
      ["drivers license", "international passport"].includes(
        data?.IDCardType?.toLowerCase(),
      )
    ) {
      if (!isValidFormDate(data?.IDCardExpiryDate)) {
        onErrorChange((state: any) => ({
          ...state,
          IDCardExpiryDate: ["Date ID will expire is required"],
        }));
        isValid = false;
      } else {
        if (new Date(data?.IDCardExpiryDate) < new Date()) {
          onErrorChange((state: any) => ({
            ...state,
            IDCardExpiryDate: [
              "Date ID will expire must be greater than today",
            ],
          }));
          isValid = false;
        }
      }
    }

    if (
      !files?.passportImage ||
      !files?.idImage ||
      !files?.businessLogo ||
      !files?.signature
    ) {
      notification({
        message: `Please upload all required documents`,
        type: "warning",
      });
      isValid = false;
    }

    return isValid;
  };

  const submitForm = (e: any) => {
    e.preventDefault();

    if (validation(data, files)) {
      onSubmit();
    } else {
      notification({
        message: `Please pass all required information`,
        type: "danger",
      });
    }
  };

  return (
    <form onSubmit={submitForm}>
      <p className="mb-[24px] text-center text-brandgray">
        Your business category is {data?.category}
      </p>

      <SelectField
        boxStyle="mb-[24px]"
        name="IDCardType"
        defaultName="Select your ID Type"
        defaultValue=""
        options={ID_TYPES?.length > 0 ? [...ID_TYPES] : []}
        value={data?.IDCardType}
        onChange={(e: any) => {
          onChange(e);
        }}
        error={errors?.IDCardType[0]}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            IDCardType: [],
          }));
        }}
      />

      <InputField
        boxStyle="mb-[24px]"
        placeholder="ID Number"
        name="IDCardNumber"
        value={data?.IDCardNumber}
        onChange={(e: any) => {
          onChange(e);
        }}
        error={errors?.IDCardNumber[0]}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            IDCardNumber: [],
          }));
        }}
      />

      <InputField
        boxStyle="mb-[24px]"
        label="ID Card Issued Date"
        name="IDCardIssuedDate"
        type="date"
        value={data?.IDCardIssuedDate}
        onChange={(e: any) => {
          onChange(e);
        }}
        error={errors?.IDCardIssuedDate[0]}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            IDCardIssuedDate: [],
          }));
        }}
      />

      {["drivers license", "international passport"].includes(
        data?.IDCardType?.toLowerCase(),
      ) && (
        <InputField
          boxStyle="mb-[24px]"
          label="ID Card Expiry Date"
          name="IDCardExpiryDate"
          type="date"
          value={data?.IDCardExpiryDate}
          onChange={(e: any) => {
            onChange(e);
          }}
          error={errors?.IDCardExpiryDate[0]}
          onBlur={() => {
            onErrorChange((state: any) => ({
              ...state,
              IDCardExpiryDate: [],
            }));
          }}
        />
      )}

      <UploadField
        boxStyle="mb-[24px]"
        label="Upload means of Identification"
        name="idImage"
        onChange={onFileUpload}
        value={files?.idImage}
      />

      <UploadField
        boxStyle="mb-[24px]"
        label="Upload your business logo"
        name="businessLogo"
        onChange={onFileUpload}
        value={files?.businessLogo}
      />

      <UploadField
        boxStyle="mb-[24px]"
        label="Upload your Passport"
        name="passportImage"
        onChange={onFileUpload}
        value={files?.passportImage}
      />

      <UploadField
        boxStyle="mb-[24px]"
        label="Upload your Signature"
        name="signature"
        onChange={onFileUpload}
        value={files?.signature}
      />

      <div className="flex gap-3">
        <button
          onClick={() => onBack()}
          className={`${btn} w-full !bg-[#DFA1F4] text-white`}
        >
          Back
        </button>
        <button className={`${btn} w-full bg-brand text-white`}>Submit</button>
      </div>
    </form>
  );
};
