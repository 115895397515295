import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import { back, productImg } from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import { formatSimpleDate } from "core/helpers/generalHelpers";
import useTransportationStore from "core/services/stores/useTransportationStore";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const DriverTrips = () => {
  const { agentId } = useParams();
  const navigate = useNavigate();

  const agent = useTransportationStore((store) => store.agent);
  const setAgent = useTransportationStore((store) => store.setAgent);
  const getTripAction = useTransportationStore((store) => store.getDriverTrips);
  const trips = useTransportationStore((store) => store.driverTrips);

  useEffect(() => {
    if (agentId != null && agentId?.length > 1) {
      getTripAction(agentId!);
    } else {
      setAgent(null);
      navigate("/transportation/drivers");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="mx-auto mb-[28px] w-[95%]">
      <div className="flex items-center justify-between">
        <header className="flex items-center gap-3">
          <img src={productImg} alt="" loading="lazy" />
          <h5 className="text-[18px] font-[500] text-brand">
            Trip of {agent?.fullName}
          </h5>
        </header>

        <button
          onClick={() => {
            setAgent(null);
            navigate(`/transportation/drivers`);
          }}
          className="flex w-[100px] items-center justify-between px-4 py-2 text-[14px] text-brand"
        >
          <img src={back} loading="lazy" alt="" />
          <span>Back</span>
        </button>
      </div>

      <div className="my-[16px] flex items-center justify-between gap-5">
        <div className="flex justify-start gap-3 rounded-[25px]">
          <div className={`${actionbtn} bg-[#F2EAF8] shadow-md`}>
            No of Trips: {trips?.length}
          </div>

          <div className={`${actionbtn} bg-[#F2EAF8] shadow-md`}>
            Vehicle:{" "}
            <span>
              {agent?.vehicle?.vehicleType} -{" "}
              {agent?.vehicle?.registrationNumber}
            </span>
          </div>

          <div className={`${actionbtn} bg-[#F2EAF8] shadow-md`}>
            No of Seats: {agent?.vehicle?.seats}
          </div>
        </div>
      </div>

      <main className="my-[24px]">
        <Table
          headers={[
            "S/N",
            "Trip Name",
            "Departure Day",
            "Departure Time",
            "Departure Date",
            "Status",
          ]}
        >
          {trips?.length > 0 ? (
            trips?.map((trip, index) => (
              <tr key={trip?.id}>
                <Row value={index + 1} />
                <Row value={trip?.tripName} />
                <Row value={trip?.departureDay} />
                <Row value={trip?.departureTime} />
                <Row value={formatSimpleDate(trip?.departureDate)} />
                <Row value={trip?.status} />
              </tr>
            ))
          ) : (
            <tr>
              <Row colSpan={6} value="No trip record yet" />
            </tr>
          )}
        </Table>
      </main>
    </section>
  );
};

export default DriverTrips;
