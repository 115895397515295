import { apicall } from "./apicall";

export const getDashboardStat = (query: SupportStatQuery) => {
  return apicall({
    endpoint: "/support/dashboard",
    method: "GET",
    pQuery: { ...query },
    auth: true,
  });
};

export const getDashboardBusinessAnalytics = (query: AnalyticsQuery) => {
  return apicall({
    endpoint: "/support/dashboardanalytics",
    method: "GET",
    pQuery: { ...query },
    auth: true,
  });
};