import {
  activeModule,
  home,
  locationImg,
  productImg,
  profileuser2,
  settlement,
  viewOrderImg,
} from "core/consts/images";
import Dashboard from "./views/Dashboard";
import ViewOrders from "./views/ViewOrders";
import DeliveryArea from "./views/DeliveryArea";
import Notifications from "./views/Notifications";
import MyEarnings from "./views/MyEarnings";
import CreateEngineeringService from "./views/CreateEngineeringService";
import UpdateEngineeringService from "./views/UpdateEngineeringService";
import ViewEngineeringService from "./views/ViewEngineeringService";
import ViewOrderDetails from "./views/ViewOrderDetails";

const routes: RoutesType[] = [
  {
    name: "Dashboard",
    layout: "/engineering",
    path: "dashboard",
    icon: home,
    component: <Dashboard />,
    children: [],
  },
  {
    name: "Create Engineering Service",
    layout: "/engineering",
    path: "create-service",
    icon: activeModule,
    component: <CreateEngineeringService />,
    children: [],
  },
  {
    name: "",
    layout: "/engineering",
    path: "update-service",
    icon: activeModule,
    component: <UpdateEngineeringService />,
    children: [],
  },
  {
    name: "View Engineering Service",
    layout: "/engineering",
    path: "view-service",
    icon: productImg,
    component: <ViewEngineeringService />,
    children: [],
  },
  {
    name: " ",
    layout: "/engineering",
    path: "view-orders/:orderId",
    icon: viewOrderImg,
    component: <ViewOrderDetails />,
    children: [],
  },
  {
    name: "View Orders ",
    layout: "/engineering",
    path: "view-orders",
    icon: viewOrderImg,
    component: <ViewOrders />,
    children: [],
  },
  {
    name: "Delivery Area",
    layout: "/engineering",
    path: "delivery-area",
    icon: locationImg,
    component: <DeliveryArea />,
    children: [],
  },
  {
    name: "Notifications",
    layout: "/engineering",
    path: "notifications",
    icon: profileuser2,
    component: <Notifications />,
    children: [],
  },
  {
    name: "My Earnings",
    layout: "/engineering",
    path: "earnings",
    icon: settlement,
    component: <MyEarnings />,
    children: [],
  },
];

export default routes;
