import SystemNotifications from "modules/partials/SystemNotifications";

const Notifications = () => {
  return (
    <>
      <SystemNotifications module="laundry" />
    </>
  );
};

export default Notifications;
