import InputField from "core/components/formfields/InputField";
import ActionRow from "core/components/table/Actionrow";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import { actionbtn, btn } from "core/consts/styling";
import useLocationStore from "core/services/stores/useLocationStore";
import { useEffect, useState } from "react";
import {
  formatSimpleDate,
  formatToFormDate,
  isObjectEmpty,
} from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import { deleteIcon, editIcon, eyeclose, eyeopen } from "core/consts/images";
import Modal from "core/components/Modal";

import ads from "assets/icons/ads.svg";
import { ADVERT_PLACEMENT, ADVERT_TYPES } from "core/consts/systemConst";
import UploadField from "core/components/formfields/UploadField";
import TextField from "core/components/formfields/TextField";
import MultiSelectField from "core/components/formfields/MultiSelectField";
import placeHolderImg from "assets/img/placeholder.png";
import useAdvertStore from "core/services/stores/useAdvertStore";
import useUserStore from "core/services/stores/useUserStore";
import Pagination from "core/components/Pagination";
import refreshIcon from "assets/icons/refresh.svg";

const AdManagement = () => {
  const [adType, setAdType] = useState(ADVERT_TYPES.main);
  const locations = useLocationStore((store) => store.locations);
  const user = useUserStore((store) => store.user);
  const currentLocation =
    locations?.length > 0
      // eslint-disable-next-line eqeqeq
      ? locations?.find((x) => x.name == user?.location)?.id
      : "";

  const getLocationAction = useLocationStore((store) => store.getLocations);

  const advertList = useAdvertStore((store) => store.advertList);
  const getAdvertsAction = useAdvertStore((store) => store.getAdverts);
  const addAdvertAction = useAdvertStore((store) => store.addAdvert);
  const updateAdvertAction = useAdvertStore((store) => store.updateAdvert);
  const deleteAdvertAction = useAdvertStore((store) => store.deleteAdvert);
  const addUpdateDefaultAdvertAction = useAdvertStore(
    (store) => store.addUpdateDefaultAd,
  );
  const reRunAdvertAction = useAdvertStore((store) => store.reRunAdvert);
  const uploadAdvertGraphicAction = useAdvertStore(
    (store) => store.uploadAdvertGraphic,
  );
  const changeAdvertVisibilityAction = useAdvertStore(
    (store) => store.changeAdvertVisibility,
  );

  const [query] = useState<AdvertQuery>({
    searchText: "",
    expiryEndDate: "",
    expiryStartDate: "",
    locationId: currentLocation!,
  });

  const adInitialState = {
    image: "",
    title: "",
    descriptor: "",
    redirectionUrl: "",
    locationId: [],
    owner: "",
    placement: [],
    endDate: "",
    startDate: "",
  };

  const [newAd, setNewAd] = useState<NewAdvert>({
    ...adInitialState,
  });

  const defaultAdInitialState = {
    image: "",
    title: "",
    descriptor: "",
    redirectionUrl: "",
  };

  const reRunErrorInitialState = {
    StartDate: [],
    EndDate: [],
  };

  const [newDefaultAd, setNewDefaultAd] = useState<NewDefaultAdvert>({
    ...defaultAdInitialState,
  });

  const [reRunError, setRerunError] = useState({ ...reRunErrorInitialState });
  const [selectedAd, setSelectedAd] = useState<Advert | any>({});
  const [updatedAd, setUpdatedAd] = useState<NewAdvert>({ ...adInitialState });

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showReRunModal, setShowReRunModal] = useState(false);
  const [newBanner, setNewBanner] = useState<any>({ banner: "" });
  const [reRunTime, setReRunTime] = useState({
    startDate: "",
    endDate: "",
  });
  const [reach, setReach] = useState<any>([]);
  const [placement, setPlacement] = useState<any>([]);

  const adErrorInitialState = {
    EndDate: [],
    LocationId: [],
    Owner: [],
    StartDate: [],
    Placement: [],
    Title: [],
    Image: [],
  };

  const [adErrors, setAdErrors] = useState<any>({
    ...adErrorInitialState,
  });

  const defaultAdErrorInitialState = {
    Image: [],
    Title: [],
    Descriptor: [],
    RedirectionUrl: [],
  };

  const [defaultAdErrors, setDefaultAdErrors] = useState<any>({
    ...defaultAdErrorInitialState,
  });

  const onFormChange = (e: any, onChange: any, isSelect: boolean) => {
    const { name, value } = e.target;
    var options: any = [];

    if (isSelect) {
      options = e?.target?.options;

      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
    }

    onChange((state: any) => ({
      ...state,
      [name]: value,
    }));
  };

  const onErrorChange = (setState: any, name: string, value: string[]) => {
    setState((state: any) => ({
      ...state,
      [name]: value,
    }));
  };

  const validateDefaultAd = (data: NewDefaultAdvert) => {
    var isValid = true;

    if (data?.image?.length < 1) {
      onErrorChange(setDefaultAdErrors, "Image", [
        "Please upload a banner for Ad",
      ]);
      notification({
        message: "Please upload a banner for Ad",
        type: "warning",
      });
      isValid = false;
    }

    if (data?.title?.length < 1) {
      onErrorChange(setDefaultAdErrors, "Title", ["Title is required"]);
      isValid = false;
    }

    if (data?.descriptor?.length > 100) {
      onErrorChange(setDefaultAdErrors, "Descriptor", [
        "Descriptor should be less than 100 characters",
      ]);
      isValid = false;
    }

    return isValid;
  };

  const validateReRunTime = (data: { startDate: string; endDate: string }) => {
    var isValid = true;

    if (data?.startDate?.length < 1) {
      onErrorChange(setRerunError, "StartDate", ["Start Date is required"]);
      isValid = false;
    }

    if (data?.endDate?.length < 1) {
      onErrorChange(setRerunError, "EndDate", ["End Date is required"]);
      isValid = false;
    }

    return isValid;
  };

  const validateAd = (data: NewAdvert, isUpdate: boolean = false) => {
    var isValid = true;

    if (!isUpdate && data?.image?.length < 1) {
      onErrorChange(setAdErrors, "Image", ["Please upload a banner for Ad"]);
      notification({
        message: "Please upload a banner for Ad",
        type: "warning",
      });
      isValid = false;
    }

    if (data?.title?.length < 1) {
      onErrorChange(setAdErrors, "Title", ["Title is required"]);
      isValid = false;
    }

    if (data?.descriptor?.length > 100) {
      onErrorChange(setAdErrors, "Descriptor", [
        "Descriptor should be less than 100 characters",
      ]);
      isValid = false;
    }

    if (data?.owner?.length < 1) {
      onErrorChange(setAdErrors, "Owner", ["Ad Owner is required"]);
      isValid = false;
    }

    if (data?.placement?.length < 1) {
      onErrorChange(setAdErrors, "Placement", [
        "Please select a placement for Ad",
      ]);
      isValid = false;
    }

    if (data?.locationId?.length < 1) {
      onErrorChange(setAdErrors, "LocationId", [
        "Please select a placement for Ad",
      ]);
      isValid = false;
    }

    if (data?.startDate?.length < 1) {
      onErrorChange(setAdErrors, "StartDate", ["Start Date is required"]);
      isValid = false;
    }

    if (data?.endDate?.length < 1) {
      onErrorChange(setAdErrors, "EndDate", ["End Date is required"]);
      isValid = false;
    }

    return isValid;
  };

  const addDefaultAdvert = async (e: any) => {
    e.preventDefault();

    if (validateDefaultAd(newDefaultAd)) {
      var res: any = await addUpdateDefaultAdvertAction(
        {
          ...newDefaultAd,
        },
        isObjectEmpty(advertList?.defaultAdvert)
          ? ""
          : advertList?.defaultAdvert?.id,
      );

      if (res?.isSuccessful) {
        if (isObjectEmpty(advertList?.defaultAdvert)) {
          setNewDefaultAd({
            ...defaultAdInitialState,
          });
        } else {
          setNewDefaultAd((state) => ({
            ...state,
            image: "",
          }));
        }
      } else {
        if (res?.data?.errors != null) {
          setDefaultAdErrors(res?.data?.errors);
        }
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const addMainAdvert = async (e: any) => {
    e.preventDefault();

    newAd.placement = placement?.map((item: any) => item?.value);
    var selectedLocations = reach?.map((item: any) => item?.value);

    newAd.locationId = selectedLocations?.includes("All")
      ? locations?.map((loc) => loc?.id)
      : selectedLocations;

    if (validateAd(newAd)) {
      var res: any = await addAdvertAction(newAd);

      if (res?.isSuccessful) {
        setNewAd({
          ...adInitialState,
        });
        setReach([]);
        setPlacement([]);
      } else {
        if (res?.data?.errors != null) {
          setAdErrors(res?.data?.errors);
        }
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const openUpdateAdvert = (advert: Advert) => {
    var placement: string[] = [];

    setPlacement([]);

    if (advert?.isHomeEnabled) {
      placement.push("Home");
    }

    if (advert?.isMarketplaceEnabled) {
      placement.push("Marketplace");
    }

    setSelectedAd({ ...advert });

    setPlacement(
      placement?.length > 0
        ? placement?.map((place: any) => ({
            label: place === "Home" ? place : "Market Place",
            value: place,
          }))
        : [],
    );

    var selectedLocations = advert?.reach;

    setReach(
      selectedLocations?.length === locations?.length
        ? [
            {
              label: "All schools/location",
              value: "All",
            },
          ]
        : [
            ...selectedLocations?.map((loc) => ({
              label: loc?.locationName,
              value: loc?.locationId,
            })),
          ],
    );

    setUpdatedAd({
      descriptor: advert?.descriptor ?? "",
      image: advert?.imageUrl,
      locationId: selectedLocations?.map((loc) => loc.locationId),
      owner: advert?.owner,
      placement: placement,
      redirectionUrl: advert?.redirectionUrl ?? "",
      endDate: formatToFormDate(advert?.endDate),
      startDate: formatToFormDate(advert?.startDate),
      title: advert?.title,
    });

    setShowUpdateModal(true);
  };

  const closeUpdateAdvert = () => {
    setSelectedAd({});
    setUpdatedAd({
      ...adInitialState,
    });
    setReach([]);
    setPlacement([]);
    setAdErrors({ ...adErrorInitialState });
    setShowUpdateModal(false);
  };

  const updateAdvert = async (e: any) => {
    e.preventDefault();

    updatedAd.placement = placement?.map((item: any) => item?.value);
    var selectedLocations = reach?.map((item: any) => item?.value);

    updatedAd.locationId = selectedLocations?.includes("All")
      ? locations?.map((loc) => loc?.id)
      : selectedLocations;

    if (validateAd(updatedAd, true)) {
      var res: any = await updateAdvertAction(selectedAd?.id, updatedAd!);

      if (!res?.isSuccessful) {
        if (res?.data?.errors != null) {
          setAdErrors(res?.data?.errors);
        }
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const uploadBanner = async (e: any) => {
    e.preventDefault();

    if (newBanner?.banner?.size > 1) {
      await uploadAdvertGraphicAction(selectedAd?.id, newBanner?.banner!);
    } else {
      notification({
        message: "Please upload new banner",
        type: "warning",
      });
    }
  };

  const reRunAdvert = async (e: any) => {
    e.preventDefault();

    if (validateReRunTime(reRunTime)) {
      var res: any = await reRunAdvertAction(
        selectedAd?.id,
        reRunTime?.startDate,
        reRunTime?.endDate,
      );

      if (!res?.isSuccessful) {
        if (res?.data?.errors != null) {
          setRerunError(res?.data?.errors);
        }
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const openReRunModal = (advert: Advert) => {
    setSelectedAd({ ...advert });

    setReRunTime({
      startDate: formatToFormDate(advert?.startDate),
      endDate: formatToFormDate(advert?.endDate),
    });

    setShowReRunModal(true);
  };

  const closeReRunModal = () => {
    setSelectedAd({});

    setReRunTime({
      startDate: "",
      endDate: "",
    });

    setRerunError({ ...reRunErrorInitialState });

    setShowReRunModal(false);
  };

  const toggleAdvert = async (advert: Advert) => {
    if (
      window.confirm(
        `Click 'OK' to ${
          advert?.visibility ? "deactivate" : "activate"
        } ${advert?.title}`,
      )
    ) {
      await changeAdvertVisibilityAction(advert?.id, !advert?.visibility);
    }
  };

  const deleteAdvert = async (advert: Advert) => {
    if (window.confirm(`Click 'OK' to delete advert: ${advert?.title}`)) {
      await deleteAdvertAction(advert?.id);
    }
  };

  const fetchMore = (page: number) => {
    getAdvertsAction(query, page, advertList?.pageSize);
  };

  // TODO: Create an image cropper

  const handleOption = (selections: any) => {
    setReach(selections);
  };

  const handlePlacement = (selections: any) => {
    setPlacement(selections);
  };

  const fetchAdverts = async () => {
    await getAdvertsAction(query, advertList?.pageNumber, advertList?.pageSize);

    if (!isObjectEmpty(advertList?.defaultAdvert)) {
      setNewDefaultAd({ ...advertList?.defaultAdvert });
    }
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();
    fetchAdverts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mx-auto w-[95%]">
        <header className="mb-[28px] flex items-center gap-3">
          <img src={ads} loading="lazy" alt="" />
          <h5 className="text-[18px] font-[500] text-brand">Ad Management</h5>
        </header>

        <div className="mb-[28px] flex flex-col gap-5 lg:flex-row">
          <div className="w-full rounded-[5px] bg-white p-5 lg:w-4/12">
            <div className="mb-[28px] flex items-center justify-between">
              <button
                className={`${
                  adType === ADVERT_TYPES.main && "text-brand underline"
                }`}
                onClick={() => setAdType(ADVERT_TYPES.main)}
              >
                Create Ad
              </button>
              <button
                className={`${
                  adType === ADVERT_TYPES.default && "text-brand underline"
                }`}
                onClick={() => setAdType(ADVERT_TYPES.default)}
              >
                Update Default Ad
              </button>
            </div>
            {adType === ADVERT_TYPES.main && (
              <form onSubmit={addMainAdvert}>
                <MultiSelectField
                  multiple
                  isRequired
                  label="Select Placement"
                  boxStyle="mb-[24px]"
                  name="placement"
                  value={placement}
                  options={[
                    ...ADVERT_PLACEMENT?.map((placement) => ({
                      label: placement?.name,
                      value: placement?.value,
                    })),
                  ]}
                  error={adErrors?.Placement[0]}
                  onBlur={() => onErrorChange(setAdErrors, "Placement", [])}
                  onChange={handlePlacement}
                />

                <MultiSelectField
                  multiple
                  isRequired
                  label="Select Reach"
                  name="locationId"
                  boxStyle="mb-[24px]"
                  value={reach}
                  options={
                    locations?.length > 0
                      ? [
                          {
                            label: "All schools/location",
                            value: "All",
                          },
                          ...locations?.map((location) => ({
                            label: location.name,
                            value: location.id,
                          })),
                        ]
                      : []
                  }
                  onChange={handleOption}
                  error={adErrors?.LocationId[0]}
                  onBlur={() => onErrorChange(setAdErrors, "LocationId", [])}
                />

                <InputField
                  boxStyle="mb-[24px]"
                  label="Ad Owner"
                  name="owner"
                  isRequired
                  value={newAd?.owner}
                  onChange={(e: any) => onFormChange(e, setNewAd, false)}
                  error={adErrors?.Owner[0]}
                  onBlur={() => onErrorChange(setAdErrors, "Owner", [])}
                />

                <InputField
                  boxStyle="mb-[24px]"
                  label="Ad Title"
                  name="title"
                  isRequired
                  value={newAd?.title}
                  onChange={(e: any) => onFormChange(e, setNewAd, false)}
                  error={adErrors?.Title[0]}
                  onBlur={() => onErrorChange(setAdErrors, "Title", [])}
                />

                <UploadField
                  boxStyle="mb-[24px]"
                  label="Upload Ad (110px by 85px)"
                  name="image"
                  isRequired
                  onChange={setNewAd}
                  value={newAd?.image}
                />

                <TextField
                  boxStyle="mb-[24px]"
                  name="descriptor"
                  placeholder="100 characters max"
                  label="Descriptor"
                  rows={5}
                  value={newAd?.descriptor}
                  onChange={(e: any) => onFormChange(e, setNewAd, false)}
                  error={adErrors?.Title[0]}
                  onBlur={() => onErrorChange(setAdErrors, "Title", [])}
                />

                <div className="flex items-center justify-between gap-5">
                  <InputField
                    boxStyle="mb-[24px] w-1/2"
                    label="Start Date"
                    name="startDate"
                    isRequired
                    type="date"
                    value={newAd?.startDate}
                    onChange={(e: any) => onFormChange(e, setNewAd, false)}
                    error={adErrors?.StartDate[0]}
                    onBlur={() => onErrorChange(setAdErrors, "StartDate", [])}
                  />

                  <InputField
                    boxStyle="mb-[24px] w-1/2"
                    label="End Date"
                    name="endDate"
                    isRequired
                    type="date"
                    value={newAd?.endDate}
                    onChange={(e: any) => onFormChange(e, setNewAd, false)}
                    error={adErrors?.EndDate[0]}
                    onBlur={() => onErrorChange(setAdErrors, "EndDate", [])}
                  />
                </div>

                <InputField
                  boxStyle="mb-[24px]"
                  label="Redirect URL"
                  name="redirectionUrl"
                  value={newAd?.redirectionUrl}
                  onChange={(e: any) => onFormChange(e, setNewAd, false)}
                />

                <button className={`${btn} w-full bg-brand text-white`}>
                  Submit
                </button>
              </form>
            )}

            {adType === ADVERT_TYPES.default && (
              <form onSubmit={addDefaultAdvert}>
                <InputField
                  boxStyle="mb-[24px]"
                  label="Ad Title"
                  name="title"
                  isRequired
                  value={newDefaultAd?.title}
                  onChange={(e: any) => onFormChange(e, setNewDefaultAd, false)}
                  error={defaultAdErrors?.Title[0]}
                  onBlur={() => onErrorChange(setDefaultAdErrors, "Title", [])}
                />

                <UploadField
                  boxStyle="mb-[24px]"
                  label="Upload Ad (110px by 85px)"
                  name="image"
                  isRequired
                  onChange={setNewDefaultAd}
                  value={newDefaultAd?.image}
                />

                <TextField
                  boxStyle="mb-[24px]"
                  name="descriptor"
                  placeholder="100 characters max"
                  label="Descriptor"
                  isRequired
                  rows={5}
                  value={newDefaultAd?.descriptor}
                  onChange={(e: any) => onFormChange(e, setNewDefaultAd, false)}
                  error={defaultAdErrors?.Descriptor[0]}
                  onBlur={() =>
                    onErrorChange(setDefaultAdErrors, "Descriptor", [])
                  }
                />

                <InputField
                  boxStyle="mb-[24px]"
                  label="Redirect URL"
                  name="redirectionUrl"
                  value={newDefaultAd?.redirectionUrl}
                  onChange={(e: any) => onFormChange(e, setNewDefaultAd, false)}
                  error={defaultAdErrors?.RedirectionUrl[0]}
                  onBlur={() =>
                    onErrorChange(setDefaultAdErrors, "RedirectionUrl", [])
                  }
                />

                <button className={`${btn} w-full bg-brand text-white`}>
                  Submit
                </button>
              </form>
            )}
          </div>
          <div className="w-full lg:w-8/12">
            <Table
              headers={[
                "",
                "Ad Owner",
                "Title",
                "Reach",
                "Start Date",
                "End Date",
                "Action",
              ]}
            >
              {!isObjectEmpty(advertList?.defaultAdvert) && (
                <tr key={advertList?.defaultAdvert?.id}>
                  <ActionRow
                    style={`hover:cursor-pointer flex items-center justify-center gap-3`}
                  >
                    <img
                      onClick={() => {
                        setSelectedAd(advertList?.defaultAdvert);
                        setShowImageModal(true);
                      }}
                      src={advertList?.defaultAdvert?.imageUrl}
                      className="h-[42px] w-[55px]"
                      alt="Default Ad"
                    />
                  </ActionRow>
                  <Row value="CR" />
                  <Row value={advertList?.defaultAdvert?.title} />
                  <ActionRow>
                    <p>All locations</p>
                  </ActionRow>
                  <Row value="N/A" />
                  <Row value="N/A" />
                  <ActionRow style={`flex items-center justify-center gap-3`}>
                    <></>
                  </ActionRow>
                </tr>
              )}

              {advertList?.adverts?.length > 0 ? (
                advertList?.adverts?.map((advert: Advert) => (
                  <tr key={advert?.id}>
                    <ActionRow
                      style={`hover:cursor-pointer flex items-center justify-center gap-3`}
                    >
                      <img
                        onClick={() => {
                          setSelectedAd(advert);
                          setShowImageModal(true);
                        }}
                        src={advert?.imageUrl}
                        className="h-[42px] w-[55px]"
                        alt={advert?.owner}
                      />
                    </ActionRow>
                    <Row value={advert?.owner} />
                    <Row value={advert?.title} />
                    <ActionRow>
                      {advert?.reach?.length !== locations?.length ? (
                        advert?.reach?.map((reach) => (
                          <p key={reach?.locationId}>{reach?.locationName}</p>
                        ))
                      ) : (
                        <p key={advert?.id}>All locations</p>
                      )}
                    </ActionRow>
                    <Row value={formatSimpleDate(advert?.startDate)} />
                    <Row value={formatSimpleDate(advert?.endDate)} />
                    <ActionRow
                      style={`w-[80px] grid grid-cols-2 items-center justify-center gap-3`}
                    >
                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] border-brand bg-[#F3CFFF] p-2"
                        onClick={() => toggleAdvert(advert)}
                      >
                        <img
                          src={advert?.visibility ? eyeclose : eyeopen}
                          loading="lazy"
                          className="h-[16px]"
                          alt=""
                        />
                      </button>
                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] border-yellow-500 bg-yellow-200 p-2"
                        onClick={() => openReRunModal(advert)}
                      >
                        <img
                          src={refreshIcon}
                          loading="lazy"
                          className="h-[16px]"
                          alt="Rerun advert"
                        />
                      </button>
                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] border-[#59960B] bg-[#D9FEAA] p-2"
                        onClick={() => openUpdateAdvert(advert)}
                      >
                        <img
                          src={editIcon}
                          loading="lazy"
                          className="h-[16px]"
                          alt=""
                        />
                      </button>
                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] border-[#FF0000] bg-[#FFC8C8] p-2"
                        onClick={() => deleteAdvert(advert)}
                      >
                        <img
                          src={deleteIcon}
                          className="h-[16px]"
                          loading="lazy"
                          alt=""
                        />
                      </button>
                    </ActionRow>
                  </tr>
                ))
              ) : (
                <tr>
                  <Row colSpan={7} value="no advert has been added." />
                </tr>
              )}
            </Table>

            <Pagination
              pageNumber={advertList?.pageNumber}
              pageSize={advertList?.pageSize}
              totalCount={advertList?.totalCount}
              totalPage={advertList?.totalPage}
              onFetch={fetchMore}
            />
          </div>
        </div>
      </div>

      {showUpdateModal && (
        <Modal
          header={`Update Ad - ${selectedAd?.title}`}
          onClose={() => closeUpdateAdvert()}
        >
          <form onSubmit={updateAdvert}>
            <MultiSelectField
              multiple
              isRequired
              label="Select Placement"
              boxStyle="mb-[24px]"
              name="placement"
              value={placement}
              options={[
                ...ADVERT_PLACEMENT?.map((placement) => ({
                  label: placement?.name,
                  value: placement?.value,
                })),
              ]}
              error={adErrors?.Placement[0]}
              onBlur={() => onErrorChange(setAdErrors, "Placement", [])}
              onChange={handlePlacement}
            />

            <MultiSelectField
              multiple
              isRequired
              label="Select Reach"
              name="locationId"
              boxStyle="mb-[24px]"
              value={reach}
              options={
                locations?.length > 0
                  ? [
                      {
                        label: "All schools/location",
                        value: "All",
                      },
                      ...locations?.map((location) => ({
                        label: location.name,
                        value: location.id,
                      })),
                    ]
                  : []
              }
              onChange={handleOption}
              error={adErrors?.LocationId[0]}
              onBlur={() => onErrorChange(setAdErrors, "LocationId", [])}
            />

            <InputField
              boxStyle="mb-[24px]"
              label="Ad Owner"
              name="owner"
              isRequired
              value={updatedAd?.owner}
              onChange={(e: any) => onFormChange(e, setUpdatedAd, false)}
              error={adErrors?.Owner[0]}
              onBlur={() => onErrorChange(setAdErrors, "Owner", [])}
            />

            <InputField
              boxStyle="mb-[24px]"
              label="Ad Title"
              name="title"
              isRequired
              value={updatedAd?.title}
              onChange={(e: any) => onFormChange(e, setUpdatedAd, false)}
              error={adErrors?.Title[0]}
              onBlur={() => onErrorChange(setAdErrors, "Title", [])}
            />

            <TextField
              boxStyle="mb-[24px]"
              name="descriptor"
              placeholder="100 characters max"
              label="Descriptor"
              rows={5}
              value={updatedAd?.descriptor}
              onChange={(e: any) => onFormChange(e, setUpdatedAd, false)}
              error={adErrors?.Title[0]}
              onBlur={() => onErrorChange(setAdErrors, "Title", [])}
            />

            <div className="flex items-center justify-between gap-5">
              <InputField
                boxStyle="mb-[24px] w-1/2"
                label="Start Date"
                name="startDate"
                isRequired
                type="date"
                value={updatedAd?.startDate}
                onChange={(e: any) => onFormChange(e, setUpdatedAd, false)}
                error={adErrors?.StartDate[0]}
                onBlur={() => onErrorChange(setAdErrors, "StartDate", [])}
              />

              <InputField
                boxStyle="mb-[24px] w-1/2"
                label="End Date"
                name="endDate"
                isRequired
                type="date"
                value={updatedAd?.endDate}
                onChange={(e: any) => onFormChange(e, setUpdatedAd, false)}
                error={adErrors?.EndDate[0]}
                onBlur={() => onErrorChange(setAdErrors, "EndDate", [])}
              />
            </div>

            <InputField
              boxStyle="mb-[24px]"
              label="Redirect URL"
              name="redirectionUrl"
              value={updatedAd?.redirectionUrl}
              onChange={(e: any) => onFormChange(e, setUpdatedAd, false)}
            />

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => closeUpdateAdvert()}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}

      {showImageModal && (
        <Modal
          header={`Update Ad Banner - ${selectedAd?.title}`}
          onClose={() => {
            setShowImageModal(false);
            setSelectedAd({});
            setNewBanner({ banner: "" });
          }}
        >
          <form onSubmit={uploadBanner}>
            <div className="my-[16px] text-[14px] font-bold">
              <span className="text-[12px] font-[400] text-[#868686]">
                Ad Title
              </span>
              <br />
              {selectedAd?.title}
            </div>

            <div className="my-[16px] text-[14px] font-bold">
              <span className="text-[12px] font-[400] text-[#868686]">
                Owner
              </span>
              <br />
              {selectedAd?.owner ?? "CR"}
            </div>

            <div className="my-[16px] text-[14px] font-bold">
              <span className="text-[12px] font-[400] text-[#868686]">
                Placement
              </span>
              <br />
              {(selectedAd?.owner == null || selectedAd?.owner?.length < 1) && (
                <>
                  <span>Home</span>
                  <br />
                  <span>Market Place</span>
                </>
              )}

              {selectedAd?.isHomeEnabled && (
                <>
                  <span>Home</span>
                  <br />
                </>
              )}

              {selectedAd?.isMarketplaceEnabled && (
                <>
                  <span>Market Place</span>
                  <br />
                </>
              )}
            </div>

            <div className="my-[16px] text-[14px] font-bold">
              <span className="text-[12px] font-[400] text-[#868686]">
                Descriptor
              </span>
              <br />
              {selectedAd?.descriptor ?? "No descriptor"}
            </div>

            <div className="mb-[42px] flex items-center justify-start gap-5">
              <div className="w-1/2 text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  Start Date
                </span>
                <br />
                {selectedAd?.startDate
                  ? formatSimpleDate(selectedAd?.startDate)
                  : "N/A"}
              </div>
              <div className="text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  End Date
                </span>
                <br />
                {selectedAd?.endDate
                  ? formatSimpleDate(selectedAd?.endDate)
                  : "N/A"}
              </div>
            </div>

            <UploadField
              boxStyle="mb-[24px]"
              label="Upload Ad (110px by 85px)"
              name="banner"
              isRequired
              onChange={setNewBanner}
              value={newBanner?.banner}
            />

            <div className="mb-[24px] flex items-center justify-center gap-1">
              {newBanner?.banner?.size > 0 ? (
                <>
                  <img
                    src={URL.createObjectURL(newBanner?.banner)}
                    alt="new Banner"
                    className="h-[85px] w-[110px]"
                  />
                </>
              ) : (
                <img
                  src={selectedAd?.imageUrl ?? placeHolderImg}
                  alt={selectedAd?.title}
                  className="h-[85px] w-[110px]"
                />
              )}
            </div>

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setShowImageModal(false);
                  setSelectedAd({});
                  setNewBanner({ banner: "" });
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}

      {showReRunModal && (
        <Modal
          header={`Rerun Ad - ${selectedAd?.title}`}
          onClose={() => closeReRunModal()}
        >
          <form onSubmit={reRunAdvert}>
            <div className="my-[16px] text-[14px] font-bold">
              <span className="text-[12px] font-[400] text-[#868686]">
                Ad Title
              </span>
              <br />
              {selectedAd?.title}
            </div>

            <div className="my-[16px] text-[14px] font-bold">
              <span className="text-[12px] font-[400] text-[#868686]">
                Owner
              </span>
              <br />
              {selectedAd?.owner ?? "CR"}
            </div>

            <div className="my-[16px] text-[14px] font-bold">
              <span className="text-[12px] font-[400] text-[#868686]">
                Placement
              </span>
              <br />
              {(selectedAd?.owner == null || selectedAd?.owner?.length < 1) && (
                <>
                  <span>Home</span>
                  <br />
                  <span>Market Place</span>
                </>
              )}

              {selectedAd?.isHomeEnabled && (
                <>
                  <span>Home</span>
                  <br />
                </>
              )}

              {selectedAd?.isMarketplaceEnabled && (
                <>
                  <span>Market Place</span>
                  <br />
                </>
              )}
            </div>

            <div className="my-[16px] text-[14px] font-bold">
              <span className="text-[12px] font-[400] text-[#868686]">
                Descriptor
              </span>
              <br />
              {selectedAd?.descriptor ?? "No descriptor"}
            </div>

            <div className="mb-[42px] flex items-center justify-start gap-5">
              <div className="w-1/2 text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  Start Date
                </span>
                <br />
                {selectedAd?.startDate
                  ? formatSimpleDate(selectedAd?.startDate)
                  : "N/A"}
              </div>
              <div className="text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  End Date
                </span>
                <br />
                {selectedAd?.endDate
                  ? formatSimpleDate(selectedAd?.endDate)
                  : "N/A"}
              </div>
            </div>

            <div className="mb-[24px] flex items-center justify-center">
              <img
                src={selectedAd?.imageUrl ?? placeHolderImg}
                alt={selectedAd?.title}
                className="h-[85px] w-[110px]"
              />
            </div>

            <div className="flex items-center justify-between gap-5">
              <InputField
                boxStyle="mb-[24px] w-1/2"
                label="New Start Date"
                name="startDate"
                isRequired
                type="date"
                value={reRunTime?.startDate}
                onChange={(e: any) => onFormChange(e, setReRunTime, false)}
                error={reRunError?.StartDate[0]}
                onBlur={() => onErrorChange(setAdErrors, "StartDate", [])}
              />

              <InputField
                boxStyle="mb-[24px] w-1/2"
                label="New End Date"
                name="endDate"
                isRequired
                type="date"
                value={reRunTime?.endDate}
                onChange={(e: any) => onFormChange(e, setReRunTime, false)}
                error={reRunError?.StartDate[0]}
                onBlur={() => onErrorChange(setAdErrors, "StartDate", [])}
              />
            </div>

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => closeReRunModal()}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default AdManagement;
