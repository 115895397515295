import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";

interface Props {
  title?: string;
  message?: string;
  onRemoval?: any;
  duration?: number;
  type: "default" | "success" | "info" | "warning" | "danger";
}

const notification = ({
  title = "",
  message = "",
  type = "warning",
  onRemoval = () => {},
  duration = 5000,
}: Props) => {
  const id = Store.addNotification({
    title: title,
    message: message,
    type: type, // 'default', 'success', 'info', 'warning'
    container: "top-left", // where to position the notifications
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration,
      showIcon: true,
      onScreen: true,
      pauseOnHover: true,
    },
    onRemoval,
  });

  return { id, Store };
};

export default notification;
