import { useEffect, useState } from "react";
import FileUploadField from "core/components/formfields/FileUploadField";
import InputField from "core/components/formfields/InputField";
import TextField from "core/components/formfields/TextField";
import { activeModule } from "core/consts/images";
import { actionbtn, btn } from "core/consts/styling";
import { useSearchParams } from "react-router-dom";
import notification from "core/helpers/notification";
import useUserStore from "core/services/stores/useUserStore";
import useLaundryStore from "core/services/stores/useLaundryStore";

interface Props {
  isUpdate: boolean;
}

const LaundryForm = ({ isUpdate = false }: Props) => {
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const [searchParams]: any = useSearchParams();
  const [, setClotheId] = useState("");

  const clothe = useLaundryStore((store) => store.clothe);
  const getClotheAction = useLaundryStore((store) => store.getClotheById);
  const addUpdateClotheAction = useLaundryStore(
    (store) => store.addUpdateClothe,
  );
  const deleteClotheImageAction = useLaundryStore(
    (store) => store.deleteClotheImage,
  );

  const [files, setFiles] = useState([]);
  const [newClothe, setNewClothe] = useState<NewClothe>({
    convenienceFee: 0,
    extraDetails: "",
    clotheType: "",
    expressAmount: 0,
    normalAmount: 0,
    images: [],
  });

  const [errors, setErrors] = useState({
    ConvenienceFee: [],
    ExtraDetails: [],
    ClotheType: [],
    ExpressAmount: [],
    NormalAmount: [],
    Images: [],
  });

  const removeImage = (index: number) => {
    setFiles([...files.filter((file, i) => i !== index)]);
  };

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const deleteImage = async (url: any) => {
    if (window.confirm(`Click 'OK' to delete this image from the server.`)) {
      await deleteClotheImageAction("laundry", clothe?.clotheId, url);
    }
  };

  const validation = (data: NewClothe, files: any[]) => {
    var isValid = true;

    // TODO: Add Validations
    return isValid;
  };

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;

    setNewClothe((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const addUpdateClothe = async (e: any) => {
    e.preventDefault();

    if (validation(newClothe, files)) {
      var res = await addUpdateClotheAction(
        {
          ...newClothe,
          images: files,
        },
        isUpdate ? clothe?.clotheId : "",
      );

      if (res?.isSuccessful) {
        if (!isUpdate) {
          setNewClothe({
            convenienceFee: 0,
            extraDetails: "",
            clotheType: "",
            expressAmount: 0,
            normalAmount: 0,
            images: [],
          });
        }

        setFiles([]);
      } else {
        setErrors((state) => ({
          ...state,
          ...res?.data?.errors,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    var id = searchParams.get("clotheId");

    if (isUpdate && id?.length > 0) {
      setClotheId(id);
      getClotheAction(id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams && isUpdate]);

  useEffect(() => {
    isUpdate &&
      clothe != null &&
      setNewClothe({
        clotheType: clothe?.clotheType,
        convenienceFee: clothe?.convenienceFee,
        expressAmount: clothe?.expressAmount,
        extraDetails: clothe?.extraDetails,
        normalAmount: clothe?.normalAmount,
        images: [],
      });
  }, [isUpdate, clothe]);

  return (
    <section className="mx-auto mb-[28px] w-[95%]">
      <header className="mb-[14px] flex items-center gap-3">
        <img src={activeModule} alt="active module" loading="lazy" />
        <h5 className="text-[18px] font-[500] capitalize text-brand">
          {isUpdate && clothe
            ? `Update ${clothe && clothe?.clotheType}`
            : "Create Laundry Service"}
        </h5>
      </header>
      <form className="flex gap-5" onSubmit={addUpdateClothe}>
        <div className="w-3/5">
          <InputField
            boxStyle="mb-[18px]"
            placeholder="Clothe Type"
            label="Clothe Type"
            name="clotheType"
            isRequired
            value={newClothe?.clotheType}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.ClotheType[0]}
            onBlur={() => onErrorChange("ClotheType", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            placeholder="Express Amount(12 - 24 Hours)"
            label="Express Amount(12 - 24 Hours)"
            name="expressAmount"
            isRequired
            value={newClothe?.expressAmount}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.ExpressAmount[0]}
            onBlur={() => onErrorChange("ExpressAmount", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            label="Normal Amount(48 - 120 Hours)"
            placeholder="Normal Amount(48 - 120 Hours)"
            name="normalAmount"
            isRequired
            value={newClothe?.normalAmount}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.NormalAmount[0]}
            onBlur={() => onErrorChange("NormalAmount", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            label="Convenience Fee"
            placeholder="Convenience Fee"
            name="convenienceFee"
            isRequired
            value={newClothe?.convenienceFee}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.ConvenienceFee[0]}
            onBlur={() => onErrorChange("ConvenienceFee", [])}
          />

          <TextField
            boxStyle="mb-[18px]"
            name="extraDetails"
            label="Extra Details"
            placeholder="Extra Details"
            isRequired
            rows={5}
            value={newClothe?.extraDetails}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.ExtraDetails[0]}
            onBlur={() => onErrorChange("ExtraDetails", [])}
          />

          <button
            disabled={isSuperAdmin}
            className={`${btn} w-full bg-brand text-white`}
          >
            Submit
          </button>
        </div>
        <div className="w-2/6">
          <>
            <FileUploadField
              multiple={true}
              boxStyle="w-3/5"
              label="Upload Pictures"
              name="signature"
              onChange={setFiles}
              showUploadedImages={false}
            />

            <div className="mt-[16px] grid grid-cols-2 gap-3 ">
              {files?.length > 0 &&
                files.map((file: any, index: number) => (
                  <div
                    key={file?.name + index}
                    className="relative overflow-hidden rounded-[5px] border border-brandLight"
                  >
                    <img src={URL.createObjectURL(file)} alt={`logo${index}`} />
                    <button
                      type="button"
                      className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                      onClick={() => removeImage(index)}
                    >
                      Delete
                    </button>
                  </div>
                ))}

              {isUpdate &&
                clothe?.image?.length > 0 &&
                clothe?.image?.map((image: string, index: number) => (
                  <div
                    key={index}
                    className="relative overflow-hidden rounded-[5px] border border-brandLight"
                  >
                    <img src={image} alt={`logo${index}`} />
                    <button
                      type="button"
                      className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                      onClick={() => deleteImage(image)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
            </div>
          </>
        </div>
      </form>
    </section>
  );
};

export default LaundryForm;
