import { apicall } from "./apicall";

export const getCustomerData = (query: UserDataQueryParam) => {
  return apicall({
    endpoint: "/auth/getcustomerdatacenter",
    method: "GET",
    pQuery: { ...query },
    auth: true,
  });
};
