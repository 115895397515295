const DashStat = ({
  name = "",
  value = "",
  img = "",
}: {
  name: string;
  value: string | number;
  img?: string;
}) => {
  return (
    <div className="flex items-center justify-between gap-5 rounded-[5px] bg-brand p-3 text-white">
      <div>
        <p className="text-[24px]">{Number(value).toLocaleString("en-US")}</p>
        <span className="text-[12px] text-[#DFA1F4]">{name}</span>
      </div>
      {img?.length > 1 && <img src={img} alt={name} loading="lazy" />}
    </div>
  );
};

export default DashStat;
