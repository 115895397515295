import ActionRow from "core/components/table/Actionrow";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import {
  deleteIcon,
  eyeclose,
  eyeopen,
  profileuser2,
} from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import { NotificationType } from "core/consts/systemConst";
import { getDateTime } from "core/helpers/generalHelpers";
import useTransportationStore from "core/services/stores/useTransportationStore";
import { useEffect } from "react";

const Notifications = () => {
  const notifications = useTransportationStore((store) => store.notifications);

  const getNotificationsAction = useTransportationStore(
    (store) => store.getNotifications,
  );

  const markAsReadAction = useTransportationStore((store) => store.markAsRead);

  const deleteNotificationAction = useTransportationStore(
    (store) => store.deleteNotification,
  );

  const deleteNotification = async (noticeId: string) => {
    if (window.confirm(`Click 'OK' to delete notification.`)) {
      await deleteNotificationAction(noticeId);
    }
  };

  const markAllAsRead = async () => {
    if (notifications?.length < 1) return;

    if (window.confirm(`Click 'OK' to mark all notification as read.`)) {
      await markAsReadAction("");
    }
  };

  useEffect(() => {
    getNotificationsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="mx-auto w-[95%]">
      <header className="mb-[14px] flex items-end gap-3">
        <img src={profileuser2} loading="lazy" alt="" />
        <h5 className="text-[18px] font-[500] text-brand">Notifications</h5>
      </header>
      <section>
        <div className="mb-[24px] flex justify-start gap-3 rounded-[25px]">
          <button className={`${actionbtn} !bg-brand !text-white shadow-md`}>
            Total Notification: {notifications?.length}
          </button>
          <button
            disabled={notifications?.length < 1}
            onClick={() => markAllAsRead()}
            className={`${actionbtn} bg-[#F2EAF8] shadow-md`}
          >
            Mark All as Read
          </button>
        </div>
        <section>
          <Table
            headers={[
              "Title",
              "Type",
              "Detail",
              "Read Update",
              "Date Created",
              "Action",
            ]}
          >
            {notifications?.length > 0 ? (
              notifications?.map((notice, index: number) => (
                <tr key={notice?.id}>
                  <Row value={notice?.title} />
                  <Row
                    value={NotificationType[`${notice?.notificationType}`]}
                  />
                  <Row value={notice?.body} />
                  <Row value={notice?.read ? "Yes" : "No"} />
                  <Row value={getDateTime(notice?.dateTimeCreated)} />
                  <ActionRow style={`flex items-center justify-center gap-3`}>
                    <button
                      className="flex w-[35px] items-center justify-center border-[1px] !border-[#6C18A4] !bg-[#DFA1F4] p-2"
                      onClick={() => markAsReadAction(notice?.id)}
                    >
                      <img
                        src={notice?.read ? eyeopen : eyeclose}
                        loading="lazy"
                        className="h-[16px]"
                        alt=""
                      />
                    </button>
                    <button
                      className="flex w-[35px] items-center justify-center border-[1px] border-[#FF0000] bg-[#FFC8C8] p-2"
                      onClick={() => deleteNotification(notice?.id)}
                    >
                      <img
                        src={deleteIcon}
                        className="h-[16px]"
                        loading="lazy"
                        alt=""
                      />
                    </button>
                  </ActionRow>
                </tr>
              ))
            ) : (
              <tr>
                <Row colSpan={6} value="no notifications yet" />
              </tr>
            )}
          </Table>
        </section>
      </section>
    </section>
  );
};

export default Notifications;
