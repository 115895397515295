// Custom components
import { numbersOnly } from "core/helpers/generalHelpers";
import React, { useState } from "react";

function InputField({
  id = "",
  label = "",
  placeholder = "",
  state = "",
  disabled = false,
  type = "text",
  name = "",
  isRequired = false,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onKeyUp = () => {},
  value,
  error = "",
  isNumberOnly = false,
  dataList = [],
  dataListId = "",
  instruction = "",
  boxStyle = "",
  inputStyle = "",
}: {
  id?: string;
  label?: string;
  placeholder?: string;
  state?: string;
  disabled?: boolean;
  type?: string;
  name?: string;
  isRequired?: boolean;
  onChange?: any;
  onFocus?: any;
  onBlur?: any;
  onKeyUp?: any;
  value?: string | number;
  error?: string;
  dataList?: any;
  dataListId?: string;
  instruction?: string;
  boxStyle?: string;
  inputStyle?: string;
  isNumberOnly?: boolean;
}) {
  const uniqueId =
    id != null && id.length > 0 ? id : Math.random().toString(36).substring(2);

  const [inputType, setInputType] = useState(type);

  return (
    <div className={`${boxStyle}`}>
      {label && label?.length > 0 && (
        <label htmlFor={uniqueId} className={`mb-2 text-[14px] text-brandgray`}>
          {label} {isRequired && <span className="text-red-500">*</span>}
        </label>
      )}

      <div className="relative">
        <input
          disabled={disabled}
          type={inputType}
          id={uniqueId}
          autoComplete="on"
          name={name}
          onChange={onChange}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          onKeyDown={(e) => {
            if (isNumberOnly) {
              numbersOnly(e);
            }
          }}
          onBlur={onBlur}
          placeholder={placeholder}
          value={value}
          list={dataListId}
          aria-autocomplete="none"
          className={`flex h-12 w-full items-center justify-center rounded-[5px] border border-brandgray bg-white p-3 text-sm outline-none disabled:cursor-not-allowed ${
            disabled === true
              ? "!border-none !bg-gray-100"
              : state === "error"
                ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400"
                : state === "success"
                  ? "border-green-500 text-green-500 placeholder:text-green-500"
                  : "border-brandgray"
          } ${inputStyle}`}
        />

        {type === "password" && (
          <div className="absolute right-5 top-3 hover:cursor-pointer">
            {inputType === "password" ? (
              <button
                type="button"
                className="text-brand font-bold h-[24px] w-[30px]"
                onClick={() => setInputType("text")}
              >
                Show
              </button>
            ) : (
              <button
                type="button"
                className="text-brand font-bold h-[24px] w-[30px]"
                onClick={() => setInputType("password")}
              >
                Hide
              </button>
            )}
          </div>
        )}
      </div>

      {dataList?.length > 0 && (
        <datalist id={dataListId}>
          {dataList.map((data: any) => (
            <option key={data?.value} value={data?.value}>
              {data?.name}
            </option>
          ))}
        </datalist>
      )}

      <span className="text-xs text-brandgray">{instruction}</span>
      <span className="text-[14px] text-red-500">{error}</span>
    </div>
  );
}

export default InputField;
