import DeliveryAreas from "modules/partials/DeliveryAreas";

const DeliveryArea = () => {
  return (
    <>
      <DeliveryAreas category="foodstuff" />
    </>
  );
};

export default DeliveryArea;
