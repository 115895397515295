import { apicall } from "./apicall";

export const getSmsVendor = () => {
  return apicall({
    endpoint: "/manage/smsVendor",
    method: "GET",
    auth: true,
  });
};

export const addUpdateSmsVendor = (name: string, vendorId: string) => {
  if (vendorId?.length > 1) {
    return apicall({
      endpoint: "/manage/smsVendor",
      param: vendorId,
      method: "PATCH",
      body: {
        name,
      },
      auth: true,
    });
  } else {
    return apicall({
      endpoint: "/manage/smsVendor",
      method: "POST",
      body: {
        name,
      },
      auth: true,
    });
  }
};

export const updateSmsVendorStatus = (
  vendorId: string,
  status: boolean,
  commission: boolean,
) => {
  return apicall({
    endpoint: "/manage/smsVendorStatus",
    param: vendorId,
    method: "PATCH",
    body: {
      status,
      commission,
    },
    auth: true,
  });
};

export const deleteSmsVendor = (vendorId: string) => {
  return apicall({
    endpoint: "/manage/smsVendor",
    param: vendorId,
    method: "DELETE",
    auth: true,
  });
};
