import Modal from "core/components/Modal";
import Pagination from "core/components/Pagination";
import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import ActionRow from "core/components/table/Actionrow";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import { activeModule, downloadImg } from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import { exportToCSV } from "core/helpers/exportToExcel";
import { getDateTime } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useLocationStore from "core/services/stores/useLocationStore";
import useTransportationStore from "core/services/stores/useTransportationStore";
import useUserStore from "core/services/stores/useUserStore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ManageTrips = () => {
  // need state
  const user = useUserStore((store) => store.user);
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const tripList = useTransportationStore((store) => store.plannedTripList);
  const drivers = useTransportationStore((store) => store.drivers);

  // actions
  const getTripsAction = useTransportationStore(
    (store) => store.getPlannedTrips,
  );

  const getDriversAction = useTransportationStore((store) => store.getDrivers);

  const assignDriverToTripAction = useTransportationStore(
    (store) => store.assignDriverToTrip,
  );

  const [selectedDriver, setSelectedDriver] = useState("");
  const [selectedTrip, setSelectedTrip] = useState<any>(null);

  const [showAssignModal, setShowAssignModal] = useState(false);

  const [query, setQuery] = useState<TripQuery>({
    search: "",
    status: "",
    startDate: "",
    endDate: "",
    locationFilter: !isSuperAdmin ? user?.location : "",
  });

  const onQueryChange = (e: any) => {
    const { name, value } = e.target;
    setQuery((state) => ({ ...state, [name]: value }));
  };

  const search = (e: any) => {
    e.preventDefault();
    getTripsAction(query, tripList?.pageNumber, tripList?.pageSize);
  };

  const fetchMore = async (page: number) => {
    await getTripsAction(query, page, tripList?.pageSize);
  };

  const assignTripToDriver = async (e: any) => {
    e?.preventDefault();

    if (selectedDriver?.length > 0) {
      var res = await assignDriverToTripAction(
        selectedTrip?.id,
        selectedDriver,
      );

      if (res?.isSuccessful) {
        setSelectedTrip(null);
        setSelectedDriver("");
        setShowAssignModal(false);
      }
    } else {
      notification({
        message: "Please choose a driver",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();
    drivers?.length < 1 && getDriversAction();

    getTripsAction(query, tripList?.pageNumber, tripList?.pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mx-auto w-[95%] overflow-x-scroll">
        <header className="mb-[28px] flex flex-col justify-start gap-2 lg:flex-row lg:items-center lg:justify-between">
          <div className="flex items-center gap-3">
            <img src={activeModule} loading="lazy" alt="" />
            <h5 className="text-[18px] font-[500] text-brand">
              Manage Daily Trips
            </h5>
          </div>
          <div className="flex items-center justify-between gap-5">
            <Link
              className="text-brand hover:underline"
              to="/transportation/assign-bus"
            >
              Assign Driver to Bus
            </Link>
            <Link
              className="text-brand hover:underline"
              to="/transportation/plan-trips"
            >
              Plan Trips
            </Link>
            <Link
              className="text-brand hover:underline"
              to="/transportation/driver-schedule"
            >
              Driver Schedule
            </Link>
          </div>
        </header>

        <section>
          <form
            className="mb-[28px] flex items-end justify-between gap-2 overflow-x-scroll rounded-[5px] border border-t-[3px] border-brand bg-white p-3"
            onSubmit={search}
          >
            <InputField
              label="Search"
              boxStyle="min-w-[200px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="search"
              value={query?.search}
              onChange={onQueryChange}
            />

            <SelectField
              label="Select Location"
              boxStyle="min-w-[200px]"
              selectStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              defaultName="Select your Location"
              defaultValue=""
              name="locationFilter"
              options={
                locations?.length > 0
                  ? [
                      ...locations?.map((location) => ({
                        name: location?.name,
                        value: location?.name,
                      })),
                    ]
                  : []
              }
              value={query?.locationFilter}
              onChange={onQueryChange}
            />

            <InputField
              label="Start Date"
              type="date"
              boxStyle="min-w-[200px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="startDate"
              value={query?.startDate}
              onChange={onQueryChange}
            />

            <InputField
              label="End  Date"
              type="date"
              boxStyle="min-w-[200px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="endDate"
              value={query?.endDate}
              onChange={onQueryChange}
            />

            <div className="flex min-w-[400px] items-center gap-3">
              <button className="flex h-[50px] items-center justify-center gap-3 rounded-[5px] border border-brand bg-[#DFA1F4] p-3  px-8 text-white">
                <span className="text-[14px text-brand">Search</span>
              </button>

              <button
                type="button"
                disabled={
                  tripList == null || tripList?.plannedTrips?.length < 1
                }
                className="flex h-[50px] items-center justify-between gap-3 rounded-[5px] bg-brand p-3 text-white disabled:cursor-not-allowed"
                onClick={() => {
                  exportToCSV(tripList?.plannedTrips, "Planned Trips");

                  notification({
                    message: "You have successfully exported the data to excel",
                    type: "success",
                  });
                }}
              >
                <span className="text-[14px]">Download</span>
                <img src={downloadImg} alt="" loading="lazy" />
              </button>
            </div>
          </form>
        </section>
        <section className="mb-[28px]">
          <Table
            headers={[
              "Route",
              "Date",
              "Day",
              "Trip Time",
              "Assigned Driver",
              "Status",
              "Action",
            ]}
          >
            {tripList?.plannedTrips?.length > 0 ? (
              tripList?.plannedTrips?.map((trip) => (
                <tr key={trip?.id}>
                  <Row value={trip?.tripName} />
                  <Row value={getDateTime(trip?.departureDate)} />
                  <Row value={trip?.departureDay} />
                  <Row value={trip?.departureTime} />
                  <Row value={trip?.assignedDriver} />
                  <Row value={trip?.status} />
                  <ActionRow style={`flex items-center justify-center gap-3`}>
                    <button
                      onClick={() => {
                        setSelectedTrip(trip);
                        setSelectedDriver(
                          drivers?.find(
                            (x) => x?.fullName === trip?.assignedDriver,
                          )?.agentId!,
                        );
                        setShowAssignModal(true);
                      }}
                      disabled={isSuperAdmin}
                      className={`${actionbtn} !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                    >
                      Assign to Driver
                    </button>
                  </ActionRow>
                </tr>
              ))
            ) : (
              <tr>
                <Row colSpan={7} value="No planned trip yet" />
              </tr>
            )}
          </Table>

          <Pagination
            pageNumber={tripList?.pageNumber}
            pageSize={tripList?.pageSize}
            totalCount={tripList?.totalCount}
            totalPage={tripList?.totalPage}
            onFetch={fetchMore}
          />
        </section>
      </div>

      {showAssignModal && (
        <Modal
          header="Assign Trip to Driver"
          onClose={() => {
            setSelectedDriver("");
            setSelectedTrip(null);
            setShowAssignModal(false);
          }}
        >
          <form onSubmit={assignTripToDriver}>
            <SelectField
              boxStyle="mb-[18px]"
              label="Select Driver"
              name="selectedDriver"
              defaultName="Select Driver"
              defaultValue=""
              options={
                drivers?.length > 0
                  ? [
                      ...drivers?.map((driver) => ({
                        name: driver?.fullName,
                        value: driver?.agentId,
                      })),
                    ]
                  : []
              }
              value={selectedDriver}
              onChange={(e: any) => setSelectedDriver(e?.target?.value)}
            />

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setSelectedDriver("");
                  setSelectedTrip(null);
                  setShowAssignModal(false);
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default ManageTrips;
