import FileUploadField from "core/components/formfields/FileUploadField";
import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import { actionbtn, btn } from "core/consts/styling";
import { MARKETPLACE_CATEGORY_TYPES } from "core/consts/systemConst";
import notification from "core/helpers/notification";
import useMarketPlaceStore from "core/services/stores/useMarketPlaceStore";
import { useEffect, useState } from "react";

interface Props {
  category: string;
  product: any;
  isUpdate: boolean;
  isSuperAdmin: boolean;
}

const MediaProductionForm = ({
  category = "",
  product = null,
  isUpdate = false,
  isSuperAdmin = false,
}: Props) => {
  const [files, setFiles] = useState([]);

  const addUpdateProductAction = useMarketPlaceStore(
    (store) => store.addUpdateProduct,
  );
  const deleteImageAction = useMarketPlaceStore(
    (store) => store.deleteProductImage,
  );

  const [newProduct, setNewProduct] = useState<NewMediaProduction>({
    name: "",
    category: category,
    serviceProviderAddress: "",
    serviceType: "",
    convenienceFee: 0,
    amountColored: 0,
    amountBW: 0,
  });

  const [errors, setErrors] = useState({
    Name: [],
    ServiceProviderAddress: [],
    ServiceType: [],
    ConvenienceFee: [],
    AmountColored: [],
    AmountBW: [],
  });

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const removeImage = (index: number) => {
    setFiles([...files.filter((file, i) => i !== index)]);
  };

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;
    setNewProduct((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const validation = (data: NewMediaProduction) => {
    var isValid = true;

    if (data?.name?.length < 1) {
      isValid = false;
      onErrorChange("Name", ["Service Name is required"]);
    }

    if (data?.serviceProviderAddress?.length < 1) {
      isValid = false;
      onErrorChange("ServiceProviderAddress", [
        "Service Provider Address is required",
      ]);
    }

    if (data?.amountColored < 0) {
      isValid = false;
      onErrorChange("AmountColored", [
        "Amount (Colored Print) must not be less than 0",
      ]);
    }

    if (data?.amountBW < 0) {
      isValid = false;
      onErrorChange("AmountBW", [
        "Amount (Black/White Print) must not be less than 0",
      ]);
    }

    if (data?.convenienceFee < 0) {
      isValid = false;
      onErrorChange("ConvenienceFee", [
        "ConvenienceFee must not be less than 0",
      ]);
    }

    return isValid;
  };

  const addUpdateProduct = async (e: any) => {
    e.preventDefault();

    if (validation(newProduct)) {
      var res = await addUpdateProductAction(
        {
          ...newProduct,
          filess: files,
        },
        isUpdate ? product?.productId : "",
      );

      if (res?.isSuccessful) {
        !isUpdate &&
          setNewProduct({
            name: "",
            category: category,
            serviceProviderAddress: "",
            convenienceFee: 0,
            amountColored: 0,
            amountBW: 0,
            serviceType: "",
          });
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const deleteImage = async (url: any) => {
    if (window.confirm(`Click 'OK' to delete this image from the server.`)) {
      await deleteImageAction("product", product?.productId, url);
    }
  };

  useEffect(() => {
    isUpdate &&
      product != null &&
      setNewProduct({
        category: category,
        name: product?.name,
        amountBW: product?.amountBW,
        amountColored: product?.amountColored,
        serviceProviderAddress: product?.serviceProviderAddress,
        convenienceFee: product?.convenienceFee,
        serviceType: product?.serviceType,
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate, product]);

  return (
    <form onSubmit={addUpdateProduct} className="mb-[24px] flex gap-5">
      <div className="w-3/5">
        <InputField
          boxStyle="mb-[18px]"
          placeholder="Service Name"
          label="Service Name"
          name="name"
          value={newProduct?.name}
          onChange={(e: any) => onFormChange(e)}
          error={errors?.Name[0]}
          onBlur={() => onErrorChange("Name", [])}
        />

        <SelectField
          boxStyle="mb-[18px]"
          selectStyle="!border-brand text-[#615353]"
          name="serviceType"
          defaultName="Select Service Type"
          label="Service Type"
          defaultValue=""
          options={[
            {
              name: "Normal",
              value: "Normal",
            },
            {
              name: "Express",
              value: "Express",
            },
          ]}
          value={newProduct?.serviceType}
          onChange={(e: any) => onFormChange(e)}
          error={errors?.ServiceType[0]}
          onBlur={() => onErrorChange("ServiceType", [])}
        />

        <InputField
          boxStyle="mb-[18px]"
          placeholder="Service Provider Address"
          label="Service Provider Address"
          name="serviceProviderAddress"
          value={newProduct?.serviceProviderAddress}
          onChange={(e: any) => onFormChange(e)}
          error={errors?.ServiceProviderAddress[0]}
          onBlur={() => onErrorChange("ServiceProviderAddress", [])}
        />

        <div className="mb-[18px] flex items-center justify-between gap-3">
          <InputField
            boxStyle="w-1/2"
            label="Amount (Black/White Print)"
            placeholder="Amount (Black/White Print)"
            name="amountBW"
            value={newProduct?.amountBW}
            type="number"
            onChange={(e: any) => onFormChange(e)}
            error={errors?.AmountBW[0]}
            onBlur={() => onErrorChange("AmountBW", [])}
          />
          <InputField
            boxStyle="w-1/2"
            label="Amount (Colored Print)"
            placeholder="Amount (Colored Print)"
            name="amountColored"
            value={newProduct?.amountColored}
            type="number"
            onChange={(e: any) => onFormChange(e)}
            error={errors?.AmountColored[0]}
            onBlur={() => onErrorChange("AmountColored", [])}
          />
        </div>

        <InputField
          boxStyle="mb-[18px]"
          placeholder="Convenience Fee"
          label="Convenience Fee"
          name="convenienceFee"
          value={newProduct?.convenienceFee}
          type="number"
          onChange={(e: any) => onFormChange(e)}
          error={errors?.ConvenienceFee[0]}
          onBlur={() => onErrorChange("ConvenienceFee", [])}
        />

        <button
          disabled={isSuperAdmin}
          className={`${btn} w-full bg-brand text-white`}
        >
          Submit
        </button>
      </div>
      <div className="w-2/6">
        {category !== MARKETPLACE_CATEGORY_TYPES.Media_Production && (
          <>
            <FileUploadField
              multiple={true}
              boxStyle="w-3/5"
              label="Upload Pictures"
              name="signature"
              onChange={setFiles}
              showUploadedImages={false}
            />

            <div className="mt-[16px] grid grid-cols-2 gap-3 ">
              {files?.length > 0 &&
                files.map((file: any, index: number) => (
                  <div
                    key={file?.name + index}
                    className="relative overflow-hidden rounded-[5px] border border-brandLight"
                  >
                    <img src={URL.createObjectURL(file)} alt={`logo${index}`} />
                    <button
                      type="button"
                      className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                      onClick={() => removeImage(index)}
                    >
                      Delete
                    </button>
                  </div>
                ))}

              {isUpdate &&
                product?.uploadedImageList?.length > 0 &&
                product?.uploadedImageList?.map(
                  (image: string, index: number) => (
                    <div
                      key={index}
                      className="relative overflow-hidden rounded-[5px] border border-brandLight"
                    >
                      <img src={image} alt={`logo${index}`} />
                      <button
                        type="button"
                        className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                        onClick={() => deleteImage(image)}
                      >
                        Delete
                      </button>
                    </div>
                  ),
                )}
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default MediaProductionForm;
