import { apicall } from "./apicall";

export const getAccommodationDashboard = (query: DateLocationQuery) => {
  return apicall({
    endpoint: "/accommodation/dashboard",
    method: "GET",
    pQuery: {
      ...query,
    },
    auth: true,
  });
};

export const getProperty = (
  query: AccommodationQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: "/accommodation",
    method: "GET",
    pQuery: {
      ...query,
      pageNumber,
      pageSize,
    },
    auth: true,
  });
};

export const getPropertyByID = (accommodationId: string) => {
  return apicall({
    endpoint: "/accommodation",
    param: accommodationId,
    method: "GET",
    auth: true,
  });
};

export const addUpdateProperty = async (
  property: NewProperty,
  accommodationId: string,
) => {
  const data = new FormData();

  data.append("apartmentType", property?.apartmentType);
  data.append("amenities", property?.amenities);
  data.append("propertyAddress", property?.propertyAddress);
  data.append("area", property?.area);
  data.append("otherClause", property?.otherClause);
  data.append("agentName", property?.agentName);
  data.append("agentContact", property?.agentContact);
  data.append("landlordName", property?.landlordName);
  data.append("landlordContact", property?.landlordContact);

  data.append("rent", `${property?.rent}`);
  data.append("commission", `${property?.commission}`);

  if (property?.images?.length > 0) {
    property?.images.forEach((file: any, i: any) => {
      data.append(`uploadedImageList${i}`, file);
    });
  }

  var isUpdate = accommodationId?.length > 0;

  return apicall({
    endpoint: "/accommodation",
    param: isUpdate ? accommodationId : "",
    method: isUpdate ? "PATCH" : "POST",
    multipart: true,
    auth: true,
    body: data,
  });
};

export const toggleAccommodation = (
  accommodationId: string,
  visibility: boolean,
) => {
  return apicall({
    endpoint: "/accommodation/toggle",
    param: accommodationId,
    method: "PATCH",
    body: {
      visibility,
    },
    auth: true,
  });
};

export const updatePropertyStatus = (
  accommodationId: string,
  status: string,
) => {
  return apicall({
    endpoint: "/accommodation/status",
    param: accommodationId,
    method: "PATCH",
    body: {
      status,
    },
    auth: true,
  });
};

export const deleteProperty = (accommodationId: string) => {
  return apicall({
    endpoint: "/accommodation",
    param: accommodationId,
    method: "DELETE",
    auth: true,
  });
};

export const deletePropertyImage = (
  category: string,
  accommodationId: string,
  image: string,
) => {
  return apicall({
    endpoint: "/accommodation/image",
    method: "DELETE",
    body: {
      itemId: accommodationId,
      category,
      imagePath: image,
    },
    auth: true,
  });
};

export const getInspectionRequest = (
  query: InspectionQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: "/propertyrent/inspection/requests",
    method: "GET",
    pQuery: {
      ...query,
      pageNumber,
      pageSize,
    },
    auth: true,
  });
};

export const updateInspectionStatus = (
  requestId: string,
  status: string,
  reason: string,
) => {
  return apicall({
    endpoint: "/propertyrent/inspection/request",
    param: requestId,
    method: "PATCH",
    body: {
      status,
      reason,
    },
    auth: true,
  });
};
