import { actionbtn } from "core/consts/styling";
import useAdminStore from "core/services/stores/useAdminStore";
import { useEffect, useState } from "react";
import Modal from "core/components/Modal";
import InputField from "core/components/formfields/InputField";
import notification from "core/helpers/notification";
import { isEmail } from "core/helpers/generalHelpers";
import { addWhiteIcon, male, profileuser } from "core/consts/images";

const SupportList = () => {
  const supports = useAdminStore((store) => store.supportPersonnels);
  const getSupports = useAdminStore((store) => store.getSupportPersonnels);
  const addSupportAction = useAdminStore((store) => store.addSupportPersonnel);
  const updateSupportAction = useAdminStore(
    (store) => store.updateSupportPersonnel,
  );
  const deleteSupportAction = useAdminStore(
    (store) => store.deleteSupportPersonnel,
  );

  const [errors, setErrors] = useState<any>({
    Email: [],
    FirstName: [],
    LastName: [],
    Password: [],
    ConfirmPassword: [],
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedSupport, setSelectedSupport] = useState<SupportPersonnel>({
    email: "",
    firstName: "",
    lastName: "",
    supportId: "",
  });
  const [newPersonnel, setNewPersonnel] = useState<NewSupportPersonnel>({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
  });

  const resetError = () => {
    setErrors({
      Email: [],
      FirstName: [],
      LastName: [],
      Password: [],
      ConfirmPassword: [],
    });
  };

  const onFormChange = (e: any, action: string = "add") => {
    const { name, value } = e?.target;

    switch (action) {
      case "add":
        setNewPersonnel((state) => ({
          ...state,
          [name]: value,
        }));
        break;
      case "update":
        setSelectedSupport((state: any) => ({
          ...state,
          [name]: value,
        }));
        break;
      default:
        break;
    }
  };

  const validateNew = (support: NewSupportPersonnel) => {
    var isValid = true;

    if (support?.firstName?.length < 1) {
      setErrors((state: any) => ({
        ...state,
        FirstName: ["First name is required"],
      }));
      isValid = false;
    }

    if (support?.lastName?.length < 1) {
      setErrors((state: any) => ({
        ...state,
        LastName: ["Last name is required"],
      }));
      isValid = false;
    }

    if (!isEmail(support?.email)) {
      setErrors((state: any) => ({
        ...state,
        Email: ["Email should be a valid email address."],
      }));
      isValid = false;
    }

    if (support?.password?.length < 1) {
      setErrors((state: any) => ({
        ...state,
        Password: ["Password is required"],
      }));
      isValid = false;
    } else {
      if (support?.confirmPassword !== support?.password) {
        setErrors((state: any) => ({
          ...state,
          ConfirmPassword: ["Confirm password must be equal to password"],
        }));
        isValid = false;
      }
    }

    return isValid;
  };

  const validateUpdate = (support: SupportPersonnel) => {
    var isValid = true;

    if (support?.firstName?.length < 1) {
      setErrors((state: any) => ({
        ...state,
        FirstName: ["First name is required"],
      }));
      isValid = false;
    }

    if (support?.lastName?.length < 1) {
      setErrors((state: any) => ({
        ...state,
        LastName: ["Last name is required"],
      }));
      isValid = false;
    }

    if (!isEmail(support?.email)) {
      setErrors((state: any) => ({
        ...state,
        Email: ["Email should be a valid email address."],
      }));
      isValid = false;
    }

    return isValid;
  };

  const addSupport = async (e: any) => {
    e.preventDefault();

    if (validateNew(newPersonnel)) {
      var res = await addSupportAction(newPersonnel);

      if (res?.isSuccessful) {
        setNewPersonnel({
          email: "",
          firstName: "",
          lastName: "",
          password: "",
          confirmPassword: "",
        });
        setShowAddModal(false);
      } else {
        if (res?.data?.errors != null) {
          setErrors(res?.data?.errors);
        }
      }
    } else {
      notification({
        title: "",
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const updateSupport = async (e: any) => {
    e.preventDefault();

    if (validateUpdate(selectedSupport)) {
      var res = await updateSupportAction(selectedSupport);

      if (!res?.isSuccessful) {
        if (res?.data?.errors != null) {
          setErrors(res?.data?.errors);
        }
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const deleteSupport = async (support: SupportPersonnel) => {
    if (
      window.confirm(`Click 'OK' to delete ${support?.firstName} as a support`)
    ) {
      await deleteSupportAction(support?.supportId);
    }
  };

  useEffect(() => {
    supports?.length < 1 && getSupports();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="mx-auto w-[95%]">
        <header className="mb-[14px] flex items-end gap-3">
          <img src={profileuser} loading="lazy" alt="" />
          <h5 className="text-[18px] font-[500] text-brand">Support Users</h5>
        </header>
        <div>
          <button
            onClick={() => setShowAddModal(true)}
            className="flex w-full items-center justify-between rounded-[5px] bg-brand px-4 py-3 text-[14px] text-white shadow-md sm:w-1/2 md:w-[300px]"
          >
            <span>Add New Support Personnel</span>
            <img src={addWhiteIcon} loading="lazy" alt="" />
          </button>
          {supports?.length > 0 ? (
            <div className="my-[24px] grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
              {supports?.map((support: SupportPersonnel) => (
                <>
                  <div
                    key={support?.supportId}
                    className="flex flex-col items-center rounded-[5px] border-[1px] border-brand bg-white p-5 shadow-md"
                  >
                    <img src={male} loading="lazy" alt="" />
                    <p className="text-[14px] font-bold">
                      {support?.firstName} {support?.lastName}
                    </p>
                    <p className="mb-[28px] text-[12px] text-[#868686]">
                      {support?.email}
                    </p>
                    <button
                      onClick={() => {
                        setSelectedSupport(support);
                        setShowViewModal(true);
                      }}
                      className={`${actionbtn} mb-[10px] bg-[#F2EAF8]`}
                    >
                      View Support
                    </button>
                    <button
                      onClick={() => {
                        setSelectedSupport(support);
                        setShowUpdateModal(true);
                      }}
                      className={`${actionbtn} mb-[15px] bg-[#DFA1F4]`}
                    >
                      Edit Support
                    </button>
                    <button
                      onClick={() => deleteSupport(support)}
                      className="text-[12px] text-[#D80303] disabled:cursor-not-allowed"
                    >
                      Delete Support
                    </button>
                  </div>
                </>
              ))}
            </div>
          ) : (
            <div className="my-[24px]">
              <p>There are no support personnels at the moment</p>
            </div>
          )}
        </div>
      </section>

      {showAddModal && (
        <Modal
          header="Add Support Personnel"
          onClose={() => {
            setShowAddModal(false);
            resetError();
          }}
        >
          <form onSubmit={addSupport}>
            <InputField
              placeholder="First Name"
              boxStyle="mb-[24px]"
              name="firstName"
              value={newPersonnel?.firstName}
              onChange={(e: any) => onFormChange(e, "add")}
              error={errors?.FirstName[0]}
              onBlur={() => {
                setErrors((state: any) => ({
                  ...state,
                  FirstName: [],
                }));
              }}
            />
            <InputField
              placeholder="Last Name"
              boxStyle="mb-[24px]"
              name="lastName"
              value={newPersonnel?.lastName}
              onChange={(e: any) => onFormChange(e, "add")}
              error={errors?.LastName[0]}
              onBlur={() => {
                setErrors((state: any) => ({
                  ...state,
                  LastName: [],
                }));
              }}
            />
            <InputField
              placeholder="Email Address"
              boxStyle="mb-[24px]"
              name="email"
              value={newPersonnel?.email}
              onChange={(e: any) => onFormChange(e, "add")}
              error={errors?.Email[0]}
              onBlur={() => {
                setErrors((state: any) => ({
                  ...state,
                  Email: [],
                }));
              }}
            />
            <InputField
              placeholder="Password"
              boxStyle="mb-[24px]"
              name="password"
              type="password"
              value={newPersonnel?.password}
              onChange={(e: any) => onFormChange(e, "add")}
              error={errors?.Password[0]}
              onBlur={() => {
                setErrors((state: any) => ({
                  ...state,
                  Password: [],
                }));
              }}
            />
            <InputField
              placeholder="Confirm Password"
              boxStyle="mb-[24px]"
              name="confirmPassword"
              type="password"
              value={newPersonnel?.confirmPassword}
              onChange={(e: any) => onFormChange(e, "add")}
              error={errors?.ConfirmPassword[0]}
              onBlur={() => {
                setErrors((state: any) => ({
                  ...state,
                  ConfirmPassword: [],
                }));
              }}
            />
            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setShowAddModal(false);
                  resetError();
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Add
              </button>
            </div>
          </form>
        </Modal>
      )}

      {showViewModal && (
        <Modal
          header="Support Personnel"
          onClose={() => {
            setShowViewModal(false);
            setSelectedSupport({
              email: "",
              firstName: "",
              lastName: "",
              supportId: "",
            });
          }}
        >
          <div>
            <div
              key={selectedSupport?.supportId}
              className="flex flex-col items-center"
            >
              <img src={male} alt="" loading="lazy" />
              <div className="my-[28px] text-center text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  First Name
                </span>
                <br />
                {selectedSupport?.firstName}
              </div>
              <div className="mb-[28px] text-center text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  Last Name
                </span>
                <br />
                {selectedSupport?.lastName}
              </div>
              <div className="mb-[28px] text-center text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  Email
                </span>
                <br />
                {selectedSupport?.email}
              </div>
            </div>
            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setShowViewModal(false);
                  setSelectedSupport({
                    email: "",
                    firstName: "",
                    lastName: "",
                    supportId: "",
                  });
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}

      {showUpdateModal && (
        <Modal
          header="Update Support Personnel"
          onClose={() => {
            setShowUpdateModal(false);
            setSelectedSupport({
              email: "",
              firstName: "",
              lastName: "",
              supportId: "",
            });
            resetError();
          }}
        >
          <form onSubmit={updateSupport}>
            <InputField
              placeholder="First Name"
              boxStyle="mb-[24px]"
              name="firstName"
              value={selectedSupport?.firstName}
              onChange={(e: any) => onFormChange(e, "update")}
              error={errors?.FirstName[0]}
              onBlur={() => {
                setErrors((state: any) => ({
                  ...state,
                  FirstName: [],
                }));
              }}
            />
            <InputField
              placeholder="Last Name"
              boxStyle="mb-[24px]"
              name="lastName"
              value={selectedSupport?.lastName}
              onChange={(e: any) => onFormChange(e, "update")}
              error={errors?.LastName[0]}
              onBlur={() => {
                setErrors((state: any) => ({
                  ...state,
                  LastName: [],
                }));
              }}
            />
            <InputField
              placeholder="Email Address"
              boxStyle="mb-[24px]"
              name="email"
              value={selectedSupport?.email}
              onChange={(e: any) => onFormChange(e, "update")}
              error={errors?.Email[0]}
              onBlur={() => {
                setErrors((state: any) => ({
                  ...state,
                  Email: [],
                }));
              }}
            />

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setShowUpdateModal(false);
                  setSelectedSupport({
                    email: "",
                    firstName: "",
                    lastName: "",
                    supportId: "",
                  });
                  resetError();
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default SupportList;
