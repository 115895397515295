import { MARKETPLACE_CATEGORY_TYPES } from "core/consts/systemConst";
import { apicall } from "./apicall";

export const getMarketplaceAnalytics = (query: DateLocationQuery) => {
  return apicall({
    endpoint: "/marketplace/dashboard",
    method: "GET",
    pQuery: {
      ...query,
    },
    auth: true,
  });
};

export const getMarketPlaceProducts = (
  query: ProductQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: "/marketplace/product",
    param: query?.category,
    method: "GET",
    pQuery: {
      ...query,
      pageNumber,
      pageSize,
    },
    auth: true,
  });
};

export const getMarketPlaceProductById = (
  category: string,
  productId: string,
) => {
  return apicall({
    endpoint: "/marketplace/product",
    param: `${category}/${productId}`,
    method: "GET",
    auth: true,
  });
};

export const getMarketPlaceServices = (
  query: ProductQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: "/marketplace/service",
    method: "GET",
    pQuery: {
      ...query,
      pageNumber,
      pageSize,
    },
    auth: true,
  });
};

export const getMarketPlaceOrders = (
  query: ProductQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: "/marketplace/order",
    param: query?.category,
    method: "GET",
    pQuery: {
      endDate: query?.endDate,
      startDate: query?.startDate,
      userId: query?.userId,
      locationFilter: query?.locationFilter,
      searchText: query?.searchText,
      orderStatus: query?.orderStatus,
      pageNumber,
      pageSize,
    },
    auth: true,
  });
};

export const getMarketSalesHistory = (query: DateLocationQuery) => {
  return apicall({
    endpoint: "/marketplace/sales_history",
    method: "GET",
    pQuery: {
      ...query,
    },
    auth: true,
  });
};

export const AddUpdateMarketProduct = async (
  product: any,
  productId: string,
) => {
  const data = new FormData();

  switch (product?.category) {
    case MARKETPLACE_CATEGORY_TYPES.Fast_Food.valueOf():
      data.append("amount", `${product?.amount}`);
      data.append("vendor", product?.vendor);
      break;
    case MARKETPLACE_CATEGORY_TYPES.Gas_Refill.valueOf():
      data.append("amount", `${product?.amount}`);
      data.append("convenienceFee", `${product?.convenienceFee}`);
      break;
    case MARKETPLACE_CATEGORY_TYPES.Hotel.valueOf():
      data.append("address", product?.address);
      data.append(
        "hotelCategories",
        JSON.stringify({ hotelCategories: product?.hotelCategories }),
      );
      data.append("convenienceFee", `${product?.convenienceFee}`);
      break;
    case MARKETPLACE_CATEGORY_TYPES.Media_Production.valueOf():
      data.append("amountBW", `${product?.amountBW}`);
      data.append("amountColored", `${product?.amountColored}`);
      data.append("serviceProviderAddress", product?.serviceProviderAddress);
      data.append("convenienceFee", `${product?.convenienceFee}`);
      data.append("serviceType", `${product?.serviceType}`);
      break;
    case MARKETPLACE_CATEGORY_TYPES.Event_Ticket.valueOf():
      data.append("venue", product?.venue);
      data.append("eventDate", product?.eventDate);
      data.append("eventTime", product?.eventTime);
      data.append("ticketType", product?.ticketType);
      data.append(
        "eventCategories",
        JSON.stringify({ eventCategories: product?.eventCategories }),
      );
      data.append("convenienceFee", `${product?.convenienceFee}`);
      break;
    default:
      break;
  }

  data.append("name", product?.name);
  data.append("category", product?.category);
  data.append("description", product?.description);

  if (product?.filess?.length > 0) {
    product?.filess.forEach((file: any, i: any) => {
      data.append(`uploadedImageList${i}`, file);
    });
  }

  var isUpdate = productId?.length > 0;

  return apicall({
    endpoint: "/marketPlace/product",
    param: isUpdate ? `${product?.category}/${productId}` : product?.category,
    method: isUpdate ? "PATCH" : "POST",
    multipart: true,
    auth: true,
    body: data,
  });
};

export const toggleMarketProductVisibility = (
  category: string,
  productId: string,
  visibility: boolean,
) => {
  return apicall({
    endpoint: "/marketplace/product/toggle",
    param: `${category}/${productId}`,
    method: "PATCH",
    body: {
      visibility,
    },
    auth: true,
  });
};

export const deleteMarketProduct = (category: string, productId: string) => {
  return apicall({
    endpoint: "/marketplace/product",
    param: `${category}/${productId}`,
    method: "DELETE",
    auth: true,
  });
};

export const deleteMarketProductImage = (
  category: string,
  productId: string,
  image: string,
) => {
  return apicall({
    endpoint: "/marketplace/image",
    method: "DELETE",
    body: {
      itemId: productId,
      category: category,
      imagePath: image,
    },
    auth: true,
  });
};

export const AddUpdateService = async (
  service: NewMarketplaceService,
  serviceId: string,
) => {
  const data = new FormData();

  data.append("category", service?.category);
  data.append("businessName", service?.businessName);
  data.append("phoneNumbers", service?.phoneNumbers);
  data.append("email", service?.email);
  data.append("description", service?.description);
  data.append("address", service?.address);
  data.append("twitter", service?.twitter);
  data.append("instagram", service?.instagram);
  data.append("serviceFee", `${service?.serviceFee}`);

  if (service?.filess?.length > 0) {
    service?.filess.forEach((file: any, i: any) => {
      data.append(`uploadedImageList${i}`, file);
    });
  }

  var isUpdate = serviceId?.length > 0;

  return apicall({
    endpoint: isUpdate
      ? "/marketplace/service"
      : "/marketPlace/createServiceByAdmin",
    param: isUpdate ? serviceId : "",
    method: isUpdate ? "PATCH" : "POST",
    multipart: true,
    auth: true,
    body: data,
  });
};

export const toggleMarketServiceVisibility = (
  serviceId: string,
  visibility: boolean,
) => {
  return apicall({
    endpoint: "/marketplace/service/toggle",
    param: serviceId,
    method: "PATCH",
    body: {
      visibility,
    },
    auth: true,
  });
};

export const cancelMarketService = (serviceId: string, comment: string) => {
  return apicall({
    endpoint: "/marketplace/cancelService",
    param: serviceId,
    method: "PATCH",
    body: {
      comment,
    },
    auth: true,
  });
};

export const deleteMarketService = (serviceId: string) => {
  return apicall({
    endpoint: "/marketplace/service",
    param: serviceId,
    method: "DELETE",
    auth: true,
  });
};

export const getMarketServiceById = (serviceId: string) => {
  return apicall({
    endpoint: "/marketplace/service",
    param: serviceId,
    method: "GET",
    auth: true,
  });
};

/* VENDORS */
export const getFastFoodVendors = () => {
  return apicall({
    endpoint: "/marketplace/fastfood/vendor",
    method: "GET",
    auth: true,
  });
};

export const getFastFoodVendorById = (vendorId: string) => {
  return apicall({
    endpoint: "/marketplace/fastfood/vendor",
    param: vendorId,
    method: "GET",
    auth: true,
  });
};

export const addUpdateFastFoodVendor = async (
  vendor: NewFastFoodVendor,
  vendorId: string,
) => {
  const data = new FormData();

  data.append("vendorName", vendor?.vendorName);
  data.append("address", vendor?.address);
  data.append("operationDays", vendor?.operationDays);
  data.append("operationTime", vendor?.operationTime);
  data.append("convenienceFee", `${vendor?.convenienceFee}`);

  if (vendor?.logo?.length > 0) {
    vendor?.logo.forEach((file: any, i: any) => {
      data.append(`logo${i}`, file);
    });
  }

  var isUpdate = vendorId?.length > 0;

  return apicall({
    endpoint: "/marketplace/fastfood/vendor",
    param: isUpdate ? vendorId : "",
    method: isUpdate ? "PATCH" : "POST",
    multipart: true,
    auth: true,
    body: data,
  });
};

export const deleteFastFoodVendor = (vendorId: string) => {
  return apicall({
    endpoint: "/marketplace/fastfood/vendor",
    param: vendorId,
    method: "DELETE",
    auth: true,
  });
};

export const deleteFastFoodVendorImage = (
  category: string,
  vendorId: string,
  image: string,
) => {
  return apicall({
    endpoint: "/fastfood/vendor/image",
    method: "DELETE",
    body: {
      vendorId,
      category,
      imagePath: image,
    },
    auth: true,
  });
};

// directory service
export const addUpdateDirectoryService = (
  service: NewDirectoryService,
  id: string,
) => {
  return apicall({
    endpoint: "/marketplace/directoryServiceFee",
    param: id?.length > 1 ? id : "",
    method: id?.length > 1 ? "PATCH" : "POST",
    body: {
      subscriptionFee: +service?.subscriptionFee,
      convenienceFee: +service?.convenienceFee,
      locationId: service?.locationId,
    },
    auth: true,
  });
};

export const getDirectoryService = (pageNumber: number, pageSize: number) => {
  return apicall({
    endpoint: "/marketplace/directoryServiceFee",
    method: "GET",
    pQuery: {
      pageNumber,
      pageSize,
    },
    auth: true,
  });
};

export const getMarketplaceOrderById = (category: string, orderId: string) => {
  return apicall({
    endpoint: `/marketplace/order`,
    param: `${category}/${orderId}`,
    method: "GET",
    auth: true,
  });
};

export const getOrderStatus = (orderId: string) => {
  return apicall({
    endpoint: "/GetorderStatus",
    param: orderId,
    method: "GET",
    auth: true,
  });
};
