import Modal from "core/components/Modal";
import TextField from "core/components/formfields/TextField";
import { btn } from "core/consts/styling";
import {
  PROCESS_INSPECTION_REQUEST,
  PROCESS_ORDER,
} from "core/consts/systemConst";
import notification from "core/helpers/notification";
import { useState } from "react";

interface Props {
  onShow?: any;
  show: boolean;
  onCancel?: any;
  order?: any;
  header?: string;
}

const CancelReasonForm = ({
  show = false,
  onShow = () => {},
  onCancel = () => {},
  header = "Order Cancellation",
  order = null,
}: Props) => {
  const [reason, setReason] = useState("");

  const submitForm = async (e: any) => {
    e.preventDefault();

    if (reason?.length > 1) {
      onCancel(
        order,
        header?.includes("Inspection")
          ? PROCESS_INSPECTION_REQUEST.declined
          : PROCESS_ORDER.cancel,
      );
    } else {
      notification({
        message: "Please pass a reason for cancellation",
        type: "danger",
      });
    }
  };

  return (
    <>
      {show && (
        <Modal
          header={header}
          onClose={() => {
            onShow(false);
            setReason("");
          }}
        >
          <form onSubmit={submitForm}>
            <TextField
              boxStyle="mb-[24px]"
              placeholder="Reason for Cancellation"
              name="reason"
              value={reason}
              onChange={(e: any) => setReason(e?.target?.value)}
            />

            <button className={`${btn} w-full bg-brand text-white`}>
              Submit
            </button>
          </form>
        </Modal>
      )}
    </>
  );
};

export default CancelReasonForm;
