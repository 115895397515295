import { apicall } from "./apicall";

export const getLocations = () => {
  return apicall({
    endpoint: "/location",
    method: "GET",
  });
};

export const getLocationById = (id: string) => {
  return apicall({
    endpoint: "/location",
    param: id,
    method: "GET",
  });
};

export const addLocation = (newLocation: NewCampusLocation) => {
  return apicall({
    endpoint: "/location/create",
    method: "POST",
    body: {
      ...newLocation,
    },
  });
};

export const updateLocation = (location: CampusLocation) => {
  return apicall({
    endpoint: "/location/update",
    param: location?.id,
    method: "PATCH",
    body: {
      name: location?.name,
      state: location?.state,
      lga: location?.lga,
      address: location?.address,
    },
  });
};

export const toggleLocation = (id: string, isActive: boolean) => {
  return apicall({
    endpoint: "/location/toggle",
    param: id,
    method: "PATCH",
    body: {
      isActive,
    },
  });
};

export const deleteLocationById = (id: string) => {
  return apicall({
    endpoint: "/location/remove",
    param: id,
    method: "DELETE",
  });
};

export const getDeliveryArea = (
  query: DeliveryAreaQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: "/deliveryArea",
    method: "GET",
    pQuery: { ...query, pageNumber, pageSize },
    auth: true,
  });
};

export const addUpdateDeliveryArea = (
  area: NewDeliveryArea,
  areaId: string,
) => {
  const isUpdate = areaId?.length > 0;

  return apicall({
    endpoint: "/deliveryArea",
    param: isUpdate ? areaId : "",
    method: isUpdate ? "PATCH" : "POST",
    body: {
      ...area,
      deliveryFee: +area?.deliveryFee,
    },
    auth: true,
  });
};

export const getDeliveryAreaById = (areaId: string) => {
  return apicall({
    endpoint: "/deliveryArea",
    param: areaId,
    method: "GET",
    auth: true,
  });
};

export const deleteDeliveryArea = (areaId: string) => {
  return apicall({
    endpoint: "/deliveryArea",
    param: areaId,
    method: "DELETE",
    auth: true,
  });
};
