import FileUploadField from "core/components/formfields/FileUploadField";
import InputField from "core/components/formfields/InputField";
import TextField from "core/components/formfields/TextField";
import { actionbtn, btn } from "core/consts/styling";
import notification from "core/helpers/notification";
import useMarketPlaceStore from "core/services/stores/useMarketPlaceStore";
import { useEffect, useState } from "react";

interface Props {
  category: string;
  product: any;
  isUpdate: boolean;
  isSuperAdmin: boolean;
}

const HotelForm = ({
  category = "",
  product = null,
  isUpdate = false,
  isSuperAdmin = false,
}: Props) => {
  const [files, setFiles] = useState([]);

  const addUpdateProductAction = useMarketPlaceStore(
    (store) => store.addUpdateProduct,
  );
  const deleteImageAction = useMarketPlaceStore(
    (store) => store.deleteProductImage,
  );

  const [subCategories, setSubCategories] = useState<ProductSubcategory[]>([
    { amount: 0, category: "" },
  ]);

  const [newProduct, setNewProduct] = useState<NewHotelProduct>({
    category: category,
    name: "",
    address: "",
    hotelCategories: subCategories,
    convenienceFee: 0,
    description: "",
    imagePath: [],
    filess: [],
  });

  const [errors, setErrors] = useState({
    Name: [],
    Address: [],
    ConvenienceFee: [],
    Description: [],
    HotelCategories: [],
    ImagePath: [],
    Filess: [],
  });

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const removeImage = (index: number) => {
    setFiles([...files.filter((file, i) => i !== index)]);
  };

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;
    setNewProduct((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const onSubCategoryChange = (e: any, index: number) => {
    const { name, value }: any = e?.target;

    const data: any = [...subCategories];
    data[index][name] = value;

    setSubCategories(data);

    setNewProduct((state: any) => ({ ...state, hotelCategories: data }));
  };

  const addCategory = () => {
    setSubCategories([...subCategories, { category: "", amount: 0 }]);
  };

  const removeCategory = (index: number) => {
    const data = [...subCategories];

    data.splice(index, 1);

    setSubCategories(data);

    setNewProduct((state: any) => ({ ...state, hotelCategories: data }));
  };

  // TODO: Add Validation for subcategory

  const validation = (data: NewHotelProduct, files: any[]) => {
    var isValid = true;

    if (data?.name?.length < 1) {
      isValid = false;
      onErrorChange("Name", ["Name is required"]);
    }

    if (data?.convenienceFee < 0) {
      isValid = false;
      onErrorChange("ConvenienceFee", [
        "ConvenienceFee must not be less than 0",
      ]);
    }

    if (!isUpdate && files.length < 1) {
      isValid = false;
      notification({
        message: "Please upload photos",
        type: "danger",
      });
    }

    return isValid;
  };

  const addUpdateProduct = async (e: any) => {
    e.preventDefault();

    if (validation(newProduct, files)) {
      var res = await addUpdateProductAction(
        {
          ...newProduct,
          filess: files,
        },
        isUpdate ? product?.productId : "",
      );

      if (res?.isSuccessful) {
        if (!isUpdate) {
          setSubCategories([{ category: "", amount: 0 }]);

          setNewProduct({
            category: category,
            name: "",
            convenienceFee: 0,
            description: "",
            hotelCategories: [{ category: "", amount: 0 }],
            address: "",
            imagePath: [],
            filess: [],
          });
        }

        setFiles([]);
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const deleteImage = async (url: any) => {
    if (window.confirm(`Click 'OK' to delete this image from the server.`)) {
      await deleteImageAction("product", product?.productId, url);
    }
  };

  useEffect(() => {
    isUpdate &&
      product != null &&
      setNewProduct({
        category: category,
        name: product?.name,
        convenienceFee: product?.convenienceFee,
        imagePath: [],
        filess: [],
        description: product?.description != null ? product?.description : "",
        hotelCategories:
          product?.hotelCategories?.length > 0
            ? product?.eventCategories
            : [{ category: "", amount: 0 }],
        address: product?.address != null ? product?.address : "",
      });

    setSubCategories(
      product?.hotelCategories?.length > 0
        ? product?.hotelCategories
        : [{ category: "", amount: 0 }],
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate, product]);

  return (
    <form onSubmit={addUpdateProduct} className="mb-[24px] flex gap-5">
      <div className="w-3/5">
        <InputField
          boxStyle="mb-[18px]"
          label="Hotel Name"
          placeholder="Hotel Name"
          name="name"
          value={newProduct?.name}
          onChange={(e: any) => onFormChange(e)}
          error={errors?.Name[0]}
          onBlur={() => onErrorChange("Name", [])}
        />

        <InputField
          boxStyle="mb-[18px]"
          label="Hotel Address"
          placeholder="Hotel Address"
          name="address"
          value={newProduct?.address}
          onChange={(e: any) => onFormChange(e)}
          error={errors?.Address[0]}
          onBlur={() => onErrorChange("Address", [])}
        />

        <div className="mb-[18px]">
          <label className={`mb-2 text-[14px] text-brandgray`}>
            Hotel Categories<span className="text-red-500">*</span>
          </label>

          {subCategories?.length > 0 &&
            subCategories?.map((subcat, index: number) => (
              <div
                key={index}
                className="item-center mb-[18px] flex items-center justify-between gap-3"
              >
                <InputField
                  boxStyle="w-2/6"
                  label="Category"
                  placeholder="Category"
                  name="category"
                  value={subcat?.category}
                  onChange={(e: any) => onSubCategoryChange(e, index)}
                />

                <InputField
                  boxStyle="w-2/6"
                  placeholder="Amount"
                  label="Amount"
                  name="amount"
                  type="number"
                  value={subcat?.amount}
                  onChange={(e: any) => onSubCategoryChange(e, index)}
                />

                <div className="flex w-2/6 items-center gap-3">
                  <button
                    type="button"
                    className={`${btn} !w-1/2 !border-[#6C18A4] !bg-[#DFA1F4] !text-black`}
                    onClick={() => addCategory()}
                  >
                    Add
                  </button>

                  {subCategories?.length > 1 && (
                    <button
                      type="button"
                      className={`${btn} !w-1/2 !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                      onClick={() => removeCategory(index)}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            ))}
        </div>

        <InputField
          boxStyle="mb-[18px]"
          placeholder="Convenience Fee"
          label="Convenience Fee"
          name="convenienceFee"
          value={newProduct?.convenienceFee}
          type="number"
          onChange={(e: any) => onFormChange(e)}
          error={errors?.ConvenienceFee[0]}
          onBlur={() => onErrorChange("ConvenienceFee", [])}
        />

        <TextField
          boxStyle="mb-[18px]"
          placeholder="Description"
          label="Description"
          name="description"
          value={newProduct?.description}
          onChange={(e: any) => onFormChange(e)}
          rows={5}
          error={errors?.Description[0]}
          onBlur={() => onErrorChange("Description", [])}
        />

        <button
          disabled={isSuperAdmin}
          className={`${btn} w-full bg-brand text-white`}
        >
          Submit
        </button>
      </div>
      <div className="w-2/6">
        <>
          <FileUploadField
            multiple={true}
            boxStyle="w-3/5"
            label="Upload Pictures"
            name="signature"
            onChange={setFiles}
            showUploadedImages={false}
          />

          <div className="mt-[16px] grid grid-cols-2 gap-3 ">
            {files?.length > 0 &&
              files.map((file: any, index: number) => (
                <div
                  key={file?.name + index}
                  className="relative overflow-hidden rounded-[5px] border border-brandLight"
                >
                  <img src={URL.createObjectURL(file)} alt={`logo${index}`} />
                  <button
                    type="button"
                    className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                    onClick={() => removeImage(index)}
                  >
                    Delete
                  </button>
                </div>
              ))}

            {isUpdate &&
              product?.uploadedImageList?.length > 0 &&
              product?.uploadedImageList?.map(
                (image: string, index: number) => (
                  <div
                    key={index}
                    className="relative overflow-hidden rounded-[5px] border border-brandLight"
                  >
                    <img src={image} alt={`logo${index}`} />
                    <button
                      type="button"
                      className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                      onClick={() => deleteImage(image)}
                    >
                      Delete
                    </button>
                  </div>
                ),
              )}
          </div>
        </>
      </div>
    </form>
  );
};

export default HotelForm;
