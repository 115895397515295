import Accordion from "core/components/Accordion";
import Modal from "core/components/Modal";
import Timeline from "core/components/Timeline";
import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import ActionRow from "core/components/table/Actionrow";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import {
  addIcon,
  deleteIcon,
  editIcon,
  users,
  eyeopen,
} from "core/consts/images";
import { actionbtn, btn } from "core/consts/styling";
import {
  adjustTripAccordionHeight,
  convertTimeTo12Hour,
} from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useTransportationStore from "core/services/stores/useTransportationStore";
import useUserStore from "core/services/stores/useUserStore";
import { useEffect, useState } from "react";

const SettingsType = {
  trip: "trip",
  busStop: "busstop",
};

const RiderSettings = () => {
  // settings state
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const busStops = useTransportationStore((store) => store.busStops);
  const trips = useTransportationStore((store) => store.busTrips);

  // selected
  const [selected, setSelected] = useState<any>(null);
  const [selectedType, setSelectedType] = useState("");

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [expandedRows, setExpandedRows] = useState<any>([]);
  const [, setExpandState] = useState({});

  const handleExpandRow = (e: any, tripId: string) => {
    const currentExpandedRows: any = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(tripId!);
    const obj: any = {};

    isRowExpanded ? (obj[tripId!] = false) : (obj[tripId!] = true);
    setExpandState(obj);

    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id: any) => id !== tripId)
      : currentExpandedRows.concat(tripId!);

    setExpandedRows(newExpandedRows);
  };

  // get actions
  const getBusStopsAction = useTransportationStore(
    (store) => store.getBusStops,
  );
  const getTripAction = useTransportationStore((store) => store.getTrips);

  // add update actions
  const addUpdateBusStopAction = useTransportationStore(
    (store) => store.addUpateBusStop,
  );
  const addUpdateTripAction = useTransportationStore(
    (store) => store.addUpdateBusTrip,
  );

  // delete actions
  const deleteBusStopAction = useTransportationStore(
    (store) => store.deleteBusStop,
  );
  const deleteTripAction = useTransportationStore((store) => store.deleteTrip);

  // form state
  const [newTerminals, setNewTerminals] = useState<NewBusTerminal[]>([
    {
      busPointId: "",
      expectedArrivalTime: "",
      order: 0,
    },
  ]);

  const [newTrip, setNewTrip] = useState<NewTrip>({
    city: "",
    endPoint: "",
    startPoint: "",
    terminals: newTerminals,
    tripName: "",
  });

  const [newBusStop, setNewBusStop] = useState<NewBusStop>({
    city: "",
    name: "",
  });

  // error state
  const [tripErrors, setTripErrors] = useState({
    City: [],
    EndPoint: [],
    StartPoint: [],
    Terminals: [],
    TripName: [],
  });

  const [busStopErrors, setBusStopErrors] = useState({
    City: [],
    Name: [],
  });

  // handle form changes
  const onFormChange = (type: string, e: any) => {
    const { name, value } = e?.target;

    switch (type) {
      case SettingsType.busStop:
        setNewBusStop((state) => ({
          ...state,
          [name]: value,
        }));
        break;
      case SettingsType.trip:
        setNewTrip((state) => ({
          ...state,
          [name]: value,
        }));
        break;
      default:
        break;
    }
  };

  const onTerminalChange = (e: any, index: number) => {
    const { name, value }: any = e?.target;

    const data: any = [...newTerminals];
    data[index][name] = value;

    setNewTerminals(data);
    setNewTrip((state: any) => ({ ...state, terminals: data }));
  };

  const addTerminal = () => {
    setNewTerminals([
      ...newTerminals,
      {
        busPointId: "",
        expectedArrivalTime: "",
        order: newTerminals.length,
      },
    ]);

    adjustTripAccordionHeight(newTerminals?.length - 1);
  };

  const removeTerminal = (index: number) => {
    const data = [...newTerminals];

    data.splice(index, 1);

    var updatedTerminal = data?.map((x, index: number) => ({
      busPointId: x?.busPointId,
      expectedArrivalTime: x?.expectedArrivalTime,
      order: index,
    }));

    setNewTerminals(updatedTerminal);
    setNewTrip((state: any) => ({ ...state, terminals: updatedTerminal }));
  };

  // handle errors
  const onErrorChange = (type: string, name: string, value: string[]) => {
    switch (type) {
      case SettingsType.trip:
        setTripErrors((state) => ({
          ...state,
          [name]: value,
        }));
        break;
      case SettingsType.busStop:
        setBusStopErrors((state) => ({
          ...state,
          [name]: value,
        }));
        break;
      default:
        break;
    }
  };

  // close update
  const closeUpdateForm = (type: string) => {
    switch (type) {
      case SettingsType.busStop:
        setShowUpdateModal(false);
        setSelected(null);
        setSelectedType("");
        setNewBusStop({
          city: "",
          name: "",
        });
        setBusStopErrors({
          City: [],
          Name: [],
        });
        break;
      case SettingsType.trip:
        setShowUpdateModal(false);
        setSelected(null);
        setSelectedType("");
        setNewTerminals([
          { busPointId: "", expectedArrivalTime: "", order: 0 },
        ]);
        setNewTrip({
          city: "",
          endPoint: "",
          startPoint: "",
          terminals: [{ busPointId: "", expectedArrivalTime: "", order: 0 }],
          tripName: "",
        });
        setTripErrors({
          City: [],
          EndPoint: [],
          StartPoint: [],
          Terminals: [],
          TripName: [],
        });
        break;
      default:
        break;
    }
  };

  // validations
  const busStopValidation = (data: NewBusStop) => {
    var isValid = true;

    if (data?.name?.length < 1) {
      isValid = false;
      onErrorChange(SettingsType.busStop, "Name", [
        "Bus Stop name is required",
      ]);
    }

    if (data?.city?.length < 1) {
      isValid = false;
      onErrorChange(SettingsType.busStop, "City", [
        "Bus stop City is required",
      ]);
    }

    return isValid;
  };

  const tripValidation = (data: NewTrip) => {
    var isValid = true;

    if (data?.tripName?.length < 1) {
      isValid = false;
      onErrorChange(SettingsType.trip, "TripName", ["Trip Name is required"]);
    }

    if (data?.terminals?.length < 1) {
      isValid = false;
      notification({
        message: "Please include bus stop terminals",
        type: "warning",
      });
    }

    if (data?.startPoint?.length < 1) {
      isValid = false;
      onErrorChange(SettingsType.trip, "StartPoint", [
        "Trip Start Point is required",
      ]);
    }

    if (data?.endPoint?.length < 1) {
      isValid = false;
      onErrorChange(SettingsType.trip, "EndPoint", [
        "Trip End Point is required",
      ]);
    }

    if (data?.city?.length < 1) {
      isValid = false;
      onErrorChange(SettingsType.busStop, "City", [
        "Bus stop City is required",
      ]);
    }

    return isValid;
  };

  // add update
  const addUpdateBusStops = async (e: any, isUpdate: boolean = false) => {
    e.preventDefault();

    if (busStopValidation(newBusStop)) {
      var res = await addUpdateBusStopAction(
        newBusStop,
        isUpdate ? selected?.id : "",
      );

      if (res?.isSuccessful) {
        setNewBusStop({
          city: "",
          name: "",
        });

        if (isUpdate) {
          setSelected(null);
          setSelectedType("");
          setShowUpdateModal(false);
        }
      } else {
        setBusStopErrors((state) => ({
          ...state,
          ...res?.data?.errors,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const addUpdateTrip = async (e: any, isUpdate: boolean = false) => {
    e.preventDefault();

    if (tripValidation(newTrip)) {
      var res = await addUpdateTripAction(
        {
          ...newTrip,
          terminals: [
            ...newTerminals.map((t) => ({
              ...t,
              expectedArrivalTime: convertTimeTo12Hour(t?.expectedArrivalTime!),
            })),
          ],
        },
        isUpdate ? selected?.id : "",
      );

      if (res?.isSuccessful) {
        setNewTerminals([
          { busPointId: "", expectedArrivalTime: "", order: 0 },
        ]);

        setNewTrip({
          city: "",
          endPoint: "",
          startPoint: "",
          terminals: [{ busPointId: "", expectedArrivalTime: "", order: 0 }],
          tripName: "",
        });

        if (isUpdate) {
          setSelected(null);
          setSelectedType("");
          setShowUpdateModal(false);
        }
      } else {
        setTripErrors((state) => ({
          ...state,
          ...res?.data?.errors,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  // delete
  const deleteSettings = async (type: string, id: string) => {
    if (window.confirm(`Click 'OK' to delete this "${type}"`)) {
      switch (type) {
        case SettingsType.busStop:
          await deleteBusStopAction(id);
          break;
        case SettingsType.trip:
          await deleteTripAction(id);
          break;
        default:
          break;
      }
    }
  };

  // TODO: Add update function

  useEffect(() => {
    trips?.length < 1 && getTripAction();
    busStops?.length < 1 && getBusStopsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mx-auto w-[95%]">
        <header className="mb-[28px] flex items-center gap-3">
          <img src={users} loading="lazy" alt="" />
          <h5 className="text-[18px] font-[500] text-brand">Rider Settings</h5>
        </header>
        <section className="mb-[28px]">
          <Accordion id="busStops" title="Create Bus Stops">
            <section className="flex flex-col gap-5 overflow-x-scroll lg:flex-row">
              <div className="w-full lg:w-4/12">
                <form
                  className="rounded-[5px] bg-white p-5"
                  onSubmit={(e) => addUpdateBusStops(e, false)}
                >
                  <InputField
                    boxStyle="mb-[18px]"
                    placeholder="Bus Stop Name"
                    name="name"
                    isRequired
                    value={newBusStop?.name}
                    onChange={(e: any) => onFormChange(SettingsType.busStop, e)}
                    error={busStopErrors?.Name[0]}
                    onBlur={() =>
                      onErrorChange(SettingsType.busStop, "Name", [])
                    }
                  />

                  <InputField
                    boxStyle="mb-[18px]"
                    placeholder="City/Town"
                    name="city"
                    isRequired
                    value={newBusStop?.city}
                    onChange={(e: any) => onFormChange(SettingsType.busStop, e)}
                    error={busStopErrors?.City[0]}
                    onBlur={() =>
                      onErrorChange(SettingsType.busStop, "City", [])
                    }
                  />

                  <button
                    disabled={isSuperAdmin}
                    className={`${btn} w-full bg-brand text-white`}
                  >
                    Submit
                  </button>
                </form>
              </div>
              <div className="w-full lg:w-8/12">
                <Table
                  headers={["Bus Stops", "City/Town", "Last Updated", "Action"]}
                >
                  {busStops?.length > 0 ? (
                    busStops?.map((busStop) => (
                      <tr key={busStop?.id}>
                        <Row value={busStop?.name} />
                        <Row value={busStop?.city} />
                        <Row value={busStop?.dateAdded} />
                        <ActionRow
                          style={`flex items-center justify-center gap-3`}
                        >
                          <button
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#59960B] bg-[#D9FEAA] p-2 disabled:cursor-not-allowed"
                            onClick={() => {
                              setSelectedType(SettingsType.busStop);
                              setSelected(busStop);
                              setNewBusStop({
                                city: busStop?.city,
                                name: busStop?.name,
                              });
                              setShowUpdateModal(true);
                            }}
                          >
                            <img
                              src={editIcon}
                              loading="lazy"
                              className="h-[16px]"
                              alt="edit"
                            />
                          </button>
                          <button
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#FF0000] bg-[#FFC8C8] p-2"
                            onClick={() => {
                              deleteSettings(SettingsType.busStop, busStop?.id);
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="h-[16px]"
                              loading="lazy"
                              alt="delete"
                            />
                          </button>
                        </ActionRow>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <Row colSpan={4} value="No Bus stops yet" />
                    </tr>
                  )}
                </Table>
              </div>
            </section>
          </Accordion>
        </section>
        <section className="mb-[28px]">
          <Accordion id="busTrips" title="Create Bus Trips">
            <section className="flex flex-col gap-5 overflow-x-scroll lg:flex-row">
              <div className="w-full lg:w-4/12">
                <form
                  className="rounded-[5px] bg-white p-5 "
                  onSubmit={(e) => addUpdateTrip(e, false)}
                >
                  <InputField
                    boxStyle="mb-[18px]"
                    label="Trip Name"
                    placeholder="Trip Name"
                    name="tripName"
                    isRequired
                    value={newTrip?.tripName}
                    onChange={(e: any) => onFormChange(SettingsType.trip, e)}
                    error={tripErrors?.TripName[0]}
                    onBlur={() =>
                      onErrorChange(SettingsType.trip, "TripName", [])
                    }
                  />

                  <SelectField
                    boxStyle="mb-[18px]"
                    label="Select City/Town"
                    name="city"
                    defaultName="Select City/Town"
                    defaultValue=""
                    options={
                      busStops?.length > 0
                        ? [
                            ...busStops?.map((stop) => ({
                              name: stop?.city,
                              value: stop?.city,
                            })),
                          ]
                        : []
                    }
                    value={newTrip?.city}
                    onChange={(e: any) => onFormChange(SettingsType.trip, e)}
                    error={tripErrors?.City[0]}
                    onBlur={() => onErrorChange(SettingsType.trip, "City", [])}
                  />

                  <SelectField
                    boxStyle="mb-[18px]"
                    label="Start Point"
                    name="startPoint"
                    defaultName="Select start point"
                    defaultValue=""
                    options={
                      busStops?.length > 0
                        ? [
                            ...busStops?.map((stop) => ({
                              name: stop?.name,
                              value: stop?.name,
                            })),
                          ]
                        : []
                    }
                    value={newTrip?.startPoint}
                    onChange={(e: any) => onFormChange(SettingsType.trip, e)}
                    error={tripErrors?.StartPoint[0]}
                    onBlur={() =>
                      onErrorChange(SettingsType.trip, "StartPoint", [])
                    }
                  />

                  <SelectField
                    boxStyle="mb-[18px]"
                    label="End Point"
                    name="endPoint"
                    defaultName="Select end point"
                    defaultValue=""
                    options={
                      busStops?.length > 0
                        ? [
                            ...busStops?.map((stop) => ({
                              name: stop?.name,
                              value: stop?.name,
                            })),
                          ]
                        : []
                    }
                    value={newTrip?.endPoint}
                    onChange={(e: any) => onFormChange(SettingsType.trip, e)}
                    error={tripErrors?.EndPoint[0]}
                    onBlur={() =>
                      onErrorChange(SettingsType.trip, "EndPoint", [])
                    }
                  />

                  <div className="mb-[18px]">
                    <label className={`mb-5 text-[14px] text-brandgray`}>
                      Bus Stops<span className="text-red-500">*</span>
                    </label>

                    {newTerminals?.length > 0 &&
                      newTerminals?.map((terminal, index: number) => (
                        <div
                          id={`terminalstop${index}`}
                          key={`terminalstop${index}`}
                          className="mb-[18px]"
                        >
                          <div className="mb-[5px] flex items-center justify-between gap-5">
                            <label
                              htmlFor={`busstop-${index}`}
                              className="text-[14px] text-brandgray"
                            >
                              Order - {terminal?.order}
                            </label>

                            {newTerminals?.length > 1 && (
                              <button
                                type="button"
                                className={`!text-white`}
                                onClick={() => removeTerminal(index)}
                              >
                                <img src={deleteIcon} alt="delete" />
                              </button>
                            )}
                          </div>

                          <SelectField
                            boxStyle="mb-[18px]"
                            name="busPointId"
                            defaultName="Select bus stop"
                            defaultValue=""
                            options={
                              busStops?.length > 0
                                ? [
                                    ...busStops?.map((stop) => ({
                                      name: stop?.name,
                                      value: stop?.id,
                                    })),
                                  ]
                                : []
                            }
                            value={terminal?.busPointId}
                            onChange={(e: any) => onTerminalChange(e, index)}
                            error={tripErrors?.EndPoint[0]}
                            onBlur={() =>
                              onErrorChange(SettingsType.trip, "EndPoint", [])
                            }
                          />

                          <InputField
                            boxStyle="mb-[18px]"
                            label="Travel Time from Last Point"
                            name="expectedArrivalTime"
                            type="time"
                            value={terminal?.expectedArrivalTime}
                            onChange={(e: any) => {
                              onTerminalChange(e, index);
                            }}
                          />
                        </div>
                      ))}

                    <div className="flex justify-end">
                      <button
                        type="button"
                        className={`${btn} !border-[#6C18A4] !bg-[#DFA1F4] !px-2 !py-2 !text-brand`}
                        onClick={() => addTerminal()}
                      >
                        <img src={addIcon} alt="" />
                        <span>bus stop</span>
                      </button>
                    </div>
                  </div>

                  <button
                    disabled={isSuperAdmin}
                    id="submitTrip"
                    className={`${btn} w-full bg-brand text-white`}
                  >
                    Submit
                  </button>
                </form>
              </div>
              <div className="w-full lg:w-8/12">
                <Table
                  headers={["Trip", "Start", "End", "Date Added", "Action"]}
                >
                  {trips?.length > 0 ? (
                    trips?.map((trip) => (
                      <>
                        <tr key={trip?.id}>
                          <Row value={trip?.tripName} />
                          <Row value={trip?.startPoint} />
                          <Row value={trip?.endPoint} />
                          <Row value={trip?.dateAdded} />
                          <ActionRow
                            style={`flex items-center justify-center gap-3`}
                          >
                            <button
                              className="flex w-[35px] items-center justify-center border-[1px] !border-[#6C18A4] !bg-[#DFA1F4] p-2"
                              onClick={(e) => handleExpandRow(e, trip?.id)}
                            >
                              <img
                                src={eyeopen}
                                loading="lazy"
                                className="h-[16px]"
                                alt=""
                              />
                            </button>
                            <button
                              className="flex w-[35px] items-center justify-center border-[1px] border-[#59960B] bg-[#D9FEAA] p-2 disabled:cursor-not-allowed"
                              onClick={() => {
                                setSelectedType(SettingsType.trip);
                                setSelected(trip);
                                setNewTerminals([
                                  {
                                    busPointId: "",
                                    order: 0,
                                    expectedArrivalTime: "",
                                  },
                                ]);
                                // TODO:  Update New Terminal form
                                setShowUpdateModal(true);
                              }}
                            >
                              <img
                                src={editIcon}
                                loading="lazy"
                                className="h-[16px]"
                                alt="edit"
                              />
                            </button>

                            <button
                              className="flex w-[35px] items-center justify-center border-[1px] border-[#FF0000] bg-[#FFC8C8] p-2"
                              onClick={() => {
                                deleteSettings(SettingsType.trip, trip?.id);
                              }}
                            >
                              <img
                                src={deleteIcon}
                                className="h-[16px]"
                                loading="lazy"
                                alt="delete"
                              />
                            </button>
                          </ActionRow>
                        </tr>
                        {expandedRows.includes(trip?.id) ? (
                          <tr className="bg-white">
                            <td className="py-4" colSpan={6}>
                              <div>
                                <Timeline
                                  stops={trip?.terminals ? trip.terminals : []}
                                  startPoint={trip?.startPoint}
                                  finalPoint={trip?.endPoint}
                                />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <tr />
                        )}
                      </>
                    ))
                  ) : (
                    <tr>
                      <Row colSpan={5} value="No bus trips yet" />
                    </tr>
                  )}
                </Table>
              </div>
            </section>
          </Accordion>
        </section>
      </div>

      {showUpdateModal && selectedType === SettingsType.busStop && (
        <Modal
          header="Update Bus Stop"
          onClose={() => closeUpdateForm(SettingsType.busStop)}
        >
          <form onSubmit={(e) => addUpdateBusStops(e, true)}>
            <InputField
              boxStyle="mb-[18px]"
              placeholder="Bus Stop Name"
              name="name"
              isRequired
              value={newBusStop?.name}
              onChange={(e: any) => onFormChange(SettingsType.busStop, e)}
              error={busStopErrors?.Name[0]}
              onBlur={() => onErrorChange(SettingsType.busStop, "Name", [])}
            />

            <InputField
              boxStyle="mb-[18px]"
              placeholder="City/Town"
              name="city"
              isRequired
              value={newBusStop?.city}
              onChange={(e: any) => onFormChange(SettingsType.busStop, e)}
              error={busStopErrors?.City[0]}
              onBlur={() => onErrorChange(SettingsType.busStop, "City", [])}
            />

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => closeUpdateForm(SettingsType.busStop)}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}

      {showUpdateModal && selectedType === SettingsType.trip && (
        <Modal
          header="Update Bus Stop"
          onClose={() => closeUpdateForm(SettingsType.busStop)}
        >
          <form onSubmit={(e) => addUpdateBusStops(e, true)}>
            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => closeUpdateForm(SettingsType.busStop)}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                disabled
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default RiderSettings;
