import DatePicker from "core/components/formfields/DatePicker";
import SelectField from "core/components/formfields/SelectField";
import useLocationStore from "core/services/stores/useLocationStore";
import useUserStore from "core/services/stores/useUserStore";
import { useEffect, useState } from "react";
import StatBoard from "core/components/Analytics/StatBoard";
import useFoodStuffStore from "core/services/stores/useFoodStuffStore";
import { home } from "core/consts/images";

const Dashboard = () => {
  const user: any = useUserStore((store) => store.user);
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);

  const locations = useLocationStore((store) => store.locations);
  const analytics: any = useFoodStuffStore((store) => store.analytics);

  const getAnalyticsAction = useFoodStuffStore(
    (store) => store.getDashboardAnalytics,
  );
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const [query, setQuery] = useState<DateLocationQuery>({
    startDate: "",
    endDate: "",
    locationFilter: user?.location,
  });

  const onQueryChange = (e: any) => {
    const { name, value } = e?.target;

    setQuery((state) => ({
      ...state,
      [name]: value,
    }));

    getAnalyticsAction({
      ...query,
      [name]: value,
    });
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();
    getAnalyticsAction(query);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mx-auto w-[95%]">
      <header className="mb-[14px] flex items-center gap-3">
        <img src={home} alt="active module" loading="lazy" />
        <h5 className="text-[18px] font-[500] text-brand">
          Hello {user?.firstname}!
        </h5>
      </header>

      <div className="mb-[34px] grid grid-cols-4 gap-5">
        <SelectField
          boxStyle="col-span-2"
          name="locationFilter"
          defaultName="Select your Location"
          defaultValue=""
          disabled={!isSuperAdmin}
          options={
            locations?.length > 0
              ? [
                  ...locations?.map((location) => ({
                    name: location?.name,
                    value: location?.name,
                  })),
                ]
              : []
          }
          value={query?.locationFilter}
          onChange={(e: any) => {
            onQueryChange(e);
          }}
        />

        <DatePicker
          name="startDate"
          label="Start Date"
          value={query?.startDate}
          className={`flex h-12 w-full items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
          onChange={(e: any) => {
            onQueryChange(e);
          }}
        />

        <DatePicker
          name="endDate"
          label="End Date"
          value={query?.endDate}
          className={`flex h-12 w-full items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
          onChange={(e: any) => {
            onQueryChange(e);
          }}
        />
      </div>
      <div className="grid grid-cols-2 gap-5 md:grid-cols-4 lg:grid-cols-6">
        {Object?.keys(analytics).map((key: string) => (
          <StatBoard key={key} name={key} value={analytics[key]} />
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
