import SelectField from "core/components/formfields/SelectField";
import { userIcon } from "core/consts/images";
import {
  MARKETPLACE_CATEGORY,
  MARKETPLACE_CATEGORY_TYPES,
} from "core/consts/systemConst";
import { useEffect, useState } from "react";
import FastFoodForm from "../partials/FastFoodForm";
import { useSearchParams } from "react-router-dom";
import useMarketPlaceStore from "core/services/stores/useMarketPlaceStore";
import GasRefillForm from "./GasRefillForm";
import MediaProductionForm from "./MediaProductionForm";
import EventTicketForm from "./EventTicketForm";
import HotelForm from "./HotelForm";
import useUserStore from "core/services/stores/useUserStore";

interface Props {
  isUpdate: boolean;
}

const ProductForm = ({ isUpdate = false }: Props) => {
  const [category, setCategory] = useState("");
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);

  const [productId, setProductId] = useState("");
  const getProductAction = useMarketPlaceStore((store) => store.getProductById);

  const product = useMarketPlaceStore((store) => store.product);
  const [searchParams]: any = useSearchParams();

  useEffect(() => {
    var searchCategory = searchParams.get("category");
    var productId = searchParams.get("productId");

    if (isUpdate && searchCategory?.length > 0 && productId?.length > 0) {
      setCategory(searchCategory);
      setProductId(productId);
      getProductAction(searchCategory, productId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams && isUpdate]);

  return (
    <section className="mx-auto w-[95%]">
      <header className="mb-[14px] flex items-center gap-3">
        <img src={userIcon} alt="active module" loading="lazy" />
        <h5 className="text-[18px] font-[500] capitalize text-brand">
          {isUpdate && product
            ? `Update ${product && product?.name}`
            : "Create Product"}
        </h5>
      </header>
      <div className="w-3/5">
        <SelectField
          boxStyle="mb-[18px]"
          selectStyle="!border-brand text-[#615353]"
          name="category"
          defaultName="Select Market Category"
          label="Market Category"
          defaultValue=""
          disabled={productId?.length > 1}
          options={
            MARKETPLACE_CATEGORY?.length > 0
              ? [
                  ...MARKETPLACE_CATEGORY?.map((cat) => ({
                    name: cat?.name,
                    value: cat?.value,
                  })),
                ]
              : []
          }
          value={category}
          onChange={(e: any) => setCategory(e?.target?.value)}
        />
      </div>

      {(() => {
        switch (category) {
          case MARKETPLACE_CATEGORY_TYPES.Fast_Food:
            return (
              <FastFoodForm
                isSuperAdmin={isSuperAdmin}
                product={product}
                category={category}
                isUpdate={isUpdate}
              />
            );
          case MARKETPLACE_CATEGORY_TYPES.Gas_Refill:
            return (
              <GasRefillForm
                isSuperAdmin={isSuperAdmin}
                product={product}
                category={category}
                isUpdate={isUpdate}
              />
            );
          case MARKETPLACE_CATEGORY_TYPES.Media_Production:
            return (
              <MediaProductionForm
                isSuperAdmin={isSuperAdmin}
                product={product}
                category={category}
                isUpdate={isUpdate}
              />
            );
          case MARKETPLACE_CATEGORY_TYPES.Event_Ticket:
            return (
              <EventTicketForm
                isSuperAdmin={isSuperAdmin}
                product={product}
                category={category}
                isUpdate={isUpdate}
              />
            );
          case MARKETPLACE_CATEGORY_TYPES.Hotel:
            return (
              <HotelForm
                isSuperAdmin={isSuperAdmin}
                product={product}
                category={category}
                isUpdate={isUpdate}
              />
            );
          default:
            return <></>;
        }
      })()}
    </section>
  );
};

export default ProductForm;
