import { useEffect, useState } from "react";
import FileUploadField from "core/components/formfields/FileUploadField";
import InputField from "core/components/formfields/InputField";
import TextField from "core/components/formfields/TextField";
import { activeModule } from "core/consts/images";
import { actionbtn, btn } from "core/consts/styling";
import { useSearchParams } from "react-router-dom";
import notification from "core/helpers/notification";
import useUserStore from "core/services/stores/useUserStore";
import useEngineeringStore from "core/services/stores/useEngineeringStore";

interface Props {
  isUpdate: boolean;
}

const ServiceForm = ({ isUpdate = false }: Props) => {
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const [searchParams]: any = useSearchParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [serviceId, setServiceId] = useState("");

  const service = useEngineeringStore((store) => store.service);
  const getServiceAction = useEngineeringStore((store) => store.getServiceById);
  const deleteImageAction = useEngineeringStore(
    (store) => store.deleteServiceImage,
  );
  const addUpdateServiceAction = useEngineeringStore(
    (store) => store.addUpdateService,
  );

  const [files, setFiles] = useState([]);
  const [newService, setNewService] = useState<NewEngineeringService>({
    convenienceFee: 0,
    extraDetails: "",
    applianceType: "",
    engineerAddress: "",
    engineerName: "",
    engineerPhoneNo: "",
    images: [],
  });

  const [errors, setErrors] = useState({
    ConvenienceFee: [],
    ExtraDetails: [],
    ApplianceType: [],
    EngineerAddress: [],
    EngineerName: [],
    EngineerPhoneNo: [],
    Images: [],
  });

  const removeImage = (index: number) => {
    setFiles([...files.filter((file, i) => i !== index)]);
  };

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const deleteImage = async (url: any) => {
    if (window.confirm(`Click 'OK' to delete this image from the server.`)) {
      await deleteImageAction("engineering", service?.serviceId, url);
    }
  };

  const validation = (data: NewEngineeringService, files: any[]) => {
    var isValid = true;

    // TODO: Add Validations
    return isValid;
  };

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;

    setNewService((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const addUpdateService = async (e: any) => {
    e.preventDefault();

    if (validation(newService, files)) {
      var res = await addUpdateServiceAction(
        {
          ...newService,
          images: files,
        },
        isUpdate ? service?.serviceId : "",
      );

      if (res?.isSuccessful) {
        if (!isUpdate) {
          setNewService({
            convenienceFee: 0,
            extraDetails: "",
            applianceType: "",
            engineerAddress: "",
            engineerName: "",
            engineerPhoneNo: "",
            images: [],
          });
        }

        setFiles([]);
      } else {
        setErrors((state) => ({
          ...state,
          ...res?.data?.errors,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    var id = searchParams.get("serviceId");

    if (isUpdate && id?.length > 0) {
      setServiceId(id);
      getServiceAction(id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams && isUpdate]);

  useEffect(() => {
    isUpdate &&
      service != null &&
      setNewService({
        applianceType: service?.applianceType,
        convenienceFee: service?.convenienceFee,
        engineerAddress: service?.engineerAddress,
        engineerName: service?.engineerName,
        engineerPhoneNo: service?.engineerPhoneNo,
        extraDetails: service?.extraDetails,
        images: [],
      });
  }, [isUpdate, service]);

  return (
    <section className="mx-auto mb-[28px] w-[95%]">
      <header className="mb-[14px] flex items-center gap-3">
        <img src={activeModule} alt="active module" loading="lazy" />
        <h5 className="text-[18px] font-[500] text-brand">
          {isUpdate && service
            ? `Update ${service && service?.serviceId}`
            : "Create Engineering Service"}
        </h5>
      </header>
      <form className="flex gap-5" onSubmit={addUpdateService}>
        <div className="w-3/5">
          <InputField
            boxStyle="mb-[18px]"
            placeholder="Appliance Type"
            label="Appliance Type"
            name="applianceType"
            isRequired
            value={newService?.applianceType}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.ApplianceType[0]}
            onBlur={() => onErrorChange("ApplianceType", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            placeholder="Engineer Name"
            label="Engineer Name"
            name="engineerName"
            isRequired
            value={newService?.engineerName}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.EngineerName[0]}
            onBlur={() => onErrorChange("EngineerName", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            placeholder="Engineer Address"
            label="Engineer Address"
            name="engineerAddress"
            isRequired
            value={newService?.engineerAddress}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.EngineerAddress[0]}
            onBlur={() => onErrorChange("EngineerAddress", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            placeholder="Engineer Phone Number"
            label="Engineer Phone Number"
            name="engineerPhoneNo"
            isRequired
            value={newService?.engineerPhoneNo}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.EngineerPhoneNo[0]}
            onBlur={() => onErrorChange("EngineerPhoneNo", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            label="Convenience Fee"
            placeholder="Convenience Fee"
            name="convenienceFee"
            isRequired
            value={newService?.convenienceFee}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.ConvenienceFee[0]}
            onBlur={() => onErrorChange("ConvenienceFee", [])}
          />

          <TextField
            boxStyle="mb-[18px]"
            name="extraDetails"
            placeholder="Extra Details"
            label="Extra Details"
            isRequired
            rows={5}
            value={newService?.extraDetails}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.ExtraDetails[0]}
            onBlur={() => onErrorChange("ExtraDetails", [])}
          />

          <button
            disabled={isSuperAdmin}
            className={`${btn} w-full bg-brand text-white`}
          >
            Submit
          </button>
        </div>
        <div className="w-2/6">
          <>
            <FileUploadField
              multiple={true}
              boxStyle="w-3/5"
              label="Upload Pictures"
              name="signature"
              onChange={setFiles}
              showUploadedImages={false}
            />

            <div className="mt-[16px] grid grid-cols-2 gap-3 ">
              {files?.length > 0 &&
                files.map((file: any, index: number) => (
                  <div
                    key={file?.name + index}
                    className="relative overflow-hidden rounded-[5px] border border-brandLight"
                  >
                    <img src={URL.createObjectURL(file)} alt={`logo${index}`} />
                    <button
                      type="button"
                      className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                      onClick={() => removeImage(index)}
                    >
                      Delete
                    </button>
                  </div>
                ))}

              {isUpdate &&
                service?.image?.length > 0 &&
                service?.image?.map((image: string, index: number) => (
                  <div
                    key={index}
                    className="relative overflow-hidden rounded-[5px] border border-brandLight"
                  >
                    <img src={image} alt={`logo${index}`} />
                    <button
                      type="button"
                      className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                      onClick={() => deleteImage(image)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
            </div>
          </>
        </div>
      </form>
    </section>
  );
};

export default ServiceForm;
