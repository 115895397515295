import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Auth from "modules/auth/Auth";
import SuperAdmin from "modules/superadmin/SuperAdmin";
import Loader from "core/components/Loader";
import useUserStore from "core/services/stores/useUserStore";
import { ReactNotifications } from "react-notifications-component";
import useLocationStore from "core/services/stores/useLocationStore";
import useAdminStore from "core/services/stores/useAdminStore";
import useReferralStore from "core/services/stores/useReferralStore";
import useSupportStore from "core/services/stores/useSupportStore";
import useAnalyticStore from "core/services/stores/useAnalyticStore";
import MarketPlace from "modules/marketplace/MarketPlace";
import FoodStuff from "modules/foodstuffs/FoodStuff";
import Laundry from "modules/laundry/Laundry";
import Transportation from "modules/transportation/Transportation";
import useMarketPlaceStore from "core/services/stores/useMarketPlaceStore";
import useNotificationStore from "core/services/stores/useNotificationStore";
import useFoodStuffStore from "core/services/stores/useFoodStuffStore";
import useLaundryStore from "core/services/stores/useLaundryStore";
import useEngineeringStore from "core/services/stores/useEngineeringStore";
import Engineering from "modules/engineering/Engineering";
import Accommodation from "modules/accommodation/Accommodation";
import useAccommodationStore from "core/services/stores/useAccommodationStore";
import useTransportationStore from "core/services/stores/useTransportationStore";
import useAdvertStore from "core/services/stores/useAdvertStore";
import useSmsVendorStore from "core/services/stores/useSmsVendorStore";

const App = () => {
  const isLocationStoreLoading = useLocationStore((store) => store.isLoading);
  const isUserStoreLoading = useUserStore((store) => store.isLoading);
  const isAdminStoreLoading = useAdminStore((store) => store.isLoading);
  const isReferralStoreLoading = useReferralStore((store) => store.isLoading);
  const isSupportStoreLoading = useSupportStore((store) => store.isLoading);
  const isAnalyticStoreLoading = useAnalyticStore((store) => store.isLoading);
  const isMarketplaceLoading = useMarketPlaceStore((store) => store.isLoading);
  const isNotificationLoading = useNotificationStore(
    (store) => store.isLoading,
  );
  const isFoodStuffStoreLoading = useFoodStuffStore((store) => store.isLoading);
  const isLaundryStoreLoading = useLaundryStore((store) => store.isLoading);
  const isEngineeringStoreLoading = useEngineeringStore(
    (store) => store.isLoading,
  );
  const isAccommodationStoreLoading = useAccommodationStore(
    (store) => store.isLoading,
  );
  const isTransportationStoreLoading = useTransportationStore(
    (store) => store.isLoading,
  );
  const isAdvertStoreLoading = useAdvertStore((store) => store.isLoading);
  const isSmsVendorStoreLoading = useSmsVendorStore((store) => store.isLoading);

  return (
    <>
      {(isUserStoreLoading ||
        isLocationStoreLoading ||
        isReferralStoreLoading ||
        isAdminStoreLoading ||
        isSupportStoreLoading ||
        isMarketplaceLoading ||
        isNotificationLoading ||
        isFoodStuffStoreLoading ||
        isLaundryStoreLoading ||
        isEngineeringStoreLoading ||
        isAccommodationStoreLoading ||
        isTransportationStoreLoading ||
        isAnalyticStoreLoading ||
        isSmsVendorStoreLoading ||
        isAdvertStoreLoading) && <Loader />}
      <Router>
        <ReactNotifications />
        <Routes>
          <Route path="/auth/*" element={<Auth />} />
          <Route path="/superadmin/*" element={<SuperAdmin />} />
          <Route path="/marketplace/*" element={<MarketPlace />} />
          <Route path="/foodstuff/*" element={<FoodStuff />} />
          <Route path="/laundry/*" element={<Laundry />} />
          <Route path="/transportation/*" element={<Transportation />} />
          <Route path="/engineering/*" element={<Engineering />} />
          <Route path="/accommodation/*" element={<Accommodation />} />
          <Route path="/" element={<Navigate to="/auth/login" replace />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
