import React from "react";

export default function Table({
  children = <div></div>,
  headers = [""],
  uniqueId = "",
  onCheck = () => {},
  isChecked = false,
}: {
  children: any;
  headers: string[];
  uniqueId?: string;
  isChecked?: boolean;
  onCheck?: any;
}) {
  return (
    <>
      <div
        className={`overflow-x-scroll xl:overflow-x-hidden ${uniqueId} rounded-[5px] border-[0.5px] border-brand shadow-md`}
      >
        <table className="w-full">
          <thead>
            <tr className="border-[1px] border-brand">
              {headers.map((header: any, index: any) => (
                <th
                  key={index}
                  className="cursor-pointer border-x-[1px] border-x-[#DFA1F4] bg-brand px-[10px] py-[16px] text-start"
                >
                  <>
                    {header === "checkbox" ? (
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          checked={isChecked}
                          className="h-[25px] w-[25px] rounded-[5px] text-white !accent-[#DFA1F4]"
                          onChange={(e: any) => {
                            onCheck(e);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="flex items-center justify-start">
                        <p className="text-sm font-bold text-white">{header}</p>
                      </div>
                    )}
                  </>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="mt-2">{children}</tbody>
        </table>
      </div>
    </>
  );
}
