import { useEffect, useState } from "react";
import FileUploadField from "core/components/formfields/FileUploadField";
import InputField from "core/components/formfields/InputField";
import { activeModule } from "core/consts/images";
import { actionbtn, btn } from "core/consts/styling";
import useMarketPlaceStore from "core/services/stores/useMarketPlaceStore";
import { useSearchParams } from "react-router-dom";
import notification from "core/helpers/notification";
import useUserStore from "core/services/stores/useUserStore";

interface Props {
  isUpdate: boolean;
}

const FastFoodVendorForm = ({ isUpdate = false }: Props) => {
  const [files, setFiles] = useState([]);
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const vendor = useMarketPlaceStore((store) => store.fastFoodVendor);
  const [, setVendorId] = useState("");

  const getVendorAction = useMarketPlaceStore(
    (store) => store.getFastFoodVendorById,
  );
  const addUpdateVendorAction = useMarketPlaceStore(
    (store) => store.addUpdateFastFoodVendor,
  );
  const deleteImageAction = useMarketPlaceStore(
    (store) => store.deleteFastFoodImage,
  );
  const [searchParams]: any = useSearchParams();

  const [newVendor, setNewVendor] = useState<NewFastFoodVendor>({
    address: "",
    convenienceFee: 0,
    image: "",
    logo: "",
    operationDays: "",
    operationTime: "",
    vendorName: "",
  });

  const [errors, setErrors] = useState({
    Address: [],
    ConvenienceFee: [],
    OperationDays: [],
    OperationTime: [],
    VendorName: [],
  });

  const removeImage = (index: number) => {
    setFiles([...files.filter((file, i) => i !== index)]);
  };

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const deleteImage = async (url: any) => {
    if (window.confirm(`Click 'OK' to delete this image from the server.`)) {
      await deleteImageAction("service", vendor?.vendorId, url);
    }
  };

  const validation = (data: NewFastFoodVendor, files: any[]) => {
    var isValid = true;

    // TODO: Add Validations
    return isValid;
  };

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;

    setNewVendor((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const addUpdateVendor = async (e: any) => {
    e.preventDefault();

    if (validation(newVendor, files)) {
      var res = await addUpdateVendorAction(
        {
          ...newVendor,
          logo: files,
        },
        isUpdate ? vendor?.vendorId : "",
      );

      if (res?.isSuccessful) {
        if (!isUpdate) {
          setNewVendor({
            address: "",
            convenienceFee: 0,
            image: "",
            logo: "",
            operationDays: "",
            operationTime: "",
            vendorName: "",
          });
        }

        setFiles([]);
      } else {
        setErrors((state) => ({
          ...state,
          ...res?.data?.errors,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    var vendorId = searchParams.get("vendorId");

    if (isUpdate && vendorId?.length > 0) {
      setVendorId(vendorId);
      getVendorAction(vendorId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, isUpdate]);

  useEffect(() => {
    isUpdate &&
      vendor != null &&
      setNewVendor({
        convenienceFee: vendor?.convenienceFee,
        operationDays: vendor?.operationDays,
        operationTime: vendor?.operationTime,
        vendorName: vendor?.vendorName,
        address: vendor?.address != null ? vendor?.address : vendor?.address,
        image: "",
        logo: "",
      });
  }, [isUpdate, vendor]);

  return (
    <section className="mx-auto mb-[28px] w-[95%]">
      <header className="mb-[14px] flex items-center gap-3">
        <img src={activeModule} alt="active module" loading="lazy" />
        <h5 className="text-[18px] font-[500] text-brand">
          {isUpdate && vendor
            ? `Update ${vendor && vendor?.vendorName}`
            : "Create Vendor"}
        </h5>
      </header>
      <form className="flex gap-5" onSubmit={addUpdateVendor}>
        <div className="w-3/5">
          <InputField
            boxStyle="mb-[18px]"
            placeholder="Vendor Name"
            label="Vendor Name"
            name="vendorName"
            isRequired
            value={newVendor?.vendorName}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.VendorName[0]}
            onBlur={() => onErrorChange("VendorName", [])}
          />

          <InputField
            boxStyle="mb-[24px]"
            placeholder="Address"
            label="Address"
            name="address"
            value={newVendor?.address}
            isRequired
            onChange={(e: any) => onFormChange(e)}
            error={errors?.Address[0]}
            onBlur={() => onErrorChange("Address", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            label="Operation Time"
            placeholder="Operation Time"
            name="operationTime"
            value={newVendor?.operationTime}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.OperationTime[0]}
            onBlur={() => onErrorChange("OperationTime", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            label="Operation Days"
            placeholder="Operation Days"
            name="operationDays"
            value={newVendor?.operationDays}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.OperationDays[0]}
            onBlur={() => onErrorChange("OperationDays", [])}
          />

          <InputField
            boxStyle="mb-[24px]"
            label="ConvenienceFee Fee"
            name="convenienceFee"
            type="number"
            value={newVendor?.convenienceFee}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.ConvenienceFee[0]}
            onBlur={() => onErrorChange("ConvenienceFee", [])}
          />

          <button
            disabled={isSuperAdmin}
            className={`${btn} w-full bg-brand text-white`}
          >
            Submit
          </button>
        </div>
        <div className="w-2/6">
          <>
            <FileUploadField
              multiple={true}
              boxStyle="w-3/5"
              label="Upload Pictures"
              name="signature"
              onChange={setFiles}
              showUploadedImages={false}
            />

            <div className="mt-[16px] grid grid-cols-2 gap-3 ">
              {files?.length > 0 &&
                files.map((file: any, index: number) => (
                  <div
                    key={file?.name + index}
                    className="relative overflow-hidden rounded-[5px] border border-brandLight"
                  >
                    <img src={URL.createObjectURL(file)} alt={`logo${index}`} />
                    <button
                      type="button"
                      className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                      onClick={() => removeImage(index)}
                    >
                      Delete
                    </button>
                  </div>
                ))}

              {isUpdate &&
                vendor?.logo?.length > 0 &&
                vendor?.logo?.map((image: string, index: number) => (
                  <div
                    key={index}
                    className="relative overflow-hidden rounded-[5px] border border-brandLight"
                  >
                    <img src={image} alt={`logo${index}`} />
                    <button
                      type="button"
                      className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                      onClick={() => deleteImage(image)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
            </div>
          </>
        </div>
      </form>
    </section>
  );
};

export default FastFoodVendorForm;
