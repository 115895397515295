export default function Row({
  value = "",
  colSpan = 1,
  style = "",
  onClick = () => {},
  enableAction = false,
}: {
  style?: string;
  onClick?: any;
  value: any;
  enableAction?: boolean;
  colSpan?: number;
}) {
  return (
    <td
      colSpan={colSpan}
      className={`min-w-[60px] border-[1px] border-[#767676] bg-white px-[10px] py-[12px] rounded-md text-start`}
    >
      <p onClick={onClick} className={`text-[14px] text-[#767676] ${style}`}>
        {value}
      </p>
    </td>
  );
}
