export const logo = require("assets/icons/logo.png");
export const waves = require("assets/img/background.png");
export const back = require("assets/icons/back.png");
export const closeIcon = require("assets/icons/close.png");
export const addIcon = require("assets/icons/add.png");
export const calender = require("assets/icons/dates.png");
export const activeModule = require("assets/icons/activemodule.png");
export const userIcon = require("assets/icons/user.png");
export const users = require("assets/icons/users.png");
export const userswhite = require("assets/icons/userswhite.png");
export const admin = require("assets/icons/admins.png");
export const analytics = require("assets/icons/analytics.png");
export const downloadImg = require("assets/icons/download.png");
export const settlementwhite = require("assets/icons/settlementwhite.png");
export const settlement = require("assets/icons/settlement.png");
export const locImg = require("assets/icons/locations.png");
export const eyeclose = require("assets/icons/eyeclose.png");
export const eyeopen = require("assets/icons/eyeopen.png");
export const editIcon = require("assets/icons/edit.png");
export const deleteIcon = require("assets/icons/delete.png");
export const male = require("assets/icons/male1.png");
export const profileuser = require("assets/icons/profile.png");
export const profileuser2 = require("assets/icons/profileuser.png");
export const addWhiteIcon = require("assets/icons/addwhite.png");
export const home = require("assets/icons/home.png");
export const locationImg = require("assets/icons/locations.png");
export const uploadImg = require("assets/icons/upload.png");

export const productImg = require("assets/icons/viewproducts.png");
export const directoryImg = require("assets/icons/directory.png");
export const fastFoodImg = require("assets/icons/fastfood.png");
export const rawFoodImg = require("assets/icons/rawfood.png");
export const viewOrderImg = require("assets/icons/vieworders.png");
export const camera = require("assets/icons/camera.png");
export const fastFoodSvg = require("assets/icons/fastfoodList.svg");
export const searchImg = require("assets/icons/search.png");
export const brocholli = require("assets/img/brocholli.png");
export const mapImg = require("assets/img/mapImg.png");

export const bookingimg = require("assets/icons/bookings.png");
export const orderImg = require("assets/icons/totalorder.png");
export const merchants2Img = require("assets/icons/merchants2.png");
export const foodItemImg = require("assets/icons/fooditems.png");
export const minibus = require("assets/icons/minibus.png");
