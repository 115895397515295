import InputField from "core/components/formfields/InputField";
import { logo } from "core/consts/images";
import { btn } from "core/consts/styling";
import { isEmail } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useUserStore from "core/services/stores/useUserStore";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const generateTokenAction = useUserStore((store) => store.generateToken);
  const confirmTokenAction = useUserStore((store) => store.confirmToken);
  const resetPasswordAction = useUserStore((store) => store.resetPassword);

  const [steps, setSteps] = useState(1);
  const [email, setEmail] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [errors, setErrors] = useState<any>({
    email: "",
    token: "",
    password: "",
    confirmPassword: "",
  });

  const validatePasswordReset = (password: string, confirmPassword: string) => {
    var isValid = true;

    if (password?.length < 1) {
      setErrors((state: any) => ({
        ...state,
        password: "Password is required.",
      }));
      isValid = false;
    } else {
      if (password !== confirmPassword) {
        setErrors((state: any) => ({
          ...state,
          confirmPassword: "Confirm password must be the same as password",
        }));
        isValid = false;
      }
    }

    return isValid;
  };

  const generateToken = async (e: any) => {
    e.preventDefault();

    if (isEmail(email)) {
      var res = await generateTokenAction(email);

      if (res?.isSuccessful) {
        setSteps(2);
      }
    } else {
      setErrors((state: any) => ({
        ...state,
        email: "Please provide a valid email address.",
      }));

      notification({
        title: "",
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const confirmToken = async (e: any) => {
    e.preventDefault();

    if (token?.length > 0) {
      var res = await confirmTokenAction(email, token);

      if (res?.isSuccessful) {
        setSteps(3);
      }
    } else {
      setErrors((state: any) => ({
        ...state,
        token: "Token is required",
      }));

      notification({
        title: "",
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const resetPassword = async (e: any) => {
    e.preventDefault();

    if (validatePasswordReset(password, confirmPassword)) {
      var res = await resetPasswordAction(email, password, confirmPassword);

      if (res?.isSuccessful) {
        navigate("/auth/login");
      }
    } else {
      notification({
        title: "",
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  return (
    <div className="mx-auto flex h-[80vh] w-11/12 justify-center md:w-4/5">
      <section className="mt-[100px] flex h-auto w-full flex-col items-center justify-center md:w-2/3 lg:w-2/5">
        {steps === 1 && (
          <form
            className="mb-[16px] w-full rounded-[8px] !bg-white px-[16px] py-[38px] md:px-[38px]"
            onSubmit={generateToken}
          >
            <div className="mb-[24px] mt-[-80px] flex flex-col items-center justify-center">
              <img src={logo} alt="campusrunz" loading="lazy" />
            </div>
            <h5 className="text-center text-[18px] font-[500] text-brand">
              Reset Password
            </h5>
            <h5 className="mb-[32px] text-center">
              A token will be sent to your email
            </h5>
            <InputField
              label="Email Address"
              boxStyle="mb-[24px]"
              placeholder="Enter email address"
              value={email}
              onChange={(e: any) => setEmail(e?.target?.value)}
              error={errors?.email}
              onBlur={() =>
                setErrors((state: any) => ({ ...state, email: "" }))
              }
            />
            <button className={`${btn} w-full bg-brand text-white`}>
              Send Reset Token
            </button>
          </form>
        )}

        {steps === 2 && (
          <form
            className="mb-[16px] w-full rounded-[8px] !bg-white px-[16px] py-[38px] md:px-[38px]"
            onSubmit={confirmToken}
          >
            <div className="mb-[24px] mt-[-80px] flex flex-col items-center justify-center">
              <img src={logo} alt="campusrunz" loading="lazy" />
            </div>
            <h5 className="text-center text-[18px] font-[500] text-brand">
              Reset Password
            </h5>
            <h5 className="mb-[32px] text-center">
              {`Enter the token sent to ${email}`}
            </h5>
            <InputField
              label="Reset Token"
              boxStyle="mb-[24px]"
              placeholder="Enter token"
              name="token"
              value={token}
              onChange={(e: any) => setToken(e?.target?.value)}
              error={errors?.token}
              onBlur={() =>
                setErrors((state: any) => ({ ...state, token: "" }))
              }
            />

            <button className={`${btn} mb-[16px] w-full bg-brand text-white`}>
              Verify Token
            </button>

            <button
              type="button"
              onClick={() => {
                if (email?.length > 0) {
                  generateTokenAction(email);
                }
              }}
              className={`${btn} w-full !bg-[#DFA1F4] text-white`}
            >
              Resend Token
            </button>
          </form>
        )}

        {steps === 3 && (
          <form
            className="mb-[16px] w-full rounded-[8px] !bg-white px-[16px] py-[38px] md:px-[38px]"
            onSubmit={resetPassword}
          >
            <div className="mb-[24px] mt-[-80px] flex flex-col items-center justify-center">
              <img src={logo} alt="campusrunz" loading="lazy" />
            </div>
            <h5 className="text-center text-[18px] font-[500] text-brand">
              Reset Password
            </h5>
            <h5 className="mb-[32px] text-center">Enter your new password</h5>
            <InputField
              boxStyle="mb-[24px]"
              label="Password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e: any) => setPassword(e?.target?.value)}
              error={errors?.password}
              onBlur={() =>
                setErrors((state: any) => ({ ...state, password: "" }))
              }
            />
            <InputField
              boxStyle="mb-[24px]"
              label="New Password"
              type="password"
              disabled={password?.length < 1}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e: any) => setConfirmPassword(e?.target?.value)}
              error={errors?.confirmPassword}
              onBlur={() =>
                setErrors((state: any) => ({ ...state, confirmPassword: "" }))
              }
            />
            <button className={`${btn} w-full bg-brand text-white`}>
              Reset Password
            </button>
          </form>
        )}

        <div className="flex w-full items-center justify-between">
          <Link to="/auth/login" className="text-[12px] text-brandblue">
            Login
          </Link>
          <Link to="/auth/signup" className="text-[12px] text-brandblue">
            Create Account
          </Link>
        </div>
      </section>
    </div>
  );
};

export default ForgotPassword;
