/* eslint-disable no-empty-pattern */
import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import { btn } from "core/consts/styling";
import {
  isEmail,
  isValidFormDate,
  isValidPassword,
} from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";

export const PersonalDetails = ({
  onSubmit = () => {},
  onChange = () => {},
  data,
  errors = [],
  onErrorChange = () => {},
}: {
  onSubmit?: any;
  data: NewUser;
  onChange?: any;
  errors?: any;
  onErrorChange?: any;
}) => {
  const validation = (data: NewUser) => {
    var isValid = true;

    if (data?.firstname?.length < 1) {
      onErrorChange((state: any) => ({
        ...state,
        Firstname: ["First Name is required"],
      }));
      isValid = false;
    }

    if (data?.middlename?.length < 1) {
      onErrorChange((state: any) => ({
        ...state,
        Middlename: ["Middle name is required"],
      }));
      isValid = false;
    }

    if (data?.lastname?.length < 1) {
      onErrorChange((state: any) => ({
        ...state,
        Lastname: ["Last Name is required"],
      }));
      isValid = false;
    }

    if (!isEmail(data?.email)) {
      onErrorChange((state: any) => ({
        ...state,
        Email: ["A valid email address is required"],
      }));
      isValid = false;
    }

    if (data?.phoneNo?.length < 11) {
      onErrorChange((state: any) => ({
        ...state,
        PhoneNo: ["A valid 11 digits phone number is required"],
      }));
      isValid = false;
    }

    if (data?.bvn?.length !== 11) {
      onErrorChange((state: any) => ({
        ...state,
        Bvn: ["A valid 11 digits BVN is required"],
      }));
      isValid = false;
    }

    if (!isValidPassword(data?.password)) {
      onErrorChange((state: any) => ({
        ...state,
        Password: [
          "Password must be at least 9 digits and must contain an uppercase, lowercase and a number",
        ],
      }));
      isValid = false;
    } else {
      if (data?.password !== data?.confirmPassword) {
        onErrorChange((state: any) => ({
          ...state,
          ConfirmPassword: ["Confirm password must match password"],
        }));
        isValid = false;
      }
    }

    if (!isValidFormDate(data?.dateOfBirth)) {
      onErrorChange((state: any) => ({
        ...state,
        DateOfBirth: ["A valid date of birth (yyyy-mm-dd) is required"],
      }));
      isValid = false;
    } else {
      if (new Date(data?.dateOfBirth) > new Date()) {
        onErrorChange((state: any) => ({
          ...state,
          DateOfBirth: ["Date of birth must not be greater than today"],
        }));
        isValid = false;
      }
    }

    if (data?.gender?.length < 1) {
      onErrorChange((state: any) => ({
        ...state,
        Gender: ["Gender is required"],
      }));
      isValid = false;
    }

    return isValid;
  };

  const submitForm = (e: any) => {
    e.preventDefault();

    if (validation(data)) {
      onSubmit();
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  return (
    <form onSubmit={submitForm}>
      <InputField
        boxStyle="mb-[24px]"
        placeholder="First Name"
        name="firstname"
        value={data?.firstname}
        onChange={(e: any) => {
          onChange(e);
        }}
        error={errors?.Firstname && errors?.Firstname[0]}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            FirstName: [],
          }));
        }}
      />
      <InputField
        boxStyle="mb-[24px]"
        placeholder="Middle Name"
        name="middlename"
        value={data?.middlename}
        onChange={(e: any) => {
          onChange(e);
        }}
        error={errors?.Middlename && errors?.Middlename[0]}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            MiddleName: [],
          }));
        }}
      />
      <InputField
        boxStyle="mb-[24px]"
        placeholder="Last Name"
        name="lastname"
        value={data?.lastname}
        onChange={(e: any) => {
          onChange(e);
        }}
        error={errors?.Lastname && errors?.Lastname[0]}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            Lastname: [],
          }));
        }}
      />
      <InputField
        boxStyle="mb-[24px]"
        placeholder="Email Address"
        name="email"
        value={data?.email}
        onChange={(e: any) => {
          onChange(e);
        }}
        error={errors?.Email[0]}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            Email: [],
          }));
        }}
      />
      <InputField
        boxStyle="mb-[24px]"
        placeholder="Phone Number"
        label="Phone Number"
        name="phoneNo"
        isNumberOnly
        value={data?.phoneNo}
        onChange={(e: any) => {
          onChange(e);
        }}
        error={errors?.PhoneNo[0]}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            PhoneNo: [],
          }));
        }}
      />
      <InputField
        boxStyle="mb-[24px]"
        placeholder="BVN"
        label="BVN"
        name="bvn"
        value={data?.bvn}
        isNumberOnly
        onChange={(e: any) => {
          onChange(e);
        }}
        error={errors?.Bvn[0]}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            Bvn: [],
          }));
        }}
      />
      <InputField
        boxStyle="mb-[24px]"
        placeholder="Password"
        name="password"
        type="password"
        value={data?.password}
        onChange={(e: any) => {
          onChange(e);
        }}
        error={errors?.Password[0]}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            Password: [],
          }));
        }}
      />
      <InputField
        boxStyle="mb-[24px]"
        placeholder="Confirm Password"
        name="confirmPassword"
        type="password"
        disabled={data?.password?.length < 9}
        value={data?.confirmPassword}
        onChange={(e: any) => {
          onChange(e);
        }}
        error={errors?.ConfirmPassword[0]}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            ConfirmPassword: [],
          }));
        }}
      />

      <InputField
        boxStyle="mb-[24px]"
        label="Date of Birth"
        name="dateOfBirth"
        type="date"
        value={data?.dateOfBirth}
        onChange={(e: any) => {
          onChange(e);
        }}
        error={errors?.DateOfBirth && errors?.DateOfBirth[0]}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            DateOfBirth: [],
          }));
        }}
      />
      
      <SelectField
        boxStyle="mb-[24px]"
        name="gender"
        defaultName="Select Gender"
        defaultValue=""
        options={[
          { name: "Male", value: "Male" },
          { name: "Female", value: "Female" },
        ]}
        value={data?.gender}
        onChange={(e: any) => {
          onChange(e);
        }}
        error={errors?.Gender[0]}
        onBlur={() => {
          onErrorChange((state: any) => ({
            ...state,
            Gender: [],
          }));
        }}
      />
      <button className={`${btn} w-full bg-brand text-white`}>Continue</button>
    </form>
  );
};
