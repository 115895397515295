import { camera } from "core/consts/images";
import { useState } from "react";

interface Props {
  boxStyle?: string;
  name: string;
  onChange?: any;
  label?: string;
  multiple?: boolean;
  uploadRef?: any;
  showUploadedImages?: boolean;
}

function FileUploadField({
  boxStyle = "",
  name = "",
  onChange = () => {},
  label = "",
  uploadRef,
  multiple = false,
  showUploadedImages = false,
}: Props) {
  const [inputError, setError] = useState("");
  const uploadField = document.getElementById(
    `upload-${name}`,
  ) as HTMLInputElement;

  const onFileChange = async (e: any) => {
    const { files } = e?.target;

    if (files) {
      var uploadedFile: File[] = e.target?.files;

      if (uploadedFile?.length > 0) {
        var isValid = true;

        Array.from(uploadedFile).forEach((file, index: number) => {
          if (file !== null && file?.type?.includes("image")) {
          } else {
            isValid = false;
          }
        });

        if (isValid) {
          onChange([...uploadedFile]);
          uploadField.value = "";
        } else {
          onChange([]);
          setError("Only images of type: jpg,png or gif are allowed");
        }
      } else {
        setError(
          "Please upload an image. Only images of type: jpg,png or gif are allowed",
        );
      }
    }
  };

  return (
    <div ref={uploadRef} className={`${boxStyle}`}>
      <div
        onClick={() => {
          setError("");
        }}
        className="flex h-[170px] w-full items-center justify-center rounded-[5px] bg-brand"
      >
        <label htmlFor={`upload-${name}`} className="flex flex-col gap-5">
          <img src={camera} alt="" />
          <span className="text-[14px] text-white">{label}</span>
        </label>
        <input
          type="file"
          multiple={multiple}
          id={`upload-${name}`}
          key={`upload-${name}`}
          name={`upload-${name}`}
          onChange={onFileChange}
          className="hidden"
        />
      </div>

      <span className="mt-[16px] text-[14px] text-red-500">{inputError}</span>
    </div>
  );
}

export default FileUploadField;
