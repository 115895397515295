import {
  formatCurrency,
  splitAndCapitalize,
} from "core/helpers/generalHelpers";
import { getSvg } from "core/helpers/getSvg";

const StatBoard = ({
  name = "",
  value = "",
}: {
  name: string;
  value: string | number;
}) => {
  return (
    <div className="flex flex-col items-center rounded-[5px] border-[1px] border-brand bg-white p-5 shadow-md">
      {getSvg(name)}
      <p className="mt-[16px] inline-block text-[18px] font-bold">
        {name?.toLocaleLowerCase()?.includes("income") ||
        name?.toLocaleLowerCase()?.includes("fee")
          ? formatCurrency(value)
          : Number(value).toLocaleString("en-US")}
      </p>
      <p className="text-center text-[12px] text-[#868686]">
        {name === "totalquantity" ? "Total Quantity" : splitAndCapitalize(name)}
      </p>
    </div>
  );
};

export default StatBoard;
