import InputField from "core/components/formfields/InputField";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import { activeModule, downloadImg } from "core/consts/images";
import {
  getRandomLightBackgroundColor,
  isObjectEmpty,
} from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useTransportationStore from "core/services/stores/useTransportationStore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const DriverSchedule = () => {
  // need state
  const tripRecord = useTransportationStore((store) => store.tripRecord);

  // actions
  const getTripRecordAction = useTransportationStore(
    (store) => store.getTripRecord,
  );

  const [query, setQuery] = useState<TripQuery>({
    search: "",
    status: "",
    startDate: "",
    endDate: "",
    locationFilter: "",
  });

  const onQueryChange = (e: any) => {
    const { name, value } = e.target;
    setQuery((state) => ({ ...state, [name]: value }));
  };

  const search = (e: any) => {
    e.preventDefault();
    getTripRecordAction(query);
  };

  useEffect(() => {
    getTripRecordAction(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mx-auto w-[95%]">
        <header className="mb-[28px] flex flex-col justify-start gap-2 lg:flex-row lg:items-center lg:justify-between">
          <div className="flex items-center gap-3">
            <img src={activeModule} loading="lazy" alt="" />
            <h5 className="text-[18px] font-[500] text-brand">
              Drivers Schedule
            </h5>
          </div>
          <div className="flex items-center justify-between gap-5">
            <Link
              className="text-brand hover:underline"
              to="/transportation/assign-bus"
            >
              Assign Driver to Bus
            </Link>
            <Link
              className="text-brand hover:underline"
              to="/transportation/plan-trips"
            >
              Plan Trips
            </Link>
            <Link
              className="text-brand hover:underline"
              to="/transportation/manage-trips"
            >
              Manage Trips
            </Link>
          </div>
        </header>

        <section>
          <form
            className="mb-[28px] flex items-end justify-between gap-2 overflow-x-scroll rounded-[5px] border border-t-[3px] border-brand bg-white p-3"
            onSubmit={search}
          >
            <InputField
              label="Search"
              boxStyle="min-w-[200px]"
              inputStyle="border-white overflow-x-scroll rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="search"
              value={query?.search}
              onChange={onQueryChange}
            />

            <InputField
              label="Start Date"
              type="date"
              boxStyle="min-w-[200px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="startDate"
              value={query?.startDate}
              onChange={onQueryChange}
            />

            <InputField
              label="End  Date"
              type="date"
              boxStyle="min-w-[200px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="endDate"
              value={query?.endDate}
              onChange={onQueryChange}
            />

            <div className="flex min-w-[400px] items-center gap-3">
              <button className="flex h-[50px] items-center justify-center gap-3 rounded-[5px] border border-brand bg-[#DFA1F4] p-3 px-8 text-white disabled:cursor-not-allowed">
                <span className="text-[14px text-brand">Search</span>
              </button>

              <button
                type="button"
                disabled
                className="flex h-[50px] items-center justify-between gap-3 rounded-[5px] bg-brand p-3 text-white disabled:cursor-not-allowed"
                onClick={() => {
                  // exportToCSV(vehicles, "Vehicles");

                  notification({
                    message: "You have successfully exported the data to excel",
                    type: "success",
                  });
                }}
              >
                <span className="text-[14px]">Download</span>
                <img src={downloadImg} alt="" loading="lazy" />
              </button>
            </div>
          </form>
        </section>
        <section className="mb-[28px] flex flex-col gap-5 lg:flex-row">
          <div className="w-full lg:w-3/12">
            <div className="flex items-center justify-between">
              <p>Summary</p>
              <p className="font-[500]">
                <>
                  Total:{" "}
                  {Object.values(tripRecord?.summary)?.reduce(
                    (a: any, b: any) => a + b,
                    0,
                  )}{" "}
                </>
              </p>
            </div>
            <Table headers={["Assigned Driver", "Count"]}>
              {!isObjectEmpty(tripRecord?.summary) ? (
                Object.keys(tripRecord?.summary)?.map((val: any, index) => (
                  <tr key={val + index}>
                    <Row value={val} />
                    <Row value={tripRecord?.summary[val]} />
                  </tr>
                ))
              ) : (
                <tr>
                  <Row colSpan={2} value="No trip record yet" />
                </tr>
              )}
            </Table>
          </div>

          <div className="grid w-full grid-cols-1 gap-3 md:grid-cols-2 lg:w-9/12 lg:grid-cols-3">
            {!isObjectEmpty(tripRecord?.tripHistories) && (
              <>
                {Object.keys(tripRecord?.tripHistories).map((val, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index}>
                    <div className="mb-3 rounded-[5px] bg-white py-[10px] text-center font-[500]">
                      {val}
                    </div>

                    {tripRecord?.tripHistories[val]?.length > 0 &&
                      tripRecord?.tripHistories[val].map((x: any, i: any) => (
                        <div
                          className="mb-2 flex flex-col items-center justify-center rounded-[5px] px-[20px] py-[40px] text-white"
                          style={{
                            backgroundColor: getRandomLightBackgroundColor(),
                          }}
                        >
                          <p className="font-[500]">{x?.driverName}</p>
                          <p>{x?.tripName}</p>
                        </div>
                      ))}
                  </div>
                ))}
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default DriverSchedule;
