import Pagination from "core/components/Pagination";
import DatePicker from "core/components/formfields/DatePicker";
import SelectField from "core/components/formfields/SelectField";
import { addWhiteIcon, locationImg, mapImg } from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import { MARKETPLACE_CATEGORY, USER_ROLES } from "core/consts/systemConst";
import { formatCurrency, getDateTime } from "core/helpers/generalHelpers";
import useLocationStore from "core/services/stores/useLocationStore";
import useUserStore from "core/services/stores/useUserStore";
import DeliveryAreaForm from "modules/partials/DeliveryAreaForm";
import { useEffect, useState } from "react";

interface Props {
  category: string;
}

const DeliveryAreas = ({ category = "" }: Props) => {
  const locations = useLocationStore((store) => store.locations);
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const location = useUserStore((store) => store.location);

  const getLocationAction = useLocationStore((store) => store.getLocations);

  const deliveryArealist = useLocationStore((store) => store.deliveryAreaList);
  const getDeliveryAreaAction = useLocationStore(
    (store) => store.getDeliveryAreas,
  );
  const deleteDeliveryAreaAction = useLocationStore(
    (store) => store.deleteDeliveryArea,
  );

  const [query, setQuery] = useState<DeliveryAreaQuery>({
    category: category,
    subCategory: "",
    locationFilter: location,
    endDate: "",
    startDate: "",
  });

  const [showForm, setShowForm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedArea, setSelectedArea] = useState<any>(null);

  const onQueryChange = (e: any) => {
    const { name, value } = e?.target;

    setQuery((state) => ({
      ...state,
      [name]: value,
    }));

    getDeliveryAreaAction(
      { ...query, [name]: value },
      deliveryArealist?.pageNumber,
      deliveryArealist?.pageSize,
    );
  };

  const fetchMore = (page: number) => {
    getDeliveryAreaAction(query, page, deliveryArealist?.pageSize);
  };

  const deleteArea = async (area: DeliveryArea) => {
    if (window.confirm(`Click 'OK' to delete ${area?.deliveryArea}`)) {
      await deleteDeliveryAreaAction(area?.areaId);
    }
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();

    getDeliveryAreaAction(
      query,
      deliveryArealist?.pageNumber,
      deliveryArealist?.pageSize,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="mx-auto mb-[28px] w-[95%]">
        <div className="mb-[24px] flex items-center justify-between">
          <header className="flex items-center gap-3">
            <img src={locationImg} alt="active module" loading="lazy" />
            <h5 className="text-[18px] font-[500] text-brand">
              Delivery Areas
            </h5>
          </header>
        </div>

        <div className="flex items-center justify-between gap-5">
          <div className="w-1/5">
            <SelectField
              selectStyle="!border-brand !shadow !text-[14px] !text-brand"
              name="locationFilter"
              defaultName="Select your Location"
              defaultValue=""
              disabled={!isSuperAdmin}
              options={
                locations?.length > 0
                  ? [
                      ...locations?.map((location) => ({
                        name: location?.name,
                        value: location?.name,
                      })),
                    ]
                  : []
              }
              value={query?.locationFilter}
              onChange={(e: any) => onQueryChange(e)}
            />
          </div>

          {category === USER_ROLES.marketplace ? (
            <div className="w-1/5">
              <SelectField
                selectStyle="!border-brand !shadow !text-[14px] !bg-brandLight !text-brand"
                name="subCategory"
                defaultName="Select Category"
                defaultValue=""
                options={
                  MARKETPLACE_CATEGORY?.length > 0
                    ? [
                        ...MARKETPLACE_CATEGORY?.map((cat) => ({
                          name: cat?.name,
                          value: cat?.value,
                        })),
                      ]
                    : []
                }
                value={query?.subCategory}
                onChange={(e: any) => onQueryChange(e)}
              />
            </div>
          ) : (
            <div className="w-1/5"></div>
          )}

          <DatePicker
            name="startDate"
            label="Start Date"
            value={query?.startDate}
            className={`flex h-12 w-1/5 items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
            onChange={(e: any) => {
              onQueryChange(e);
            }}
          />

          <DatePicker
            name="endDate"
            label="End Date"
            value={query?.endDate}
            className={`flex h-12 w-1/5 items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
            onChange={(e: any) => {
              onQueryChange(e);
            }}
          />

          <button
            disabled={isSuperAdmin}
            className="flex w-1/5 items-center justify-between rounded-[5px] bg-brand px-4 py-3 text-[14px] text-white shadow-md disabled:cursor-not-allowed"
            onClick={() => {
              setSelectedArea(null);
              setIsUpdate(false);
              setShowForm(true);
            }}
          >
            <img src={addWhiteIcon} loading="lazy" alt="" />
            <span>New Delivery Area</span>
          </button>
        </div>

        <main className="my-[24px]">
          {deliveryArealist?.areas?.length > 0 ? (
            deliveryArealist?.areas.map((area: DeliveryArea) => (
              <div
                key={area?.areaId}
                className="rounded-[5px]border-[1px] mb-[24px] flex items-center justify-between gap-5 border-brand bg-white px-4 py-3 shadow-md"
              >
                <div className="flex w-2/3 items-center gap-3">
                  <img src={mapImg} alt="" />
                  <div>
                    <p className="mb-[5px] font-[500] capitalize text-brand">
                      {area?.deliveryArea}
                    </p>
                    <p className="text-[14px] font-[500] capitalize text-[#8E8787]">
                      Delivery Fee: {formatCurrency(area?.deliveryFee)}
                    </p>
                    <p className="text-[14px] capitalize text-[#8E8787]">
                      Location: {area?.location}
                    </p>
                    <p className="text-[14px] capitalize text-[#8E8787]">
                      Category: {area?.category}{" "}
                      {area?.subCategory?.length > 0
                        ? `| ${area?.subCategory}`
                        : ""}
                    </p>
                    <p className="text-[14px] capitalize text-[#8E8787]">
                      Added on: {getDateTime(area?.createdAt)}
                    </p>
                  </div>
                </div>
                <div className="flex gap-3">
                  <button
                    disabled={isSuperAdmin}
                    className={`${actionbtn} !border-[#6C18A4] !bg-[#DFA1F4]`}
                    onClick={() => {
                      setSelectedArea(area);
                      setIsUpdate(true);
                      setShowForm(true);
                    }}
                  >
                    Update
                  </button>
                  <button
                    disabled={isSuperAdmin}
                    className={`${actionbtn} !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                    onClick={() => deleteArea(area)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="rounded-[5px]border-[1px] mb-[24px] flex items-center justify-between gap-5 border-brand bg-white px-4 py-3 shadow-md">
              <div>
                <p className="mb-[5px] font-[500] text-[#2E2626]">
                  No delivery Area yet
                </p>
              </div>
            </div>
          )}

          <Pagination
            pageNumber={deliveryArealist?.pageNumber}
            pageSize={deliveryArealist?.pageSize}
            totalCount={deliveryArealist?.totalCount}
            totalPage={deliveryArealist?.totalPage}
            onFetch={fetchMore}
          />
        </main>
      </section>

      <DeliveryAreaForm
        category={category}
        show={showForm}
        isUpdate={isUpdate}
        selectedArea={selectedArea}
        onShow={setShowForm}
      />
    </>
  );
};

export default DeliveryAreas;
