import Settlements from "modules/partials/Settlements";

const MyEarnings = () => {
  return (
    <Settlements
      module="foodstuff"
      header={
        <>
          <h5 className="text-[18px] font-[500] text-brand">My Earnings</h5>
        </>
      }
    />
  );
};

export default MyEarnings;
