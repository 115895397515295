import Modal from "core/components/Modal";
import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import UploadField from "core/components/formfields/UploadField";
import { btn } from "core/consts/styling";
import {
  TRANSPORTATION_AGENTS,
  TRANSPORTATION_AGENTS_TYPE,
} from "core/consts/systemConst";
import { isEmail } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useLocationStore from "core/services/stores/useLocationStore";
import useTransportationStore from "core/services/stores/useTransportationStore";
import useUserStore from "core/services/stores/useUserStore";
import { useEffect, useState } from "react";

interface Props {
  type: number;
  selectedAgent?: any;
  isUpdate?: boolean;
  show: boolean;
  onShow?: any;
  setSelected?: any;
}

const AgentsForm = ({
  type = 1,
  selectedAgent = null,
  isUpdate = false,
  show = false,
  onShow = () => {},
  setSelected = () => {},
}: Props) => {
  const [, setFiles] = useState([]);

  const location = useUserStore((store) => store.location);
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const [newAgent, setNewAgent] = useState<NewTransportationAgent>({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    contactAddress: "",
    userType: type,
    applicationForm: "",
    locationId: "",
  });

  const defaultErrors = {
    FirstName: [],
    LastName: [],
    PhoneNumber: [],
    Email: [],
    ContactAddress: [],
    UserType: [],
    ApplicationForm: [],
    LocationId: [],
  };

  const [errors, setErrors] = useState(defaultErrors);

  const addUpdateAgentAction = useTransportationStore(
    (store) => store.addUpdateAgent,
  );

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;

    setNewAgent((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const validation = (data: NewTransportationAgent) => {
    var isValid = true;

    if (data?.firstName?.length < 1) {
      isValid = false;
      onErrorChange("FirstName", ["First Name is required"]);
    }

    if (data?.lastName?.length < 1) {
      isValid = false;
      onErrorChange("LastName", ["Last Name is required"]);
    }

    if (data?.contactAddress?.length < 1) {
      isValid = false;
      onErrorChange("ContactAddress", ["Contact Address is required"]);
    }

    if (!isEmail(data?.email)) {
      isValid = false;
      onErrorChange("Email", ["A valid email address is required"]);
    }

    if (data?.phoneNumber?.length < 11) {
      isValid = false;
      onErrorChange("PhoneNumber", [
        "A valid 11 digits phone number is required",
      ]);
    }

    if (data?.applicationForm?.length < 1) {
      isValid = false;
      notification({
        type: "warning",
        message: "Please upload the application form",
      });
    }

    return isValid;
  };

  const submitForm = async (e: any) => {
    e.preventDefault();

    if (validation(newAgent)) {
      var res = await addUpdateAgentAction(
        { ...newAgent },
        isUpdate ? selectedAgent?.agentId : "",
      );

      if (res?.isSuccessful) {
        setNewAgent((state) => ({
          ...state,
          firstName: "",
          lastName: "",
          phoneNumber: "",
          email: "",
          contactAddress: "",
          userType: type,
          applicationForm: "",
        }));

        onShow(false);

        setSelected(null);

        setFiles([]);
      } else {
        setErrors((state) => ({
          ...state,
          ...res?.data?.errors,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    isUpdate &&
    selectedAgent != null &&
    setNewAgent((state) => ({
      ...state,
      firstName: selectedAgent?.firstName,
      lastName: selectedAgent?.lastName,
      phoneNumber: selectedAgent?.phoneNumber,
      email: selectedAgent?.email,
      contactAddress: selectedAgent?.contactAddress,
      userType: type,
      applicationForm: "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate, selectedAgent?.agentId]);
  
  useEffect(() => {
    if (locations?.length > 0) {
      setNewAgent((state: any) => ({
        ...state,
        locationId: locations.find((x) => x.name === location)?.id,
      }));
    }
    
    locations?.length < 1 && getLocationAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, selectedAgent]);
  
  return (
    <>
      {show && (
        <Modal
          header={
            isUpdate
              ? `Update Agent ${selectedAgent?.firstName}`
              : `Add ${
                  type === TRANSPORTATION_AGENTS_TYPE.deliveryAgent
                    ? "Delivery"
                    : "Driver"
                } Agent`
          }
          onClose={() => {
            onShow(false);
            setNewAgent((state) => ({
              ...state,
              firstName: "",
              lastName: "",
              phoneNumber: "",
              email: "",
              contactAddress: "",
              userType: type,
              applicationForm: "",
            }));
            setErrors(defaultErrors);
            setSelected(null);
          }}
        >
          <form onSubmit={submitForm}>
            <div className="mb-[24px] flex items-center gap-5">
              <SelectField
                label="Location"
                boxStyle="w-1/2 col-span-2"
                name="locationId"
                defaultName="Select your Location"
                defaultValue=""
                disabled
                options={
                  locations?.length > 0
                    ? [
                        ...locations?.map((location) => ({
                          name: location?.name,
                          value: location?.id,
                        })),
                      ]
                    : []
                }
                value={newAgent?.locationId}
                onChange={(e: any) => onFormChange(e)}
                error={errors?.LocationId[0]}
                onBlur={() => onErrorChange("LocationId", [])}
              />
              
              <SelectField
                label="Agent Type"
                boxStyle="w-1/2"
                name="userType"
                disabled
                defaultName="Select Agent Type"
                defaultValue=""
                options={
                  TRANSPORTATION_AGENTS?.length > 0
                    ? [
                        ...TRANSPORTATION_AGENTS?.map((cat) => ({
                          name: cat?.name,
                          value: cat?.value,
                        })),
                      ]
                    : []
                }
                value={newAgent?.userType}
                onChange={(e: any) => onFormChange(e)}
                error={errors?.UserType[0]}
                onBlur={() => onErrorChange("UserType", [])}
              />
            </div>

            <InputField
              boxStyle="mb-[18px]"
              label="First Name"
              placeholder="First Name"
              name="firstName"
              isRequired
              value={newAgent?.firstName}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.FirstName[0]}
              onBlur={() => onErrorChange("FirstName", [])}
            />

            <InputField
              boxStyle="mb-[18px]"
              label="Last Name"
              placeholder="Last Name"
              name="lastName"
              isRequired
              value={newAgent?.lastName}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.LastName[0]}
              onBlur={() => onErrorChange("LastName", [])}
            />

            <InputField
              boxStyle="mb-[18px]"
              label="Email"
              placeholder="Email"
              name="email"
              isRequired
              type="email"
              value={newAgent?.email}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.Email[0]}
              onBlur={() => onErrorChange("Email", [])}
            />

            <InputField
              boxStyle="mb-[18px]"
              label="Phone Number"
              placeholder="Phone Number"
              name="phoneNumber"
              isRequired
              value={newAgent?.phoneNumber}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.PhoneNumber[0]}
              onBlur={() => onErrorChange("PhoneNumber", [])}
            />

            <InputField
              boxStyle="mb-[18px]"
              label="Contact Address"
              placeholder="Contact Address"
              name="contactAddress"
              isRequired
              value={newAgent?.contactAddress}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.ContactAddress[0]}
              onBlur={() => onErrorChange("ContactAddress", [])}
            />

            {!isUpdate && (
              <UploadField
                boxStyle="mb-[24px]"
                label="Upload Application Form"
                name="applicationForm"
                onChange={setNewAgent}
                value={newAgent?.applicationForm}
              />
            )}

            <button
              disabled={isSuperAdmin}
              className={`${btn} w-full bg-brand text-white`}
            >
              Submit
            </button>
          </form>
        </Modal>
      )}
    </>
  );
};

export default AgentsForm;
