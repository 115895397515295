import {
  activeModule,
  directoryImg,
  fastFoodImg,
  home,
  locationImg,
  productImg,
  profileuser2,
  settlement,
  userIcon,
  users,
  viewOrderImg,
} from "core/consts/images";
import Dashboard from "./views/Dashboard";
import CreateProduct from "./views/CreateProduct";
import CreateService from "./views/CreateService";
import ViewProducts from "./views/ViewProducts";
import DirectoryServices from "./views/DirectoryServices";
import FastFoodVendors from "./views/FastFoodVendors";
import ViewOrders from "./views/ViewOrders";
import DeliveryArea from "./views/DeliveryArea";
import SalesHistory from "./views/SalesHistory";
import Notifications from "./views/Notifications";
import MyEarnings from "./views/MyEarnings";
import UpdateProduct from "./views/UpdateProduct";
import UpdateService from "./views/UpdateService";
import CreateVendor from "./views/CreateVendor";
import UpdateVendor from "./views/UpdateVendor";
import CreateServiceDirectory from "./views/CreateServiceDirectory";
import ViewOrderDetails from "./views/ViewOrderDetails";

const routes: RoutesType[] = [
  {
    name: "Dashboard",
    layout: "/marketplace",
    path: "dashboard",
    icon: home,
    component: <Dashboard />,
    children: [],
  },
  {
    name: "Create Product",
    layout: "/marketplace",
    path: "create-product",
    icon: userIcon,
    component: <CreateProduct />,
    children: [],
  },
  {
    name: "",
    layout: "/marketplace",
    path: "update-product",
    icon: <></>,
    component: <UpdateProduct />,
    children: [],
  },
  {
    name: "Create Service",
    layout: "/marketplace",
    path: "create-service",
    icon: activeModule,
    component: <CreateService />,
    children: [],
  },
  {
    name: "Create Service/Directory",
    layout: "/marketplace",
    path: "create-directory",
    icon: activeModule,
    component: <CreateServiceDirectory />,
    children: [],
  },
  {
    name: "",
    layout: "/marketplace",
    path: "update-service",
    icon: activeModule,
    component: <UpdateService />,
    children: [],
  },
  {
    name: "View Product",
    layout: "/marketplace",
    path: "view-product",
    icon: productImg,
    component: <ViewProducts />,
    children: [],
  },
  {
    name: "Directory Services",
    layout: "/marketplace",
    path: "directory-services",
    icon: directoryImg,
    component: <DirectoryServices />,
    children: [],
  },
  {
    name: "Fast Food Vendors",
    layout: "/marketplace",
    path: "fast-food-vendors",
    icon: fastFoodImg,
    component: <FastFoodVendors />,
    children: [],
  },
  {
    name: "",
    layout: "/marketplace",
    path: "fast-food-vendors/create-vendor",
    icon: fastFoodImg,
    component: <CreateVendor />,
    children: [],
  },
  {
    name: "",
    layout: "/marketplace",
    path: "fast-food-vendors/update-vendor",
    icon: fastFoodImg,
    component: <UpdateVendor />,
    children: [],
  },
  {
    name: " ",
    layout: "/marketplace",
    path: "view-orders/:category/:orderId",
    icon: viewOrderImg,
    component: <ViewOrderDetails />,
    children: [],
  },
  {
    name: "View Orders ",
    layout: "/marketplace",
    path: "view-orders",
    icon: viewOrderImg,
    component: <ViewOrders />,
    children: [],
  },
  {
    name: "Delivery Area",
    layout: "/marketplace",
    path: "delivery-area",
    icon: locationImg,
    component: <DeliveryArea />,
    children: [],
  },
  {
    name: "Sales History ",
    layout: "/marketplace",
    path: "sales-history",
    icon: users,
    component: <SalesHistory />,
    children: [],
  },
  {
    name: "Notifications",
    layout: "/marketplace",
    path: "notifications",
    icon: profileuser2,
    component: <Notifications />,
    children: [],
  },
  {
    name: "My Earnings",
    layout: "/marketplace",
    path: "earnings",
    icon: settlement,
    component: <MyEarnings />,
    children: [],
  },
];

export default routes;
