import { ADVERT_PLACEMENT_TYPES } from "core/consts/systemConst";
import { apicall } from "./apicall";

export const getAdverts = (
  query: AdvertQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: "/advert/pagination",
    method: "GET",
    pQuery: {
      ...query,
      locationId: "",
      pageNumber,
      pageSize,
    },
    auth: true,
  });
};

export const getAdvertById = (advertId: string) => {
  return apicall({
    endpoint: "/advert/GetById",
    param: advertId,
    method: "GET",
    auth: true,
  });
};

export const addAdvert = async (advert: NewAdvert) => {
  const data = new FormData();

  data.append("Title", advert?.title);
  data.append("Image", advert?.image);
  data.append("Descriptor", advert?.descriptor);
  data.append("RedirectionUrl", advert?.redirectionUrl);

  data.append("StartDate", advert?.startDate);
  data.append("EndDate", advert?.endDate);
  data.append("Owner", advert?.owner);
  data.append("Owner", advert?.owner);
  data.append("LocationId", advert?.locationId?.join(","));

  data.append(
    "IsHomeEnabled",
    advert?.placement?.includes(ADVERT_PLACEMENT_TYPES.Home) ? "true" : "false",
  );

  data.append(
    "IsMarketplaceEnabled",
    advert?.placement?.includes(ADVERT_PLACEMENT_TYPES.Marketplace)
      ? "true"
      : "false",
  );

  return apicall({
    endpoint: "/advert/create",
    method: "POST",
    multipart: true,
    auth: true,
    body: data,
  });
};

export const uploadAdvertGraphics = async (advertId: string, image: string) => {
  const data = new FormData();

  data.append("Id", advertId);
  data.append("Image", image);

  return apicall({
    endpoint: "/advert/UploadNewGraphic",
    method: "POST",
    multipart: true,
    auth: true,
    body: data,
  });
};

export const updateAdvert = async (advertId: string, advert: NewAdvert) => {
  return apicall({
    endpoint: "/advert/update",
    param: advertId,
    method: "PATCH",
    auth: true,
    body: {
      owner: advert?.owner,
      title: advert?.title,
      isHomeEnabled: advert?.placement?.includes(ADVERT_PLACEMENT_TYPES.Home)
        ? true
        : false,
      isMarketplaceEnabled: advert?.placement?.includes(
        ADVERT_PLACEMENT_TYPES.Marketplace,
      )
        ? true
        : false,
      descriptor: advert?.descriptor,
      redirectionUrl: advert?.redirectionUrl,
      startDate: advert?.startDate,
      endDate: advert?.endDate,
      locationId: advert?.locationId?.join(","),
    },
  });
};

export const reRunAdvert = (
  advertId: string,
  startDate: string,
  endDate: string,
) => {
  return apicall({
    endpoint: "/advert/rerun",
    param: advertId,
    method: "PATCH",
    body: {
      startDate,
      endDate,
    },
    auth: true,
  });
};

export const deleteAdvert = (advertId: string) => {
  return apicall({
    endpoint: "/advert/delete",
    param: advertId,
    method: "DELETE",
    auth: true,
  });
};

export const changeAdvertVisibility = (advertId: string) => {
  return apicall({
    endpoint: "/advert/updateVisibility",
    param: advertId,
    method: "PATCH",
    body: advertId,
    auth: true,
  });
};

// Default Advert
export const addUpdateDefaultAdvert = async (
  advert: NewDefaultAdvert,
  advertId: string,
) => {
  var isUpdate = advertId?.length > 0;

  const data = new FormData();

  if (isUpdate) data.append("Id", advertId);

  data.append("Title", advert?.title);
  data.append("Image", advert?.image);
  data.append("Descriptor", advert?.descriptor);
  data.append("RedirectionUrl", advert?.redirectionUrl);

  return apicall({
    endpoint: "/advert/defaultAd",
    method: isUpdate ? "PATCH" : "POST",
    multipart: true,
    auth: true,
    body: data,
  });
};

// Get Default Ad
export const getDefaultAdvert = () => {
  return apicall({
    endpoint: "/advert/defaultAd",
    method: "GET",
    auth: true,
  });
};

// Loyalty Program
export const getLoyalty = (pageNumber: number, pageSize: number) => {
  return apicall({
    endpoint: "/loyalty/campaign/get",
    method: "GET",
    pQuery: { pageNumber, pageSize },
    auth: true,
  });
};

export const addUpdateLoyalty = async (data: NewLoyalty, loyaltyId: string) => {
  var isUpdate = loyaltyId?.length > 0 ? true : false;

  return apicall({
    endpoint: isUpdate ? "/loyalty/campaign/update" : "/loyalty/campaign/add",
    param: isUpdate ? loyaltyId : "",
    method: isUpdate ? "PATCH" : "POST",
    auth: true,
    body: data,
  });
};

export const deleteLoyalty = (loyaltyId: string) => {
  return apicall({
    endpoint: "/loyalty/campaign/delete",
    param: loyaltyId,
    method: "DELETE",
    auth: true,
  });
};
