import { actionbtn } from "core/consts/styling";
import Table from "core/components/table/Table";
import Row from "core/components/table/Row";
import ActionRow from "core/components/table/Actionrow";
import SelectField from "core/components/formfields/SelectField";
import useAdminStore from "core/services/stores/useAdminStore";
import { useEffect, useState } from "react";
import { formatCurrency } from "core/helpers/generalHelpers";
import useLocationStore from "core/services/stores/useLocationStore";
import { BUSINESS_MODULES, USER_ROLES } from "core/consts/systemConst";
import DatePicker from "core/components/formfields/DatePicker";
import notification from "core/helpers/notification";
import { exportToCSV } from "core/helpers/exportToExcel";
import { downloadImg, settlement, settlementwhite } from "core/consts/images";
import useUserStore from "core/services/stores/useUserStore";
import Pagination from "core/components/Pagination";

interface Props {
  module: string;
  header: any;
}

const Settlements = ({ module = "", header = <></> }: Props) => {
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const location = useUserStore((store) => store.location);

  const settlementlogList = useAdminStore((store) => store.settlementLogList);
  const getSettlementlogAction = useAdminStore(
    (store) => store.getSettlementLog,
  );
  const markAsPaidAction = useAdminStore((store) => store.markAsPaid);
  const [isChecked, setIsChecked] = useState(true);

  const [selectedData, setSelectedData] = useState<SettlementLog[]>(
    settlementlogList?.data !== null && settlementlogList?.data?.length > 0
      ? [...settlementlogList?.data]
      : [],
  );

  const [query, setQuery] = useState<LogQueryParam>({
    endDate: "",
    startDate: "",
    locationFilter: isSuperAdmin ? "" : location,
    module: module,
    status: "",
    pageNumber: 1,
    pageSize: 20,
  });

  const onQueryChange = (name: string, value: string) => {
    setQuery((state) => ({
      ...state,
      [name]: value,
    }));

    getSettlementlogAction({
      ...query,
      [name]: value,
    });
  };

  const markTransactionAsPaid = async () => {
    if (selectedData?.length > 0) {
      await markAsPaidAction(selectedData);
      setSelectedData([]);
    } else {
      notification({
        title: "",
        message: "Please select the field you want to mark as paid",
        type: "danger",
      });
    }
  };

  const exportData = async () => {
    if (selectedData?.length > 0) {
      exportToCSV(selectedData, "Settlement List");

      setSelectedData([]);

      setIsChecked(false);

      notification({
        message: "You have successfully exported the data to excel",
        type: "success",
      });
    } else {
      notification({
        message: "Please select the field you want to export",
        type: "danger",
      });
    }
  };

  const fetchMore = async (page: number) => {
    setQuery((state) => ({
      ...state,
      pageNumber: page,
    }));

    await getSettlementlogAction({
      ...query,
      pageNumber: page,
      pageSize: settlementlogList?.pageSize,
    });
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();
    !isSuperAdmin && getSettlementlogAction(query);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="mx-auto w-[95%]">
      <header className="mb-[14px] flex items-end gap-3">
        <img src={settlement} loading="lazy" alt="" />
        <>{header}</>
      </header>
      <div className="flex flex-col-reverse gap-5 lg:flex-row">
        <div className="w-full lg:w-8/12">
          <div className="mb-[24px] grid grid-cols-2 gap-3 rounded-[25px] lg:grid-cols-4">
            <button className={`${actionbtn} !bg-brand !text-white shadow-md`}>
              Total Amount: {formatCurrency(settlementlogList?.totalAmount)}
            </button>
            <button
              onClick={() => onQueryChange("status", "")}
              className={`${actionbtn} bg-[#F2EAF8] shadow-md`}
            >
              View All
            </button>
            <>
              <button
                onClick={() => onQueryChange("status", "pending")}
                className={`${actionbtn} bg-[#DFA1F4] shadow-md`}
              >
                Pending
              </button>
              <button
                onClick={() => onQueryChange("status", "paid")}
                className={`${actionbtn} bg-brand text-white shadow-md`}
              >
                Paid
              </button>
            </>
          </div>

          {query?.locationFilter?.length > 1 ? (
            <div>
              <Table
                headers={[
                  "checkbox",
                  "S/N",
                  "Module",
                  "Category",
                  "Status",
                  "Amount",
                ]}
                isChecked={isChecked}
                onCheck={(e: any) => {
                  setIsChecked(e?.target?.checked);
                  if (e?.target?.checked) {
                    setSelectedData([...settlementlogList?.data]);
                  } else {
                    setSelectedData([]);
                  }
                }}
              >
                {settlementlogList?.data?.length > 0 ? (
                  settlementlogList?.data?.map(
                    (log: SettlementLog, index: number) => (
                      <tr key={log?.id}>
                        <ActionRow style={`flex items-center justify-center`}>
                          <input
                            type="checkbox"
                            checked={selectedData?.some(
                              (data) => data?.id === log?.id,
                            )}
                            onChange={(e) => {
                              if (e?.target?.checked) {
                                setSelectedData((state) => [
                                  ...state,
                                  { ...log },
                                ]);
                              } else {
                                setSelectedData((state) =>
                                  state.filter((el) => el.id !== log?.id),
                                );
                              }
                            }}
                            className="h-[25px] w-[25px] rounded-[5px] text-white !accent-[#DFA1F4]"
                          />
                        </ActionRow>
                        <Row
                          value={
                            index +
                            1 +
                            (query?.pageNumber - 1) * query?.pageSize
                          }
                        />
                        <Row style={`capitalize`} value={log?.module} />
                        <Row style={`capitalize`} value={log?.category} />
                        <Row style={`capitalize`} value={log?.paymentStatus} />
                        <Row
                          style={`min-w-[80px]`}
                          value={formatCurrency(log?.amount)}
                        />
                      </tr>
                    ),
                  )
                ) : (
                  <tr>
                    <Row
                      colSpan={6}
                      value={
                        module === USER_ROLES?.super_admin
                          ? "No transaction settlement data yet"
                          : "No earnings yet"
                      }
                    />
                  </tr>
                )}
              </Table>

              {module?.length > 1 && (
                <Pagination
                  pageNumber={settlementlogList?.pageNumber}
                  pageSize={settlementlogList?.pageSize}
                  totalCount={settlementlogList?.totalCount}
                  totalPage={settlementlogList?.totalPage}
                  onFetch={fetchMore}
                />
              )}
            </div>
          ) : (
            <div>Please select a location</div>
          )}
        </div>

        <div className="w-full lg:w-4/12">
          <SelectField
            boxStyle="mb-[18px]"
            selectStyle="!border-brand text-[#615353]"
            name="locationFilter"
            defaultName="Select your Location"
            defaultValue=""
            disabled={!isSuperAdmin}
            options={
              locations?.length > 0
                ? [
                    ...locations?.map((location) => ({
                      name: location?.name,
                      value: location?.name,
                    })),
                  ]
                : []
            }
            value={query?.locationFilter}
            onChange={(e: any) =>
              onQueryChange(e?.target?.name, e?.target?.value)
            }
          />

          <SelectField
            boxStyle="mb-[18px]"
            selectStyle="!border-brand !bg-brand text-white"
            name="module"
            defaultName="Filter By Module"
            defaultValue=""
            disabled={!isSuperAdmin || query?.locationFilter?.length < 1}
            options={[
              ...BUSINESS_MODULES?.map(
                (module: { name: string; value: string }) => ({
                  name: module?.name,
                  value: module?.value,
                }),
              ),
            ]}
            value={query?.module}
            onChange={(e: any) =>
              onQueryChange(e?.target?.name, e?.target?.value)
            }
          />

          <div className="grid grid-cols-2 gap-5">
            <DatePicker
              name="startDate"
              label="Start Date"
              value={query?.startDate}
              disabled={query?.locationFilter?.length < 1}
              className={`flex h-12 w-full items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
              onChange={(e: any) =>
                onQueryChange(e?.target?.name, e?.target?.value)
              }
            />

            <DatePicker
              name="endDate"
              label="End Date"
              disabled={query?.locationFilter?.length < 1}
              value={query?.endDate}
              className={`flex h-12 w-full items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
              onChange={(e: any) =>
                onQueryChange(e?.target?.name, e?.target?.value)
              }
            />

            <>
              {module?.length < 1 && (
                <button
                  disabled={selectedData?.length < 1}
                  className="flex items-center justify-between rounded-[5px] bg-[#D80303] p-3 text-white disabled:cursor-not-allowed"
                  onClick={() => markTransactionAsPaid()}
                >
                  <span className="text-[14px]">Make Payment</span>
                  <img src={settlementwhite} loading="lazy" alt="" />
                </button>
              )}

              <button
                disabled={selectedData?.length < 1}
                className="flex items-center justify-between rounded-[5px] bg-brand p-3 text-white disabled:cursor-not-allowed"
                onClick={() => exportData()}
              >
                <span className="text-[14px]">Download</span>
                <img src={downloadImg} loading="lazy" alt="" />
              </button>
            </>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Settlements;

// TODO:  filter by payment status
