import { settlement } from "core/consts/images";

const MyEarnings = () => {
  return (
    <div className="mx-auto w-[95%]">
      <header className="mb-[28px] flex items-center justify-between">
        <div className="flex items-center gap-3">
          <img src={settlement} loading="lazy" alt="" />
          <h5 className="text-[18px] font-[500] text-brand">Earnings</h5>
        </div>
      </header>
      <section>
        <p>
          This feature does not exist currently. You would be notified when new
          updates are available.
        </p>
      </section>
    </div>
  );
};

export default MyEarnings;
