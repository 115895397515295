import { apicall } from "./apicall";

export const getLaundryDashboard = (query: DateLocationQuery) => {
  return apicall({
    endpoint: "/laundry/dashboard",
    method: "GET",
    pQuery: {
      ...query,
    },
    auth: true,
  });
};

export const getClothes = (
  query: LaundryQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: "/laundry/clothe",
    method: "GET",
    pQuery: {
      ...query,
      pageNumber,
      pageSize,
    },
    auth: true,
  });
};

export const getClotheById = (clotheId: string) => {
  return apicall({
    endpoint: "/laundry/clothe",
    param: clotheId,
    method: "GET",
    auth: true,
  });
};

export const addUpdateClothe = async (clothe: NewClothe, clotheId: string) => {
  const data = new FormData();

  data.append("clotheType", clothe?.clotheType);
  data.append("extraDetails", clothe?.extraDetails);
  data.append("convenienceFee", `${clothe?.convenienceFee}`);
  data.append("expressAmount", `${clothe?.expressAmount}`);
  data.append("normalAmount", `${clothe?.normalAmount}`);

  if (clothe?.images?.length > 0) {
    clothe?.images.forEach((file: any, i: any) => {
      data.append(`uploadedImageList${i}`, file);
    });
  }

  var isUpdate = clotheId?.length > 0;

  return apicall({
    endpoint: "/laundry/clothe",
    param: isUpdate ? clotheId : "",
    method: isUpdate ? "PATCH" : "POST",
    multipart: true,
    auth: true,
    body: data,
  });
};

export const toggleClothe = (clotheId: string, visibility: boolean) => {
  return apicall({
    endpoint: "/laundry/clothe/toggle",
    param: clotheId,
    method: "PATCH",
    body: {
      visibility,
    },
    auth: true,
  });
};

export const deleteClothe = (clotheId: string) => {
  return apicall({
    endpoint: "/laundry/clothe",
    param: clotheId,
    method: "DELETE",
    auth: true,
  });
};

export const deleteClotheImage = (
  category: string,
  clotheId: string,
  image: string,
) => {
  return apicall({
    endpoint: "/laundry/image",
    method: "DELETE",
    body: {
      itemId: clotheId,
      category,
      imagePath: image,
    },
    auth: true,
  });
};

export const getLaundryOrders = (
  query: LaundryQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: "/laundry/order",
    method: "GET",
    pQuery: {
      endDate: query?.endDate,
      startDate: query?.startDate,
      userId: query?.userId,
      locationFilter: query?.locationFilter,
      searchText: query?.searchText,
      orderStatus: query?.orderStatus,
      pageNumber,
      pageSize,
    },
    auth: true,
  });
};

export const getLaundryOrderById = (orderId: string) => {
  return apicall({
    endpoint: "/laundry/order",
    param: orderId,
    method: "GET",
    auth: true,
  });
};
