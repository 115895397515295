import DatePicker from "core/components/formfields/DatePicker";
import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import { users } from "core/consts/images";
import { formatCurrency } from "core/helpers/generalHelpers";
import useLocationStore from "core/services/stores/useLocationStore";
import useMarketPlaceStore from "core/services/stores/useMarketPlaceStore";
import useUserStore from "core/services/stores/useUserStore";
import { useEffect, useState } from "react";

const SalesHistory = () => {
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const location = useUserStore((store) => store.location);

  const salesHistory = useMarketPlaceStore((store) => store.salesHistory);
  const getSalesHistoryAction = useMarketPlaceStore(
    (store) => store.getSalesHistory,
  );

  const [query, setQuery] = useState<DateLocationQuery>({
    startDate: "",
    endDate: "",
    locationFilter: location,
  });

  const onQueryChange = (e: any, auto: boolean = true) => {
    const { name, value } = e?.target;

    setQuery((state) => ({
      ...state,
      [name]: value,
    }));

    if (auto) getSalesHistoryAction({ ...query, [name]: value });
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();
    getSalesHistoryAction(query);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="mx-auto mb-[28px] w-[95%]">
      <header className="mb-[14px] flex items-center gap-3">
        <img src={users} alt="active module" loading="lazy" />
        <h5 className="text-[18px] font-[500] text-brand">Sales History</h5>
      </header>

      <div className="flex items-center justify-between gap-5">
        <InputField
          boxStyle="w-1/4"
          inputStyle="!bg-white !text-[18px] !text-brand !border-brand"
          label="Income made till Date"
          value={formatCurrency(salesHistory?.header?.totalIncome)}
        />
        <InputField
          boxStyle="w-1/4"
          inputStyle="!bg-brandLight !text-[18px] !text-brand !border-brand"
          label="Commission made till Date"
          value={formatCurrency(salesHistory?.header?.totalCommission)}
        />
        <SelectField
          boxStyle="w-1/4 !mt-6"
          name="locationFilter"
          defaultName="Select your Location"
          defaultValue=""
          disabled={!isSuperAdmin}
          options={
            locations?.length > 0
              ? [
                  ...locations?.map((location) => ({
                    name: location?.name,
                    value: location?.name,
                  })),
                ]
              : []
          }
          value={query?.locationFilter}
          onChange={(e: any) => {
            onQueryChange(e);
          }}
        />
        <DatePicker
          name="startDate"
          label="Start Date"
          value={query?.startDate}
          className={`!mt-6 flex h-12 w-1/4 items-center justify-between  rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
          onChange={(e: any) => {
            onQueryChange(e);
          }}
        />

        <DatePicker
          name="endDate"
          label="End Date"
          value={query?.endDate}
          className={`!mt-6 flex h-12 w-1/4 items-center justify-between  rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
          onChange={(e: any) => {
            onQueryChange(e);
          }}
        />
      </div>

      <main className="my-[24px]"></main>
      <Table
        headers={[
          "Categories",
          "No of Merchants/Vendors",
          "Unit Cost",
          "Total Quantity",
          "Amount",
        ]}
      >
        {salesHistory?.sales?.length > 0 ? (
          salesHistory?.sales?.map((sale, index) => (
            <tr>
              <Row value={sale?.category} />
              <Row value={sale?.noOfMerchants?.toLocaleString("en-US")} />
              <Row value={sale?.unitCost?.toLocaleString("en-US")} />
              <Row value={sale?.order?.toLocaleString("en-US")} />
              <Row value={formatCurrency(sale?.amount)} />
            </tr>
          ))
        ) : (
          <tr>
            <Row colSpan={5} value="No sales history yet" />
          </tr>
        )}
      </Table>
    </section>
  );
};

export default SalesHistory;
