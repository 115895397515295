import FileUploadField from "core/components/formfields/FileUploadField";
import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import TextField from "core/components/formfields/TextField";
import { actionbtn, btn } from "core/consts/styling";
import notification from "core/helpers/notification";
import useMarketPlaceStore from "core/services/stores/useMarketPlaceStore";
import { useEffect, useState } from "react";

interface Props {
  category: string;
  product: any;
  isUpdate: boolean;
  isSuperAdmin: boolean;
}

const FastFoodForm = ({
  category = "",
  product = null,
  isUpdate = false,
  isSuperAdmin = false,
}: Props) => {
  const [files, setFiles] = useState([]);

  const addUpdateProductAction = useMarketPlaceStore(
    (store) => store.addUpdateProduct,
  );
  const getFastFoodVendors = useMarketPlaceStore(
    (store) => store.getFastFoodVendors,
  );
  const deleteImageAction = useMarketPlaceStore(
    (store) => store.deleteProductImage,
  );

  const vendors = useMarketPlaceStore((store) => store.fastFoodVendors);

  const [newProduct, setNewProduct] = useState<NewFastFoodProduct>({
    category: category,
    name: "",
    vendor: "",
    amount: 0,
    description: "",
    imagePath: [],
    filess: [],
  });

  const [errors, setErrors] = useState({
    Name: [],
    Vendor: [],
    Amount: [],
    Description: [],
    Filess: [],
  });

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const removeImage = (index: number) => {
    setFiles([...files.filter((file, i) => i !== index)]);
  };

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;
    setNewProduct((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const validation = (data: NewFastFoodProduct, files: any[]) => {
    var isValid = true;

    if (data?.name?.length < 1) {
      isValid = false;
      onErrorChange("Name", ["Name is required"]);
    }

    if (data?.vendor?.length < 1) {
      isValid = false;
      onErrorChange("Vendor", ["Please select a Vendor"]);
    }

    if (data?.amount < 0) {
      isValid = false;
      onErrorChange("Amount", ["Amount must not be less than 0"]);
    }

    if (!isUpdate && files.length < 1) {
      isValid = false;
      notification({
        message: "Please upload photos",
        type: "danger",
      });
    }

    return isValid;
  };

  const addUpdateProduct = async (e: any) => {
    e.preventDefault();

    if (validation(newProduct, files)) {
      var res = await addUpdateProductAction(
        {
          ...newProduct,
          filess: files,
        },
        isUpdate ? product?.productId : "",
      );

      if (res?.isSuccessful) {
        !isUpdate &&
          setNewProduct({
            category: category,
            name: "",
            vendor: "",
            amount: 0,
            description: "",
            imagePath: [],
            filess: [],
          });
        setFiles([]);
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const deleteImage = async (url: any) => {
    if (window.confirm(`Click 'OK' to delete this image from the server.`)) {
      await deleteImageAction("product", product?.productId, url);
    }
  };

  useEffect(() => {
    vendors?.length < 1 && getFastFoodVendors();

    isUpdate &&
      product != null &&
      setNewProduct({
        category: category,
        name: product?.name,
        vendor: product?.vendor?.id,
        amount: product?.amount,
        description: product?.description,
        imagePath: [],
        filess: [],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate, product]);

  return (
    <form onSubmit={addUpdateProduct} className="mb-[24px] flex gap-5">
      <div className="w-3/5">
        <InputField
          boxStyle="mb-[18px]"
          placeholder="Product Name"
          label="Product Name"
          name="name"
          value={newProduct?.name}
          onChange={(e: any) => onFormChange(e)}
          error={errors?.Name[0]}
          onBlur={() => onErrorChange("Name", [])}
        />

        <InputField
          boxStyle="mb-[18px]"
          label="Amount"
          placeholder="Amount"
          name="amount"
          value={newProduct?.amount}
          type="number"
          onChange={(e: any) => onFormChange(e)}
          error={errors?.Amount[0]}
          onBlur={() => onErrorChange("Amount", [])}
        />

        <div className="mb-[18px] flex items-center gap-3">
          <p>This is product handled by a vendor</p>
          <input
            name=""
            type="checkbox"
            className="border-1 h-[25px] w-[25px] rounded-[5px] border border-red-500 text-white !accent-[#DFA1F4]"
          />
        </div>

        <SelectField
          boxStyle="mb-[18px]"
          selectStyle="!border-brand text-[#615353]"
          name="vendor"
          defaultName="Select Vendor"
          label="Vendor"
          defaultValue=""
          options={
            vendors?.length > 0
              ? [
                  ...vendors?.map((vendor) => ({
                    name: vendor?.vendorName,
                    value: vendor?.vendorId,
                  })),
                ]
              : []
          }
          value={newProduct?.vendor}
          onChange={(e: any) => onFormChange(e)}
          error={errors?.Vendor[0]}
          onBlur={() => onErrorChange("Vendor", [])}
        />

        <TextField
          boxStyle="mb-[18px]"
          label="Description"
          placeholder="Description"
          name="description"
          value={newProduct?.description}
          onChange={(e: any) => onFormChange(e)}
          rows={5}
          error={errors?.Description[0]}
          onBlur={() => onErrorChange("Description", [])}
        />

        <button
          disabled={isSuperAdmin}
          className={`${btn} w-full bg-brand text-white`}
        >
          Submit
        </button>
      </div>
      <div className="w-2/6">
        <>
          <FileUploadField
            multiple={true}
            boxStyle="w-3/5"
            label="Upload Pictures"
            name="signature"
            onChange={setFiles}
            showUploadedImages={false}
          />

          <div className="mt-[16px] grid grid-cols-2 gap-3 ">
            {files?.length > 0 &&
              files?.map((file: any, index: number) => (
                <div
                  key={file?.name + index}
                  className="relative overflow-hidden rounded-[5px] border border-brandLight"
                >
                  <img src={URL.createObjectURL(file)} alt={`logo${index}`} />
                  <button
                    type="button"
                    className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                    onClick={() => removeImage(index)}
                  >
                    Delete
                  </button>
                </div>
              ))}

            {isUpdate &&
              product?.uploadedImageList?.length > 0 &&
              product?.uploadedImageList?.map(
                (image: string, index: number) => (
                  <div
                    key={index}
                    className="relative overflow-hidden rounded-[5px] border border-brandLight"
                  >
                    <img src={image} alt={`logo${index}`} />
                    <button
                      type="button"
                      className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                      onClick={() => deleteImage(image)}
                    >
                      Delete
                    </button>
                  </div>
                ),
              )}
          </div>
        </>
      </div>
    </form>
  );
};

export default FastFoodForm;
