/* eslint-disable react/button-has-type */
import { addIcon, closeIcon } from "core/consts/images";
import React, { useState, useEffect, useRef } from "react";

const Accordion = ({
  children,
  title = "Header",
  id = "",
  bodyStyle = "",
}: {
  children?: any;
  title?: string;
  id?: string;
  bodyStyle?: string;
}) => {
  const [clicked, setClicked] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleToggle = () => {
    setClicked((prev) => !prev);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className={`mb-[24px] ${clicked ? "active" : ""}`}>
      <div className="flex items-center justify-between rounded-[5px] border-[1px] border-brand bg-white p-5 shadow-md">
        <p className="text-[14px]">{title}</p>
        <button onClick={handleToggle}>
          <img
            src={clicked ? closeIcon : addIcon}
            loading="lazy"
            alt={clicked ? "close" : "open"}
          />
        </button>
      </div>

      <div
        ref={inputRef}
        id={id}
        className="h-[0px] overflow-hidden transition delay-150 ease-in-out"
        style={
          clicked
            ? { height: inputRef?.current?.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className={`m-[0px] mt-5 ${bodyStyle}`}>{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
