import ForgotPassword from "./views/ForgotPassword";
import Login from "./views/Login";
import Signup from "./views/Signup";
import SupportLogin from "./views/SupportLogin";

const routes: RoutesType[] = [
  {
    name: "Sign Up",
    layout: "/auth",
    path: "signup",
    icon: <></>,
    component: <Signup />,
    children: [],
  },
  {
    name: "Login",
    layout: "/auth",
    path: "login",
    icon: <></>,
    component: <Login />,
    children: [],
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "forgot-password",
    icon: <></>,
    component: <ForgotPassword />,
    children: [],
  },
  {
    name: "Support Login",
    layout: "/auth",
    path: "support",
    icon: <></>,
    component: <SupportLogin />,
    children: [],
  },
];
export default routes;
