/* eslint-disable eqeqeq */
import Detail from "core/components/Detail";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import { back, viewOrderImg } from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import {
  MARKETPLACE_CATEGORY_TYPES,
  ORDER_STATUS,
} from "core/consts/systemConst";
import {
  formatCurrency,
  formatNumber,
  getDateTime,
  getStatusBackgroundColor,
} from "core/helpers/generalHelpers";
import useMarketPlaceStore from "core/services/stores/useMarketPlaceStore";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ViewOrderDetails = () => {
  const { orderId, category } = useParams();
  const navigate = useNavigate();

  const order = useMarketPlaceStore((store) => store.order);
  const getOrderAction = useMarketPlaceStore((store) => store.getOrderById);
  const resetOrder = useMarketPlaceStore((store) => store.resetOrder);

  useEffect(() => {
    if (
      category != null &&
      category?.length > 1 &&
      orderId != null &&
      orderId?.length > 1
    ) {
      getOrderAction(category, orderId!);
    } else {
      resetOrder();
      navigate("/marketplace/view-orders");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="mx-auto mb-[28px] w-[95%]">
      <div className="flex items-center justify-between">
        <header className="flex items-center gap-3">
          <img src={viewOrderImg} alt="" loading="lazy" />
          <h5 className="text-[18px] font-[500] text-brand">
            Order from {order?.customerName} - {order?.status}
          </h5>
        </header>

        <button
          onClick={() => {
            resetOrder();
            navigate("/marketplace/view-orders");
          }}
          className="flex w-[100px] items-center justify-between px-4 py-2 text-[14px] text-brand"
        >
          <img src={back} loading="lazy" alt="" />
          <span>Back</span>
        </button>
      </div>

      <div className="my-[16px] flex items-center justify-between gap-5">
        <div className="flex justify-start gap-3 rounded-[25px]">
          <div className={`${actionbtn} bg-[#F2EAF8] shadow-md`}>
            Receipt Number: {order?.receiptNumber}
          </div>
        </div>
      </div>

      <section className="mb-[32px]">
        <Detail name="Customer Name" value={order?.customerName} />
        <Detail name="Customer ID" value={order?.customerId} />
        <Detail
          name="Customer Phone number"
          value={order?.customerPhoneNumber}
        />
        <Detail
          name="Customer Email"
          style={`!lowercase`}
          value={order?.customerEmailAddress}
        />
        <Detail name="Order Time" value={getDateTime(order!?.createdAt)} />
        <Detail
          name="Processing Time"
          value={order?.processedAt ? getDateTime(order!?.processedAt) : "N/A"}
        />
      </section>

      <section className="mb-[32px]">
        <div className="flex justify-start gap-3 rounded-[25px]">
          <div className={`${actionbtn} bg-[#F2EAF8] shadow-md`}>
            Module Info
          </div>
        </div>

        <Detail name="Market Category" value={order?.category} />
        <Detail name="Sub Category" value={order?.subCategory ?? "N/A"} />
        <Detail
          name="Service Provider"
          value={order?.serviceProvider ?? "N/A"}
        />

        {order?.subCategory === MARKETPLACE_CATEGORY_TYPES.Event_Ticket && (
          <>
            <Detail
              name="Ticket type"
              value={order?.ticketType ?? "N/A"}
            />
          </>
        )}
      </section>

      <section className="my-[32px]">
        <div className="mb-3 flex justify-start gap-3 rounded-[25px]">
          <div className={`${actionbtn} bg-[#F2EAF8] shadow-md`}>
            Order Breakdown
          </div>
        </div>

        {(() => {
          switch (order?.subCategory) {
            case "fast_food":
              return (
                <>
                  <Table
                    headers={[
                      "S/N",
                      "Product ID",
                      "Product",
                      "Service Provider",
                      "Quantity",
                      "Amount",
                    ]}
                  >
                    {order?.orders!?.length > 0 ? (
                      order?.orders?.map((item: any, index: number) => (
                        <tr key={item?.productId}>
                          <Row value={index + 1} />
                          <Row value={item?.productId} />
                          <Row value={item?.productName} />
                          <Row value={item?.serviceProvider} />
                          <Row value={formatNumber(item?.quantity)} />
                          <Row value={formatCurrency(item?.amount)} />
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <Row colSpan={6} value="No order item" />
                      </tr>
                    )}
                  </Table>
                </>
              );
            case "media_production":
            case "gas_refill":
              return (
                <>
                  <Table
                    headers={["S/N", "Product ID", "Product", "Total Amount"]}
                  >
                    <tr>
                      <Row value={1} />
                      <Row value={order?.productId} />
                      <Row value={order?.productName} />
                      <Row value={formatCurrency(order?.transactionAmount)} />
                    </tr>
                  </Table>
                </>
              );
            case "event_ticket":
              return (
                <>
                  <Table
                    headers={[
                      "S/N",
                      "Order ID",
                      "Category",
                      "Price",
                      "Quantity",
                      "Total Amount",
                    ]}
                  >
                    {order?.eventTicketCategories!?.length > 0 ? (
                      order?.eventTicketCategories?.map(
                        (item: any, index: number) => (
                          <tr key={item?.orderId}>
                            <Row value={index + 1} />
                            <Row value={item?.orderId} />
                            <Row value={item?.category} />
                            <Row value={formatCurrency(item?.price)} />
                            <Row value={formatNumber(item?.quantity)} />
                            <Row value={formatCurrency(item?.totalAmount)} />
                          </tr>
                        ),
                      )
                    ) : (
                      <tr>
                        <Row colSpan={6} value="No order item" />
                      </tr>
                    )}
                  </Table>
                </>
              );
            case "hotel":
              return (
                <>
                  <Table
                    headers={[
                      "S/N",
                      "Order ID",
                      "Category",
                      "Price",
                      "Quantity",
                      "Total Amount",
                    ]}
                  >
                    {order?.hotelCategories!?.length > 0 ? (
                      order?.hotelCategories?.map(
                        (item: any, index: number) => (
                          <tr key={item?.orderId}>
                            <Row value={index + 1} />
                            <Row value={item?.orderId} />
                            <Row value={item?.category} />
                            <Row value={formatCurrency(item?.price)} />
                            <Row value={formatNumber(item?.quantity)} />
                            <Row value={formatCurrency(item?.totalAmount)} />
                          </tr>
                        ),
                      )
                    ) : (
                      <tr>
                        <Row colSpan={6} value="No order item" />
                      </tr>
                    )}
                  </Table>
                </>
              );
            default:
              return <></>;
          }
        })()}
      </section>

      <section className="mb-[32px]">
        <div className="flex justify-start gap-3 rounded-[25px]">
          <div className={`${actionbtn} bg-[#F2EAF8] shadow-md`}>
            Delivery Detail
          </div>
        </div>

        <Detail name="Pick up Area" value={order?.pickupArea ?? "N/A"} />
        <Detail name="Pick up Address" value={order?.pickupAddress ?? "N/A"} />
        <Detail
          name="Delivery Address"
          value={order?.deliveryAddress ?? "N/A"}
        />
        <Detail name="Delivery Area" value={order?.deliveryArea ?? "N/A"} />
        <Detail
          name="Delivered At"
          value={order?.deliveredAt ? order?.deliveredAt : "N/A"}
        />
      </section>

      <section className="mb-[32px]">
        <div className="flex justify-start gap-3 rounded-[25px]">
          <div className={`${actionbtn} bg-[#F2EAF8] shadow-md`}>
            Billing Detail
          </div>
        </div>

        <Detail
          name="Transaction Amount"
          value={formatCurrency(order?.transactionAmount)}
        />
        <Detail
          name="Convenience Fee"
          value={formatCurrency(order?.convenienceFee)}
        />
        <Detail
          name="Delivery Fee"
          value={formatCurrency(order?.deliveryFee)}
        />
        <Detail
          name="Total Amount"
          value={formatCurrency(order?.totalAmount)}
        />
      </section>

      <section className="mb-[32px]">
        <div className="flex justify-start gap-3 rounded-[25px]">
          <div
            className={`${actionbtn} bg-[#F2EAF8] shadow-md ${
              order?.status && getStatusBackgroundColor(order!?.status)
            }`}
          >
            Status Detail - {order?.status}
          </div>
        </div>
        <Detail
          name="Error Message"
          value={order?.errorMessage ? order?.errorMessage : "N/A"}
        />

        {order?.status?.toLowerCase() ==
          ORDER_STATUS.cancelled?.toLowerCase() && (
          <>
            <Detail name="Cancelled By" value={order?.cancelledBy} />{" "}
            <Detail
              name="Cancelled At"
              value={
                order?.cancelledAt != null
                  ? getDateTime(order?.cancelledAt)
                  : "N/A"
              }
            />{" "}
            <Detail
              name="Reason For Cancelling"
              value={order?.reasonForCancelling}
            />
          </>
        )}
      </section>

      <div className="flex justify-end">
        <button
          onClick={() => {
            resetOrder();
            navigate("/marketplace/view-orders");
          }}
          className="flex items-center justify-between px-4 py-2 text-[14px] text-brand"
        >
          <img src={back} loading="lazy" alt="" />
          <span>Back To View Order</span>
        </button>
      </div>
    </section>
  );
};

export default ViewOrderDetails;
