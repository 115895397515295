import { apicall } from "./apicall";

// TODO: Update api for get notifications
export const getNotifications = (
  query: NotificationQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: `/notification/getAllNotifications`,
    method: "GET",
    pQuery: {
      endDate: query?.endDate,
      startDate: query?.startDate,
      category: query?.category,
      customerId: query?.userId,
      userId: query?.userId,
      pageNumber,
      pageSize,
    },
    auth: true,
  });
};

export const markAsRead = (module: string, notificationId: string) => {
  return apicall({
    endpoint: `/notification/update/read`,
    param: notificationId,
    method: "PATCH",
    auth: true,
  });
};

export const deleteNotification = (module: string, notificationId: string) => {
  return apicall({
    endpoint: `/notification/delete`,
    param: notificationId,
    method: "DELETE",
    auth: true,
  });
};
