import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
  confirmToken,
  generateToken,
  resetPassword,
  signin,
  signupUser,
  uploadBusinessDocument,
} from "../api/userapi";
import notification from "core/helpers/notification";
import { USER_ROLES } from "core/consts/systemConst";

type UserState = {
  isLoading: boolean;
  user: User | any;
  token: string;
  location: string;
  isSuperAdmin: boolean;
  refreshToken: string;
  generateToken: (username: string) => Promise<UIResponse>;
  confirmToken: (username: string, token: string) => Promise<UIResponse>;
  resetPassword: (
    username: string,
    password: string,
    confirmPassword: string,
  ) => Promise<UIResponse>;
  reset: () => void;
  signupUser: (newUser: NewUser, documents: any) => Promise<UIResponse>;
  signin: (username: string, password: string) => Promise<UIResponse>;
};

const defaultState = {
  isLoading: false,
  token: "",
  refreshToken: "",
  isSuperAdmin: false,
  location: "",
  user: {},
};

const useUserStore = create<UserState>()(
  devtools(
    persist(
      (set, get): UserState => ({
        ...defaultState,
        reset: () => {
          set({ ...defaultState });
        },
        signin: async (username, password) => {
          set({ isLoading: true });
          var res = await signin(username, password);
          var status = false;
          var message = "An unknown error occured when processing request";

          if (res?.data?.code === "200") {
            if (res?.data?.user?.status === "approved") {
              set({
                user: res?.data?.user,
                refreshToken: res?.data?.refreshToken,
                token: res?.data?.token,
                location: res?.data?.user?.location,
                isSuperAdmin:
                  res?.data?.user?.role === USER_ROLES?.super_admin?.valueOf() ? true : false,
              });

              status = true;
              message = `Welcome back ${res?.data?.user?.firstname}`;
            } else {
              message =
                "Sorry, your account is either awaiting approval or has been suspended. Try again later. ";
            }
          } else {
            message = res?.data?.error;
          }

          notification({
            message: message,
            type: status ? "success" : "danger",
          });

          set({ isLoading: false });

          return {
            isSuccessful: status,
            status: res?.status,
            data: status ? res?.data?.user : null,
          };
        },
        generateToken: async (username) => {
          set({ isLoading: true });
          var res: UIResponse = await generateToken(username);

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        confirmToken: async (username, token) => {
          set({ isLoading: true });
          var res: UIResponse = await confirmToken(username, token);

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        resetPassword: async (username, password, confirmPassword) => {
          set({ isLoading: true });
          var res: UIResponse = await resetPassword(
            username,
            password,
            confirmPassword,
          );

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        signupUser: async (newUser, documents) => {
          set({ isLoading: true });
          var res = await signupUser(newUser);

          if (res?.isSuccessful) {
            const { user } = res?.data;

            var uploadLogo = await uploadBusinessDocument(
              user?.userId,
              documents?.businessLogo,
              "uploadImage",
            );

            var uploadPassport = await uploadBusinessDocument(
              user?.userId,
              documents?.passportImage,
              "uploadPassport",
            );

            var uploadID = await uploadBusinessDocument(
              user?.userId,
              documents?.idImage,
              "uploadid",
            );

            var uploadSignature = await uploadBusinessDocument(
              user?.userId,
              documents?.businessLogo,
              "uploadsignature",
            );

            if (!uploadLogo?.isSuccessful) {
              notification({
                title: "Business Logo upload failed",
                message:
                  "Please, ensure your internet connection is stable before uploading your image. Try again when you are logged in.",
                type: "warning",
              });
            }

            if (!uploadPassport?.isSuccessful) {
              notification({
                title: "Selfie upload failed",
                message:
                  "Please, ensure your internet connection is stable before uploading your image. Try again when you are logged in.",
                type: "warning",
              });
            }

            if (!uploadID?.isSuccessful) {
              notification({
                title: "ID upload failed",
                message:
                  "Please, ensure your internet connection is stable before uploading your image. Try again when you are logged in.",
                type: "warning",
              });
            }

            if (!uploadSignature?.isSuccessful) {
              notification({
                title: "Signature upload failed",
                message:
                  "Please, ensure your internet connection is stable before uploading your image. Try again when you are logged in.",
                type: "warning",
              });
            }
          }

          notification({
            message: res?.isSuccessful
              ? `Congratulations! ${newUser?.firstname} You have successfully created your profile on Campus Runz Admin Portal. Kindly login with your username and password.`
              : res?.data?.title ?? res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
      }),
      {
        name: "userStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useUserStore;
