/* eslint-disable eqeqeq */
import Detail from "core/components/Detail";
import InputField from "core/components/formfields/InputField";
import TextField from "core/components/formfields/TextField";
import Modal from "core/components/Modal";
import { back, viewOrderImg } from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import { ORDER_STATUS } from "core/consts/systemConst";
import {
  formatCurrency,
  getDateTime,
  getStatusBackgroundColor,
} from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useTransportationStore from "core/services/stores/useTransportationStore";
import useUserStore from "core/services/stores/useUserStore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ViewOrderDetails = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const user = useUserStore((store) => store.user);

  const order = useTransportationStore((store) => store.deliveryOrderItem);
  const getOrderAction = useTransportationStore(
    (store) => store.getDeliveryByOrderId,
  );
  const resetOrder = useTransportationStore((store) => store.resetOrder);
  const cancelDeliveryAction = useTransportationStore(
    (store) => store.cancelDelivery,
  );

  const [comments, setComments] = useState("");
  const [cancelOrderModal, setCancelOrderModal] = useState(false);

  const cancelOrder = async (e: any) => {
    e.preventDefault();

    if (comments?.length > 0) {
      const response = window.confirm(
        "Click 'OK' to confirm the cancelation of this order'.",
      );

      if (!response) return;

      var res = await cancelDeliveryAction(order?.id!, comments!);

      if (res?.isSuccessful) {
        setComments("");
        setCancelOrderModal(false);
      }
    } else {
      notification({
        message:
          "Please include a comment as to why this order is being cancelled",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    if (orderId != null && orderId?.length > 1) {
      getOrderAction(orderId!);
    } else {
      resetOrder();
      navigate("/transportation/manage-orders");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="mx-auto mb-[28px] w-[95%]">
        <div className="flex items-center justify-between">
          <header className="flex items-center gap-3">
            <img src={viewOrderImg} alt="" loading="lazy" />
            <h5 className="text-[18px] font-[500] text-brand">
              Order from {order?.customerName} - {order?.orderStatus}
            </h5>
          </header>

          <button
            onClick={() => {
              resetOrder();
              navigate("/transportation/manage-orders");
            }}
            className="flex w-[100px] items-center justify-between px-4 py-2 text-[14px] text-brand"
          >
            <img src={back} loading="lazy" alt="" />
            <span>Back</span>
          </button>
        </div>

        <section className="mb-[32px]">
          <Detail name="Customer Name" value={order?.customerName} />
          <Detail name="Customer ID" value={order?.customerId} />
          <Detail
            name="Contact Phone number"
            value={order?.contactPhoneNumber}
          />
          <Detail
            name="Order Time"
            value={getDateTime(order!?.dateTimeCreated)}
          />
          <Detail
            name="Processing Time"
            value={
              order?.processedAt ? getDateTime(order!?.processedAt) : "N/A"
            }
          />
        </section>

        <section className="mb-[32px]">
          <div className="flex justify-start gap-3 rounded-[25px]">
            <div className={`${actionbtn} bg-[#F2EAF8] shadow-md`}>
              Item Detail
            </div>
          </div>

          <Detail name="Item Name" value={order?.itemName} />
          <Detail name="Item type" value={order?.itemType} />
          <Detail name="Weight" value={order?.weightOfItem} />
          <Detail
            name="Is Weight Adjusted"
            value={order?.isWeightAdjusted ? "Yes" : "No"}
          />
          <Detail name="Service Type" value={order?.serviceType} />
          <Detail name="Extra Details" value={order?.extraDetails} />
        </section>

        <section className="mb-[32px]">
          <div className="flex justify-start gap-3 rounded-[25px]">
            <div className={`${actionbtn} bg-[#F2EAF8] shadow-md`}>
              Delivery Detail
            </div>
          </div>

          <Detail name="Pickup Address" value={order?.pickUpAddress} />
          <Detail name="Delivery Address" value={order?.deliveryAddress} />
          <Detail name="Delivery Area" value={order?.deliveryArea} />
          <Detail name="Recipient Name" value={order?.recipientName} />
          <Detail
            name="Recipient Phone Number"
            value={order?.recipientPhoneNumber}
          />
          <Detail
            name="Delivered At"
            value={order?.deliveredAt ? order?.deliveredAt : "N/A"}
          />
        </section>

        <section className="mb-[32px]">
          <div className="flex justify-start gap-3 rounded-[25px]">
            <div className={`${actionbtn} bg-[#F2EAF8] shadow-md`}>
              Billing Detail
            </div>
          </div>

          <Detail
            name="Convenience Fee"
            value={formatCurrency(order?.convenienceFee)}
          />
          <Detail
            name="Delivery Fee"
            value={formatCurrency(order?.deliveryFee)}
          />
          <Detail
            name="Total Amount"
            value={formatCurrency(order?.totalAmount)}
          />
        </section>

        <section className="mb-[32px]">
          <div className="flex justify-start gap-3 rounded-[25px]">
            <div
              className={`${actionbtn} bg-[#F2EAF8] shadow-md ${
                order?.orderStatus &&
                getStatusBackgroundColor(order!?.orderStatus)
              }`}
            >
              Status Detail - {order?.orderStatus}
            </div>
          </div>

          {order?.orderStatus?.toLowerCase() ==
            ORDER_STATUS.cancelled?.toLowerCase() && (
            <>
              <Detail name="Cancelled By" value={order?.cancelledBy} />{" "}
              <Detail
                name="Reason For Cancelling"
                value={order?.cancellationComment}
              />
            </>
          )}
        </section>

        <div className="flex w-full justify-between gap-5 lg:w-2/3">
          <button
            onClick={() => {
              resetOrder();
              navigate("/transportation/manage-orders");
            }}
            className={`${actionbtn} flex w-1/2 items-center justify-center !bg-brandLight !px-[26px] !py-3`}
          >
            <img src={back} loading="lazy" alt="" />
            <span>Back To View Order</span>
          </button>

          {order?.orderStatus !== "Delivered" &&
            order?.orderStatus !== "Cancelled" && (
              <button
                onClick={() => setCancelOrderModal(true)}
                className={`${actionbtn} w-1/2 bg-[#ff6e6e] !px-[26px] !py-3`}
              >
                Cancel Order
              </button>
            )}
        </div>
      </section>

      {cancelOrderModal && (
        <Modal
          header="Cancel Order"
          onClose={() => {
            setComments("");
            setCancelOrderModal(false);
          }}
        >
          <form autoComplete="off" onSubmit={cancelOrder}>
            <InputField
              boxStyle="mb-[24px]"
              name="cancelledBy"
              label="Cancelled By"
              value={user?.name}
              disabled
            />

            <TextField
              boxStyle="mb-[24px]"
              name="comment"
              label="Comment"
              placeholder="Reason for cancellation"
              rows={5}
              ref={null}
              value={comments}
              onChange={(e: any) => {
                setComments(e?.target?.value);
              }}
            />

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setComments("");
                  setCancelOrderModal(false);
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Cancel
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default ViewOrderDetails;
