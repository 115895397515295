import { getRoutes } from "core/helpers/getRoutes";
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "./routes";
import { waves } from "core/consts/images";

export default function Auth() {
  return (
    <div className="relative m-0 w-full">
      <div className="fixed top-0 h-[100px] w-full" style={{ zIndex: 100 }}>
        <img
          src={waves}
          loading="lazy"
          alt=""
          className="mt-0 h-[100px] sm:h-auto md:mt-[-30px] lg:mt-[-60px]"
        />
      </div>
      <div
        className="pt-[40px]"
        style={{
          zIndex: 50,
          minHeight: "80vh",
        }}
      >
        <Routes>
          {getRoutes(routes)}
          <Route path="/" element={<Navigate to="/auth/login" replace />} />
        </Routes>
      </div>
    </div>
  );
}
