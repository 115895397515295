import { openInNewTab } from "core/helpers/generalHelpers";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SupportLogin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // redirect to old url
    openInNewTab("http://sourceone-001-site13.etempurl.com/support");
    navigate("/");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mx-auto flex h-[80vh] w-11/12 justify-center md:w-4/5">
      <p>Support Login</p>
    </div>
  );
};

export default SupportLogin;
