import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import notification from "core/helpers/notification";
import {
  addUpdateFoodItem,
  deleteFoodItem,
  deleteFoodItemImage,
  getFoodItem,
  getFoodItemById,
  getFoodStuffOrderById,
  getFoodStuffOrders,
  getFoodstuffDashboard,
  toggleFoodItem,
} from "../api/foodstuff.api";
import { updateOrderStatus } from "../api/userapi";
import { getProcessStatus } from "core/helpers/generalHelpers";

type StoreState = {
  isLoading: boolean;
  analytics: FoodstuffAnalytics;
  foodItemList: FoodItemList;
  foodItem: any;
  order: FoodStuffOrder | null;
  orderList: {
    orders: FoodOrder[];
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPage: number;
  };
  getDashboardAnalytics: (query: DateLocationQuery) => Promise<void>;
  getFoodItems: (
    query: FoodItemQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  getFoodItemById: (foodItemId: string) => Promise<void>;
  addUpdateFoodItem: (
    foodItem: NewFoodItem,
    foodItemId: string,
  ) => Promise<UIResponse>;
  toggleFoodItem: (foodItemId: string, visibility: boolean) => Promise<void>;
  deleteFoodItem: (foodItemId: string) => Promise<void>;
  deleteFoodItemImage: (
    category: string,
    foodItemId: string,
    image: string,
  ) => Promise<void>;
  getOrders: (
    query: FoodItemQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  updateOrderStatus: (
    orderId: string,
    orderStatus: ManageOrder,
    reason: string,
  ) => Promise<UIResponse>;
  getOrderById: (orderId: string) => Promise<void>;
  resetOrder: () => void;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  analytics: {
    listedFooditems: 0,
    totalOrders: 0,
    totalquantity: 0,
    totalFoodstuffIncome: 0,
    totalConvenienceFee: 0,
    totalIncome: 0,
  },
  foodItemList: {
    foodItems: [],
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPage: 0,
  },
  orderList: {
    orders: [],
    pageNumber: 1,
    pageSize: 15,
    totalCount: 1,
    totalPage: 1,
  },
  foodItem: null,
  order: null,
};

const useFoodStuffStore = create<StoreState>()(
  devtools(
    persist(
      (set, get): StoreState => ({
        ...defaultState,
        getDashboardAnalytics: async (query) => {
          set({ isLoading: true });

          var res: UIResponse = await getFoodstuffDashboard(query);
          if (res?.isSuccessful) {
            set({
              analytics: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getOrders: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getFoodStuffOrders(
            query,
            pageNumber,
            pageSize,
          );

          if (res?.isSuccessful) {
            set({
              orderList: {
                orders: res?.data?.data?.orders,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: res?.data?.data?.pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
        },
        getOrderById: async (orderId) => {
          set({ isLoading: true });

          var res: UIResponse = await getFoodStuffOrderById(orderId);

          if (res?.isSuccessful) {
            set({
              order: res?.data?.data,
            });
          }
          
          set({ isLoading: false });
        },
        getFoodItems: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getFoodItem(query, pageNumber, pageSize);

          if (res?.isSuccessful) {
            set({
              foodItemList: {
                foodItems: res?.data?.data?.data,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: res?.data?.data?.pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
        },
        getFoodItemById: async (foodItemId) => {
          set({ isLoading: true });

          var res: UIResponse = await getFoodItemById(foodItemId);

          if (res?.isSuccessful) {
            set({
              foodItem: res?.data?.data,
              isLoading: false,
            });
          } else {
            set({ foodItem: null, isLoading: false });
          }
        },
        addUpdateFoodItem: async (foodItem, foodItemId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateFoodItem(foodItem, foodItemId);

          if (res?.isSuccessful && foodItemId?.length > 1) {
            get().getFoodItemById(foodItemId);
          } else {
            set({ isLoading: false });
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        deleteFoodItemImage: async (category, foodItemId, image) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteFoodItemImage(
            category,
            foodItemId,
            image,
          );

          if (res?.isSuccessful) {
            get().getFoodItemById(foodItemId);
          }

          notification({
            message: res?.isSuccessful
              ? "Image has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });
        },
        deleteFoodItem: async (foodItemId) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteFoodItem(foodItemId);

          if (res?.isSuccessful) {
            set((state) => ({
              foodItemList: {
                ...state.foodItemList,
                foodItems: state?.foodItemList.foodItems?.filter(
                  (item: any) => item?.foodItemId !== foodItemId,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Food Item has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        toggleFoodItem: async (foodItemId, visibility) => {
          set({ isLoading: true });

          var res: UIResponse = await toggleFoodItem(foodItemId, visibility);

          if (res?.isSuccessful) {
            set((state) => ({
              foodItemList: {
                ...state.foodItemList,
                foodItems: state?.foodItemList.foodItems?.map((item: any) =>
                  item?.foodItemId === foodItemId
                    ? { ...item, visibility: visibility }
                    : item,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        updateOrderStatus: async (orderId, orderStatus, reason) => {
          set({ isLoading: true });

          var res: UIResponse = await updateOrderStatus(
            orderId,
            orderStatus,
            reason,
          );

          var processStatus = "";
          if (res?.isSuccessful) {
            processStatus = getProcessStatus(orderStatus);

            set((state) => ({
              orderList: {
                ...state.orderList,
                orders: state?.orderList?.orders?.map((order: FoodOrder) =>
                  order?.orderId === orderId
                    ? { ...order, status: processStatus }
                    : order,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? `Order status has been updated successfully to ${processStatus}`
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        resetOrder: () => {
          set({ order: null });
        },
        reset: () => {
          set({ ...defaultState });
        },
      }),
      {
        name: "foodStuffStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useFoodStuffStore;
