import SelectField from "core/components/formfields/SelectField";
import { activeModule } from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import { USER_ROLES, USER_STATUS } from "core/consts/systemConst";
import useAdminStore from "core/services/stores/useAdminStore";
import useLocationStore from "core/services/stores/useLocationStore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ActiveModules = () => {
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const admins = useAdminStore((store) => store.admins);
  const getAdminsAction = useAdminStore((store) => store.getAdmins);
  const manageAdminAction = useAdminStore((store) => store.manageAdmin);

  const [filteredLocation, setFilteredLocation] = useState("");

  const manageAdmin = async (
    id: string,
    name: string,
    action: ManageActions,
  ) => {
    if (window.confirm(`Click 'OK' to ${action} ${name}'s account.`)) {
      await manageAdminAction(id, action);
    }
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();
    admins?.length < 1 && getAdminsAction(filteredLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="mx-auto w-[95%]">
      <header className="mb-[14px] flex items-end gap-3">
        <img src={activeModule} alt="active module" loading="lazy" />
        <h5 className="text-[18px] font-[500] text-brand">Active Modules</h5>
      </header>
      <div className="flex flex-col-reverse gap-5 lg:flex-row">
        <div className="w-full overflow-x-scroll lg:w-8/12">
          <div>
            {admins?.filter((x: Admin) => x?.status === USER_STATUS.approved)
              ?.length > 0 ? (
              admins
                ?.filter((x: Admin) => x?.status === USER_STATUS.approved)
                ?.map((admin: Admin) => (
                  <div
                    key={admin?.adminId}
                    className="rounded-[5px] border-[1px] mb-[24px] flex flex-col items-center justify-between gap-5 overflow-x-scroll border-brand bg-white px-4 py-3 shadow-md md:flex-row"
                  >
                    <div className="w-full md:w-1/2">
                      <p className="mb-[5px] w-[200px] font-[500] capitalize text-[#2E2626]">
                        {admin?.category}
                      </p>
                      <p className="text-[14px] text-[#8E8787]">
                        <span className="capitalize">
                          {admin?.businessName} | {admin?.name}
                        </span>
                      </p>
                    </div>
                    <div className="flex gap-3">
                      <Link
                        to={`/${admin?.category}/dashboard`}
                        className={`${actionbtn} w-[120px] bg-[#F2EAF8] !px-[10px] text-center`}
                      >
                        Visit Module
                      </Link>
                      <button
                        disabled={admin?.category === USER_ROLES.super_admin}
                        className={`${actionbtn} w-[100px] bg-[#DFA1F4] !px-[10px] text-center`}
                        onClick={() =>
                          manageAdmin(admin?.adminId, admin?.name, "deactivate")
                        }
                      >
                        Suspend
                      </button>
                      <button
                        className={`${actionbtn} w-[100px] bg-red-500 !px-[10px] text-center text-white`}
                        disabled={admin?.category === USER_ROLES.super_admin}
                        onClick={() =>
                          manageAdmin(admin?.adminId, admin?.name, "remove")
                        }
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))
            ) : (
              <div className="mb-[24px] flex items-center justify-between gap-5 rounded-[5px] border-[1px] border-brand bg-white px-4 py-3 shadow-md">
                <div>
                  <p className="mb-[5px] font-[500] text-[#2E2626]">
                    No active module
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full lg:w-4/12">
          <SelectField
            boxStyle="mb-[18px]"
            selectStyle="!border-brand text-[#615353]"
            name="location"
            defaultName="Select your Location"
            defaultValue=""
            options={
              locations?.length > 0
                ? [
                    ...locations?.map((location) => ({
                      name: location?.name,
                      value: location?.name,
                    })),
                  ]
                : []
            }
            value={filteredLocation}
            onChange={(e: any) => {
              setFilteredLocation(e?.target?.value);
              getAdminsAction(e?.target?.value);
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default ActiveModules;
