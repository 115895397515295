import React from "react";

function SelectField({
  boxStyle = "",
  selectStyle = "",
  value = "",
  name = "",
  options = [],
  disabled = false,
  onChange = () => {},
  onBlur = () => {},
  label = "",
  error = "",
  defaultName = "",
  defaultValue = "",
  isRequired = false,
  multiple = false,
}: {
  boxStyle?: string;
  selectStyle?: string;
  value?: any;
  name?: string;
  options?: any;
  disabled?: boolean;
  onChange?: any;
  onBlur?: any;
  label?: string;
  error?: any;
  defaultName?: string;
  defaultValue?: string | number;
  isRequired?: boolean;
  multiple?: boolean;
}) {
  return (
    <div className={`${boxStyle}`}>
      {label && label?.length > 0 && (
        <label htmlFor={name} className="mb-2 text-[14px] text-brandgray">
          {label} {isRequired && <span className="text-red-500">*</span>}
        </label>
      )}

      <select
        value={value}
        className={`h-12 w-full rounded-[5px] border-[1px] border-brandgray bg-white p-3 text-sm text-brandgray outline-none disabled:cursor-not-allowed ${selectStyle}`}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        multiple={multiple}
      >
        {defaultName?.length > 1 && (
          <option key={defaultValue} value={defaultValue}>
            {defaultName}
          </option>
        )}
        {options &&
          options?.length > 0 &&
          options?.map((option: any) => (
            <option className="py-3" key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
      </select>
      {error.length > 1 ? (
        <span className="text-[14px] text-red-500">{error}</span>
      ) : null}
    </div>
  );
}

export default SelectField;
