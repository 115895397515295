import { downloadImg, male, users } from "core/consts/images";
import { navbtn } from "core/consts/styling";
import { exportToCSV } from "core/helpers/exportToExcel";
import notification from "core/helpers/notification";
import useReferralStore from "core/services/stores/useReferralStore";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const CustomerReferree = () => {
  const { referrerId } = useParams();

  const userReferreeList = useReferralStore((store) => store.userReferreeList);
  const getUserReferreeAction = useReferralStore(
    (store) => store.getUserReferree,
  );

  const referrer: any = JSON.parse(sessionStorage.getItem("referrer")!);

  const fetchMore = (next: boolean) => {
    switch (next) {
      case true:
        if (userReferreeList?.pageNumber < userReferreeList?.totalPage) {
          getUserReferreeAction(referrerId!, {
            pageNumber: userReferreeList?.pageNumber + 1,
            pageSize: userReferreeList?.pageSize,
          });
        }
        break;
      case false:
        if (userReferreeList?.pageNumber > 1) {
          getUserReferreeAction(referrerId!, {
            pageNumber: userReferreeList?.pageNumber - 1,
            pageSize: userReferreeList?.pageSize,
          });
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getUserReferreeAction(referrerId!, {
      pageNumber: userReferreeList?.pageNumber,
      pageSize: userReferreeList?.pageSize,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mx-auto w-[95%]">
      <header className="mb-[28px] flex flex-col-reverse justify-start lg:flex-col lg:items-center lg:justify-between">
        <div className="flex flex-col items-start gap-3 font-[500] text-brand lg:flex-row lg:items-center">
          <img src={users} loading="lazy" alt="" />
          <Link to="/superadmin/referral-agencies" className="hover:underline">
            Referral Program / Customers
          </Link>
          <span className="capitalize text-gray-500">
            {" "}
            / {referrer?.referrerFullName}
          </span>
        </div>
        <div className="flex items-center gap-5">
          <Link
            className="text-brand hover:underline"
            to="/superadmin/referral-customers"
          >
            Back
          </Link>
        </div>
      </header>
      <section className="mb-[28px] flex items-end justify-end gap-2 rounded-[5px] border border-t-[3px] border-brand bg-white p-3">
        <button
          type="button"
          disabled={userReferreeList?.referredUsers?.length < 1}
          className="flex h-[50px] items-center justify-between gap-3 rounded-[5px] bg-brand p-3 text-white disabled:cursor-not-allowed"
          onClick={() => {
            exportToCSV(
              userReferreeList?.referredUsers,
              `${referrer?.referrerFullName}'s Referrees`,
            );

            notification({
              message: "You have successfully exported the data to excel",
              type: "success",
            });
          }}
        >
          <span className="text-[14px]">Download</span>
          <img src={downloadImg} alt="" loading="lazy" />
        </button>
      </section>
      <section className="mb-[28px]">
        <div className="over flex min-w-[400px] items-center justify-between gap-2 overflow-x-scroll border-b-[3px] border-b-brand py-3 font-[500] text-brand">
          <div className="w-2/5"></div>
          <div className="w-1/5">
            <p>Completed Transactions</p>
          </div>
          <div className="w-1/5">
            <p>Date</p>
          </div>
        </div>
        {userReferreeList?.referredUsers?.length > 0 ? (
          <>
            {userReferreeList?.referredUsers?.map(
              (referral: UserReferree, index: number) => (
                <div
                  key={index}
                  className="mb-5 flex min-w-[400px] items-center justify-between gap-2 overflow-x-scroll rounded-[5px] border border-brand bg-white p-3"
                >
                  <div className="w-2/5">
                    <div className="flex items-center gap-2">
                      <img
                        src={male}
                        alt=""
                        loading="lazy"
                        className="w-[35px]"
                      />
                      <div>
                        <p className="text-[14px] font-[500] text-[#3A3A3A]">
                          {referral?.referredUserFullName}
                        </p>
                        <p className="text-[12px] text-[#7D7D7D]">
                          {referral?.referredUserLocation}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-1/5">
                    <p>{referral?.transactionCount}</p>
                  </div>
                  <div className="w-1/5">{referral?.discount}</div>
                </div>
              ),
            )}

            <div className="mt-[40px] flex items-center justify-between text-brand">
              <div className="flex items-center gap-2">
                <p className="text-[14px]">
                  <span className="font-[600]">
                    Page {userReferreeList?.pageNumber}/
                  </span>
                  <span>{userReferreeList?.totalPage}</span>
                </p>
                <span>|</span>
                <p className="text-[14px]">
                  Total: {userReferreeList?.totalCount}
                </p>
              </div>
              <div>
                <button
                  className={`${navbtn} rounded-l-[5px] !px-5 disabled:cursor-not-allowed`}
                  onClick={() => fetchMore(false)}
                  disabled={userReferreeList?.pageNumber === 1}
                >
                  Prev
                </button>

                <button
                  className={`${navbtn} hover:!bg-white hover:!text-brand`}
                  disabled
                >
                  {userReferreeList?.pageNumber} of{" "}
                  {userReferreeList?.totalPage}
                </button>
                <button
                  disabled={
                    userReferreeList?.pageNumber === userReferreeList?.totalPage
                  }
                  onClick={() => fetchMore(true)}
                  className={`${navbtn} rounded-r-[5px] !px-5 disabled:cursor-not-allowed`}
                >
                  Next
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="mb-5 flex items-center justify-between gap-2 rounded-[5px] border border-brand bg-white p-3">
            <div className="w-2/5">
              <div className="flex items-center gap-2">
                <div>
                  <p className="text-[14px] font-[500] text-[#3A3A3A]">
                    no referees yet
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default CustomerReferree;
