import Settlements from "modules/partials/Settlements";

const TransactionSettlement = () => {
  return (
    <Settlements
      module=""
      header={
        <>
          <h5 className="text-[18px] font-[500] text-brand">
            Transactions Settlement /
            <span className="font-[400]">All Transactions</span>
          </h5>
        </>
      }
    />
  );
};

export default TransactionSettlement;