import { back, logo } from "core/consts/images";
import { activeStep, inactiveStep, step } from "core/consts/styling";
import { Link, useNavigate } from "react-router-dom";
import { PersonalDetails } from "../partials/PersonalDetails";
import { BusinessDetails } from "../partials/BusinessDetails";
import { DocumentDetails } from "../partials/DocumentDetails";
import useLocationStore from "core/services/stores/useLocationStore";
import { useEffect, useState } from "react";
import useUserStore from "core/services/stores/useUserStore";

const Signup = () => {
  const navigate = useNavigate();
  const [level, setLevel] = useState(1);
  const [isFormValid, setIsFormValid] = useState({
    personalDetailForm: false,
    businessDetailForm: false,
    documentForm: false,
  });

  const [files, setFiles] = useState({
    passportImage: "", //uploadpassport
    idImage: "", //uploadid
    businessLogo: "", // uploadimage
    signature: "", //uploadSignature
  });

  const [newUser, setNewUser] = useState<NewUser>({
    businessDescription: "",
    businessLogo: "",
    businessName: "",
    bvn: "",
    category: "",
    confirmPassword: "",
    dateOfBirth: "",
    email: "",
    firstname: "",
    gender: "",
    IDCardExpiryDate: "",
    IDCardIssuedDate: "",
    IDCardNumber: "",
    IDCardType: "",
    lastname: "",
    location: "",
    middlename: "",
    password: "",
    phoneModel: "",
    phoneNo: "",
    securityAnswer: "",
    securityQuestion: "",
    transactionPin: "",
  });

  const [errors, setErrors] = useState<any>({
    BusinessDescription: [],
    BusinessLogo: [],
    BusinessName: [],
    Bvn: [],
    Category: [],
    ConfirmPassword: [],
    DateOfBirth: [],
    Email: [],
    Firstname: [],
    Gender: [],
    IDCardExpiryDate: [],
    IDCardIssuedDate: [],
    IDCardNumber: [],
    IDCardType: [],
    Lastname: [],
    Location: [],
    Middlename: [],
    Password: [],
    PhoneModel: [],
    PhoneNo: [],
    SecurityAnswer: [],
    SecurityQuestion: [],
    TransactionPin: [],
  });

  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);
  const signupAction = useUserStore((store) => store.signupUser);

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;

    setNewUser((state) => ({
      ...state,
      [name]: value,
    }));
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signupNewUser = async () => {
    var res = await signupAction(newUser, files);

    if (res?.isSuccessful) {
      navigate("/auth/login");
    } else {
      if (res?.data?.errors != null) {
        setErrors((state: any) => ({
          ...state,
          ...res?.data?.errors,
        }));
      }
    }
  };

  return (
    <div className="mx-auto flex w-11/12 justify-center md:w-4/5">
      <section className="mb-[24px] mt-[150px] flex h-auto w-full flex-col items-center justify-center md:w-2/3 lg:w-2/5">
        <div className="relative mb-[16px] w-full rounded-[8px] !bg-white px-[16px] py-[38px] md:px-[38px]">
          {level !== 1 && (
            <img
              src={back}
              alt="back"
              loading="lazy"
              onClick={() => setLevel(level - 1)}
              className="absolute left-[22px] top-[16px]"
            />
          )}
          <div className="mb-[24px] mt-[-80px] flex flex-col items-center justify-center">
            <img src={logo} loading="lazy" alt="campusrunz" />
          </div>
          <h5 className="mb-[32px] text-center text-[18px] font-[500] text-brand">
            Create your Admin Profile
          </h5>

          <div className="relative mx-auto w-10/12">
            <div className="relative z-20 mb-[32px] flex justify-center gap-[15%]">
              <button
                onClick={() => setLevel(1)}
                className={`cursor-pointer disabled:cursor-not-allowed ${step} ${
                  level === 1 ? activeStep : inactiveStep
                }`}
              >
                1
              </button>
              <button
                onClick={() => setLevel(2)}
                className={`cursor-pointer disabled:cursor-not-allowed ${step} ${
                  level === 2 ? activeStep : inactiveStep
                }`}
                disabled={!isFormValid.personalDetailForm}
              >
                2
              </button>
              <button
                onClick={() => setLevel(3)}
                className={`cursor-pointer disabled:cursor-not-allowed ${step} ${
                  level === 3 ? activeStep : inactiveStep
                }`}
                disabled={!isFormValid?.businessDetailForm}
              >
                3
              </button>
            </div>
            <div className="absolute top-[50%] z-0 h-[1px] w-full bg-[#C8C3C3]"></div>
          </div>
          {level === 1 && (
            <PersonalDetails
              onSubmit={() => {
                setIsFormValid((state) => ({
                  ...state,
                  personalDetailForm: true,
                }));
                setLevel(2);
              }}
              data={newUser}
              onChange={onFormChange}
              errors={errors}
              onErrorChange={setErrors}
            />
          )}

          {level === 2 && (
            <BusinessDetails
              onSubmit={() => {
                setIsFormValid((state) => ({
                  ...state,
                  businessDetailForm: true,
                }));
                setLevel(3);
              }}
              data={newUser}
              errors={errors}
              onErrorChange={setErrors}
              onChange={onFormChange}
              onBack={() => setLevel(1)}
            />
          )}

          {level === 3 && (
            <DocumentDetails
              onSubmit={() => {
                setIsFormValid((state) => ({
                  ...state,
                  documentForm: true,
                }));
                signupNewUser();
              }}
              data={newUser}
              files={files}
              onChange={onFormChange}
              onFileUpload={setFiles}
              onBack={() => setLevel(2)}
              errors={errors}
              onErrorChange={setErrors}
            />
          )}
        </div>
        <div className="flex w-full items-center justify-between">
          <Link to="/auth/login" className="text-[12px] text-brandblue">
            Already have an Account?
          </Link>
          <Link
            to="/auth/forgot-password"
            className="text-[12px] text-brandblue"
          >
            Forgot Password
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Signup;
