import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { getCustomerData } from "../api/analytics";

type AnalyticsState = {
  isLoading: boolean;
  customerDataList: CustomerDataList;
  customerData: CustomerData[];
  getCustomerDataList: (query: UserDataQueryParam) => Promise<void>;
  reset: () => void;
};

const initialState = {
  isLoading: false,
  customerDataList: {
    data: [],
    pageNumber: 1,
    pageSize: 20,
    totalCount: 0,
    totalPage: 1,
  },
  customerData: [],
};

const useAnalyticStore = create<AnalyticsState>()(
  devtools(
    persist(
      (set, get): AnalyticsState => ({
        ...initialState,
        getCustomerDataList: async (query) => {
          set({ isLoading: true });
          var res: UIResponse = await getCustomerData(query);

          if (res?.isSuccessful) {
            set({ customerData: res?.data?.data });
          }

          set({ isLoading: false });
          return;
        },
        reset: () => {
          set({
            ...initialState,
          });
        },
      }),
      {
        name: "analyticStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useAnalyticStore;
