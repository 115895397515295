import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import notification from "core/helpers/notification";
import {
  addAgency,
  createReferralReward,
  deleteAgency,
  deleteReferralReward,
  getAgencyReferralList,
  getReferralAgents,
  getReferralRewardTypes,
  getReferralRewardsForUser,
  getReferrerList,
  getUsersReferredByAgency,
  getUsersReferredByReferree,
  manageReferralReward,
  updateAgency,
  updateReferralReward,
} from "../api/referralapi";

type UserState = {
  isLoading: boolean;
  rewards: ReferralReward[] | [];
  rewardTypes: RewardType[] | [];
  agencies: Agency[] | [];
  userReferralList: UserRefererList;
  userReferreeList: UserReferreeList;
  agencyReferralList: AgencyRefererList;
  agencyReferreeList: AgencyReferreeList;
  getReferralRewardsForUser: () => Promise<void>;
  getReferralRewardTypes: () => Promise<void>;
  createReferralReward: (
    reward: NewReferralReward,
    location: CampusLocation,
  ) => Promise<UIResponse>;
  updateReferralReward: (
    id: string,
    reward: NewReferralReward,
  ) => Promise<UIResponse>;
  manageReferralReward: (id: string, isActive: boolean) => Promise<void>;
  deleteReferralReward: (id: string) => Promise<void>;
  getAgencies: () => Promise<void>;
  addAgency: (agency: NewAgency) => Promise<UIResponse>;
  updateAgency: (id: string, agency: NewAgency) => Promise<UIResponse>;
  deleteAgency: (id: string) => Promise<void>;
  getUserReferral: (query: ReferralQuery) => Promise<void>;
  getUserReferree: (
    referrerId: string,
    query: { pageNumber: number; pageSize: number },
  ) => Promise<void>;
  getAgencyReferral: (query: ReferralQuery) => Promise<void>;
  getAgencyReferree: (
    referrerCode: string,
    query: { pageNumber: number; pageSize: number },
  ) => Promise<void>;
  reset: () => void;
};

const useReferralStore = create<UserState>()(
  devtools(
    persist(
      (set, get): UserState => ({
        isLoading: false,
        rewards: [],
        rewardTypes: [],
        agencies: [],
        userReferralList: {
          referers: [],
          pageNumber: 1,
          pageSize: 20,
          totalCount: 0,
          totalPage: 1,
        },
        userReferreeList: {
          referredUsers: [],
          pageNumber: 1,
          pageSize: 20,
          totalCount: 0,
          totalPage: 1,
        },
        agencyReferralList: {
          referers: [],
          pageNumber: 1,
          pageSize: 20,
          totalCount: 0,
          totalPage: 1,
        },
        agencyReferreeList: {
          referredUsers: [],
          pageNumber: 1,
          pageSize: 20,
          totalCount: 0,
          totalPage: 1,
        },
        getReferralRewardsForUser: async () => {
          set({ isLoading: true });
          var res: UIResponse = await getReferralRewardsForUser();
          if (res?.isSuccessful && res?.data?.data?.length > 0) {
            set({ rewards: res?.data?.data });
          }
          set({ isLoading: false });
          return;
        },
        getReferralRewardTypes: async () => {
          set({ isLoading: true });
          var res: UIResponse = await getReferralRewardTypes();
          if (res?.isSuccessful && res?.data?.data?.length > 0) {
            set({ rewardTypes: res?.data?.data });
          }
          set({ isLoading: false });
          return;
        },
        createReferralReward: async (reward, location) => {
          set({ isLoading: true });
          var res: UIResponse = await createReferralReward(reward);

          if (res?.data?.status) {
            set((state) => ({
              rewards: [
                ...state.rewards,
                {
                  ...res?.data?.data,
                  location: location?.name,
                },
              ],
            }));
          }

          notification({
            message: res?.data?.message,
            type: res?.data?.status ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        deleteReferralReward: async (id) => {
          set({ isLoading: true });
          var res = await deleteReferralReward(id);

          if (res?.isSuccessful) {
            set((state) => ({
              rewards: state.rewards.filter((loc) => loc.id !== id),
            }));
          }

          notification({
            message: res?.data?.message,
            type: res?.data?.status ? "success" : "danger",
          });

          set({ isLoading: false });

          return;
        },
        manageReferralReward: async (id, isActive) => {
          set({ isLoading: true });
          var res = await manageReferralReward(id, isActive);

          if (res?.isSuccessful && res?.data?.status) {
            set((state) => ({
              rewards: state.rewards.map((reward) =>
                reward.id === id
                  ? {
                      ...res?.data?.data,
                    }
                  : reward,
              ),
            }));
          }

          notification({
            message: res?.data?.message,
            type: res?.data?.status ? "success" : "danger",
          });

          set({ isLoading: false });

          return;
        },
        updateReferralReward: async (id, reward) => {
          set({ isLoading: true });
          var res = await updateReferralReward(id, reward);

          if (res?.isSuccessful && res?.data?.status) {
            set((state) => ({
              rewards: state.rewards.map((reward) =>
                reward.id === id
                  ? {
                      ...res?.data?.data,
                    }
                  : reward,
              ),
            }));
          }

          notification({
            message: res?.data?.message,
            type: res?.data?.status ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        getAgencies: async () => {
          set({ isLoading: true });
          var res: UIResponse = await getReferralAgents();

          if (res?.isSuccessful && res?.data?.data?.length > 0) {
            set({ agencies: res?.data?.data });
          }

          set({ isLoading: false });
          return;
        },
        addAgency: async (agency) => {
          set({ isLoading: true });
          var res: UIResponse = await addAgency(agency);

          if (res?.isSuccessful) {
            set((state) => ({
              agencies: [
                ...state.agencies,
                {
                  ...res?.data?.data,
                },
              ],
            }));
          }

          notification({
            message: res?.isSuccessful ? res?.data?.message : res?.data?.title,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        updateAgency: async (id, agency) => {
          set({ isLoading: true });
          var res = await updateAgency(id, agency);

          if (res?.isSuccessful) {
            set((state) => ({
              agencies: state.agencies.map((agent) =>
                agent.id === id
                  ? {
                      ...res?.data?.data,
                    }
                  : agent,
              ),
            }));
          }

          notification({
            message: res?.isSuccessful ? res?.data?.message : res?.data?.title,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        deleteAgency: async (id) => {
          set({ isLoading: true });
          var res = await deleteAgency(id);

          if (res?.isSuccessful) {
            set((state) => ({
              agencies: state.agencies.filter((agent) => agent.id !== id),
            }));
          }

          notification({
            message: res?.data?.message,
            type: res?.data?.status ? "success" : "danger",
          });

          set({ isLoading: false });

          return;
        },
        getUserReferral: async (query) => {
          set({ isLoading: true });
          var res: UIResponse = await getReferrerList(query);

          if (res?.isSuccessful) {
            set({ userReferralList: res?.data });
          }

          set({ isLoading: false });
          return;
        },
        getUserReferree: async (referrerId, query) => {
          set({ isLoading: true });
          var res: UIResponse = await getUsersReferredByReferree(
            referrerId,
            query,
          );

          if (res?.isSuccessful) {
            set({ userReferreeList: res?.data });
          }

          set({ isLoading: false });
          return;
        },
        getAgencyReferral: async (query) => {
          set({ isLoading: true });
          var res: UIResponse = await getAgencyReferralList(query);

          if (res?.isSuccessful) {
            set({ agencyReferralList: res?.data });
          }

          set({ isLoading: false });
          return;
        },
        getAgencyReferree: async (referrerCode, query) => {
          set({ isLoading: true });
          var res: UIResponse = await getUsersReferredByAgency(
            referrerCode,
            query,
          );

          if (res?.isSuccessful) {
            set({ agencyReferreeList: res?.data });
          }

          set({ isLoading: false });
          return;
        },
        reset: () => {
          set({
            isLoading: false,
            rewards: [],
            rewardTypes: [],
            agencies: [],
            userReferralList: {
              referers: [],
              pageNumber: 1,
              pageSize: 20,
              totalCount: 0,
              totalPage: 1,
            },
            userReferreeList: {
              referredUsers: [],
              pageNumber: 1,
              pageSize: 20,
              totalCount: 0,
              totalPage: 1,
            },
            agencyReferralList: {
              referers: [],
              pageNumber: 1,
              pageSize: 20,
              totalCount: 0,
              totalPage: 1,
            },
            agencyReferreeList: {
              referredUsers: [],
              pageNumber: 1,
              pageSize: 20,
              totalCount: 0,
              totalPage: 1,
            },
          });
        },
      }),
      {
        name: "referralStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useReferralStore;
