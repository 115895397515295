import { apicall } from "./apicall";

export const getEngineeringDashboard = (query: DateLocationQuery) => {
  return apicall({
    endpoint: "/engineering/dashboard",
    method: "GET",
    pQuery: {
      ...query,
    },
    auth: true,
  });
};

export const getEngineeringService = (
  query: EngineeringQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: "/engineering/service",
    method: "GET",
    pQuery: {
      ...query,
      pageNumber,
      pageSize,
    },
    auth: true,
  });
};

export const getEngineeringServiceById = (serviceId: string) => {
  return apicall({
    endpoint: "/engineering/service",
    param: serviceId,
    method: "GET",
    auth: true,
  });
};

export const addUpdateEngineeringService = async (
  service: NewEngineeringService,
  serviceId: string,
) => {
  const data = new FormData();

  data.append("applianceType", service?.applianceType);
  data.append("engineerName", service?.engineerName);
  data.append("engineerAddress", service?.engineerAddress);
  data.append("engineerPhoneNo", service?.engineerPhoneNo);
  data.append("extraDetails", service?.extraDetails);
  data.append("convenienceFee", `${service?.convenienceFee}`);

  if (service?.images?.length > 0) {
    service?.images.forEach((file: any, i: any) => {
      data.append(`uploadedImageList${i}`, file);
    });
  }

  var isUpdate = serviceId?.length > 0;

  return apicall({
    endpoint: "/engineering/service",
    param: isUpdate ? serviceId : "",
    method: isUpdate ? "PATCH" : "POST",
    multipart: true,
    auth: true,
    body: data,
  });
};

export const toggleEngineeringService = (
  serviceId: string,
  visibility: boolean,
) => {
  return apicall({
    endpoint: "/engineering/service/toggle",
    param: serviceId,
    method: "PATCH",
    body: {
      visibility,
    },
    auth: true,
  });
};

export const deleteEngineeringService = (serviceId: string) => {
  return apicall({
    endpoint: "/engineering/service",
    param: serviceId,
    method: "DELETE",
    auth: true,
  });
};

export const deleteEngineeringImage = (
  category: string,
  serviceId: string,
  image: string,
) => {
  return apicall({
    endpoint: "/engineering/image",
    method: "DELETE",
    body: {
      itemId: serviceId,
      category,
      imagePath: image,
    },
    auth: true,
  });
};

export const getEngineeringOrders = (
  query: EngineeringQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: "/engineering/order",
    method: "GET",
    pQuery: {
      endDate: query?.endDate,
      startDate: query?.startDate,
      userId: query?.userId,
      locationFilter: query?.locationFilter,
      searchText: query?.searchText,
      orderStatus: query?.orderStatus,
      pageNumber,
      pageSize,
    },
    auth: true,
  });
};

export const getEngineeringOrderById = (orderId: string) => {
  return apicall({
    endpoint: "/engineering/order",
    param: orderId,
    method: "GET",
    auth: true,
  });
};
