import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import { back, profileuser } from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import { formatSimpleDate } from "core/helpers/generalHelpers";
import useTransportationStore from "core/services/stores/useTransportationStore";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const DeliveryHistory = () => {
  const { agentId } = useParams();
  const navigate = useNavigate();

  const agent = useTransportationStore((store) => store.agent);
  const setAgent = useTransportationStore((store) => store.setAgent);
  const getDeliveryHistoriesAction = useTransportationStore(
    (store) => store.getDeliveryHistories,
  );
  const deliveryHistories = useTransportationStore(
    (store) => store.deliveryHistories,
  );

  useEffect(() => {
    if (agentId != null && agentId?.length > 1) {
      getDeliveryHistoriesAction(agentId!);
    } else {
      setAgent(null);
      navigate("/transportation/delivery-agents");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="mx-auto mb-[28px] w-[95%]">
      <div className="flex items-center justify-between">
        <header className="flex items-center gap-3">
          <img src={profileuser} alt="" loading="lazy" />
          <h5 className="text-[18px] font-[500] text-brand">
            Delivery History of {agent?.fullName}
          </h5>
        </header>

        <button
          onClick={() => {
            setAgent(null);
            navigate(`/transportation/delivery-agents`);
          }}
          className="flex w-[100px] items-center justify-between px-4 py-2 text-[14px] text-brand"
        >
          <img src={back} loading="lazy" alt="" />
          <span>Back</span>
        </button>
      </div>

      <div className="my-[16px] flex items-center justify-between gap-5">
        <div className="flex justify-start gap-3 rounded-[25px]">
          <div className={`${actionbtn} bg-[#F2EAF8] shadow-md`}>
            No of Deliveries: {deliveryHistories?.length}
          </div>
        </div>
      </div>

      <main className="my-[24px]">
        <Table
          headers={[
            "S/N",
            "Customer Name",
            "Pick up Address",
            "Pick up Contact",
            "Pick up Date",
            "Status",
          ]}
        >
          {deliveryHistories?.length > 0 ? (
            deliveryHistories?.map((delivery, index) => (
              <tr key={delivery?.orderId}>
                <Row value={index + 1} />
                <Row value={delivery?.customerName} />
                <Row value={delivery?.pickUpAddress} />
                <Row value={delivery?.pickupContact} />
                <Row value={formatSimpleDate(delivery?.pickupDate)} />
                <Row value={delivery?.orderStatus} />
              </tr>
            ))
          ) : (
            <tr>
              <Row colSpan={6} value="No delivery history yet" />
            </tr>
          )}
        </Table>
      </main>
    </section>
  );
};

export default DeliveryHistory;
