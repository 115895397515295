import { PROCESS_ORDER } from "core/consts/systemConst";
import { apicall } from "./apicall";

export const signin = (username: string, password: string) => {
  return apicall({
    endpoint: "/auth/signin",
    body: {
      username,
      password,
    },
    method: "POST",
  });
};

export const generateToken = (username: string) => {
  return apicall({
    endpoint: "/auth/forgot_password/generate_token",
    body: {
      username,
    },
    method: "POST",
  });
};

export const confirmToken = (username: string, token: string) => {
  return apicall({
    endpoint: "/auth/forgot_password/confirm_token",
    body: {
      email: username,
      token,
    },
    method: "POST",
  });
};

export const resetPassword = (
  username: string,
  password: string,
  confirmPassword: string,
) => {
  return apicall({
    endpoint: "/auth/forgot_password/reset_password",
    body: {
      username,
      password,
      confirmPassword,
    },
    method: "POST",
  });
};

export const signupUser = (user: NewUser) => {
  var formData = new FormData();

  formData.append("firstname", user?.firstname);
  formData.append("lastname", user?.lastname);
  formData.append("middlename", user?.middlename);
  formData.append("email", user?.email);
  formData.append("password", user?.password);
  formData.append("confirmPassword", user?.confirmPassword);
  formData.append("phoneNo", user?.phoneNo);
  formData.append("category", user?.category);
  formData.append("businessName", user?.businessName);
  formData.append("location", user?.location);
  formData.append("businessDescription", user?.businessDescription);
  formData.append("securityQuestion", user?.securityQuestion);
  formData.append("securityAnswer", user?.securityAnswer);
  formData.append("dateOfBirth", user?.dateOfBirth);
  formData.append("gender", user?.gender);
  formData.append("bvn", user?.bvn);
  formData.append("TransactionPin", user?.transactionPin);
  formData.append("IDCardType", user?.IDCardType);
  formData.append("IDCardNumber", user?.IDCardNumber);
  formData.append("IDCardIssuedDate", user?.IDCardIssuedDate);
  formData.append(
    "IDCardExpiryDate",
    ["drivers license", "international passport"].includes(
      user?.IDCardType?.toLowerCase(),
    )
      ? user?.IDCardExpiryDate
      : "",
  );

  return apicall({
    endpoint: "/auth/signup",
    method: "POST",
    multipart: true,
    auth: false,
    body: formData,
  });
};

export const uploadBusinessDocument = (
  id: string,
  file: any,
  action: string,
) => {
  const formData = new FormData();

  formData.append("userId", id);
  formData.append("file", file);

  return apicall({
    endpoint: "/auth",
    param: action,
    method: "PATCH",
    multipart: true,
    auth: false,
    body: formData,
  });
};

export const updateOrderStatus = (
  orderId: string,
  orderStatus: ManageOrder,
  reason: string,
) => {
  return apicall({
    endpoint: "/order",
    param:
      orderStatus === PROCESS_ORDER?.cancel
        ? `admin/${orderStatus}/${orderId}`
        : `${orderStatus}/${orderId}`,
    method: "PATCH",
    body: {
      reason,
    },
    auth: true,
  });
};
