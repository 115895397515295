import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
  deleteNotification,
  getNotifications,
  markAsRead,
} from "../api/notification.api";
import notification from "core/helpers/notification";

type StoreState = {
  isLoading: boolean;
  notificationList: {
    notifications: SystemNotification[];
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPage: number;
  };
  getNotifications: (
    query: NotificationQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  markAsRead: (module: string, notificationId: string) => Promise<void>;
  deleteNotification: (module: string, notificationId: string) => Promise<void>;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  notificationList: {
    notifications: [],
    pageNumber: 1,
    pageSize: 20,
    totalCount: 0,
    totalPage: 0,
  },
};

const useNotificationStore = create<StoreState>()(
  devtools(
    persist(
      (set, get): StoreState => ({
        ...defaultState,
        getNotifications: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getNotifications(
            query,
            pageNumber,
            pageSize,
          );

          if (res?.isSuccessful) {
            set({
              notificationList: {
                notifications: res?.data?.data,
                pageNumber: res?.data?.pageNumber,
                pageSize: res?.data?.pageSize,
                totalCount: res?.data?.totalCount,
                totalPage: res?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
          return;
        },
        markAsRead: async (module, notificationId) => {
          set({ isLoading: true });

          var res: UIResponse = await markAsRead(module, notificationId);

          if (res?.isSuccessful) {
            set((state) => ({
              notificationList: {
                ...state.notificationList,
                notifications: state?.notificationList.notifications?.map(
                  (notice) =>
                    notice?.notificationId === notificationId
                      ? { ...notice, read: true }
                      : notice,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        deleteNotification: async (module, notificationId) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteNotification(module, notificationId);

          if (res?.isSuccessful) {
            set((state) => ({
              notificationList: {
                ...state.notificationList,
                notifications: state?.notificationList.notifications?.filter(
                  (notice) => notice?.notificationId !== notificationId,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Notification has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });
        },
        reset: () => {
          set({ ...defaultState });
        },
      }),
      {
        name: "notificationStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useNotificationStore;
