import Accordion from "core/components/Accordion";
import Modal from "core/components/Modal";
import Pagination from "core/components/Pagination";
import InputField from "core/components/formfields/InputField";
import MultiSelectField from "core/components/formfields/MultiSelectField";
import SelectField from "core/components/formfields/SelectField";
import ActionRow from "core/components/table/Actionrow";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import { deleteIcon, editIcon, users } from "core/consts/images";
import { actionbtn, btn } from "core/consts/styling";
import { getDate } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useAdminStore from "core/services/stores/useAdminStore";
import useAdvertStore from "core/services/stores/useAdvertStore";
import useLocationStore from "core/services/stores/useLocationStore";
import { useEffect, useState } from "react";

const Settings = () => {
  const functionality = useAdminStore((store) => store.functionality);
  const getFunctionalityAction = useAdminStore(
    (store) => store.getFunctionality,
  );
  const manageFunctionalityAction = useAdminStore(
    (store) => store.manageFunctionality,
  );
  const [newPrivilege, setNewPrivilege] = useState("");
  const privileges = useAdminStore((store) => store.privileges);
  const getPrivilegesAction = useAdminStore((store) => store.getPrivilege);
  const addPrivilegeAction = useAdminStore((store) => store.addPrivilege);
  const updatePrivilegeAction = useAdminStore((store) => store.updatePrivilege);
  const deletePrivilegeAction = useAdminStore((store) => store.deletePrivilege);
  const [selectedPrivilege, setSelectedPrivilege] = useState<Roles>({
    name: "",
    id: "",
  });
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [isAll, setIsAll] = useState("false");

  const addPrivilege = async (e: any) => {
    e.preventDefault();

    if (newPrivilege?.length > 0) {
      var res = await addPrivilegeAction(newPrivilege);

      if (res?.isSuccessful) setNewPrivilege("");
    } else {
      notification({
        message: "Privilege name is required",
        type: "danger",
      });
    }
  };

  const deletePrivilege = async (privilege: Roles) => {
    if (window.confirm(`Click 'OK' to delete privilege "${privilege?.name}"`)) {
      await deletePrivilegeAction(privilege?.id);
    }
  };

  const updatePrivilege = async (e: any) => {
    e.preventDefault();

    if (selectedPrivilege?.name?.length > 0) {
      await updatePrivilegeAction(
        selectedPrivilege?.id,
        selectedPrivilege?.name,
      );
    } else {
      notification({
        message: "Privilege name is required",
        type: "danger",
      });
    }
  };

  // Role Privileges
  const roleprivileges = useAdminStore((store) => store.rolePrivileges);
  const getRolePrivilegesAction = useAdminStore(
    (store) => store.getRolePrivilege,
  );
  const addRolePrivilegesAction = useAdminStore(
    (store) => store.addRolePrivilege,
  );
  const deleteRolePrivilegeAction = useAdminStore(
    (store) => store.deleteRolePrivilege,
  );
  const getRolesAction = useAdminStore((store) => store.getRoles);
  const roles = useAdminStore((store) => store.roles);
  const [newRolePrivilege, setNewRolePrivilege] = useState({
    roleId: "",
    privileges: [],
  });

  const onRoleFormChange = (e: any) => {
    const { name, value } = e?.target;

    setNewRolePrivilege((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const deleteRolePrivilege = async (id: string, roleId: string) => {
    if (window.confirm(`Click 'OK' to delete this Role Privilege`)) {
      await deleteRolePrivilegeAction(id, roleId);
    }
  };

  const [errors, setErrors] = useState({
    RoleId: [""],
    Privileges: [""],
  });

  const isValidRolePrivilege = (data: any) => {
    var isValid = true;

    if (data?.roleId?.length < 1) {
      setErrors((state: any) => ({
        ...state,
        RoleId: ["Please choose a role"],
      }));
      isValid = false;
    }

    if (data?.privileges?.length < 1) {
      setErrors((state: any) => ({
        ...state,
        Privileges: ["Please select a privilege"],
      }));
      isValid = false;
    }

    return isValid;
  };

  const addRolePrivilege = async (e: any) => {
    e.preventDefault();

    if (isValidRolePrivilege(newRolePrivilege)) {
      var res = await addRolePrivilegesAction(
        newRolePrivilege?.roleId,
        newRolePrivilege?.privileges,
      );

      if (res?.isSuccessful) {
        setNewRolePrivilege({
          privileges: [],
          roleId: "",
        });
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  // Loyalty Programme
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);
  const loyaltyList = useAdvertStore((store) => store.loyaltyList);
  const getLoyaltyAction = useAdvertStore((store) => store.getLoyalty);
  const addUpdateLoyaltyAction = useAdvertStore(
    (store) => store.addUpdateLoyalty,
  );
  const [selectedLoyalty, setSelectedLoyalty] = useState<Loyalty | null>(null);
  const [showLoyaltyUpdateModal, setShowLoyaltyUpdateModal] = useState(false);
  const deleteLoyaltyAction = useAdvertStore((store) => store.deleteLoyalty);

  const defaultLoyalty: NewLoyalty = {
    programTitle: "",
    rewardPercentage: 0,
    startDate: "",
    endDate: "",
    applyToAllLocations: false,
    minimumTransactionCount: 0,
    minimumTransactionValue: 0,
    locations: [],
  };

  const [newLoyalty, setNewLoyalty] = useState<NewLoyalty>({
    ...defaultLoyalty,
  });

  const [updateLoyaltyData, setUpdateLoyaltyData] = useState<NewLoyalty>({
    ...defaultLoyalty,
  });

  const [loyaltyErrors, setLoyaltyErrors] = useState({
    ProgramTitle: [],
    RewardPercentage: [],
    StartDate: [],
    EndDate: [],
    MinimumTransactionCount: [],
    MinimumTransactionValue: [],
    Locations: [],
  });

  const onLoyaltyChange = (e: any, isUpdate: boolean = false) => {
    const { name, value } = e?.target;

    if (!isUpdate) {
      setNewLoyalty((state) => ({
        ...state,
        [name]: value,
      }));
    } else {
      setUpdateLoyaltyData((state) => ({
        ...state,
        [name]: value,
      }));
    }
  };

  const onLoyaltyErrorChange = (name: string, value: string) => {
    setLoyaltyErrors((state) => ({
      ...state,
      [name]: [value],
    }));
  };

  const isValidLoyalty = (data: NewLoyalty) => {
    var isValid = true;

    if (data?.programTitle?.length < 1) {
      isValid = false;
      onLoyaltyErrorChange("ProgramTitle", "Program Title is required");
    }

    if (data?.rewardPercentage < 0) {
      isValid = false;
      onLoyaltyErrorChange(
        "RewardPercentage",
        "Reward percentage must not be less than 0",
      );
    }

    if (data?.startDate?.length < 1) {
      isValid = false;
      onLoyaltyErrorChange("StartDate", "Start Date is required");
    }

    if (data?.endDate?.length < 1) {
      isValid = false;
      onLoyaltyErrorChange("EndDate", "End Date is required");
    }

    if (isAll === "false" && data?.locations?.length < 1) {
      isValid = false;
      onLoyaltyErrorChange("Locations", "Please choose a campus location");
    }

    return isValid;
  };

  const addLoyalty = async (e: any) => {
    e.preventDefault();

    if (isValidLoyalty(newLoyalty)) {
      var selectedLocations = [];

      if (isAll === "true") {
        // selectedLocations = locations?.map((item) => item?.id);
        selectedLocations = [];
      } else {
        selectedLocations = newLoyalty?.locations?.map(
          (item: any) => item.value,
        );
      }

      var res: any = await addUpdateLoyaltyAction(
        {
          ...newLoyalty,
          startDate: `${newLoyalty?.startDate}:26.479Z`,
          endDate: `${newLoyalty?.endDate}:26.479Z`,
          locations: selectedLocations,
          applyToAllLocations: isAll === "true" ? true : false,
        },
        "",
      );

      if (res?.isSuccessful) {
        setNewLoyalty({
          ...defaultLoyalty,
        });

        setIsAll("false");
      } else {
        if (res?.data?.errors != null) {
          setLoyaltyErrors(res?.data?.errors);
        }
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const deleteLoyalty = async (loyaltyId: string) => {
    if (window.confirm(`Click 'OK' to delete this cashback`)) {
      await deleteLoyaltyAction(loyaltyId);
    }
  };

  const fetchMore = (page: number) => {
    getLoyaltyAction(page, loyaltyList?.pageSize);
  };

  const openLoyaltyUpdate = (data: Loyalty) => {
    setSelectedLoyalty(data);
    setShowLoyaltyUpdateModal(true);

    setUpdateLoyaltyData((state: any) => ({
      ...state,
      ...data,
      locations: !data?.applyToAllLocations
        ? data?.locations?.map((loc) => ({
            label: loc?.name,
            value: loc?.locationId,
          }))
        : [],
    }));

    if (data?.applyToAllLocations) {
      setIsAll("true");
    } else {
      setIsAll("false");
    }
  };

  const closeLoyaltyUpdate = () => {
    setSelectedLoyalty(null);
    setUpdateLoyaltyData({ ...defaultLoyalty });
    setShowLoyaltyUpdateModal(false);
    setIsAll("false");
  };

  const updateLoyalty = async (e: any) => {
    e.preventDefault();

    if (isValidLoyalty(updateLoyaltyData)) {
      var selectedLocations = [];

      if (isAll === "true") {
        //selectedLocations = locations?.map((item) => item?.id);
        selectedLocations = [];
      } else {
        selectedLocations = updateLoyaltyData?.locations?.map(
          (item: any) => item.value,
        );
      }

      var res = await addUpdateLoyaltyAction(
        {
          ...updateLoyaltyData,
          locations: selectedLocations,
          applyToAllLocations: isAll === "true" ? true : false,
        },
        selectedLoyalty!?.id,
      );

      if (!res?.isSuccessful) {
        if (res?.data?.errors != null) {
          setLoyaltyErrors(res?.data?.errors);
        }
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    functionality?.length < 1 && getFunctionalityAction();
    roles?.length < 1 && getRolesAction();
    privileges?.length < 1 && getPrivilegesAction();
    roleprivileges?.length < 1 && getRolePrivilegesAction();
    locations?.length < 1 && getLocationAction();

    getLoyaltyAction(1, 15);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mx-auto w-[95%]">
        <header className="mb-[28px] flex items-center gap-3">
          <img src={users} loading="lazy" alt="" />
          <h5 className="text-[18px] font-[500] text-brand">Settings</h5>
        </header>

        <section className="mb-[28px]">
          <Accordion id="privileges" title="Privileges">
            <section className="mb-[28px] flex flex-col gap-5 overflow-x-scroll lg:flex-row">
              <div className="w-full lg:w-4/12">
                <form
                  className="rounded-[5px] bg-white p-5"
                  onSubmit={addPrivilege}
                >
                  <InputField
                    boxStyle="mb-[16px]"
                    label="Name"
                    placeholder="Name of privilege"
                    name="name"
                    value={newPrivilege}
                    onChange={(e: any) => setNewPrivilege(e?.target?.value)}
                  />

                  <button className={`${btn} w-full bg-brand text-white`}>
                    Submit
                  </button>
                </form>
              </div>
              <div className="w-full lg:w-8/12">
                <Table headers={["S/N", "Name", "Action"]}>
                  {privileges?.length > 0 ? (
                    privileges?.map((privilege, index) => (
                      <tr>
                        <Row value={index + 1} />
                        <Row value={privilege?.name} />
                        <ActionRow
                          style={`flex items-center justify-center gap-3`}
                        >
                          <button
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#59960B] bg-[#D9FEAA] p-2"
                            onClick={() => {
                              setSelectedPrivilege(privilege);
                              setShowUpdateModal(true);
                            }}
                          >
                            <img
                              src={editIcon}
                              loading="lazy"
                              className="h-[16px]"
                              alt="edit"
                            />
                          </button>
                          <button
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#FF0000] bg-[#FFC8C8] p-2"
                            onClick={() => {
                              deletePrivilege(privilege);
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="h-[16px]"
                              loading="lazy"
                              alt="delete"
                            />
                          </button>
                        </ActionRow>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <Row colSpan={3} value="No privilege" />
                    </tr>
                  )}
                </Table>
              </div>
            </section>
          </Accordion>
        </section>

        <section className="mb-[28px]">
          <Accordion id="roleprivileges" title="Role Privileges">
            <section className="mb-[28px] flex flex-col gap-5 overflow-x-scroll lg:flex-row">
              <div className="w-full lg:w-5/12">
                <form
                  className=" rounded-[5px] bg-white p-5"
                  onSubmit={addRolePrivilege}
                >
                  <SelectField
                    boxStyle="mb-[24px]"
                    name="roleId"
                    defaultName="Select Role"
                    defaultValue=""
                    options={
                      roles?.length > 0
                        ? [
                            ...roles.map((type) => ({
                              name: type?.name,
                              value: type?.id,
                            })),
                          ]
                        : []
                    }
                    value={newRolePrivilege?.roleId}
                    onChange={(e: any) => onRoleFormChange(e)}
                    error={errors?.RoleId[0]}
                    onBlur={() => {
                      setErrors((state: any) => ({
                        ...state,
                        RoleId: [],
                      }));
                    }}
                  />

                  <div className="grid grid-cols-2 gap-3">
                    {privileges?.length > 0 &&
                      privileges?.map((pre) => (
                        <div key={pre?.id} className="flex items-center gap-3">
                          <input
                            type="checkbox"
                            className="border-1 h-[25px] w-[25px] rounded-[5px] border border-red-500 text-white !accent-[#DFA1F4]"
                            checked={newRolePrivilege?.privileges?.some(
                              (data) => data === pre?.id,
                            )}
                            onChange={(e) => {
                              if (e?.target?.checked) {
                                setErrors((state: any) => ({
                                  ...state,
                                  Privileges: [],
                                }));

                                setNewRolePrivilege((state: any) => ({
                                  ...state,
                                  privileges: state?.privileges?.concat(
                                    pre?.id,
                                  ),
                                }));
                              } else {
                                setNewRolePrivilege((state: any) => ({
                                  ...state,
                                  privileges: [
                                    ...state?.privileges?.filter(
                                      (el: any) => el !== pre?.id,
                                    ),
                                  ],
                                }));
                              }
                            }}
                          />
                          <p>{pre?.name}</p>
                        </div>
                      ))}
                  </div>

                  {newRolePrivilege?.privileges?.length < 1 && (
                    <span className="text-[14px] text-red-500">
                      {errors?.Privileges[0]}
                    </span>
                  )}

                  <button
                    className={`mt-[24px] ${btn} w-full bg-brand text-white`}
                  >
                    Submit
                  </button>
                </form>
              </div>
              <div className="w-full lg:w-7/12">
                <Table headers={["Role", "Privileges"]}>
                  {roleprivileges?.length > 0 ? (
                    roleprivileges?.map((privilege, index) => (
                      <tr>
                        <Row value={privilege?.name} />

                        <ActionRow>
                          {privilege?.privileges?.length > 0 ? (
                            <div className="grid grid-cols-3 gap-3">
                              {privilege?.privileges?.map((pr) => (
                                <div
                                  className="flex items-center justify-between rounded-[25px] border-[1px] border-brand bg-[#DFA1F4] px-2 py-1 text-[12px] text-black"
                                  key={pr?.id}
                                  onClick={() => {
                                    deleteRolePrivilege(
                                      pr?.id,
                                      privilege?.roleId,
                                    );
                                  }}
                                >
                                  <span>{pr?.name}</span>
                                  <button>
                                    <img
                                      src={deleteIcon}
                                      className="h-[16px]"
                                      loading="lazy"
                                      alt="delete"
                                    />
                                  </button>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <span>none</span>
                          )}
                        </ActionRow>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <Row colSpan={2} value="No privilege" />
                    </tr>
                  )}
                </Table>
              </div>
            </section>
          </Accordion>
        </section>

        <section className="mb-[28px]">
          <Accordion
            id="functionalityManagement"
            title="Functionality Management"
          >
            <section className="mb-[28px] bg-white p-5">
              <div className="mb-[16px] flex items-center justify-between gap-2 font-bold">
                <div className="w-1/12">
                  <p>On</p>
                </div>
                <div className="w-1/12">
                  <p>Off</p>
                </div>
                <div className="w-10/12"></div>
              </div>
              {functionality?.length > 0 &&
                functionality?.map((func) => (
                  <div className="flex items-center justify-between gap-2">
                    <div className="w-1/12">
                      <input
                        type="radio"
                        value="true"
                        className="border-1 h-[25px] w-[25px] !border-brand !bg-brand text-brand !accent-[#DFA1F4] focus:border-brand focus:bg-brand"
                        checked={func.status === "Active"}
                        onChange={(e) =>
                          manageFunctionalityAction(func?.id, true)
                        }
                      />
                    </div>
                    <div className="w-1/12">
                      <input
                        type="radio"
                        value="false"
                        className="border-1 h-[25px] w-[25px] !border-brand !bg-brand text-brand !accent-[#DFA1F4] focus:border-brand focus:bg-brand"
                        checked={func.status === "Inactive"}
                        onChange={(e) =>
                          manageFunctionalityAction(func?.id, false)
                        }
                      />
                    </div>
                    <div className="w-10/12">
                      <p className="h-[25px]">{func?.name}</p>
                    </div>
                  </div>
                ))}
            </section>
          </Accordion>
        </section>

        <section className="mb-[28px]">
          <Accordion id="loyaltyRewards" title="Loyalty and Rewards">
            <section className="mb-[28px] flex flex-col gap-5 overflow-x-scroll lg:flex-row">
              <div className="mb-5 w-full lg:w-4/12">
                <p className="mb-3 text-[14px] font-bold">Manage Cashback</p>
                <form
                  className="rounded-[5px] bg-white p-5"
                  onSubmit={addLoyalty}
                >
                  <InputField
                    boxStyle="mb-[16px]"
                    label="Program Title"
                    name="programTitle"
                    value={newLoyalty?.programTitle}
                    onChange={onLoyaltyChange}
                    error={loyaltyErrors?.ProgramTitle[0]}
                    onBlur={() => onLoyaltyErrorChange("ProgramTitle", "")}
                  />

                  <InputField
                    boxStyle="mb-[16px]"
                    label="Reward Percentage"
                    name="rewardPercentage"
                    type="number"
                    value={newLoyalty?.rewardPercentage}
                    onChange={onLoyaltyChange}
                    error={loyaltyErrors?.RewardPercentage[0]}
                    onBlur={() => onLoyaltyErrorChange("RewardPercentage", "")}
                  />

                  <InputField
                    boxStyle="mb-[16px]"
                    label="Start Date"
                    name="startDate"
                    type="datetime-local"
                    value={newLoyalty?.startDate}
                    onChange={(e: any) => {
                      onLoyaltyChange(e);
                    }}
                    error={loyaltyErrors?.StartDate[0]}
                    onBlur={() => onLoyaltyErrorChange("StartDate", "")}
                  />

                  <InputField
                    boxStyle="mb-[16px]"
                    label="End Date"
                    name="endDate"
                    type="datetime-local"
                    value={newLoyalty?.endDate}
                    onChange={(e: any) => {
                      onLoyaltyChange(e);
                    }}
                    error={loyaltyErrors?.EndDate[0]}
                    onBlur={() => onLoyaltyErrorChange("EndDate", "")}
                  />

                  <div className="mb-[16px] flex items-center justify-between">
                    <fieldset className="flex items-center gap-2">
                      <input
                        className="h-[22px] w-[22px] accent-brand"
                        type="radio"
                        name=""
                        id=""
                        checked={isAll === "true"}
                        onChange={() => {
                          setIsAll("true");
                        }}
                      />
                      <label htmlFor="" className="text-[14px]">
                        Apply to all users
                      </label>
                    </fieldset>

                    <fieldset className="flex items-center gap-2">
                      <input
                        className="h-[22px] w-[22px] accent-brand"
                        type="radio"
                        name=""
                        id=""
                        checked={isAll === "false"}
                        onChange={() => {
                          setIsAll("false");
                        }}
                      />
                      <label htmlFor="" className="text-[14px]">
                        Others
                      </label>
                    </fieldset>
                  </div>

                  {isAll === "false" && (
                    <MultiSelectField
                      multiple
                      isRequired
                      label="Select Reach"
                      name="locationId"
                      boxStyle="mb-[24px]"
                      value={newLoyalty?.locations}
                      options={
                        locations?.length > 0
                          ? [
                              ...locations?.map((location) => ({
                                label: location.name,
                                value: location.id,
                              })),
                            ]
                          : []
                      }
                      onChange={(e: any) =>
                        setNewLoyalty((state) => ({
                          ...state,
                          locations: e,
                        }))
                      }
                      error={loyaltyErrors?.Locations[0]}
                      onBlur={() => onLoyaltyErrorChange("Locations", "")}
                    />
                  )}

                  <InputField
                    boxStyle="mb-[16px]"
                    label="Transaction Volume"
                    name="minimumTransactionCount"
                    type="number"
                    instruction="Equal or greater than"
                    value={newLoyalty?.minimumTransactionCount}
                    onChange={onLoyaltyChange}
                    error={loyaltyErrors?.MinimumTransactionCount[0]}
                    onBlur={() =>
                      onLoyaltyErrorChange("MinimumTransactionCount", "")
                    }
                  />

                  <InputField
                    boxStyle="mb-[16px]"
                    label="Transaction Value"
                    name="minimumTransactionValue"
                    type="number"
                    instruction="Equal or greater than"
                    value={newLoyalty?.minimumTransactionValue}
                    onChange={onLoyaltyChange}
                    error={loyaltyErrors?.MinimumTransactionValue[0]}
                    onBlur={() =>
                      onLoyaltyErrorChange("MinimumTransactionValue", "")
                    }
                  />

                  <button className={`${btn} w-full bg-brand text-white`}>
                    Submit
                  </button>
                </form>
              </div>

              <div className="w-full lg:w-8/12">
                <Table
                  headers={[
                    "Reward Program Title",
                    "Value",
                    "Locations",
                    "Start - End Date",
                    "Action",
                  ]}
                >
                  {loyaltyList?.data?.length > 0 ? (
                    loyaltyList?.data?.map((prog, index) => (
                      <tr>
                        <Row value={prog?.programTitle} />
                        <Row value={`${prog?.rewardPercentage}%`} />
                        <ActionRow>
                          {!prog?.applyToAllLocations ? (
                            prog?.locations?.map((loc) => (
                              <p key={loc?.locationId}>{loc?.name}</p>
                            ))
                          ) : (
                            <p key={prog?.id}>All locations</p>
                          )}
                        </ActionRow>
                        <Row
                          value={`${getDate(prog?.startDate)} - ${getDate(
                            prog?.endDate,
                          )} `}
                        />
                        <ActionRow
                          style={`flex items-center justify-center gap-3`}
                        >
                          <button
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#59960B] bg-[#D9FEAA] p-2"
                            onClick={() => {
                              openLoyaltyUpdate(prog);
                            }}
                          >
                            <img
                              src={editIcon}
                              loading="lazy"
                              className="h-[16px]"
                              alt="edit"
                            />
                          </button>
                          <button
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#FF0000] bg-[#FFC8C8] p-2"
                            onClick={() => {
                              deleteLoyalty(prog?.id);
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="h-[16px]"
                              loading="lazy"
                              alt="delete"
                            />
                          </button>
                        </ActionRow>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <Row
                        colSpan={5}
                        value="No cashback program at the moment"
                      />
                    </tr>
                  )}
                </Table>

                <Pagination
                  pageNumber={loyaltyList?.pageNumber}
                  pageSize={loyaltyList?.pageSize}
                  totalCount={loyaltyList?.totalCount}
                  totalPage={loyaltyList?.totalPage}
                  onFetch={fetchMore}
                />
              </div>
            </section>
          </Accordion>
        </section>
      </div>

      {showUpdateModal && (
        <Modal
          header="Update Privilege"
          onClose={() => {
            setShowUpdateModal(false);
            setSelectedPrivilege({
              id: "",
              name: "",
            });
          }}
        >
          <form onSubmit={updatePrivilege}>
            <InputField
              boxStyle="mb-[16px]"
              label="Name"
              placeholder="Name of privilege"
              name="name"
              value={selectedPrivilege?.name}
              onChange={(e: any) =>
                setSelectedPrivilege((state: any) => ({
                  ...state,
                  name: e?.target?.value,
                }))
              }
            />

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setShowUpdateModal(false);
                  setSelectedPrivilege({
                    id: "",
                    name: "",
                  });
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}

      {showLoyaltyUpdateModal && (
        <Modal
          header="Update Loyalty"
          onClose={() => {
            closeLoyaltyUpdate();
          }}
        >
          <form onSubmit={updateLoyalty}>
            <InputField
              boxStyle="mb-[16px]"
              label="Program Title"
              name="programTitle"
              value={updateLoyaltyData?.programTitle}
              onChange={(e: any) => onLoyaltyChange(e, true)}
              error={loyaltyErrors?.ProgramTitle[0]}
              onBlur={() => onLoyaltyErrorChange("ProgramTitle", "")}
            />

            <InputField
              boxStyle="mb-[16px]"
              label="Reward Percentage"
              name="rewardPercentage"
              type="number"
              value={updateLoyaltyData?.rewardPercentage}
              onChange={(e: any) => onLoyaltyChange(e, true)}
              error={loyaltyErrors?.RewardPercentage[0]}
              onBlur={() => onLoyaltyErrorChange("RewardPercentage", "")}
            />

            <InputField
              boxStyle="mb-[16px]"
              label="Start Date"
              name="startDate"
              type="datetime-local"
              value={updateLoyaltyData?.startDate}
              onChange={(e: any) => onLoyaltyChange(e, true)}
              error={loyaltyErrors?.StartDate[0]}
              onBlur={() => onLoyaltyErrorChange("StartDate", "")}
            />

            <InputField
              boxStyle="mb-[16px]"
              label="End Date"
              name="endDate"
              type="datetime-local"
              value={updateLoyaltyData?.endDate}
              onChange={(e: any) => onLoyaltyChange(e, true)}
              error={loyaltyErrors?.EndDate[0]}
              onBlur={() => onLoyaltyErrorChange("EndDate", "")}
            />

            <div className="mb-[16px] flex items-center justify-between">
              <fieldset className="flex items-center gap-2">
                <input
                  className="h-[22px] w-[22px] accent-brand"
                  type="radio"
                  name=""
                  id=""
                  checked={isAll === "true"}
                  onChange={() => {
                    setIsAll("true");
                  }}
                />
                <label htmlFor="" className="text-[14px]">
                  Apply to all users
                </label>
              </fieldset>

              <fieldset className="flex items-center gap-2">
                <input
                  className="h-[22px] w-[22px] accent-brand"
                  type="radio"
                  name=""
                  id=""
                  checked={isAll === "false"}
                  onChange={() => {
                    setIsAll("false");
                  }}
                />
                <label htmlFor="" className="text-[14px]">
                  Others
                </label>
              </fieldset>
            </div>

            {isAll === "false" && (
              <MultiSelectField
                multiple
                isRequired
                label="Select Reach"
                name="locationId"
                boxStyle="mb-[24px]"
                value={updateLoyaltyData?.locations}
                options={
                  locations?.length > 0
                    ? [
                        ...locations?.map((location) => ({
                          label: location.name,
                          value: location.id,
                        })),
                      ]
                    : []
                }
                onChange={(e: any) =>
                  setUpdateLoyaltyData((state) => ({
                    ...state,
                    locations: e,
                  }))
                }
                error={loyaltyErrors?.Locations[0]}
                onBlur={() => onLoyaltyErrorChange("Locations", "")}
              />
            )}

            <InputField
              boxStyle="mb-[16px]"
              label="Transaction Volume"
              name="minimumTransactionCount"
              type="number"
              instruction="Equal or greater than"
              value={updateLoyaltyData?.minimumTransactionCount}
              onChange={(e: any) => onLoyaltyChange(e, true)}
              error={loyaltyErrors?.MinimumTransactionCount[0]}
              onBlur={() => onLoyaltyErrorChange("MinimumTransactionCount", "")}
            />

            <InputField
              boxStyle="mb-[16px]"
              label="Transaction Value"
              name="minimumTransactionValue"
              type="number"
              instruction="Equal or greater than"
              value={updateLoyaltyData?.minimumTransactionValue}
              onChange={(e: any) => onLoyaltyChange(e, true)}
              error={loyaltyErrors?.MinimumTransactionValue[0]}
              onBlur={() => onLoyaltyErrorChange("MinimumTransactionValue", "")}
            />

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  closeLoyaltyUpdate();
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default Settings;
