import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
  getDashboardBusinessAnalytics,
  getDashboardStat,
} from "../api/supportapi";
import { getBusinessAnalytics } from "core/helpers/generalHelpers";

type SupportState = {
  isLoading: boolean;
  adminDashboardStats: AdminDashboardStats | {};
  businessAnalytics: BusinessAnalytics[] | [];
  businessStats: any;
  getDashboardStat: (query: SupportStatQuery) => Promise<void>;
  getDashboardAnalytics: (query: AnalyticsQuery) => Promise<void>;
  reset: () => void;
};

const useSupportStore = create<SupportState>()(
  devtools(
    persist(
      (set, get): SupportState => ({
        isLoading: false,
        adminDashboardStats: {},
        businessAnalytics: [],
        businessStats: {
          createdAt: [],
          transactions: {
            successCount: [],
            successAmount: [],
            failedCount: [],
            failedAmount: [],
          },
          signups: {
            male: [],
            female: [],
          },
          revenue: {
            successCount: [],
            successAmount: [],
            failedCount: [],
            failedAmount: [],
          },
          delivery: {
            successCount: [],
            successAmount: [],
            failedCount: [],
            failedAmount: [],
          },
        },
        getDashboardStat: async (query) => {
          set({ isLoading: true });
          var res: UIResponse = await getDashboardStat(query);

          if (res?.isSuccessful) set({ adminDashboardStats: res?.data?.data });

          set({ isLoading: false });
          return;
        },
        getDashboardAnalytics: async (query) => {
          set({ isLoading: true });
          var res: UIResponse = await getDashboardBusinessAnalytics(query);

          if (res?.isSuccessful)
            set({
              businessAnalytics: res?.data?.data,
              businessStats: getBusinessAnalytics(res?.data?.data),
            });

          set({ isLoading: false });
          return;
        },
        reset: () => {
          set({
            isLoading: false,
            adminDashboardStats: {},
            businessStats: {
              createdAt: [],
              transactions: {
                successCount: [],
                successAmount: [],
                failedCount: [],
                failedAmount: [],
              },
              signups: {
                male: [],
                female: [],
              },
              revenue: {
                successCount: [],
                successAmount: [],
                failedCount: [],
                failedAmount: [],
              },
              delivery: {
                successCount: [],
                successAmount: [],
                failedCount: [],
                failedAmount: [],
              },
            },
            businessAnalytics: [],
          });
        },
      }),
      {
        name: "supportStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useSupportStore;
