import { REWARD_TYPES } from "core/consts/systemConst";
import { apicall } from "./apicall";
import { stringToNumber } from "core/helpers/generalHelpers";

export const getReferralRewardsForUser = () => {
  return apicall({
    endpoint: "/referral/admin/getreferralrewards",
    method: "GET",
    auth: true,
  });
};

export const getReferralRewardTypes = () => {
  return apicall({
    endpoint: "/referral/admin/getReferralRewardTypes",
    method: "GET",
    auth: true,
  });
};

export const createReferralReward = (reward: NewReferralReward) => {
  return apicall({
    endpoint: "/referral/admin/createreferralreward",
    method: "POST",
    auth: true,
    body: {
      rewardType: reward?.rewardType,
      initialBonus:
        reward?.rewardType?.toLowerCase() === REWARD_TYPES?.referrerbonus
          ? +reward?.initialBonus
          : 0,
      mainBonus:
        reward?.rewardType?.toLowerCase() === REWARD_TYPES?.referrerbonus
          ? +reward?.mainBonus
          : 0,
      discountValue:
        reward?.rewardType?.toLowerCase() === REWARD_TYPES?.referreduserdiscount
          ? +reward?.discountValue
          : 0,
      discountCount:
        reward?.rewardType?.toLowerCase() === REWARD_TYPES?.referreduserdiscount
          ? +reward?.discountCount
          : 0,
      locationId: reward?.locationId,
    },
  });
};

export const updateReferralReward = (id: string, reward: NewReferralReward) => {
  return apicall({
    endpoint: "/referral/admin/updatereferralreward",
    param: id,
    method: "PUT",
    auth: true,
    body: {
      rewardType: reward?.rewardType,
      initialBonus:
        reward?.rewardType?.toLowerCase() === REWARD_TYPES?.referrerbonus
          ? +reward?.initialBonus
          : 0,
      mainBonus:
        reward?.rewardType?.toLowerCase() === REWARD_TYPES?.referrerbonus
          ? +reward?.mainBonus
          : 0,
      discountValue:
        reward?.rewardType?.toLowerCase() === REWARD_TYPES?.referreduserdiscount
          ? +reward?.discountValue
          : 0,
      discountCount:
        reward?.rewardType?.toLowerCase() === REWARD_TYPES?.referreduserdiscount
          ? +reward?.discountCount
          : 0,
      locationId: reward?.locationId,
    },
  });
};

export const manageReferralReward = (id: string, isActive: boolean) => {
  return apicall({
    endpoint: `/referral/admin/${
      isActive ? "activatereferralreward" : "deactivatereferralreward"
    }`,
    param: id,
    method: "PUT",
    auth: true,
  });
};

export const deleteReferralReward = (id: string) => {
  return apicall({
    endpoint: "/referral/admin/deletereferralreward",
    param: id,
    method: "DELETE",
    auth: true,
  });
};

export const getReferrerList = (query: ReferralQuery) => {
  return apicall({
    endpoint: "/referral/getReferrerList",
    method: "GET",
    pQuery: { ...query },
    auth: true,
  });
};

export const getUsersReferredByReferree = (
  referralId: string,
  query: { pageNumber: number; pageSize: number },
) => {
  return apicall({
    endpoint: "/referral/getUsersReferredByReferre",
    method: "GET",
    param: referralId,
    pQuery: { ...query },
    auth: true,
  });
};

// FOR AGENCIES
export const getReferralAgents = () => {
  return apicall({
    endpoint: "/referral/admin/getReferralAgents",
    method: "GET",
    auth: true,
  });
};

export const addAgency = (agency: NewAgency) => {
  return apicall({
    endpoint: "/referral/admin/profilereferralagent",
    method: "POST",
    auth: true,
    body: {
      ...agency,
      rewardAmount: +agency?.rewardAmount,
    },
  });
};

export const updateAgency = (id: string, agency: NewAgency) => {
  return apicall({
    endpoint: "/referral/admin/updatereferralagentprofile",
    method: "PUT",
    param: id,
    auth: true,
    body: {
      ...agency,
      rewardAmount: stringToNumber(agency?.rewardAmount),
    },
  });
};

export const deleteAgency = (id: string) => {
  return apicall({
    endpoint: "/referral/admin/deletereferralagent",
    param: id,
    method: "DELETE",
    auth: true,
  });
};

export const getAgencyReferralList = (query: ReferralQuery) => {
  return apicall({
    endpoint: "/referral/admin/getAgencyReferrerList",
    method: "GET",
    pQuery: { ...query },
    auth: true,
  });
};

export const getUsersReferredByAgency = (
  referralCode: string,
  query: { pageNumber: number; pageSize: number },
) => {
  return apicall({
    endpoint: "/referral/admin/getUsersReferredByAgency",
    method: "GET",
    param: referralCode,
    pQuery: { ...query },
    auth: true,
  });
};
