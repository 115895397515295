/* eslint-disable eqeqeq */
import Pagination from "core/components/Pagination";
import SelectField from "core/components/formfields/SelectField";
import { productImg, searchImg } from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import { formatCurrency, getDate } from "core/helpers/generalHelpers";
import useAccommodationStore from "core/services/stores/useAccommodationStore";
import useLocationStore from "core/services/stores/useLocationStore";
import useUserStore from "core/services/stores/useUserStore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ViewProperties = () => {
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const location = useUserStore((store) => store.location);

  const propertyList = useAccommodationStore((store) => store.propertyList);
  const getPropertyAction = useAccommodationStore(
    (store) => store.getProperties,
  );
  const deletePropertyAction = useAccommodationStore(
    (store) => store.deleteProperty,
  );
  const togglePropertyAction = useAccommodationStore(
    (store) => store.toggleProperty,
  );
  const updatePropertyAction = useAccommodationStore(
    (store) => store.updatePropertyStatus,
  );

  const [query, setQuery] = useState<AccommodationQuery>({
    apartmentType: "",
    area: "",
    location: location,
    maxiPrice: 0,
    miniPrice: 0,
    searchText: "",
  });

  const onQueryChange = (e: any, isAuto: boolean = false) => {
    const { name, value } = e?.target;

    setQuery((state) => ({
      ...state,
      [name]: value,
    }));

    if (isAuto) {
      getPropertyAction(
        { ...query, [name]: value },
        propertyList?.pageNumber,
        propertyList?.pageSize,
      );
    }
  };

  const deleteProperty = async (item: Property) => {
    if (window.confirm(`Click 'OK' to delete this property.`)) {
      await deletePropertyAction(item?.accommodationId);
    }
  };

  const markAsRented = async (item: Property, status: string) => {
    if (window.confirm(`Click 'OK' to mark this property as ${status}.`)) {
      await updatePropertyAction(item?.accommodationId, status);
    }
  };

  const toggleProperty = async (item: Property, visibility: boolean) => {
    if (
      window.confirm(
        `Click 'OK' to ${visibility ? "show" : "hide"} this property.`,
      )
    ) {
      await togglePropertyAction(item?.accommodationId, visibility);
    }
  };

  const fetchMore = (page: number) => {
    getPropertyAction(query, page, propertyList?.pageSize);
  };

  useEffect(() => {
    getPropertyAction(query, propertyList?.pageNumber, propertyList?.pageSize);
    locations?.length < 1 && getLocationAction();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="mx-auto mb-[28px] w-[95%]">
      <header className="mb-[14px] flex items-center gap-3">
        <img src={productImg} alt="active module" loading="lazy" />
        <h5 className="text-[18px] font-[500] text-brand">
          View/Update Property
        </h5>
      </header>

      <div className="flex items-center justify-between gap-5">
        <div className="flex h-12 w-3/5 items-center justify-between gap-3 rounded-l-[25px] rounded-r-[5px] border-[1px] border-brand bg-white px-2 shadow-md">
          <input
            type="text"
            name="searchText"
            placeholder="Search"
            value={query?.searchText}
            onChange={(e: any) => onQueryChange(e, false)}
            className="w-10/12 bg-transparent px-3 py-1 text-[14px] text-brand outline-none disabled:cursor-not-allowed"
          />
          <button
            type="button"
            onClick={() => {
              getPropertyAction(
                query,
                propertyList?.pageNumber,
                propertyList?.pageSize,
              );
            }}
            className="px-3 py-1 text-black disabled:cursor-not-allowed"
          >
            <img src={searchImg} alt="search" className="h-[25px] w-[25px]" />
          </button>
        </div>

        <div className="w-1/5">
          <SelectField
            selectStyle="!border-brand !bg-[#F2CDFF] !shadow !text-brand"
            name="locationFilter"
            defaultName="Select your Location"
            defaultValue=""
            disabled={!isSuperAdmin}
            options={
              locations?.length > 0
                ? [
                    ...locations?.map((location) => ({
                      name: location?.name,
                      value: location?.name,
                    })),
                  ]
                : []
            }
            value={query?.location}
            onChange={(e: any) => onQueryChange(e, true)}
          />
        </div>

        <div className="w-1/5"></div>
      </div>

      <main className="my-[24px]">
        {propertyList?.properties?.length > 0 ? (
          <>
            {propertyList?.properties.map((item) => (
              <div
                key={item?.accommodationId}
                className="mb-[24px] flex items-center justify-between gap-5 rounded-[5px] border-[1px] border-brand bg-white px-4 py-3 shadow-md"
              >
                <div className="flex w-2/3 items-center gap-10">
                  <div className="flex items-center gap-5">
                    <img
                      src={item?.image}
                      alt={item?.apartmentType}
                      className="h-[68px] w-[100px]"
                    />
                    <div>
                      <p className="mb-[5px] font-[500] capitalize text-brand">
                        {item?.apartmentType}
                      </p>
                      <p className="text-[14px] capitalize text-[#8E8787]">
                        Address: {item?.propertyAddress}
                      </p>{" "}
                      <p className="text-[14px] capitalize text-[#8E8787]">
                        Area: {item?.area}
                      </p>{" "}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div>
                      <p className="mt-[10px] text-[14px] capitalize text-[#8E8787]">
                        Rent: {formatCurrency(item?.rent)}
                      </p>{" "}
                      <p className="text-[14px] capitalize text-[#8E8787]">
                        Status: {item?.status}
                      </p>{" "}
                      <p className="text-[14px] capitalize text-[#8E8787]">
                        Added on: {getDate(item?.createdAt)}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex w-1/3 gap-3">
                  <button
                    disabled={isSuperAdmin}
                    className={`${actionbtn} ${
                      item?.status === "available"
                        ? "!border-[#4CAC00] !bg-[#C6F4A1]"
                        : "!border-[#656565] !bg-[#DADADA]"
                    }`}
                    onClick={() =>
                      markAsRented(
                        item,
                        item?.status == "available" ? "rented" : "available",
                      )
                    }
                  >
                    {item?.status == "available" ? "Available" : "Rented"}
                  </button>

                  <button
                    disabled={isSuperAdmin}
                    className={`${actionbtn} ${
                      item?.visibility
                        ? "!border-[#4CAC00] !bg-[#C6F4A1]"
                        : "!border-[#656565] !bg-[#DADADA]"
                    }`}
                    onClick={() => toggleProperty(item, !item?.visibility)}
                  >
                    {item?.visibility ? "Hide" : "Show"}
                  </button>
                  <Link
                    to={`/accommodation/update-property?accommodationId=${item?.accommodationId}`}
                    className={`${actionbtn} !border-[#6C18A4] !bg-[#DFA1F4]`}
                  >
                    Update
                  </Link>
                  <button
                    disabled={isSuperAdmin}
                    className={`${actionbtn} !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                    onClick={() => deleteProperty(item)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="mb-[24px] flex items-center justify-between gap-5 rounded-[5px] border-[1px] border-brand bg-white px-4 py-3 shadow-md">
            <div>
              <p className="mb-[5px] font-[500] text-[#2E2626]">
                No property yet
              </p>
            </div>
          </div>
        )}

        <Pagination
          pageNumber={propertyList?.pageNumber}
          pageSize={propertyList?.pageSize}
          totalCount={propertyList?.totalCount}
          totalPage={propertyList?.totalPage}
          onFetch={fetchMore}
        />
      </main>
    </section>
  );
};

export default ViewProperties;
