import {
  activeModule,
  home,
  productImg,
  profileuser2,
  settlement,
  userIcon,
  viewOrderImg,
} from "core/consts/images";
import Dashboard from "./views/Dashboard";
import MyEarnings from "./views/MyEarnings";
import Notifications from "./views/Notifications";
import BillingSystem from "./views/trips/BillingSystem";
import ManageOrders from "./views/delivery/ManageOrders";
import ManageTrips from "./views/trips/ManageTrips";
import FleetManagement from "./views/trips/FleetManagement";
import DeliveryAgent from "./views/delivery/DeliveryAgent";
import Drivers from "./views/trips/Drivers";
import DeliveryHistory from "./views/delivery/DeliveryHistory";
import DriverTrips from "./views/trips/DriverTrips";
import DeliverySettings from "./views/delivery/DeliverySettings";
import RiderSettings from "./views/trips/RiderSettings";
import AssignDriverToBus from "./views/trips/AssignDriverToBus";
import PlanTrips from "./views/trips/PlanTrips";
import DriverSchedule from "./views/trips/DriverSchedule";
import ViewOrderDetails from "./views/delivery/ViewOrderDetails";
import ManageTransportOrders from "./views/trips/ManageTransportOrders";

const routes: RoutesType[] = [
  {
    name: "Dashboard",
    layout: "/transportation",
    path: "dashboard",
    icon: home,
    component: <Dashboard />,
    children: [],
  },
  {
    name: "Delivery Agents",
    layout: "/transportation",
    path: "delivery-agents",
    icon: userIcon,
    component: <DeliveryAgent />,
    children: [],
  },
  {
    name: "",
    layout: "/transportation",
    path: "delivery-agents/:agentId",
    icon: userIcon,
    component: <DeliveryHistory />,
    children: [],
  },
  {
    name: "Drivers",
    layout: "/transportation",
    path: "drivers",
    icon: productImg,
    component: <Drivers />,
    children: [],
  },
  {
    name: "",
    layout: "/transportation",
    path: "drivers/:agentId",
    icon: productImg,
    component: <DriverTrips />,
    children: [],
  },
  {
    name: "Transport Billing System",
    layout: "/transportation",
    path: "billing-system",
    icon: settlement,
    component: <BillingSystem />,
    children: [],
  },
  {
    name: "Fleet Management",
    layout: "/transportation",
    path: "fleet-management",
    icon: productImg,
    component: <FleetManagement />,
    children: [],
  },
  {
    name: "",
    layout: "/transportation",
    path: "manage-orders/:orderId",
    icon: viewOrderImg,
    component: <ViewOrderDetails />,
    children: [],
  },
  {
    name: "Manage Orders",
    layout: "/transportation",
    path: "manage-orders",
    icon: activeModule,
    component: <ManageOrders />,
    children: [],
  },
  {
    name: "Manage Trips",
    layout: "/transportation",
    path: "manage-trips",
    icon: activeModule,
    component: <ManageTrips />,
    children: [],
  },
  {
    name: "Manage Transport Orders",
    layout: "/transportation",
    path: "manage-transport-orders",
    icon: activeModule,
    component: <ManageTransportOrders />,
    children: [],
  },
  {
    name: "Delivery Settings ",
    layout: "/transportation",
    path: "delivery-settings",
    icon: profileuser2,
    component: <DeliverySettings />,
    children: [],
  },
  {
    name: "Rider Settings ",
    layout: "/transportation",
    path: "rider-settings",
    icon: profileuser2,
    component: <RiderSettings />,
    children: [],
  },
  {
    name: "",
    layout: "/transportation",
    path: "assign-bus",
    icon: <></>,
    component: <AssignDriverToBus />,
    children: [],
  },
  {
    name: "",
    layout: "/transportation",
    path: "plan-trips",
    icon: <></>,
    component: <PlanTrips />,
    children: [],
  },
  {
    name: "",
    layout: "/transportation",
    path: "driver-schedule",
    icon: <></>,
    component: <DriverSchedule />,
    children: [],
  },
  {
    name: "Notifications",
    layout: "/transportation",
    path: "notifications",
    icon: profileuser2,
    component: <Notifications />,
    children: [],
  },
  {
    name: "My Earnings",
    layout: "/transportation",
    path: "earnings",
    icon: settlement,
    component: <MyEarnings />,
    children: [],
  },
];

export default routes;
