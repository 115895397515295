import eventTicketOrder from "assets/icons/eventTicketOrder.svg";
import eventTickets from "assets/icons/eventTickets.svg";
import fastfoodList from "assets/icons/fastfoodList.svg";
import fastfoodorder from "assets/icons/fastfoodorder.svg";
import hotelBooking from "assets/icons/hotelBooking.svg";
import listedHotel from "assets/icons/listedHotel.svg";
import listedMerchant from "assets/icons/listedMerchant.svg";
import refill from "assets/icons/refill.svg";
import { bookingimg, foodItemImg, orderImg } from "core/consts/images";

export const getSvg = (name: string) => {
  var img = <img src={bookingimg} alt=""></img>;

  switch (name) {
    case "listedFastFood":
      img = <img src={fastfoodList} alt="" />;
      break;
    case "totalOrders":
    case "totalFoodstuffIncome":
    case "totalConvenienceFee":
    case "totalIncome":
      img = <img src={orderImg} alt="" className="mb-[5px]" />;
      break;
    case "merchantCount":
      img = <img src={listedMerchant} alt="" />;
      break;
    case "gasRefillOrders":
      img = <img src={refill} alt="" />;
      break;
    case "listedHotels":
      img = <img src={listedHotel} alt="" />;
      break;
    case "listedEvents":
      img = <img src={eventTickets} alt="" />;
      break;
    case "fastFoodOrders":
      img = <img src={fastfoodorder} alt="" />;
      break;
    case "eventOrders":
      img = <img src={eventTicketOrder} alt="" />;
      break;
    case "hotelOrders":
      img = <img src={hotelBooking} alt="" />;
      break;
    case "listedFooditems":
      img = <img src={foodItemImg} alt="" />;
      break;
    default:
      break;
  }

  return img;
};
