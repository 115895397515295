import Pagination from "core/components/Pagination";
import SelectField from "core/components/formfields/SelectField";
import { productImg, searchImg } from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import {
  MARKETPLACE_CATEGORY,
  MARKETPLACE_CATEGORY_TYPES,
} from "core/consts/systemConst";
import { formatCurrency, getDate } from "core/helpers/generalHelpers";
import useLocationStore from "core/services/stores/useLocationStore";
import useMarketPlaceStore from "core/services/stores/useMarketPlaceStore";
import useUserStore from "core/services/stores/useUserStore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ViewProducts = () => {
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const location = useUserStore((store) => store.location);

  const productList = useMarketPlaceStore((store) => store.productList);
  const getProductAction = useMarketPlaceStore((store) => store.getProducts);
  const deleteProductAction = useMarketPlaceStore(
    (store) => store.deleteProduct,
  );
  const toggleProductAction = useMarketPlaceStore(
    (store) => store.toggleProductVisibility,
  );

  const [query, setQuery] = useState<ProductQuery>({
    category: MARKETPLACE_CATEGORY[0]?.value,
    endDate: "",
    locationFilter: location,
    searchText: "",
    startDate: "",
    userId: "",
    orderStatus: "",
  });

  const onQueryChange = (e: any, isAuto: boolean = false) => {
    const { name, value } = e?.target;

    setQuery((state) => ({
      ...state,
      [name]: value,
    }));

    if (isAuto) {
      getProductAction(
        { ...query, [name]: value },
        productList?.pageNumber,
        productList?.pageSize,
      );
    }
  };

  const deleteProduct = async (product: any) => {
    if (window.confirm(`Click 'OK' to delete ${product?.name}.`)) {
      await deleteProductAction(query?.category, product?.productId);
    }
  };

  const toggleProduct = async (product: any, visibility: boolean) => {
    if (
      window.confirm(
        `Click 'OK' to ${visibility ? "show" : "hide"} ${product?.name}.`,
      )
    ) {
      await toggleProductAction(
        query?.category,
        product?.productId,
        visibility,
      );
    }
  };

  const fetchMore = (page: number) => {
    getProductAction(query, page, productList?.pageSize);
  };

  useEffect(() => {
    getProductAction(query, productList?.pageNumber, productList?.pageSize);
    locations?.length < 1 && getLocationAction();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="mx-auto mb-[28px] w-[95%]">
      <header className="mb-[14px] flex items-center gap-3">
        <img src={productImg} alt="active module" loading="lazy" />
        <h5 className="text-[18px] font-[500] text-brand">
          View/Update Products
        </h5>
      </header>

      <div className="flex items-center justify-between gap-5">
        <div className="flex h-12 w-3/5 items-center justify-between gap-3 rounded-l-[25px] rounded-r-[5px] border-[1px] border-brand bg-white px-2 shadow-md">
          <input
            type="text"
            name="searchText"
            placeholder="Search"
            value={query?.searchText}
            onChange={(e: any) => onQueryChange(e, false)}
            className="w-10/12 bg-transparent px-3 py-1 text-[14px] text-brand outline-none disabled:cursor-not-allowed"
          />
          <button
            type="button"
            onClick={() => {
              getProductAction(
                query,
                productList?.pageNumber,
                productList?.pageSize,
              );
            }}
            className="px-3 py-1 text-black disabled:cursor-not-allowed"
          >
            <img src={searchImg} alt="search" className="h-[25px] w-[25px]" />
          </button>
        </div>

        <div className="w-1/5">
          <SelectField
            selectStyle="!border-brand !bg-[#F2CDFF] !shadow !text-brand"
            name="locationFilter"
            defaultName="Select your Location"
            defaultValue=""
            disabled={!isSuperAdmin}
            options={
              locations?.length > 0
                ? [
                    ...locations?.map((location) => ({
                      name: location?.name,
                      value: location?.name,
                    })),
                  ]
                : []
            }
            value={query?.locationFilter}
            onChange={(e: any) => onQueryChange(e, true)}
          />
        </div>

        <div className="w-1/5">
          <SelectField
            selectStyle="!border-brand !shadow !text-[14px] !rounded-r-[25px]"
            name="category"
            defaultName=""
            defaultValue=""
            options={
              MARKETPLACE_CATEGORY?.length > 0
                ? [
                    ...MARKETPLACE_CATEGORY?.map((cat) => ({
                      name: cat?.name,
                      value: cat?.value,
                    })),
                  ]
                : []
            }
            value={query?.category}
            onChange={(e: any) => onQueryChange(e, true)}
          />
        </div>
      </div>

      <main className="my-[24px]">
        {productList?.products?.length > 0 ? (
          <>
            {productList?.products.map((product: any) => (
              <div
                key={product?.productId}
                className="mb-[24px] flex items-center justify-between gap-5 rounded-[5px] border-[1px] border-brand bg-white px-4 py-3 shadow-md"
              >
                <div className="flex w-2/3 items-center justify-between">
                  <div className="flex items-center gap-5">
                    {product?.category !==
                      MARKETPLACE_CATEGORY_TYPES.Media_Production && (
                      <img
                        src={product?.image}
                        alt={product?.name}
                        className="h-[68px] w-[100px]"
                      />
                    )}
                    <div>
                      <p className="mb-[5px] font-[500] capitalize text-brand">
                        {product?.name}
                      </p>
                      <p className="text-[14px] capitalize text-[#8E8787]">
                        {(() => {
                          switch (product?.category) {
                            case "fast_food":
                              return <>{product?.vendor}</>;
                            case "gas_refill":
                              return <>{product?.location}</>;
                            case "event_ticket":
                              return (
                                <>
                                  <span>{product?.venue}</span> <br />
                                  <span>
                                    {`Event Date: ${product.eventDate}, Time: ${product.eventTime}`}
                                  </span>
                                </>
                              );
                            case "media_production":
                              return <>{product?.serviceProviderAddress}</>;
                            case "hotel":
                              return <>{product?.address}</>;
                            default:
                              return <></>;
                          }
                        })()}
                      </p>{" "}
                      <p className="text-[14px] capitalize text-[#8E8787]">
                        Added on: {getDate(product?.createdAt)}
                      </p>
                    </div>
                  </div>

                  <p className="text-[16px] font-bold">
                    {(() => {
                      switch (product?.category) {
                        case MARKETPLACE_CATEGORY_TYPES.Media_Production:
                          return (
                            <>
                              <span className="text-[14px] capitalize text-[#8E8787]">
                                Service Type: {product?.serviceType}
                              </span>{" "}
                              <br />
                              <span className="text-[14px] capitalize text-[#8E8787]">
                                Black White Print:{" "}
                                {formatCurrency(product?.amountBW)}
                              </span>{" "}
                              <br />
                              <span className="text-[14px] capitalize text-[#8E8787]">
                                Colored Print:{" "}
                                {formatCurrency(product?.amountColored)}
                              </span>
                            </>
                          );
                        case MARKETPLACE_CATEGORY_TYPES.Fast_Food:
                        case MARKETPLACE_CATEGORY_TYPES.Gas_Refill:
                          return <>{formatCurrency(product?.amount)}</>;
                        default:
                          <></>;
                      }
                    })()}
                  </p>
                </div>
                <div className="flex gap-3">
                  <button
                    disabled={isSuperAdmin}
                    className={`${actionbtn} ${
                      product?.visibility
                        ? "!border-[#4CAC00] !bg-[#C6F4A1]"
                        : "!border-[#656565] !bg-[#DADADA]"
                    }`}
                    onClick={() => toggleProduct(product, !product?.visibility)}
                  >
                    {product?.visibility ? "Hide" : "Show"}
                  </button>
                  <Link
                    to={`/marketplace/update-product?category=${product?.category}&productId=${product?.productId}`}
                    className={`${actionbtn} !border-[#6C18A4] !bg-[#DFA1F4]`}
                  >
                    Update
                  </Link>
                  <button
                    disabled={isSuperAdmin}
                    className={`${actionbtn} !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                    onClick={() => deleteProduct(product)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="mb-[24px] flex items-center justify-between gap-5 rounded-[5px] border-[1px] border-brand bg-white px-4 py-3 shadow-md">
            <div>
              <p className="mb-[5px] font-[500] text-[#2E2626]">
                No products yet
              </p>
            </div>
          </div>
        )}

        <Pagination
          pageNumber={productList?.pageNumber}
          pageSize={productList?.pageSize}
          totalCount={productList?.totalCount}
          totalPage={productList?.totalPage}
          onFetch={fetchMore}
        />
      </main>
    </section>
  );
};

export default ViewProducts;
