import { actionbtn } from "core/consts/styling";
import SelectField from "core/components/formfields/SelectField";
import useLocationStore from "core/services/stores/useLocationStore";
import { useEffect, useState } from "react";
import useAdminStore from "core/services/stores/useAdminStore";
import { getDate } from "core/helpers/generalHelpers";
import { USER_ROLES, USER_STATUS } from "core/consts/systemConst";
import { userIcon } from "core/consts/images";

const AdminList = () => {
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const admins = useAdminStore((store) => store.admins);
  const getAdminsAction = useAdminStore((store) => store.getAdmins);
  const manageAdminAction = useAdminStore((store) => store.manageAdmin);

  const [filteredLocation, setFilteredLocation] = useState("");
  const [searchLocation, setSearchLocation] = useState("");

  const manageAdmin = async (
    id: string,
    name: string,
    action: ManageActions,
  ) => {
    if (window.confirm(`Click 'OK' to ${action} ${name}'s account.`)) {
      await manageAdminAction(id, action);
    }
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();
    admins?.length < 1 && getAdminsAction(filteredLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Add view for single admin

  return (
    <section className="mx-auto w-[95%]">
      <header className="mb-[14px] flex items-end gap-3">
        <img src={userIcon} alt="" loading="lazy" />
        <h5 className="text-[18px] font-[500] text-brand">
          View/Update Administrators List
        </h5>
      </header>
      <div className="flex flex-col-reverse gap-5 lg:flex-row">
        <div className="w-full lg:w-8/12">
          <div className="mb-[24px] flex justify-between gap-3 rounded-[25px] border-[1px] border-brand bg-white p-2 shadow-md">
            <input
              type="text"
              disabled
              value={searchLocation}
              onChange={(e: any) => setSearchLocation(e?.target?.value)}
              className="w-10/12 bg-transparent p-1 outline-none disabled:cursor-not-allowed"
            />
            <button
              type="button"
              onClick={() => {
                getAdminsAction(searchLocation);
              }}
              disabled
              className="w-[90px] rounded-[25px] border-[1px] border-brand bg-[#DFA1F4] px-[10px] py-1 text-[12px] text-black disabled:cursor-not-allowed"
            >
              search
            </button>
          </div>
          <div>
            {admins?.length > 0 ? (
              admins.map((admin: Admin) => (
                <div
                  key={admin?.adminId}
                  className="mb-[24px] flex flex-col items-center justify-between gap-5 overflow-x-scroll rounded-[5px] border-[1px] border-brand bg-white px-4 py-3 shadow-md md:flex-row"
                >
                  <div className="w-full md:w-1/2">
                    <p className="mb-[5px] font-[500] capitalize text-[#2E2626]">
                      {admin?.name}
                    </p>
                    <p className="text-[14px] text-[#8E8787]">{admin?.email}</p>
                    <p className="text-[14px] text-[#8E8787]">
                      <span className="capitalize">
                        CampusRunz | {admin.category} |{" "}
                        {getDate(admin?.createdAt)}
                      </span>
                    </p>
                  </div>
                  <div className="flex gap-3">
                    <button
                      disabled={
                        admin?.category === USER_ROLES.super_admin ||
                        admin?.status !== USER_STATUS.pending
                      }
                      className={`${actionbtn} w-[100px] bg-[#F2EAF8] !px-[10px] text-center`}
                      onClick={() =>
                        manageAdmin(admin?.adminId, admin?.name, "approve")
                      }
                    >
                      Verify
                    </button>
                    <button
                      disabled={
                        admin?.category === USER_ROLES.super_admin ||
                        admin?.status === USER_STATUS.pending
                      }
                      className={`${actionbtn} w-[100px] !px-[10px] text-center ${
                        admin?.status === USER_STATUS.suspended
                          ? "bg-green-500"
                          : "bg-[#DFA1F4]"
                      }`}
                      onClick={() =>
                        manageAdmin(
                          admin?.adminId,
                          admin?.name,
                          admin?.status === USER_STATUS.suspended
                            ? "activate"
                            : "deactivate",
                        )
                      }
                    >
                      {admin?.status === USER_STATUS?.suspended
                        ? "Reactivate"
                        : "Suspend"}
                    </button>
                    <button
                      disabled={admin?.category === USER_ROLES.super_admin}
                      className={`${actionbtn} w-[100px] bg-red-500 !px-[10px] text-center text-white`}
                      onClick={() =>
                        manageAdmin(admin?.adminId, admin?.name, "remove")
                      }
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="mb-[24px] flex items-center justify-between gap-5 rounded-[5px] border-[1px] border-brand bg-white px-4 py-3 shadow-md">
                <div>
                  <p className="mb-[5px] font-[500] text-[#2E2626]">
                    No admin yet
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full lg:w-4/12">
          <SelectField
            boxStyle="mb-[18px]"
            selectStyle="!border-brand text-[#615353]"
            name="location"
            defaultName="Select your Location"
            defaultValue=""
            options={
              locations?.length > 0
                ? [
                    ...locations?.map((location) => ({
                      name: location?.name,
                      value: location?.name,
                    })),
                  ]
                : []
            }
            value={filteredLocation}
            onChange={(e: any) => {
              setFilteredLocation(e?.target?.value);
              getAdminsAction(e?.target?.value);
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default AdminList;
