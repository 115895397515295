export const btn =
  "flex items-center justify-center disabled:cursor-not-allowed gap-2 px-[26px] py-3 text-[14px] rounded-[5px] transition duration-300 ease-in-out";

export const step =
  "border-1 flex h-[40px] w-[40px] items-center justify-center rounded-full border text-[14px] font-bold";

export const activeStep = "border-brand bg-brand-200 text-brand";

export const inactiveStep = "border-gray-500 bg-gray-200 text-gray-500";

export const actionbtn =
  "rounded-[25px] text-black border-[1px] border-brand px-[30px] py-[5px] text-[12px] shadow-md disabled:cursor-not-allowed";

export const navbtn =
  "text-brand disabled:cursor-not-allowed bg-white border-[1px] border-[#DFA1F4] py-1 px-4 font-[400] hover:bg-brand hover:text-white";
