import { calender } from "core/consts/images";
import { formatSimpleDate } from "core/helpers/generalHelpers";

function DatePicker({
  name = "",
  value = "",
  onChange = () => {},
  onBlur = () => {},
  className = "",
  label = "",
  id = "",
  disabled = false,
  error = "",
}: {
  name?: string;
  value?: string;
  onChange?: any;
  label?: string;
  disabled?: boolean;
  className?: string;
  id?: string;
  error?: any;
  onBlur?: any;
}) {
  const uniqueId =
    id != null && id.length > 0 ? id : Math.random().toString(36).substring(2);

  return (
    <div
      className={`${className} relative ${disabled && "cursor-not-allowed"}`}
      onClick={() => {
        if (!disabled) {
          const input: HTMLInputElement | any =
            document.getElementById(uniqueId);
          input.showPicker();
        }
      }}
    >
      <p>
        <span className="leading-none">{label}</span> <br />
        <span className="text-[12px]">{formatSimpleDate(value)}</span>
      </p>
      <img
        role="button"
        className={`h-8 w-8 cursor-pointer ${disabled && "cursor-not-allowed"}`}
        src={calender}
        loading="lazy"
        alt="calender"
      />
      <input
        type="date"
        id={uniqueId}
        name={name}
        disabled={disabled}
        className="h-12 p-3"
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        style={{
          visibility: "hidden",
          position: "absolute",
          width: "100%",
        }}
      />

      {error.length > 1 ? (
        <span className="text-[14px] text-red-500">{error}</span>
      ) : null}
    </div>
  );
}

export default DatePicker;
