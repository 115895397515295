import InputField from "core/components/formfields/InputField";
import ActionRow from "core/components/table/Actionrow";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import { actionbtn, btn } from "core/consts/styling";
import SelectField from "core/components/formfields/SelectField";
import { useEffect, useState } from "react";
import { formatCurrency } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import { deleteIcon, editIcon, settlement } from "core/consts/images";
import Modal from "core/components/Modal";
import useTransportationStore from "core/services/stores/useTransportationStore";

const BillingSystem = () => {
  //tfare states
  const tFares = useTransportationStore((store) => store.tFares);
  const trips = useTransportationStore((store) => store.busTrips);
  const getTfaresAction = useTransportationStore((store) => store.getTfares);
  const getTripAction = useTransportationStore((store) => store.getTrips);

  // selected
  const [selected, setSelected] = useState<any>(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  //  actions
  const addUpdateTFareAction = useTransportationStore(
    (store) => store.addUpdateTFare,
  );
  const deleteTFareAction = useTransportationStore(
    (store) => store.deleteTFare,
  );

  // form state
  const [newTFare, setTFare] = useState<NewTransportFare>({
    convenienceFee: 0,
    fare: 0,
    tripId: "",
  });

  // error state
  const [errors, setErrors] = useState({
    ConvenienceFee: [],
    Fare: [],
    TripId: [],
  });

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;
    setTFare((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const resetErrors = () => {
    setErrors({
      ConvenienceFee: [],
      Fare: [],
      TripId: [],
    });
  };

  // validation
  const validation = (data: NewTransportFare) => {
    var isValid = true;

    //TODO: Add validations

    return isValid;
  };

  // add update
  const addUpdateTFare = async (e: any, isUpdate: boolean = false) => {
    e.preventDefault();

    if (validation(newTFare)) {
      var res = await addUpdateTFareAction(
        newTFare,
        isUpdate ? selected?.id : "",
      );

      if (res?.isSuccessful) {
        setTFare({
          convenienceFee: 0,
          fare: 0,
          tripId: "",
        });

        if (isUpdate) {
          setSelected(null);
          setShowUpdateModal(false);
        }
      } else {
        setErrors((state) => ({
          ...state,
          ...res?.data?.errors,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const deleteTFare = async (tFare: TransportFare) => {
    if (window.confirm(`Click 'OK' to delete this Transport Fare`)) {
      await deleteTFareAction(tFare?.id);
    }
  };

  useEffect(() => {
    tFares?.length < 1 && getTfaresAction();
    trips?.length < 1 && getTripAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mx-auto mb-[28px] w-[95%]">
        <header className="mb-[28px] flex items-center gap-3">
          <img src={settlement} loading="lazy" alt="" />
          <h5 className="text-[18px] font-[500] text-brand">
            Transport Billing System
          </h5>
        </header>
        <div className="mb-[28px] flex flex-col gap-5 lg:flex-row">
          <div className="w-full lg:w-4/12">
            <div className="w-full rounded-[5px] bg-white p-5">
              <div className="mb-[28px] text-center">
                <p>Add Transport Fare</p>
              </div>
              <form onSubmit={(e: any) => addUpdateTFare(e, false)}>
                <SelectField
                  boxStyle="mb-[24px]"
                  name="tripId"
                  defaultName="Select Trip"
                  defaultValue=""
                  options={
                    trips?.length > 0
                      ? [
                          ...trips?.map((st) => ({
                            name: st?.tripName,
                            value: st?.id,
                          })),
                        ]
                      : []
                  }
                  value={newTFare?.tripId}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.TripId[0]}
                  onBlur={() => onErrorChange("TripId", [])}
                />

                <InputField
                  boxStyle="mb-[24px]"
                  label="Fare"
                  placeholder="Fare"
                  name="fare"
                  type="number"
                  value={newTFare?.fare}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.Fare[0]}
                  onBlur={() => onErrorChange("Fare", [])}
                />

                <InputField
                  boxStyle="mb-[24px]"
                  placeholder="Convenience Fee"
                  label="Convenience Fee"
                  name="convenienceFee"
                  type="number"
                  value={newTFare?.convenienceFee}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.ConvenienceFee[0]}
                  onBlur={() => onErrorChange("ConvenienceFee", [])}
                />

                <button className={`${btn} w-full bg-brand text-white`}>
                  Submit
                </button>
              </form>
            </div>
          </div>
          <div className="w-full lg:w-8/12">
            <Table
              headers={[
                "Trip",
                "Route (Start Point - End Point)",
                "Fare",
                "Convenience",
                "Action",
              ]}
            >
              {tFares?.length > 0 ? (
                tFares?.map((fare) => (
                  <tr key={fare?.id}>
                    <Row value={fare?.tripName} />
                    <Row value={`${fare?.startPoint} - ${fare?.endPoint}`} />
                    <Row value={formatCurrency(fare?.fare)} />
                    <Row value={formatCurrency(fare?.convenienceFee)} />
                    <ActionRow style={`flex items-center justify-center gap-3`}>
                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] border-[#59960B] bg-[#D9FEAA] p-2"
                        onClick={() => {
                          resetErrors();
                          setSelected(fare);
                          setShowUpdateModal(true);
                          setTFare({
                            convenienceFee: fare?.convenienceFee,
                            fare: fare?.fare,
                            tripId: "",
                          });
                        }}
                      >
                        <img
                          src={editIcon}
                          loading="lazy"
                          className="h-[16px]"
                          alt=""
                        />
                      </button>
                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] border-[#FF0000] bg-[#FFC8C8] p-2"
                        onClick={() => deleteTFare(fare)}
                      >
                        <img
                          src={deleteIcon}
                          className="h-[16px]"
                          loading="lazy"
                          alt=""
                        />
                      </button>
                    </ActionRow>
                  </tr>
                ))
              ) : (
                <tr>
                  <Row colSpan={5} value="no transport fare has been added." />
                </tr>
              )}
            </Table>
          </div>
        </div>
      </div>

      {showUpdateModal && (
        <Modal
          header="Update Transport Fare"
          onClose={() => {
            setShowUpdateModal(false);
            setTFare({
              convenienceFee: 0,
              fare: 0,
              tripId: "",
            });
            resetErrors();
          }}
        >
          <form onSubmit={(e: any) => addUpdateTFare(e, true)}>
            <InputField
              boxStyle="mb-[24px]"
              label="Fare"
              placeholder="Fare"
              name="fare"
              type="number"
              value={newTFare?.fare}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.Fare[0]}
              onBlur={() => onErrorChange("Fare", [])}
            />

            <InputField
              boxStyle="mb-[24px]"
              placeholder="Convenience Fee"
              label="Convenience Fee"
              name="convenienceFee"
              type="number"
              value={newTFare?.convenienceFee}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.ConvenienceFee[0]}
              onBlur={() => onErrorChange("ConvenienceFee", [])}
            />

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setShowUpdateModal(false);
                  setTFare({
                    convenienceFee: 0,
                    fare: 0,
                    tripId: "",
                  });
                  resetErrors();
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default BillingSystem;
