import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
  AddUpdateMarketProduct,
  AddUpdateService,
  addUpdateDirectoryService,
  addUpdateFastFoodVendor,
  cancelMarketService,
  deleteFastFoodVendor,
  deleteFastFoodVendorImage,
  deleteMarketProduct,
  deleteMarketProductImage,
  deleteMarketService,
  getDirectoryService,
  getFastFoodVendorById,
  getFastFoodVendors,
  getMarketPlaceOrders,
  getMarketPlaceProductById,
  getMarketPlaceProducts,
  getMarketPlaceServices,
  getMarketSalesHistory,
  getMarketServiceById,
  getMarketplaceAnalytics,
  getMarketplaceOrderById,
  getOrderStatus,
  toggleMarketProductVisibility,
  toggleMarketServiceVisibility,
} from "../api/marketplaceapi";
import notification from "core/helpers/notification";
import { getProcessStatus } from "core/helpers/generalHelpers";
import { updateOrderStatus } from "../api/userapi";

type StoreState = {
  isLoading: boolean;
  analytics: MarketPlaceAnalytics;
  product: any;
  order: any;
  service: MarketPlaceService | any;
  productList: {
    products: any;
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPage: number;
  };
  serviceList: {
    services: any[];
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPage: number;
  };
  fastFoodVendors: FastFoodVendor[];
  fastFoodVendor: any;
  orderList: {
    orders: any[];
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPage: number;
  };
  serviceDirectory: DirectoryService | any;
  serviceDirectoryList: {
    serviceDirectories: any[];
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPage: number;
  };
  salesHistory: SalesHistory;
  getProducts: (
    query: ProductQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  getProductById: (category: string, productId: string) => Promise<void>;
  getServices: (
    query: ProductQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  getServiceById: (serviceId: string) => Promise<void>;
  getFastFoodVendors: () => Promise<void>;
  getFastFoodVendorById: (vendorId: string) => Promise<void>;
  getOrders: (
    query: ProductQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  getSalesHistory: (query: DateLocationQuery) => Promise<void>;
  getDashboardAnalytics: (query: DateLocationQuery) => Promise<void>;
  addUpdateProduct: (product: any, productId: string) => Promise<UIResponse>;
  toggleProductVisibility: (
    category: string,
    productId: string,
    visibility: boolean,
  ) => Promise<void>;
  deleteProduct: (category: string, productId: string) => Promise<void>;
  deleteProductImage: (
    category: string,
    productId: string,
    image: string,
  ) => Promise<void>;
  addUpdateService: (
    service: NewMarketplaceService,
    serviceId: string,
  ) => Promise<UIResponse>;
  toggleServiceVisibility: (
    serviceId: string,
    visibility: boolean,
  ) => Promise<void>;
  cancelService: (serviceId: string, comment: string) => Promise<void>;
  deleteService: (serviceId: string) => Promise<void>;
  addUpdateFastFoodVendor: (
    vendor: NewFastFoodVendor,
    vendorId: string,
  ) => Promise<UIResponse>;
  deleteFastFoodVendor: (vendorId: string) => Promise<void>;
  deleteFastFoodImage: (
    category: string,
    vendorId: string,
    image: string,
  ) => Promise<void>;
  updateOrderStatus: (
    orderId: string,
    orderStatus: ManageOrder,
    reason: string,
  ) => Promise<UIResponse>;
  getDirectoryService: (pageNumber: number, pageSize: number) => Promise<void>;
  addUpdateDirectoryService: (
    service: NewDirectoryService,
    serviceId: string,
  ) => Promise<UIResponse>;
  getOrderById: (category: string, orderId: string) => Promise<void>;
  getOrderStatus: (orderId: string) => Promise<UIResponse>;
  resetOrder: () => void;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  product: null,
  service: {
    serviceId: "",
    category: "",
    serviceFee: 0,
    businessName: "",
    phoneNumbers: "",
    email: "",
    address: "",
    description: "",
    twitter: "",
    instagram: "",
    visibility: false,
    image: [],
    uploadedImageList: [],
    createdAt: "",
  },
  analytics: {
    listedFastFood: 0,
    totalOrders: 0,
    merchantCount: 0,
    gasRefillOrders: 0,
    listedHotels: 0,
    listedEvents: 0,
    fastFoodOrders: 0,
    eventOrders: 0,
    hotelOrders: 0,
  },
  productList: {
    products: [],
    pageNumber: 1,
    pageSize: 15,
    totalCount: 1,
    totalPage: 1,
  },
  orderList: {
    orders: [],
    pageNumber: 1,
    pageSize: 15,
    totalCount: 1,
    totalPage: 1,
  },
  fastFoodVendor: {},
  serviceList: {
    services: [],
    pageNumber: 1,
    pageSize: 15,
    totalCount: 1,
    totalPage: 1,
  },
  fastFoodVendors: [],
  salesHistory: {
    header: {
      totalIncome: 0,
      totalCommission: 0,
    },
    sales: [],
  },
  serviceDirectoryList: {
    serviceDirectories: [],
    pageNumber: 1,
    pageSize: 15,
    totalCount: 1,
    totalPage: 1,
  },
  serviceDirectory: {},
  order: null,
};

const useMarketPlaceStore = create<StoreState>()(
  devtools(
    persist(
      (set, get): StoreState => ({
        ...defaultState,
        getProductById: async (category, productId) => {
          set({ isLoading: true });

          var res: UIResponse = await getMarketPlaceProductById(
            category,
            productId,
          );

          if (res?.isSuccessful) {
            set({
              product: res?.data?.data,
              isLoading: false,
            });
          } else {
            set({ product: null, isLoading: false });
          }
        },
        getProducts: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getMarketPlaceProducts(
            query,
            pageNumber,
            pageSize,
          );

          if (res?.isSuccessful) {
            set({
              productList: {
                products: res?.data?.data?.data,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: res?.data?.data?.pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
          return;
        },
        getServiceById: async (serviceId) => {
          set({ isLoading: true });

          var res: UIResponse = await getMarketServiceById(serviceId);

          if (res?.isSuccessful) {
            set({
              service: res?.data?.data,
              isLoading: false,
            });
          } else {
            set({ service: null, isLoading: false });
          }
        },
        getServices: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getMarketPlaceServices(
            query,
            pageNumber,
            pageSize,
          );

          if (res?.isSuccessful) {
            set({
              serviceList: {
                services: res?.data?.data?.data,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: res?.data?.data?.pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
          return;
        },
        getFastFoodVendors: async () => {
          set({ isLoading: true });

          var res: UIResponse = await getFastFoodVendors();

          if (res?.isSuccessful) {
            set({
              fastFoodVendors: res?.data?.data,
            });
          }

          set({ isLoading: false });
          return;
        },
        getFastFoodVendorById: async (vendorId) => {
          set({ isLoading: true });

          var res: UIResponse = await getFastFoodVendorById(vendorId);

          if (res?.isSuccessful) {
            set({
              fastFoodVendor: res?.data?.data,
              isLoading: false,
            });
          } else {
            set({ fastFoodVendor: null, isLoading: false });
          }
        },
        getOrders: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getMarketPlaceOrders(
            query,
            pageNumber,
            pageSize,
          );

          if (res?.isSuccessful) {
            set({
              orderList: {
                orders: res?.data?.data?.orders,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: res?.data?.data?.pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
          return;
        },
        getOrderStatus: async (orderId) => {
          set({ isLoading: true });

          var res: UIResponse = await getOrderStatus(orderId);

          if (res?.isSuccessful) {
            notification({
              type: "info",
              title: res?.data?.data?.message ?? "Order Status",
              message: `Order status is ${res?.data?.data?.orderStatus}`,
            });
          }

          set({ isLoading: false });
          return res;
        },
        getOrderById: async (category, orderId) => {
          set({ isLoading: true });

          var res: UIResponse = await getMarketplaceOrderById(
            category,
            orderId,
          );

          if (res?.isSuccessful) {
            set({
              order: res?.data?.data,
            });
          }
          set({ isLoading: false });
        },
        getSalesHistory: async (query) => {
          set({ isLoading: true });

          var res: UIResponse = await getMarketSalesHistory(query);

          if (res?.isSuccessful) {
            set({
              salesHistory: {
                sales: res?.data?.data?.data,
                header: res?.data?.data?.header,
              },
            });
          }

          set({ isLoading: false });
        },
        getDashboardAnalytics: async (query) => {
          set({ isLoading: true });

          var res: UIResponse = await getMarketplaceAnalytics(query);

          if (res?.isSuccessful) {
            set({
              analytics: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        addUpdateProduct: async (product, productId) => {
          set({ isLoading: true });

          var res: UIResponse = await AddUpdateMarketProduct(
            product,
            productId,
          );

          if (res?.isSuccessful && productId?.length > 1) {
            get().getProductById(product?.category, productId);
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        deleteProduct: async (category, productId) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteMarketProduct(category, productId);

          if (res?.isSuccessful) {
            set((state) => ({
              productList: {
                ...state.productList,
                products: state?.productList.products?.filter(
                  (prod: any) => prod?.productId !== productId,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Product has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        deleteProductImage: async (category, productId, image) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteMarketProductImage(
            category,
            productId,
            image,
          );

          if (res?.isSuccessful) {
            get().getProductById(category, productId);
          }

          notification({
            message: res?.isSuccessful
              ? "Image has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        toggleProductVisibility: async (category, productId, visibility) => {
          set({ isLoading: true });

          var res: UIResponse = await toggleMarketProductVisibility(
            category,
            productId,
            visibility,
          );

          if (res?.isSuccessful) {
            set((state) => ({
              productList: {
                ...state.productList,
                products: state?.productList.products?.map((prod: any) =>
                  prod?.productId === productId
                    ? { ...prod, visibility: visibility }
                    : prod,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        addUpdateService: async (service, serviceId) => {
          set({ isLoading: true });

          var res: UIResponse = await AddUpdateService(service, serviceId);

          if (res?.isSuccessful && serviceId?.length > 1) {
            get().getServiceById(serviceId);
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        deleteService: async (serviceId) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteMarketService(serviceId);

          if (res?.isSuccessful) {
            set((state) => ({
              serviceList: {
                ...state.serviceList,
                services: state?.serviceList.services?.filter(
                  (prod: any) => prod?.serviceId !== serviceId,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Service has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        toggleServiceVisibility: async (serviceId, visibility) => {
          set({ isLoading: true });

          var res: UIResponse = await toggleMarketServiceVisibility(
            serviceId,
            visibility,
          );

          if (res?.isSuccessful) {
            set((state) => ({
              serviceList: {
                ...state.serviceList,
                services: state?.serviceList.services?.map((prod: any) =>
                  prod?.serviceId === serviceId
                    ? { ...prod, visibility: visibility }
                    : prod,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        addUpdateFastFoodVendor: async (vendor, vendorId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateFastFoodVendor(vendor, vendorId);

          if (res?.isSuccessful && vendorId?.length > 1) {
            get().getFastFoodVendorById(vendorId);
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        deleteFastFoodVendor: async (vendorId) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteFastFoodVendor(vendorId);

          if (res?.isSuccessful) {
            set((state) => ({
              fastFoodVendors: state?.fastFoodVendors?.filter(
                (vendor: any) => vendor?.vendorId !== vendorId,
              ),
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Fast food vendor has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        deleteFastFoodImage: async (category, vendorId, image) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteFastFoodVendorImage(
            category,
            vendorId,
            image,
          );

          if (res?.isSuccessful) {
            get().getFastFoodVendorById(vendorId);
          }

          notification({
            message: res?.isSuccessful
              ? "Image has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        updateOrderStatus: async (orderId, orderStatus, reason) => {
          set({ isLoading: true });

          var res: UIResponse = await updateOrderStatus(
            orderId,
            orderStatus,
            reason,
          );

          var processStatus = "";
          if (res?.isSuccessful) {
            processStatus = getProcessStatus(orderStatus);

            //TODO: Add order interface
            set((state) => ({
              orderList: {
                ...state.orderList,
                orders: state?.orderList?.orders?.map((order: any) =>
                  order?.orderId === orderId
                    ? { ...order, status: processStatus }
                    : order,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? `Order status has been updated successfully to ${processStatus}`
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        getDirectoryService: async (pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getDirectoryService(pageNumber, pageSize);

          if (res?.isSuccessful) {
            set({
              serviceDirectoryList: {
                serviceDirectories: res?.data?.data,
                pageNumber: res?.data?.pageNumber,
                pageSize: res?.data?.pageSize,
                totalCount: res?.data?.totalCount,
                totalPage: res?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
          return;
        },
        addUpdateDirectoryService: async (service, serviceId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateDirectoryService(
            service,
            serviceId,
          );

          if (res?.isSuccessful && serviceId?.length > 1) {
            get().getDirectoryService(1, 20);
          }

          notification({
            message: res?.data?.message,
            // eslint-disable-next-line eqeqeq
            type: res?.data?.code == "200" ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        cancelService: async (serviceId, comment) => {
          set({ isLoading: true });

          var res: UIResponse = await cancelMarketService(serviceId, comment);

          if (res?.isSuccessful) {
            set((state) => ({
              serviceList: {
                ...state.serviceList,
                services: state?.serviceList.services?.map(
                  (prod: MarketPlaceService) =>
                    prod?.serviceId === serviceId
                      ? { ...prod, cancelFlag: true, cancelComment: comment }
                      : prod,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        resetOrder: () => {
          set({ order: null });
        },
        reset: () => {
          set({ ...defaultState });
        },
      }),
      {
        name: "marketPlaceStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useMarketPlaceStore;
