import routes from "./routes";
import { useNavigate } from "react-router-dom";
import useUserStore from "core/services/stores/useUserStore";
import { useEffect } from "react";
import { USER_ROLES } from "core/consts/systemConst";
import ModuleLayout from "core/components/ModuleLayout";

export default function SuperAdmin() {
  const token = useUserStore((store) => store.token);
  const user: any = useUserStore((store) => store.user);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      token == null ||
      token?.length < 1 ||
      user?.role !== USER_ROLES.super_admin
    ) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ModuleLayout defaultRoute="/superadmin/dashboard" routes={routes} />;
}
