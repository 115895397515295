import InputField from "core/components/formfields/InputField";
import ActionRow from "core/components/table/Actionrow";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import { actionbtn, btn } from "core/consts/styling";
import SelectField from "core/components/formfields/SelectField";
import useLocationStore from "core/services/stores/useLocationStore";
import { useEffect, useState } from "react";
import { getDate } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import { STATES_AND_LGAS } from "core/consts/statesandlgas";
import {
  deleteIcon,
  editIcon,
  eyeclose,
  eyeopen,
  locImg,
} from "core/consts/images";
import Modal from "core/components/Modal";

const LocationManagement = () => {
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);
  const addLocationAction = useLocationStore((store) => store.addLocation);
  const updateLocationAction = useLocationStore(
    (store) => store.updateLocation,
  );
  const deleteLocationAction = useLocationStore(
    (store) => store.deleteLocation,
  );
  const toggleLocationAction = useLocationStore(
    (store) => store.toggleLocation,
  );

  const [lgas, setLgas] = useState<any>([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [newLocation, setNewLocation] = useState<NewCampusLocation>({
    name: "",
    state: "",
    lga: "",
    address: "",
  });

  const [selectedLocation, setSelectedLocation] = useState<CampusLocation>({
    active: false,
    createdAt: "",
    id: "",
    name: "",
    state: "",
    lga: "",
    address: "",
  });

  const [errors, setErrors] = useState<any>({
    Name: [""],
    State: [""],
    LGA: [""],
    Address: [""],
  });

  const resetErrors = () => {
    setErrors({
      Name: [""],
      State: [""],
      LGA: [""],
      Address: [""],
    });
  };

  const setLga = (state: string) => {
    var filteredArea = STATES_AND_LGAS?.filter((nga) => nga?.state === state);

    if (filteredArea?.length > 0) {
      setLgas(filteredArea[0]?.lgas);
    } else {
      setLgas([]);
    }
  };

  const onFormChange = (e: any, action: string = "add") => {
    const { name, value } = e.target;

    if (name === "state") {
      setLga(value);
    }

    switch (action) {
      case "update":
        setSelectedLocation((state) => ({
          ...state,
          [name]: value,
        }));
        break;
      default:
        setNewLocation((state) => ({
          ...state,
          [name]: value,
        }));
        break;
    }
  };

  const validateLocation = (location: NewCampusLocation | CampusLocation) => {
    var isValid = true;

    if (location?.name?.length < 1) {
      setErrors((state: any) => ({
        ...state,
        Name: ["Name of school is required"],
      }));
      isValid = false;
    }

    if (location?.state?.length < 1) {
      setErrors((state: any) => ({
        ...state,
        State: ["Please select a state"],
      }));
      isValid = false;
    }

    if (location?.lga?.length < 1) {
      setErrors((state: any) => ({
        ...state,
        LGA: ["Please select a Local government area"],
      }));
      isValid = false;
    }

    if (location?.address?.length < 1) {
      setErrors((state: any) => ({
        ...state,
        Address: ["Address is required"],
      }));
      isValid = false;
    }

    return isValid;
  };

  const addLocation = async (e: any) => {
    e.preventDefault();

    if (validateLocation(newLocation)) {
      var res = await addLocationAction(newLocation);

      if (res?.isSuccessful) {
        setNewLocation({
          name: "",
          state: "",
          lga: "",
          address: "",
        });
      } else {
        if (res?.data?.errors != null) {
          setErrors(res?.data?.errors);
        }
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const updateLocation = async (e: any) => {
    e.preventDefault();

    if (validateLocation(selectedLocation)) {
      var res = await updateLocationAction(selectedLocation);

      if (!res?.isSuccessful) {
        if (res?.data?.errors != null) {
          setErrors(res?.data?.errors);
        }
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const toggleLocation = async (location: CampusLocation) => {
    if (
      window.confirm(
        `Click 'OK' to ${
          location?.active ? "deactivate" : "activate"
        } ${location?.name}`,
      )
    ) {
      await toggleLocationAction(location?.id, !location?.active);
    }
  };

  const deleteLocation = async (location: CampusLocation) => {
    if (window.confirm(`Click 'OK' to delete location: ${location?.name}`)) {
      await deleteLocationAction(location?.id);
    }
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mx-auto w-[95%]">
        <header className="mb-[28px] flex items-center gap-3">
          <img src={locImg} loading="lazy" alt="" />
          <h5 className="text-[18px] font-[500] text-brand">
            Location Management
          </h5>
        </header>
        <div className="flex flex-col gap-5 lg:flex-row">
          <div className="w-full lg:w-4/12">
            <div className="w-full rounded-[5px] bg-white p-5">
              <div className="mb-[28px] text-center">
                <p>Add New Location</p>
              </div>
              <form onSubmit={addLocation}>
                <InputField
                  boxStyle="mb-[24px]"
                  placeholder="Name of School"
                  name="name"
                  value={newLocation?.name}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.Name[0]}
                  onBlur={() => {
                    setErrors((state: any) => ({
                      ...state,
                      Name: [],
                    }));
                  }}
                />
                <SelectField
                  boxStyle="mb-[24px]"
                  name="state"
                  defaultName="Select State"
                  defaultValue=""
                  options={[
                    ...STATES_AND_LGAS?.map((st) => ({
                      name: st?.state,
                      value: st?.state,
                    })),
                  ]}
                  value={newLocation?.state}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.State[0]}
                  onBlur={() => {
                    setErrors((state: any) => ({
                      ...state,
                      State: [],
                    }));
                  }}
                />
                <SelectField
                  boxStyle="mb-[24px]"
                  name="lga"
                  defaultName="Select Local Government"
                  defaultValue=""
                  options={
                    lgas?.length > 0
                      ? [
                          ...lgas?.map((lg: string) => ({
                            name: lg,
                            value: lg,
                          })),
                        ]
                      : []
                  }
                  value={newLocation?.lga}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.LGA[0]}
                  onBlur={() => {
                    setErrors((state: any) => ({
                      ...state,
                      LGA: [],
                    }));
                  }}
                />
                <InputField
                  boxStyle="mb-[24px]"
                  placeholder="Address"
                  name="address"
                  value={newLocation?.address}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.Address[0]}
                  onBlur={() => {
                    setErrors((state: any) => ({
                      ...state,
                      Address: [],
                    }));
                  }}
                />
                <button className={`${btn} w-full bg-brand text-white`}>
                  Submit
                </button>
              </form>
            </div>
          </div>
          <div className="w-full lg:w-8/12">
            <Table
              headers={[
                "Name of School",
                "State",
                "Date Created",
                "Status",
                "Action",
              ]}
            >
              {locations?.length > 0 ? (
                locations?.map((location: CampusLocation) => (
                  <tr key={location?.id}>
                    <Row value={location?.name} style={`min-w-[150px]`} />
                    <Row value={location?.state} />
                    <Row value={getDate(location?.createdAt)} />
                    <Row value={location?.active ? "Active" : "Inactive"} />
                    <ActionRow style={`flex items-center justify-center gap-3`}>
                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] border-brand bg-[#F3CFFF] p-2"
                        onClick={() => toggleLocation(location)}
                      >
                        <img
                          src={location?.active ? eyeclose : eyeopen}
                          loading="lazy"
                          className="h-[16px]"
                          alt=""
                        />
                      </button>
                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] border-[#59960B] bg-[#D9FEAA] p-2"
                        onClick={() => {
                          resetErrors();
                          setSelectedLocation(location);
                          setLga(location?.state);
                          setShowUpdateModal(true);
                        }}
                      >
                        <img
                          src={editIcon}
                          loading="lazy"
                          className="h-[16px]"
                          alt=""
                        />
                      </button>
                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] border-[#FF0000] bg-[#FFC8C8] p-2"
                        onClick={() => deleteLocation(location)}
                      >
                        <img
                          src={deleteIcon}
                          className="h-[16px]"
                          loading="lazy"
                          alt=""
                        />
                      </button>
                    </ActionRow>
                  </tr>
                ))
              ) : (
                <tr>
                  <Row colSpan={5} value="no location has been added." />
                </tr>
              )}
            </Table>
          </div>
        </div>
      </div>

      {showUpdateModal && (
        <Modal
          header="Update Location"
          onClose={() => {
            setShowUpdateModal(false);
            setSelectedLocation({
              active: false,
              createdAt: "",
              id: "",
              name: "",
              state: "",
              lga: "",
              address: "",
            });
            resetErrors();
          }}
        >
          <form onSubmit={updateLocation}>
            <InputField
              boxStyle="mb-[24px]"
              label="Name of School"
              placeholder="Name of School"
              name="name"
              value={selectedLocation?.name}
              onChange={(e: any) => onFormChange(e, "update")}
              error={errors?.Name[0]}
              onBlur={() => {
                setErrors((state: any) => ({
                  ...state,
                  Name: [],
                }));
              }}
            />

            <SelectField
              boxStyle="mb-[24px]"
              name="state"
              label="State"
              defaultName="Select State"
              defaultValue=""
              options={[
                ...STATES_AND_LGAS?.map((st) => ({
                  name: st?.state,
                  value: st?.state,
                })),
              ]}
              value={selectedLocation?.state}
              onChange={(e: any) => onFormChange(e, "update")}
              error={errors?.State[0]}
              onBlur={() => {
                setErrors((state: any) => ({
                  ...state,
                  State: [],
                }));
              }}
            />

            <SelectField
              boxStyle="mb-[24px]"
              name="lga"
              label="LGA"
              defaultName="Select Local Government"
              defaultValue=""
              options={
                lgas?.length > 0
                  ? [
                      ...lgas?.map((lg: string) => ({
                        name: lg,
                        value: lg,
                      })),
                    ]
                  : []
              }
              value={selectedLocation?.lga}
              onChange={(e: any) => onFormChange(e, "update")}
              error={errors?.LGA[0]}
              onBlur={() => {
                setErrors((state: any) => ({
                  ...state,
                  LGA: [],
                }));
              }}
            />

            <InputField
              boxStyle="mb-[24px]"
              label="Address"
              placeholder="Address"
              name="address"
              value={selectedLocation?.address}
              onChange={(e: any) => onFormChange(e, "update")}
              error={errors?.Address[0]}
              onBlur={() => {
                setErrors((state: any) => ({
                  ...state,
                  Address: [],
                }));
              }}
            />

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setShowUpdateModal(false);
                  setSelectedLocation({
                    active: false,
                    createdAt: "",
                    id: "",
                    name: "",
                    state: "",
                    lga: "",
                    address: "",
                  });
                  resetErrors();
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default LocationManagement;
