import {
  activeModule,
  home,
  productImg,
  profileuser2,
  settlement,
  viewOrderImg,
} from "core/consts/images";
import Dashboard from "./views/Dashboard";
import Notifications from "./views/Notifications";
import MyEarnings from "./views/MyEarnings";
import CreateProperty from "./views/CreateProperty";
import UpdateProperty from "./views/UpdateProperty";
import ViewProperties from "./views/ViewProperties";
import ViewInspectionRequests from "./views/ViewInspectionRequests";

const routes: RoutesType[] = [
  {
    name: "Dashboard",
    layout: "/accommodation",
    path: "dashboard",
    icon: home,
    component: <Dashboard />,
    children: [],
  },
  {
    name: "Create Property",
    layout: "/accommodation",
    path: "create-property",
    icon: activeModule,
    component: <CreateProperty />,
    children: [],
  },
  {
    name: "",
    layout: "/accommodation",
    path: "update-property",
    icon: activeModule,
    component: <UpdateProperty />,
    children: [],
  },
  {
    name: "View Property",
    layout: "/accommodation",
    path: "view-property",
    icon: productImg,
    component: <ViewProperties />,
    children: [],
  },
  {
    name: "View Inspection Request",
    layout: "/accommodation",
    path: "view-inspection-request",
    icon: viewOrderImg,
    component: <ViewInspectionRequests />,
    children: [],
  },
  {
    name: "Notifications",
    layout: "/accommodation",
    path: "notifications",
    icon: profileuser2,
    component: <Notifications />,
    children: [],
  },
  {
    name: "My Earnings",
    layout: "/accommodation",
    path: "earnings",
    icon: settlement,
    component: <MyEarnings />,
    children: [],
  },
];

export default routes;
