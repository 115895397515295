import { logo } from "core/consts/images";

export default function Loader({ message = "" }: { message?: string }) {
  return (
    <div
      className={`no-scrollbar overlay fixed left-0 top-0 z-40 h-screen w-screen overflow-auto bg-[#6C18A4BF] bg-opacity-10`}
      style={{
        minHeight: "calc(100vh - 72px)",
        zIndex: 850,
      }}
    >
      <div className="flex h-full w-full flex-col items-center justify-center gap-1">
        <img
          src={logo}
          loading="lazy"
          alt="campusrunz"
          className="animate-bounce"
        />
        <p className="text-black">
          {message?.length > 1 ? message : "Loading..."}
        </p>
      </div>
    </div>
  );
}
