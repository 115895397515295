import Modal from "core/components/Modal";
import Pagination from "core/components/Pagination";
import DatePicker from "core/components/formfields/DatePicker";
import SelectField from "core/components/formfields/SelectField";
import TextField from "core/components/formfields/TextField";
import { addIcon, directoryImg, searchImg } from "core/consts/images";
import { actionbtn, btn } from "core/consts/styling";
import { formatCurrency, getDateTime } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useLocationStore from "core/services/stores/useLocationStore";
import useMarketPlaceStore from "core/services/stores/useMarketPlaceStore";
import useUserStore from "core/services/stores/useUserStore";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const DirectoryServices = () => {
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const navigate = useNavigate();
  const location = useUserStore((store) => store.location);

  const serviceList = useMarketPlaceStore((store) => store.serviceList);
  const getServicesAction = useMarketPlaceStore((store) => store.getServices);
  const deleteServiceAction = useMarketPlaceStore(
    (store) => store.deleteService,
  );
  const toggleServiceAction = useMarketPlaceStore(
    (store) => store.toggleServiceVisibility,
  );
  const cancelServiceAction = useMarketPlaceStore(
    (store) => store.cancelService,
  );

  const [selectedService, setSelectedService] = useState<
    any | MarketPlaceService
  >(null);
  const [comment, setComment] = useState("");
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);

  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const [query, setQuery] = useState<ProductQuery>({
    category: "",
    endDate: "",
    startDate: "",
    locationFilter: location,
    searchText: "",
    userId: "",
    orderStatus: "",
  });

  const onQueryChange = (e: any, isAuto: boolean = true) => {
    const { name, value } = e?.target;

    setQuery((state) => ({
      ...state,
      [name]: value,
    }));

    if (isAuto) {
      getServicesAction(
        { ...query, [name]: value },
        serviceList?.pageNumber,
        serviceList?.pageSize,
      );
    }
  };

  const deleteService = async (service: MarketPlaceService) => {
    if (window.confirm(`Click 'OK' to delete ${service?.businessName}.`)) {
      await deleteServiceAction(service?.serviceId);
    }
  };

  const toggleService = async (
    service: MarketPlaceService,
    visibility: boolean,
  ) => {
    if (
      window.confirm(
        `Click 'OK' to ${
          visibility ? "show" : "hide"
        } ${service?.businessName}.`,
      )
    ) {
      await toggleServiceAction(service?.serviceId, visibility);
    }
  };

  const cancelService = async (e: any) => {
    e.preventDefault();

    if (comment.length > 0) {
      await cancelServiceAction(selectedService?.serviceId, comment);

      setComment("");
    } else {
      notification({
        type: "warning",
        message: "Please include comments",
      });
    }
  };

  const fetchMore = (page: number) => {
    getServicesAction(query, page, serviceList?.pageSize);
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();
    getServicesAction(query, serviceList?.pageNumber, serviceList?.pageSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="mx-auto mb-[28px] w-[95%]">
        <div className="mb-[24px] flex items-center justify-between">
          <header className="flex items-center gap-3">
            <img src={directoryImg} alt="active module" loading="lazy" />
            <h5 className="text-[18px] font-[500] text-brand">
              Directory Services
            </h5>
          </header>

          {!isSuperAdmin && (
            <Link
              to={"/marketplace/create-service"}
              className="flex w-[220px] items-center justify-between px-4 py-3 text-[14px] text-brand"
            >
              <img src={addIcon} loading="lazy" alt="" />
              <span>Add A Service Provider</span>
            </Link>
          )}
        </div>

        <div className="flex items-center justify-between gap-5">
          <div className="border-[1px ] flex h-12 w-2/5 items-center justify-between gap-3 rounded-l-[25px] rounded-r-[5px] border-brand bg-white px-2 shadow-md">
            <input
              type="text"
              name="searchText"
              placeholder="Search"
              value={query?.searchText}
              onChange={(e: any) => onQueryChange(e, false)}
              className="w-10/12 bg-transparent px-3 py-1 text-[14px] text-brand outline-none disabled:cursor-not-allowed"
            />
            <button
              type="button"
              onClick={() => {
                getServicesAction(
                  query,
                  serviceList?.pageNumber,
                  serviceList?.pageSize,
                );
              }}
              className="px-3 py-1 text-black disabled:cursor-not-allowed"
            >
              <img src={searchImg} alt="search" className="h-[25px] w-[25px]" />
            </button>
          </div>

          <div className="w-1/5">
            <SelectField
              selectStyle="!border-brand !shadow !text-[14px] !bg-brandLight !text-brand"
              name="locationFilter"
              defaultName="Select your Location"
              disabled={!isSuperAdmin}
              defaultValue=""
              options={
                locations?.length > 0
                  ? [
                      ...locations?.map((location) => ({
                        name: location?.name,
                        value: location?.name,
                      })),
                    ]
                  : []
              }
              value={query?.locationFilter}
              onChange={(e: any) => onQueryChange(e)}
            />
          </div>

          <DatePicker
            name="startDate"
            label="Start Date"
            value={query?.startDate}
            className={`flex h-12 w-1/5 items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
            onChange={(e: any) => {
              onQueryChange(e);
            }}
          />

          <DatePicker
            name="endDate"
            label="End Date"
            value={query?.endDate}
            className={`flex h-12 w-1/5 items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
            onChange={(e: any) => {
              onQueryChange(e);
            }}
          />
        </div>

        <main className="my-[24px]">
          {serviceList?.services?.length > 0 ? (
            serviceList?.services.map((service: MarketPlaceService) => (
              <div
                key={service?.serviceId}
                className="mb-[24px] flex items-center justify-between gap-5 overflow-x-auto rounded-[5px] border-[1px] border-brand bg-white px-4 py-3 shadow-md"
              >
                <div className="flex w-2/3 items-center gap-5">
                  <div className="w-[150px] overflow-hidden">
                    <img
                      src={service?.image}
                      alt={service?.businessName}
                      className="h-[68px] w-[150px] rounded-[4px] bg-brandgray-200"
                    />
                  </div>

                  <div className="">
                    <p
                      onClick={() => {
                        setSelectedService(service);
                        setShowViewModal(true);
                      }}
                      className="mb-[5px] font-[500] capitalize text-brand"
                    >
                      {service?.businessName}
                    </p>
                    <p className="text-[14px] capitalize text-[#8E8787]">
                      Category: {service?.category}
                    </p>
                    <p className="text-[14px] capitalize text-[#8E8787]">
                      Address: {service?.address}
                    </p>{" "}
                    <p className="text-[14px] capitalize text-[#8E8787]">
                      Phone: {service?.phoneNumbers}
                    </p>{" "}
                    <p className="text-[14px] capitalize text-[#8E8787]">
                      Email: {service?.email}
                    </p>{" "}
                    <p className="text-[14px] capitalize text-[#8E8787]">
                      Added on: {getDateTime(service?.createdAt)}
                    </p>
                  </div>

                  <div className="ml-10">
                    <p className="font-semibold">
                      {formatCurrency(service?.serviceFee)}
                    </p>
                  </div>
                </div>

                <div className="flex gap-3">
                  <button
                    className={`${actionbtn} !border-[#6C18A4] !bg-[#DFA1F4]`}
                    onClick={() =>
                      navigate(
                        `/marketplace/update-service?serviceId=${service?.serviceId}`,
                      )
                    }
                  >
                    Update
                  </button>
                  <button
                    disabled={isSuperAdmin}
                    className={`${actionbtn} !border-red-500 !bg-red-500 !text-white`}
                    onClick={() => {
                      deleteService(service);
                    }}
                  >
                    Delete
                  </button>
                  <button
                    disabled={isSuperAdmin}
                    className={`${actionbtn} !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                    onClick={() => {
                      setSelectedService(service);
                      setShowCommentModal(true);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={isSuperAdmin}
                    className={`${actionbtn} ${
                      service?.visibility
                        ? "!border-[#4CAC00] !bg-[#C6F4A1]"
                        : "!border-[#656565] !bg-[#DADADA]"
                    }`}
                    onClick={() => toggleService(service, !service?.visibility)}
                  >
                    {service?.visibility ? "Hide" : "Show"}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="mb-[24px] flex items-center justify-between gap-5 rounded-[5px] border-[1px] border-brand bg-white px-4 py-3 shadow-md">
              <div>
                <p className="mb-[5px] font-[500] text-[#2E2626]">
                  No services yet
                </p>
              </div>
            </div>
          )}

          <Pagination
            pageNumber={serviceList?.pageNumber}
            pageSize={serviceList?.pageSize}
            totalCount={serviceList?.totalCount}
            totalPage={serviceList?.totalPage}
            onFetch={fetchMore}
          />
        </main>
      </section>

      {showCommentModal && (
        <Modal
          header="Cancel Service"
          onClose={() => {
            setShowCommentModal(false);
            setSelectedService(null);
            setComment("");
          }}
        >
          <form onSubmit={cancelService}>
            <TextField
              boxStyle="mb-[24px]"
              placeholder="Reason for Cancellation"
              name="reason"
              value={comment}
              onChange={(e: any) => setComment(e?.target?.value)}
            />

            <button className={`${btn} w-full bg-brand text-white`}>
              Submit
            </button>
          </form>
        </Modal>
      )}

      {showViewModal && (
        <Modal
          header="Directory Service"
          onClose={() => {
            setShowViewModal(false);
            setSelectedService(null);
          }}
        >
          <div>
            <div
              key={selectedService?.serviceId}
              className="flex flex-col items-center"
            >
              <img
                src={selectedService?.image}
                className="h-[200px]"
                alt={selectedService?.businessName}
                loading="lazy"
              />
              <div className="my-[16px] text-center text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  Business Name
                </span>
                <br />
                {selectedService?.businessName}
              </div>
              <div className="my-[16px] text-center text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  Service Fee
                </span>
                <br />
                {formatCurrency(selectedService?.serviceFee)}
              </div>
              <div className="mb-[16px] text-center text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  Description
                </span>
                <br />
                {selectedService?.description}
              </div>
              <div className="mb-[16px] text-center text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  Address
                </span>
                <br />
                {selectedService?.address}
              </div>
              <div className="mb-[16px] text-center text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  Email
                </span>
                <br />
                {selectedService?.email}
              </div>
              <div className="mb-[16px] text-center text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  Phone Number(s)
                </span>
                <br />
                {selectedService?.phoneNumbers}
              </div>
              <div className="mb-[16px] text-center text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  Is Visible
                </span>
                <br />
                {selectedService?.visibility ? "Yes" : "No"}
              </div>
              <div className="mb-[16px] text-center text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  Is Cancelled
                </span>
                <br />
                {selectedService?.cancelFlag ? "Yes" : "No"}
              </div>
              {selectedService?.cancelFlag && (
                <div className="mb-[16px] text-center text-[14px] font-bold">
                  <span className="text-[12px] font-[400] text-[#868686]">
                    Cancellation Comment
                  </span>
                  <br />
                  {selectedService?.cancelComment}
                </div>
              )}
            </div>
            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setShowViewModal(false);
                  setSelectedService(null);
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DirectoryServices;
