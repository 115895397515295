import SelectField from "core/components/formfields/SelectField";
import Accordion from "core/components/Accordion";
import { admin, analytics, home, userswhite } from "core/consts/images";
import useLocationStore from "core/services/stores/useLocationStore";
import { useState, useEffect } from "react";
import useUserStore from "core/services/stores/useUserStore";
import useSupportStore from "core/services/stores/useSupportStore";
import DatePicker from "core/components/formfields/DatePicker";
import LineChart from "core/components/charts/LineChart";
import InputField from "core/components/formfields/InputField";
import DashStat from "core/components/Analytics/DashStat";

const Dashboard = () => {
  const user: any = useUserStore((store) => store.user);

  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);
  const [statQuery, setStatQuery] = useState<SupportStatQuery>({
    endDate: "",
    startDate: "",
    location: "",
    supportEmail: user?.email,
  });

  const [analyticsQuery, setAnalyticsQuery] = useState<AnalyticsQuery>({
    endDate: "",
    location: "",
    startDate: "",
  });

  const dashboardStats: any = useSupportStore(
    (store) => store.adminDashboardStats,
  );
  const businessStats: any = useSupportStore((store) => store.businessStats);
  const getDashboardStatAction = useSupportStore(
    (store) => store.getDashboardStat,
  );
  const getDashboardAnalyticsAction = useSupportStore(
    (store) => store.getDashboardAnalytics,
  );

  const onQueryChange = (e: any) => {
    const { name, value } = e?.target;

    setStatQuery((state) => ({
      ...state,
      [name]: value,
    }));

    getDashboardStatAction({
      ...statQuery,
      [name]: value,
    });
  };

  const onAnalyticsQueryChange = (e: any) => {
    const { name, value } = e?.target;

    setAnalyticsQuery((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const search = async (e: any) => {
    e.preventDefault();
    await getDashboardAnalyticsAction(analyticsQuery);
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();
    getDashboardStatAction(statQuery);
    getDashboardAnalyticsAction(analyticsQuery);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mx-auto w-[95%]">
      <header className="mb-[14px] flex items-center gap-3">
        <img src={home} alt="active module" loading="lazy" />
        <h5 className="text-[18px] font-[500] text-brand">
          Hello {user?.firstname}!
        </h5>
      </header>

      <div className="mb-[34px] grid grid-cols-2 gap-5 md:grid-cols-4">
        <SelectField
          boxStyle="col-span-2"
          name="location"
          defaultName="Select your Location"
          defaultValue=""
          options={
            locations?.length > 0
              ? [
                  ...locations?.map((location) => ({
                    name: location?.name,
                    value: location?.name,
                  })),
                ]
              : []
          }
          value={statQuery?.location}
          onChange={(e: any) => {
            onQueryChange(e);
          }}
        />
        <DatePicker
          name="startDate"
          label="Start Date"
          value={statQuery?.startDate}
          className={`flex h-12 w-full items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
          onChange={(e: any) => {
            onQueryChange(e);
          }}
        />
        <DatePicker
          name="endDate"
          label="End Date"
          value={statQuery?.endDate}
          className={`flex h-12 w-full items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
          onChange={(e: any) => {
            onQueryChange(e);
          }}
        />
        <DashStat
          name="Total Customers"
          value={dashboardStats?.totalCustomers}
          img={userswhite}
        />
        <DashStat
          name="Total Administrators"
          value={dashboardStats?.totalAdmins}
          img={admin}
        />
        <DashStat
          name="Today's Transaction Count"
          value={dashboardStats?.todaysTransactions}
        />
        <DashStat
          name="Today's Sign up"
          value={dashboardStats?.todaysSignups}
        />
        <DashStat name="Logged Issues" value={dashboardStats?.loggedIssues} />
        <DashStat name="Pending Issues" value={dashboardStats?.pendingIssues} />
        <DashStat
          name="Resolved Issues"
          value={dashboardStats?.resolvedIssues}
        />
        <DashStat
          name="Unassigned Issues"
          value={dashboardStats?.unassignedIssues}
        />
      </div>
      <div>
        <header className="mb-[14px] flex items-end gap-3">
          <img src={analytics} alt="" loading="lazy" />
          <h5 className="text-[14px] font-[500] text-brand">
            Performance & Trends Analytics
          </h5>
        </header>

        <section>
          <form
            className="mb-[28px] flex items-end justify-between gap-2 overflow-x-scroll rounded-[5px] border border-t-[3px] border-brand bg-white p-3"
            onSubmit={search}
          >
            <SelectField
              label="Select Location"
              defaultName="Select Location"
              defaultValue=""
              boxStyle="min-w-[160px]"
              selectStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="location"
              options={
                locations?.length > 0
                  ? [
                      ...locations?.map((location) => ({
                        name: location?.name,
                        value: location?.name,
                      })),
                    ]
                  : []
              }
              value={analyticsQuery?.location}
              onChange={onAnalyticsQueryChange}
            />

            <InputField
              label="Start Date"
              type="date"
              boxStyle="min-w-[120px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="startDate"
              value={analyticsQuery?.startDate}
              onChange={onAnalyticsQueryChange}
            />

            <InputField
              label="End  Date"
              type="date"
              boxStyle="min-w-[120px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="endDate"
              value={analyticsQuery?.endDate}
              onChange={onAnalyticsQueryChange}
            />

            <div className="flex items-center gap-3">
              <button className="flex h-[50px] items-center justify-center gap-3 rounded-[5px] border border-brand bg-[#DFA1F4] p-3  px-8 text-white">
                <span className="text-[14px text-brand">Search</span>
              </button>
            </div>
          </form>
        </section>
        <Accordion
          id="signups"
          title="Sign ups"
          bodyStyle="bg-white !mt-[0px] border-b-1 border-b rounded-b-[5px] p-5"
        >
          <LineChart
            id="signupdata"
            series={[
              {
                name: "No of Female",
                data: [...businessStats?.signups?.female],
              },
              {
                name: "No of Male",
                data: [...businessStats?.signups?.male],
              },
            ]}
            colors={["#6c18a4", "#dfa1f4"]}
            categories={[...businessStats?.createdAt]}
            xLabel=""
            yAxis={[
              {
                title: {
                  style: {
                    color: "#6C18A4",
                  },
                  text: "No of Sign ups",
                },
              },
            ]}
          />
        </Accordion>
        <Accordion
          id="transactions"
          title="Transactions"
          bodyStyle="bg-white !mt-[0px] border-b-1 border-b rounded-b-[5px] p-5"
        >
          <LineChart
            id="transactionsdata"
            series={[
              {
                name: "Success count",
                data: [...businessStats?.transactions?.successCount],
              },
              {
                name: "Success amount",
                data: [...businessStats?.transactions?.successAmount],
              },
              {
                name: "Failed count",
                data: [...businessStats?.transactions?.failedCount],
              },
              {
                name: "Failed amount",
                data: [...businessStats?.transactions?.failedAmount],
              },
            ]}
            colors={["#56b26b", "#1e993b", "#e68a94", "#D80303"]}
            categories={[...businessStats?.createdAt]}
            xLabel=""
            yAxis={[
              {
                seriesName: "Success count",
                title: {
                  style: {
                    color: "#56b26b",
                  },
                  text: "success count",
                },
              },
              {
                seriesName: "Success amount",
                opposite: true,
                title: {
                  style: {
                    color: "#1e993b",
                  },
                  text: "success amount",
                },
              },
              {
                seriesName: "Failed count",
                title: {
                  style: {
                    color: "#e68a94",
                  },
                  text: "failed count",
                },
              },
              {
                seriesName: "Failed amount",
                opposite: true,
                title: {
                  style: {
                    color: "#D80303",
                  },
                  text: "failed amount",
                },
              },
            ]}
          />
        </Accordion>
        <Accordion
          id="revenue"
          title="Revenue"
          bodyStyle="bg-white !mt-[0px] border-b-1 border-b rounded-b-[5px] p-5"
        >
          <LineChart
            id="revenuedata"
            series={[
              {
                name: "Success count",
                data: [...businessStats?.revenue?.successCount],
              },
              {
                name: "Success amount",
                data: [...businessStats?.revenue?.successAmount],
              },
              {
                name: "Failed count",
                data: [...businessStats?.revenue?.failedCount],
              },
              {
                name: "Failed amount",
                data: [...businessStats?.revenue?.failedAmount],
              },
            ]}
            colors={["#56b26b", "#1e993b", "#e68a94", "#D80303"]}
            categories={[...businessStats?.createdAt]}
            xLabel=""
            yAxis={[
              {
                seriesName: "Success count",
                title: {
                  style: {
                    color: "#56b26b",
                  },
                  text: "success count",
                },
              },
              {
                seriesName: "Success amount",
                opposite: true,
                title: {
                  style: {
                    color: "#1e993b",
                  },
                  text: "success amount",
                },
              },
              {
                seriesName: "Failed count",
                title: {
                  style: {
                    color: "#e68a94",
                  },
                  text: "failed count",
                },
              },
              {
                seriesName: "Failed amount",
                opposite: true,
                title: {
                  style: {
                    color: "#D80303",
                  },
                  text: "failed amount",
                },
              },
            ]}
          />
        </Accordion>
        <Accordion
          id="delivery"
          title="Delivery"
          bodyStyle="bg-white !mt-[0px] border-b-1 border-b rounded-b-[5px] p-5"
        >
          <LineChart
            id="deliverydata"
            series={[
              {
                name: "Success count",
                data: [...businessStats?.delivery?.successCount],
              },
              {
                name: "Success amount",
                data: [...businessStats?.delivery?.successAmount],
              },
              {
                name: "Failed count",
                data: [...businessStats?.delivery?.failedCount],
              },
              {
                name: "Failed amount",
                data: [...businessStats?.delivery?.failedAmount],
              },
            ]}
            colors={["#56b26b", "#1e993b", "#e68a94", "#D80303"]}
            categories={[...businessStats?.createdAt]}
            xLabel=""
            yAxis={[
              {
                seriesName: "Success count",
                title: {
                  style: {
                    color: "#56b26b",
                  },
                  text: "success count",
                },
              },
              {
                seriesName: "Success amount",
                opposite: true,
                title: {
                  style: {
                    color: "#1e993b",
                  },
                  text: "success amount",
                },
              },
              {
                seriesName: "Failed count",
                title: {
                  style: {
                    color: "#e68a94",
                  },
                  text: "failed count",
                },
              },
              {
                seriesName: "Failed amount",
                opposite: true,
                title: {
                  style: {
                    color: "#D80303",
                  },
                  text: "failed amount",
                },
              },
            ]}
          />
        </Accordion>
      </div>
    </div>
  );
};

export default Dashboard;
