import { useEffect, useState } from "react";
import FileUploadField from "core/components/formfields/FileUploadField";
import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import TextField from "core/components/formfields/TextField";
import { activeModule } from "core/consts/images";
import { BUSINESS_CATEGORIES } from "core/consts/businessCategories";
import { actionbtn, btn } from "core/consts/styling";
import useMarketPlaceStore from "core/services/stores/useMarketPlaceStore";
import { useSearchParams } from "react-router-dom";
import notification from "core/helpers/notification";
import useUserStore from "core/services/stores/useUserStore";
import { isEmail } from "core/helpers/generalHelpers";

interface Props {
  isUpdate: boolean;
}

const ServiceForm = ({ isUpdate = false }: Props) => {
  const [files, setFiles] = useState([]);

  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const service = useMarketPlaceStore((store) => store.service);

  const [, setServiceId] = useState("");

  const getServiceAction = useMarketPlaceStore((store) => store.getServiceById);
  const addUpdateServiceAction = useMarketPlaceStore(
    (store) => store.addUpdateService,
  );
  const deleteImageAction = useMarketPlaceStore(
    (store) => store.deleteProductImage,
  );
  const [searchParams]: any = useSearchParams();

  const defaultService = {
    category: "",
    businessName: "",
    phoneNumbers: "",
    email: "",
    description: "",
    address: "",
    twitter: "",
    instagram: "",
    imagePath: [],
    filess: [],
    serviceFee: 0,
  };

  const [newService, setNewService] = useState<NewMarketplaceService>({
    ...defaultService,
  });

  const [errors, setErrors] = useState({
    Category: [],
    BusinessName: [],
    PhoneNumbers: [],
    Email: [],
    Description: [],
    Address: [],
    Twitter: [],
    Instagram: [],
    ServiceFee: [],
    ImagePath: [],
    Filess: [],
    PaymentOption: [],
    DurationInMonth: [],
  });

  const removeImage = (index: number) => {
    setFiles([...files.filter((file, i) => i !== index)]);
  };

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const deleteImage = async (url: any) => {
    if (window.confirm(`Click 'OK' to delete this image from the server.`)) {
      await deleteImageAction("service", service?.serviceId, url);
    }
  };

  const validation = (data: NewMarketplaceService, files: any[]) => {
    var isValid = true;

    if (data?.businessName?.length < 1) {
      isValid = false;
      onErrorChange("BusinessName", ["Business name is required"]);
    }

    if (data?.category?.length < 1) {
      isValid = false;
      onErrorChange("Category", ["Business Category is required"]);
    }

    if (data?.phoneNumbers?.length < 11) {
      isValid = false;
      onErrorChange("PhoneNumbers", [
        "A Valid 11 digits Business Phone number is required",
      ]);
    }

    if (data?.description?.length < 1) {
      isValid = false;
      onErrorChange("Description", ["Business description is required"]);
    }

    if (!isEmail(data?.email)) {
      isValid = false;
      onErrorChange("Email", ["A valid email is required"]);
    }

    if (data?.address?.length < 1) {
      isValid = false;
      onErrorChange("Address", ["Business address is required"]);
    }

    if (!isUpdate && files.length < 1) {
      isValid = false;
      notification({
        message: "Please upload service logo",
        type: "danger",
      });
    }

    return isValid;
  };

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;

    setNewService((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const addUpdateService = async (e: any) => {
    e.preventDefault();

    if (validation(newService, files)) {
      var res = await addUpdateServiceAction(
        {
          ...newService,
          filess: files,
        },
        isUpdate ? service?.serviceId : "",
      );

      if (res?.isSuccessful) {
        if (!isUpdate) {
          setNewService({
            ...defaultService,
          });
        }

        setFiles([]);
      } else {
        setErrors((state) => ({
          ...state,
          ...res?.data?.errors,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    var serviceId = searchParams.get("serviceId");

    if (isUpdate && serviceId?.length > 0) {
      setServiceId(serviceId);
      getServiceAction(serviceId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams && isUpdate]);

  useEffect(() => {
    isUpdate &&
      service != null &&
      setNewService({
        category: service?.category,
        businessName: service?.businessName,
        phoneNumbers: service?.phoneNumbers,
        email: service?.email,
        description: service?.description != null ? service?.description : "",
        address: service?.address != null ? service?.address : "",
        twitter: service?.twitter != null ? service?.twitter : "",
        instagram: service?.instagram != null ? service?.instagram : "",
        serviceFee: service?.serviceFee,
        imagePath: [],
        filess: [],
      });
  }, [isUpdate, service]);

  return (
    <section className="mx-auto mb-[28px] w-[95%]">
      <header className="mb-[14px] flex items-center gap-3">
        <img src={activeModule} alt="active module" loading="lazy" />
        <h5 className="text-[18px] font-[500] capitalize text-brand">
          {isUpdate && service
            ? `Update ${service && service?.businessName}`
            : "Create Service"}
        </h5>
      </header>
      <form className="flex gap-5" onSubmit={addUpdateService}>
        <div className="w-3/5">
          <InputField
            boxStyle="mb-[18px]"
            placeholder="Business Name"
            label="Business Name"
            name="businessName"
            isRequired
            value={newService?.businessName}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.BusinessName[0]}
            onBlur={() => onErrorChange("BusinessName", [])}
          />

          <SelectField
            boxStyle="mb-[18px]"
            selectStyle="!border-brand text-[#615353]"
            name="category"
            isRequired
            label="Business Category"
            defaultName="Select Business Category"
            defaultValue=""
            options={
              BUSINESS_CATEGORIES?.length > 0
                ? [
                    ...BUSINESS_CATEGORIES?.map((cat: string) => ({
                      name: cat,
                      value: cat,
                    })),
                  ]
                : []
            }
            value={newService?.category}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.Category[0]}
            onBlur={() => onErrorChange("Category", [])}
          />

          <InputField
            boxStyle="mb-[24px]"
            placeholder="Phone Number"
            label="Business Phone Number"
            name="phoneNumbers"
            isRequired
            value={newService?.phoneNumbers}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.PhoneNumbers[0]}
            onBlur={() => onErrorChange("PhoneNumbers", [])}
          />

          <TextField
            boxStyle="mb-[18px]"
            name="description"
            placeholder="Business Description"
            label="Business Description"
            value={newService?.description}
            onChange={(e: any) => onFormChange(e)}
            isRequired
            error={errors?.Description[0]}
            onBlur={() => onErrorChange("Description", [])}
            rows={5}
          />

          <InputField
            boxStyle="mb-[24px]"
            label="Service Fee"
            name="serviceFee"
            type="number"
            value={newService?.serviceFee}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.ServiceFee[0]}
            onBlur={() => onErrorChange("ServiceFee", [])}
          />

          <InputField
            boxStyle="mb-[24px]"
            name="email"
            placeholder="Email Address"
            label="Email Address"
            type="email"
            isRequired
            value={newService?.email}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.Email[0]}
            onBlur={() => onErrorChange("Email", [])}
          />

          <InputField
            boxStyle="mb-[24px]"
            placeholder="Address"
            label="Address"
            name="address"
            value={newService?.address}
            isRequired
            onChange={(e: any) => onFormChange(e)}
            error={errors?.Address[0]}
            onBlur={() => onErrorChange("Address", [])}
          />

          <InputField
            boxStyle="mb-[24px]"
            placeholder="X/Twitter Handle"
            label="X/Twitter Handle"
            name="twitter"
            value={newService?.twitter}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.Twitter[0]}
            onBlur={() => onErrorChange("Twitter", [])}
          />

          <InputField
            boxStyle="mb-[24px]"
            label="Instagram Handle"
            placeholder="Instagram Handle"
            name="instagram"
            value={newService?.instagram}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.Instagram[0]}
            onBlur={() => onErrorChange("Instagram", [])}
          />

          <button
            disabled={isSuperAdmin}
            className={`${btn} w-full bg-brand text-white`}
          >
            Submit
          </button>
        </div>
        <div className="w-2/6">
          <>
            <FileUploadField
              multiple={true}
              boxStyle="w-3/5"
              label="Upload Pictures"
              name="signature"
              onChange={setFiles}
              showUploadedImages={false}
            />

            <div className="mt-[16px] grid grid-cols-2 gap-3 ">
              {files?.length > 0 &&
                files.map((file: any, index: number) => (
                  <div
                    key={file?.name + index}
                    className="relative overflow-hidden rounded-[5px] border border-brandLight"
                  >
                    <img src={URL.createObjectURL(file)} alt={`logo${index}`} />
                    <button
                      type="button"
                      className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                      onClick={() => removeImage(index)}
                    >
                      Delete
                    </button>
                  </div>
                ))}

              {isUpdate &&
                service?.uploadedImageList?.length > 0 &&
                service?.uploadedImageList?.map(
                  (image: string, index: number) => (
                    <div
                      key={index}
                      className="relative overflow-hidden rounded-[5px] border border-brandLight"
                    >
                      <img src={image} alt={`logo${index}`} />
                      <button
                        type="button"
                        className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                        onClick={() => deleteImage(image)}
                      >
                        Delete
                      </button>
                    </div>
                  ),
                )}
            </div>
          </>
        </div>
      </form>
    </section>
  );
};

export default ServiceForm;
