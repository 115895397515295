import Pagination from "core/components/Pagination";
import SelectField from "core/components/formfields/SelectField";
import { FOOD_CATEGORY } from "core/consts/businessCategories";
import { productImg, searchImg } from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import { formatCurrency, getDate } from "core/helpers/generalHelpers";
import useFoodStuffStore from "core/services/stores/useFoodStuffStore";
import useLocationStore from "core/services/stores/useLocationStore";
import useUserStore from "core/services/stores/useUserStore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ViewFoodItem = () => {
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const location = useUserStore((store) => store.location);

  const foodItemList = useFoodStuffStore((store) => store.foodItemList);
  const getFoodItemsAction = useFoodStuffStore((store) => store.getFoodItems);
  const deleteFoodItemAction = useFoodStuffStore(
    (store) => store.deleteFoodItem,
  );
  const toggleFoodItemAction = useFoodStuffStore(
    (store) => store.toggleFoodItem,
  );

  const [query, setQuery] = useState<FoodItemQuery>({
    endDate: "",
    startDate: "",
    locationFilter: location,
    searchText: "",
    userId: "",
    category: "",
    orderStatus: ""
  });

  const onQueryChange = (e: any, isAuto: boolean = false) => {
    const { name, value } = e?.target;

    setQuery((state) => ({
      ...state,
      [name]: value,
    }));

    if (isAuto) {
      getFoodItemsAction(
        { ...query, [name]: value },
        foodItemList?.pageNumber,
        foodItemList?.pageSize,
      );
    }
  };

  const deleteFoodItem = async (item: FoodItem) => {
    if (window.confirm(`Click 'OK' to delete ${item?.name}.`)) {
      await deleteFoodItemAction(item?.foodItemId);
    }
  };

  const toggleProduct = async (item: FoodItem, visibility: boolean) => {
    if (
      window.confirm(
        `Click 'OK' to ${visibility ? "show" : "hide"} ${item?.name}.`,
      )
    ) {
      await toggleFoodItemAction(item?.foodItemId, visibility);
    }
  };

  const fetchMore = (page: number) => {
    getFoodItemsAction(query, page, foodItemList?.pageSize);
  };

  useEffect(() => {
    getFoodItemsAction(query, foodItemList?.pageNumber, foodItemList?.pageSize);
    locations?.length < 1 && getLocationAction();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="mx-auto mb-[28px] w-[95%]">
      <header className="mb-[14px] flex items-center gap-3">
        <img src={productImg} alt="active module" loading="lazy" />
        <h5 className="text-[18px] font-[500] text-brand">
          View/Update Food Items
        </h5>
      </header>

      <div className="flex items-center justify-between gap-5">
        <div className="flex h-12 w-3/5 items-center justify-between gap-3 rounded-l-[25px] rounded-r-[5px] border-[1px] border-brand bg-white px-2 shadow-md">
          <input
            type="text"
            name="searchText"
            placeholder="Search"
            value={query?.searchText}
            onChange={(e: any) => onQueryChange(e, false)}
            className="w-10/12 bg-transparent px-3 py-1 text-[14px] text-brand outline-none disabled:cursor-not-allowed"
          />
          <button
            type="button"
            onClick={() => {
              getFoodItemsAction(
                query,
                foodItemList?.pageNumber,
                foodItemList?.pageSize,
              );
            }}
            className="px-3 py-1 text-black disabled:cursor-not-allowed"
          >
            <img src={searchImg} alt="search" className="h-[25px] w-[25px]" />
          </button>
        </div>

        <div className="w-1/5">
          <SelectField
            selectStyle="!border-brand !bg-[#F2CDFF] !shadow !text-brand"
            name="locationFilter"
            defaultName="Select your Location"
            defaultValue=""
            disabled={!isSuperAdmin}
            options={
              locations?.length > 0
                ? [
                    ...locations?.map((location) => ({
                      name: location?.name,
                      value: location?.name,
                    })),
                  ]
                : []
            }
            value={query?.locationFilter}
            onChange={(e: any) => onQueryChange(e, true)}
          />
        </div>

        <div className="w-1/5">
          <SelectField
            selectStyle="!border-brand !shadow !text-[14px] !rounded-r-[25px]"
            name="category"
            defaultName=""
            defaultValue=""
            options={
              FOOD_CATEGORY?.length > 0
                ? [
                    ...FOOD_CATEGORY?.map((cat) => ({
                      name: cat,
                      value: cat,
                    })),
                  ]
                : []
            }
            value={query?.category}
            onChange={(e: any) => onQueryChange(e, true)}
          />
        </div>
      </div>

      <main className="my-[24px]">
        {foodItemList?.foodItems?.length > 0 ? (
          <>
            {foodItemList?.foodItems.map((item: FoodItem) => (
              <div
                key={item?.foodItemId}
                className="mb-[24px] flex items-center justify-between gap-5 rounded-[5px] border-[1px] border-brand bg-white px-4 py-3 shadow-md"
              >
                <div className="flex w-2/3 items-center justify-between">
                  <div className="flex items-center gap-5">
                    <img
                      src={item?.image}
                      alt={item?.name}
                      className="h-[68px] w-[100px]"
                    />
                    <div>
                      <p className="mb-[5px] font-[500] capitalize text-brand">
                        {item?.name}
                      </p>
                      <p className="text-[14px] capitalize text-[#8E8787]">
                        Amount: {formatCurrency(item?.amount)}
                      </p>{" "}
                      <p className="text-[14px] capitalize text-[#8E8787]">
                        Category: {item?.category}
                      </p>{" "}
                      <p className="text-[14px] capitalize text-[#8E8787]">
                        Added on: {getDate(item?.createdAt)}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex gap-3">
                  <button
                    disabled={isSuperAdmin}
                    className={`${actionbtn} ${
                      item?.visibility
                        ? "!border-[#4CAC00] !bg-[#C6F4A1]"
                        : "!border-[#656565] !bg-[#DADADA]"
                    }`}
                    onClick={() => toggleProduct(item, !item?.visibility)}
                  >
                    {item?.visibility ? "Hide" : "Show"}
                  </button>
                  <Link
                    to={`/foodstuff/update-food-item?foodItemId=${item?.foodItemId}`}
                    className={`${actionbtn} !border-[#6C18A4] !bg-[#DFA1F4]`}
                  >
                    Update
                  </Link>
                  <button
                    disabled={isSuperAdmin}
                    className={`${actionbtn} !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                    onClick={() => deleteFoodItem(item)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="mb-[24px] flex items-center justify-between gap-5 rounded-[5px] border-[1px] border-brand bg-white px-4 py-3 shadow-md">
            <div>
              <p className="mb-[5px] font-[500] text-[#2E2626]">
                No food items yet
              </p>
            </div>
          </div>
        )}

        <Pagination
          pageNumber={foodItemList?.pageNumber}
          pageSize={foodItemList?.pageSize}
          totalCount={foodItemList?.totalCount}
          totalPage={foodItemList?.totalPage}
          onFetch={fetchMore}
        />
      </main>
    </section>
  );
};

export default ViewFoodItem;
