export default function Detail({
  name,
  value,
  style,
}: {
  name?: string;
  value: string | any;
  style?: string;
}) {
  return (
    <div className={`my-3 text-[14px] font-bold capitalize ${style}`}>
      <span className="mr-3 text-[12px] font-[400] text-[#868686]">
        {name}:
      </span>
      <span>{value}</span>
    </div>
  );
}
