import InputField from "core/components/formfields/InputField";
import { logo } from "core/consts/images";
import { btn } from "core/consts/styling";
import { USER_ROLES, USER_STATUS } from "core/consts/systemConst";
import { isEmail } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useUserStore from "core/services/stores/useUserStore";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const loginAction = useUserStore((store) => store.signin);

  const navigate = useNavigate();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<any>({
    username: "",
    password: "",
  });

  const validateLoginInfo = (username: string, password: string) => {
    var isValid = true;

    if (!isEmail(username)) {
      setErrors((state: any) => ({
        ...state,
        username: "Username should be a valid email address.",
      }));
      isValid = false;
    }

    if (password?.length < 1) {
      setErrors((state: any) => ({
        ...state,
        password: "Password is required.",
      }));
      isValid = false;
    }

    return isValid;
  };

  const login = async (e: any) => {
    e.preventDefault();

    if (validateLoginInfo(username, password)) {
      var res = await loginAction(username, password);

      if (res?.status) {
        const module = res?.data?.category;
        const isApproved = res?.data?.status === USER_STATUS.approved;

        if (module === USER_ROLES.super_admin || isApproved) {
          setPassword("");
          setUsername("");

          switch (module) {
            case USER_ROLES?.super_admin:
              navigate("/superadmin/dashboard");
              break;
            default:
              navigate(`/${module}/dashboard`);
              break;
          }
        }
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  return (
    <div className="mx-auto flex h-[80vh] w-11/12 justify-center md:w-4/5">
      <section className="mt-[100px] flex h-auto w-full flex-col items-center justify-center md:w-2/3 lg:w-2/5">
        <form
          className="mb-[16px] w-full rounded-[8px] !bg-white px-[16px] py-[38px] md:px-[38px]"
          onSubmit={login}
        >
          <div className="mb-[24px] mt-[-80px] flex flex-col items-center justify-center">
            <img src={logo} alt="campusrunz" loading="lazy" />
          </div>
          <h5 className="mb-[32px] text-center text-[18px] font-[500] text-brand">
            Login to Your Account
          </h5>
          <InputField
            label="Email Address/Phone Number"
            boxStyle="mb-[24px]"
            placeholder="Email Address/Phone Number"
            value={username}
            onChange={(e: any) => setUsername(e?.target?.value)}
            error={errors?.username}
            onBlur={() =>
              setErrors((state: any) => ({ ...state, username: "" }))
            }
          />
          <InputField
            boxStyle="mb-[24px]"
            label="Password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e: any) => setPassword(e?.target?.value)}
            error={errors?.password}
            onBlur={() =>
              setErrors((state: any) => ({ ...state, password: "" }))
            }
          />
          <button className={`${btn} w-full bg-brand text-white`}>
            Submit
          </button>
        </form>
        <div className="flex w-full items-center justify-between">
          <Link
            to="/auth/forgot-password"
            className="text-[12px] text-brandblue"
          >
            Forgot Password?
          </Link>
          <Link to="/auth/signup" className="text-[12px] text-brandblue">
            Create Account
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Login;
