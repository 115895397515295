import React from "react";
import Select from "react-select";

function MultiSelectField({
  boxStyle = "",
  selectStyle = "",
  name = "",
  options = [],
  disabled = false,
  onChange = () => {},
  onBlur = () => {},
  label = "",
  error = "",
  value = {},
  isRequired = false,
  multiple = false,
}: {
  boxStyle?: string;
  selectStyle?: string;
  name?: string;
  options?: any;
  disabled?: boolean;
  onChange?: any;
  onBlur?: any;
  label?: string;
  error?: any;
  value?: any;
  isRequired?: boolean;
  multiple?: boolean;
}) {
  return (
    <div className={`${boxStyle}`}>
      {label && label?.length > 0 && (
        <label htmlFor={name} className="mb-2 text-[14px] text-brandgray">
          {label} {isRequired && <span className="text-red-500">*</span>}
        </label>
      )}

      <Select
        isMulti={multiple}
        classNames={{
          control: () =>
            `w-full rounded-[5px] border-[1px] border-brandgray bg-white p-3 text-sm text-brandgray outline-none disabled:cursor-not-allowed hover:outline-none ${selectStyle}`,
        }}
        value={value}
        name={name}
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        isDisabled={disabled}
      />

      {error.length > 1 ? (
        <span className="text-[14px] text-red-500">{error}</span>
      ) : null}
    </div>
  );
}

export default MultiSelectField;
