import Pagination from "core/components/Pagination";
import DatePicker from "core/components/formfields/DatePicker";
import SelectField from "core/components/formfields/SelectField";
import { searchImg, viewOrderImg } from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import {
  ORDER_STATUS,
  PROCESS_INSPECTION_REQUEST,
} from "core/consts/systemConst";
import {
  formatCurrency,
  getDateTime,
  getStatusBackgroundColor,
} from "core/helpers/generalHelpers";
import useAccommodationStore from "core/services/stores/useAccommodationStore";
import useLocationStore from "core/services/stores/useLocationStore";
import useUserStore from "core/services/stores/useUserStore";
import CancelReasonForm from "modules/partials/CancelReasonForm";
import { useEffect, useState } from "react";

const ViewInspectionRequests = () => {
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const location = useUserStore((store) => store.location);

  const inspectionList = useAccommodationStore(
    (store) => store.inspectionRequestList,
  );
  const getInspectionAction = useAccommodationStore(
    (store) => store.getInspections,
  );
  const updateInspectionAction = useAccommodationStore(
    (store) => store.updateInspection,
  );

  const [query, setQuery] = useState<InspectionQuery>({
    apartmentType: "",
    customerId: "",
    endDate: "",
    startDate: "",
  });

  const [reason] = useState("");
  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const [openReasonModal, setOpenReasonModal] = useState(false);

  const onQueryChange = async (e: any, isAuto: boolean = true) => {
    const { name, value } = e?.target;

    setQuery((state) => ({
      ...state,
      [name]: value,
    }));

    if (isAuto) {
      await getInspectionAction(
        { ...query, [name]: value },
        inspectionList?.pageNumber,
        inspectionList?.pageSize,
      );
    }
  };

  const fetchMore = async (page: number) => {
    await getInspectionAction(query, page, inspectionList?.pageSize);
  };

  const updateInspectionRequest = async (
    order: InspectionRequest,
    status: string,
  ) => {
    var msg = "";

    switch (status) {
      case PROCESS_INSPECTION_REQUEST.accepted:
        msg = `Click 'OK' to accept the inspection request from ${order?.customerName}.`;
        break;
      case PROCESS_INSPECTION_REQUEST.declined:
        msg = `Click 'OK' to confirm decline the inspection request from ${order?.customerName}.`;
        break;
      case PROCESS_INSPECTION_REQUEST.fulfilled:
        msg = `Click 'OK' to confirm inspection request from ${order?.customerName} as fullfilled.`;
        break;
      default:
        break;
    }

    if (window.confirm(msg)) {
      var res = await updateInspectionAction(order?.requestId, status, reason);

      if (res?.isSuccessful) {
        setOpenReasonModal(false);
        setSelectedRequest(null);
      }
    }
  };

  useEffect(() => {
    getInspectionAction(
      query,
      inspectionList?.pageNumber,
      inspectionList?.pageSize,
    );
    locations?.length < 1 && getLocationAction();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Handle Cancel,accept order, approve order

  return (
    <>
      <section className="mx-auto mb-[28px] w-[95%]">
        <div className="mb-[24px] flex items-center justify-between gap-5">
          <header className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <img src={viewOrderImg} alt="active module" loading="lazy" />
              <h5 className="text-[18px] font-[500] text-brand">
                View Inspection Request
              </h5>
            </div>
          </header>
        </div>

        <div className="flex items-center justify-between gap-5">
          <div className="flex h-12 w-1/4 items-center justify-between gap-3 rounded-l-[25px] rounded-r-[5px] border-[1px] border-brand bg-white px-2 shadow-md">
            <input
              type="text"
              name="searchText"
              placeholder="Search"
              value={""}
              disabled
              onChange={(e: any) => onQueryChange(e, false)}
              className="w-10/12 bg-transparent px-3 py-1 text-[14px] text-brand outline-none disabled:cursor-not-allowed"
            />
            <button
              type="button"
              onClick={() => {}}
              disabled
              className="px-3 py-1 text-black disabled:cursor-not-allowed"
            >
              <img src={searchImg} alt="search" className="h-[25px] w-[25px]" />
            </button>
          </div>

          <div className="w-1/4">
            <SelectField
              selectStyle="!border-brand !shadow !text-[14px] !bg-brandLight !text-brand"
              name="locationFilter"
              defaultName="Select Location"
              defaultValue=""
              disabled={!isSuperAdmin}
              options={
                locations?.length > 0
                  ? [
                      ...locations?.map((location) => ({
                        name: location?.name,
                        value: location?.name,
                      })),
                    ]
                  : []
              }
              value={location}
              onChange={(e: any) => {
                onQueryChange(e);
              }}
            />
          </div>

          <DatePicker
            name="startDate"
            label="Start Date"
            value={query?.startDate}
            className={`flex h-12 w-1/4 items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
            onChange={(e: any) => {
              onQueryChange(e);
            }}
          />

          <DatePicker
            name="endDate"
            label="End Date"
            value={query?.endDate}
            className={`flex h-12 w-1/4 items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
            onChange={(e: any) => {
              onQueryChange(e);
            }}
          />
        </div>

        <main className="my-[24px]">
          {
            <>
              {inspectionList?.requests?.length > 0 ? (
                <>
                  {inspectionList?.requests?.map((request) => (
                    <div
                      key={request?.requestId}
                      className={`mb-[24px] flex items-center justify-between gap-5 rounded-[5px] border-[1px] border-brand bg-white  px-4 py-3 text-[#8E8787] shadow-md
                          ${getStatusBackgroundColor(request?.status)}
                          `}
                    >
                      <div
                        className="w-1/3 hover:cursor-pointer"
                        onClick={() => {
                          // TODO: View Order Function as Modal
                        }}
                      >
                        <p
                          className={`mb-[5px] font-bold capitalize ${
                            request?.status === ORDER_STATUS?.pending &&
                            "!text-brand"
                          }`}
                        >
                          <span className="font-[400]">Customer Name:</span>{" "}
                          {request?.customerName}
                        </p>
                        <p className="text-[14px] font-[500] capitalize">
                          {`Apartment Type: ${request?.apartmentType}`}
                        </p>
                        <p className="text-[14px] font-[500] capitalize">
                          Request Date: {getDateTime(request?.requestedAt)}
                        </p>
                      </div>

                      <div
                        className="w-1/3 text-[14px] capitalize hover:cursor-pointer"
                        onClick={() => {
                          // TODO: View Order Function as Modal
                        }}
                      >
                        <p>Apartment Area: {request?.area}</p>
                        <p>Rent: {formatCurrency(request?.rent)}</p>
                        <p>Status: {request?.status}</p>
                      </div>

                      <div className="flex w-1/3 gap-3">
                        {request?.status !==
                          PROCESS_INSPECTION_REQUEST?.fulfilled &&
                          request?.status !==
                            PROCESS_INSPECTION_REQUEST?.declined && (
                            <>
                              <button
                                className={`${actionbtn} !border-[#6C18A4] !bg-brand-200`}
                                onClick={() =>
                                  updateInspectionRequest(
                                    request,
                                    PROCESS_INSPECTION_REQUEST?.accepted,
                                  )
                                }
                                disabled={
                                  isSuperAdmin ||
                                  [
                                    PROCESS_INSPECTION_REQUEST?.accepted,
                                    PROCESS_INSPECTION_REQUEST?.fulfilled,
                                    PROCESS_INSPECTION_REQUEST?.declined,
                                  ].includes(request?.status)
                                }
                              >
                                Accept
                              </button>
                              <button
                                className={`${actionbtn} !border-[#6C18A4] !bg-[#DFA1F4]`}
                                onClick={() => {
                                  setSelectedRequest(request);
                                  setOpenReasonModal(true);
                                }}
                                disabled={
                                  isSuperAdmin ||
                                  [
                                    PROCESS_INSPECTION_REQUEST?.declined,
                                    PROCESS_INSPECTION_REQUEST?.fulfilled,
                                  ].includes(request?.status)
                                }
                              >
                                Decline
                              </button>
                              <button
                                className={`${actionbtn} !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                                onClick={() =>
                                  updateInspectionRequest(
                                    request,
                                    PROCESS_INSPECTION_REQUEST?.fulfilled,
                                  )
                                }
                                disabled={
                                  isSuperAdmin ||
                                  [
                                    PROCESS_INSPECTION_REQUEST?.pending,
                                    PROCESS_INSPECTION_REQUEST?.fulfilled,
                                    PROCESS_INSPECTION_REQUEST?.declined,
                                  ].includes(request?.status)
                                }
                              >
                                Marked Fulfilled
                              </button>
                            </>
                          )}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="mb-[24px] flex items-center justify-between gap-5 rounded-[5px] border-[1px] border-brand bg-white px-4 py-3 shadow-md">
                  <div>
                    <p className="mb-[5px] font-[500] text-[#2E2626]">
                      No inspection requests yet
                    </p>
                  </div>
                </div>
              )}
            </>
          }
        </main>

        <Pagination
          pageNumber={inspectionList?.pageNumber}
          pageSize={inspectionList?.pageSize}
          totalCount={inspectionList?.totalCount}
          totalPage={inspectionList?.totalPage}
          onFetch={fetchMore}
        />
      </section>

      <CancelReasonForm
        show={openReasonModal}
        onShow={setOpenReasonModal}
        order={selectedRequest}
        header="Update Inspection Request"
        onCancel={updateInspectionRequest}
      />
    </>
  );
};

export default ViewInspectionRequests;
