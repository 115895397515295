import InputField from "core/components/formfields/InputField";
import ActionRow from "core/components/table/Actionrow";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import { actionbtn, btn } from "core/consts/styling";
import { useEffect, useState } from "react";
import { getDate } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import {
  deleteIcon,
  editIcon,
  eyeclose,
  eyeopen,
  locImg,
} from "core/consts/images";
import Modal from "core/components/Modal";
import useSmsVendorStore from "core/services/stores/useSmsVendorStore";

const SmsVendorManagement = () => {
  const vendors = useSmsVendorStore((store) => store.vendors);
  const getVendorsAction = useSmsVendorStore((store) => store.getVendors);
  const deleteVendorAction = useSmsVendorStore((store) => store.deleteVendor);
  const addUpdateVendorAction = useSmsVendorStore(
    (store) => store.addUpdateVendor,
  );
  const updateVendorStatusAction = useSmsVendorStore(
    (store) => store.updateVendorStatus,
  );

  const [vendorName, setVendorName] = useState("");
  const [vendorIsActive, setVendorIsActive] = useState("true");
  const [vendorIsCommissioned, setVendorIsCommissioned] = useState("false");

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<SmsVendor | null>(null);

  const addUpdateVendor = async (e: any, isUpdate: boolean) => {
    e.preventDefault();

    if (vendorName?.length > 0) {
      var res = await addUpdateVendorAction(
        vendorName,
        isUpdate ? selectedVendor?.id! : "",
      );

      if (res?.isSuccessful) {
        if (isUpdate) {
          setShowUpdateModal(false);
        } else {
          setVendorName("");
        }
      }
    } else {
      notification({
        message: "Vendor name is required",
        type: "danger",
      });
    }
  };

  const updateVendorStatus = async (e: any) => {
    e.preventDefault();

    await updateVendorStatusAction(
      selectedVendor?.id!,
      vendorIsActive === "true" ? true : false,
      vendorIsCommissioned === "true" ? true : false,
    );
  };

  const deleteVendor = async (vendor: SmsVendor) => {
    if (window.confirm(`Click 'OK' to delete vendor: ${vendor?.name}`)) {
      await deleteVendorAction(vendor?.id);
    }
  };

  useEffect(() => {
    getVendorsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mx-auto w-[95%]">
        <header className="mb-[28px] flex items-center gap-3">
          <img src={locImg} loading="lazy" alt="" />
          <h5 className="text-[18px] font-[500] text-brand">
            SMS Vendor Management
          </h5>
        </header>
        <div className="flex flex-col gap-5 lg:flex-row">
          <div className="w-full lg:w-4/12">
            <div className="w-full rounded-[5px] bg-white p-5">
              <div className="mb-[28px] text-center">
                <p>Add New Vendor</p>
              </div>
              <form onSubmit={(e: any) => addUpdateVendor(e, false)}>
                <InputField
                  boxStyle="mb-[24px]"
                  placeholder="Name of Vendor"
                  name="vendorName"
                  value={vendorName}
                  onChange={(e: any) => setVendorName(e?.target?.value)}
                />

                <button className={`${btn} w-full bg-brand text-white`}>
                  Submit
                </button>
              </form>
            </div>
          </div>
          <div className="w-full lg:w-8/12">
            <Table
              headers={[
                "Name of Vendor",
                "Date Created",
                "Is Active",
                "Is Commissioned",
                "",
              ]}
            >
              {vendors?.length > 0 ? (
                vendors?.map((vendor) => (
                  <tr key={vendor?.id}>
                    <Row value={vendor?.name} />
                    <Row value={getDate(vendor?.createdAt)} />
                    <Row value={vendor?.isActive ? "Active" : "Inactive"} />
                    <Row
                      value={
                        vendor?.isCommissioned
                          ? "Commissioned"
                          : "Decommissioned"
                      }
                    />

                    <ActionRow style={`flex items-center justify-center gap-3`}>
                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] border-[#59960B] bg-[#D9FEAA] p-2"
                        onClick={() => {
                          setSelectedVendor(vendor);
                          setShowUpdateModal(true);
                          setVendorName(vendor?.name);
                        }}
                      >
                        <img
                          src={editIcon}
                          loading="lazy"
                          className="h-[16px]"
                          alt=""
                        />
                      </button>

                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] border-brand bg-[#F3CFFF] p-2"
                        onClick={() => {
                          setSelectedVendor(vendor);
                          setVendorIsActive(`${vendor?.isActive}`);
                          setVendorIsCommissioned(`${vendor?.isCommissioned}`);
                          setShowUpdateStatusModal(true);
                        }}
                      >
                        <img
                          src={vendor?.isActive ? eyeclose : eyeopen}
                          loading="lazy"
                          className="h-[16px]"
                          alt="toggle is commissioned status"
                        />
                      </button>

                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] border-[#FF0000] bg-[#FFC8C8] p-2"
                        onClick={() => deleteVendor(vendor)}
                      >
                        <img
                          src={deleteIcon}
                          className="h-[16px]"
                          loading="lazy"
                          alt=""
                        />
                      </button>
                    </ActionRow>
                  </tr>
                ))
              ) : (
                <tr>
                  <Row colSpan={5} value="no vendor has been added." />
                </tr>
              )}
            </Table>
          </div>
        </div>
      </div>

      {showUpdateModal && (
        <Modal
          header="Update Vendor"
          onClose={() => {
            setShowUpdateModal(false);
            setSelectedVendor(null);
            setVendorName("");
          }}
        >
          <form onSubmit={(e: any) => addUpdateVendor(e, true)}>
            <InputField
              boxStyle="mb-[24px]"
              placeholder="Name of Vendor"
              name="vendorName"
              value={vendorName}
              onChange={(e: any) => setVendorName(e?.target?.value)}
            />

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setShowUpdateModal(false);
                  setSelectedVendor(null);
                  setVendorName("");
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}

      {showUpdateStatusModal && (
        <Modal
          header={`Update ${selectedVendor?.name} Vendor Status`}
          onClose={() => {
            setShowUpdateStatusModal(false);
            setSelectedVendor(null);
            setVendorIsActive("false");
            setVendorIsCommissioned("false");
          }}
        >
          <form onSubmit={(e: any) => updateVendorStatus(e)}>
            <div className="mb-[16px] flex items-center justify-between gap-2 font-bold">
              <div className="w-1/12">
                <p>Yes</p>
              </div>
              <div className="w-1/12">
                <p>No</p>
              </div>
              <div className="w-10/12"></div>
            </div>

            <fieldset className="mb-5 flex items-center justify-between gap-2">
              <div className="w-1/12">
                <input
                  type="radio"
                  value="true"
                  className="border-1 h-[25px] w-[25px] !border-brand !bg-brand text-brand !accent-[#DFA1F4] focus:border-brand focus:bg-brand"
                  checked={vendorIsActive === "true"}
                  onChange={(e) => setVendorIsActive("true")}
                />
              </div>

              <div className="w-1/12">
                <input
                  type="radio"
                  value="false"
                  className="border-1 h-[25px] w-[25px] !border-brand !bg-brand text-brand !accent-[#DFA1F4] focus:border-brand focus:bg-brand"
                  checked={vendorIsActive === "false"}
                  onChange={(e) => setVendorIsActive("false")}
                />
              </div>
              <div className="w-10/12">
                <p className="h-[25px]">Activate Vendor</p>
              </div>
            </fieldset>

            <fieldset className="mb-5 flex items-center justify-between gap-2">
              <div className="w-1/12">
                <input
                  type="radio"
                  value="true"
                  className="border-1 h-[25px] w-[25px] !border-brand !bg-brand text-brand !accent-[#DFA1F4] focus:border-brand focus:bg-brand"
                  checked={vendorIsCommissioned === "true"}
                  onChange={(e) => setVendorIsCommissioned("true")}
                />
              </div>

              <div className="w-1/12">
                <input
                  type="radio"
                  value="false"
                  className="border-1 h-[25px] w-[25px] !border-brand !bg-brand text-brand !accent-[#DFA1F4] focus:border-brand focus:bg-brand"
                  checked={vendorIsCommissioned === "false"}
                  onChange={(e) => setVendorIsCommissioned("false")}
                />
              </div>
              <div className="w-10/12">
                <p className="h-[25px]">Commission Vendor</p>
              </div>
            </fieldset>

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setShowUpdateStatusModal(false);
                  setSelectedVendor(null);
                  setVendorIsActive("false");
                  setVendorIsCommissioned("false");
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default SmsVendorManagement;
