import { apicall } from "./apicall";

export const getFoodstuffDashboard = (query: DateLocationQuery) => {
  return apicall({
    endpoint: "/foodstuff/dashboard",
    method: "GET",
    pQuery: {
      ...query,
    },
    auth: true,
  });
};

export const getFoodItem = (
  query: FoodItemQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: "/foodstuff/fooditem",
    method: "GET",
    pQuery: {
      ...query,
      pageNumber,
      pageSize,
    },
    auth: true,
  });
};

export const getFoodItemById = (foodItemId: string) => {
  return apicall({
    endpoint: "/foodstuff/fooditem",
    param: foodItemId,
    method: "GET",
    auth: true,
  });
};

export const addUpdateFoodItem = async (
  foodItem: NewFoodItem,
  foodItemId: string,
) => {
  const data = new FormData();

  data.append("name", foodItem?.name);
  data.append("category", foodItem?.category);
  data.append("extraDetails", foodItem?.extraDetails);
  data.append("measurement", foodItem?.measurement);
  data.append("convenienceFee", `${foodItem?.convenienceFee}`);
  data.append("amount", `${foodItem?.amount}`);

  if (foodItem?.images?.length > 0) {
    foodItem?.images.forEach((file: any, i: any) => {
      data.append(`uploadedImageList${i}`, file);
    });
  }

  var isUpdate = foodItemId?.length > 0;

  return apicall({
    endpoint: "/foodstuff/fooditem",
    param: isUpdate ? foodItemId : "",
    method: isUpdate ? "PATCH" : "POST",
    multipart: true,
    auth: true,
    body: data,
  });
};

export const toggleFoodItem = (foodItemId: string, visibility: boolean) => {
  return apicall({
    endpoint: "/foodstuff/fooditem/toggle",
    param: foodItemId,
    method: "PATCH",
    body: {
      visibility,
    },
    auth: true,
  });
};

export const deleteFoodItem = (foodItemId: string) => {
  return apicall({
    endpoint: "/foodstuff/fooditem",
    param: foodItemId,
    method: "DELETE",
    auth: true,
  });
};

export const deleteFoodItemImage = (
  category: string,
  foodItemId: string,
  image: string,
) => {
  return apicall({
    endpoint: "/foodstuff/image",
    method: "DELETE",
    body: {
      itemId: foodItemId,
      category,
      imagePath: image,
    },
    auth: true,
  });
};

export const getFoodStuffOrders = (
  query: FoodItemQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: "/foodstuff/order",
    method: "GET",
    pQuery: {
      endDate: query?.endDate,
      startDate: query?.startDate,
      userId: query?.userId,
      locationFilter: query?.locationFilter,
      searchText: query?.searchText,
      orderStatus: query?.orderStatus,
      pageNumber,
      pageSize,
    },
    auth: true,
  });
};

export const getFoodStuffOrderById = (orderId: string) => {
  return apicall({
    endpoint: "/foodstuff/order",
    param: orderId,
    method: "GET",
    auth: true,
  });
};
