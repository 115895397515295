import { navbtn } from "core/consts/styling";

export default function Pagination({
  pageNumber = 1,
  totalCount = 0,
  totalPage = 0,
  onFetch = () => {},
}: {
  pageSize?: number;
  pageNumber?: number;
  totalCount?: number;
  totalPage?: number;
  onFetch?: any;
}) {
  return (
    <div className="mt-[28px] flex items-center justify-between text-brand">
      <div className="flex items-center gap-2">
        <p className="text-[14px]">
          <span className="font-[600]">Page {pageNumber}/</span>
          <span>{totalPage}</span>
        </p>
        <span>|</span>
        <p className="text-[14px]">Total: {totalCount}</p>
      </div>
      <div>
        <button
          className={`${navbtn} rounded-l-[5px] !px-5 disabled:cursor-not-allowed`}
          onClick={() => onFetch(pageNumber - 1)}
          disabled={pageNumber === 1}
        >
          Prev
        </button>

        <button className={`${navbtn} hover:!bg-white hover:!text-brand`}>
          {pageNumber} of {totalPage}
        </button>
        <button
          disabled={pageNumber === totalPage}
          onClick={() => onFetch(pageNumber + 1)}
          className={`${navbtn} rounded-r-[5px] !px-5 disabled:cursor-not-allowed`}
        >
          Next
        </button>
      </div>
    </div>
  );
}
