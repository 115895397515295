import { useEffect, useState } from "react";
import FileUploadField from "core/components/formfields/FileUploadField";
import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import TextField from "core/components/formfields/TextField";
import { activeModule } from "core/consts/images";
import { FOOD_CATEGORY } from "core/consts/businessCategories";
import { actionbtn, btn } from "core/consts/styling";
import { useSearchParams } from "react-router-dom";
import notification from "core/helpers/notification";
import useUserStore from "core/services/stores/useUserStore";
import useFoodStuffStore from "core/services/stores/useFoodStuffStore";

interface Props {
  isUpdate: boolean;
}

const FoodItemForm = ({ isUpdate = false }: Props) => {
  const [files, setFiles] = useState([]);

  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const foodItem = useFoodStuffStore((store) => store.foodItem);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [foodItemId, setFoodItemId] = useState("");

  const getFoodItemAction = useFoodStuffStore((store) => store.getFoodItemById);
  const addUpdateFoodItemAction = useFoodStuffStore(
    (store) => store.addUpdateFoodItem,
  );
  const deleteFoodItemImageAction = useFoodStuffStore(
    (store) => store.deleteFoodItemImage,
  );

  const [searchParams]: any = useSearchParams();

  const [newFoodItem, setNewFoodItem] = useState<NewFoodItem>({
    category: "",
    amount: 0,
    convenienceFee: 0,
    extraDetails: "",
    measurement: "",
    name: "",
    images: [],
  });

  const [errors, setErrors] = useState({
    Category: [],
    Amount: [],
    ConvenienceFee: [],
    ExtraDetails: [],
    Measurement: [],
    Name: [],
    Images: [],
  });

  const removeImage = (index: number) => {
    setFiles([...files.filter((file, i) => i !== index)]);
  };

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const deleteImage = async (url: any) => {
    if (window.confirm(`Click 'OK' to delete this image from the server.`)) {
      await deleteFoodItemImageAction("foodstuff", foodItem?.foodItemId, url);
    }
  };

  const validation = (data: NewFoodItem, files: any[]) => {
    var isValid = true;

    // TODO: Add Validations
    return isValid;
  };

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;

    setNewFoodItem((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const addUpdateFoodItem = async (e: any) => {
    e.preventDefault();

    if (validation(newFoodItem, files)) {
      var res = await addUpdateFoodItemAction(
        {
          ...newFoodItem,
          images: files,
        },
        isUpdate ? foodItem?.foodItemId : "",
      );

      if (res?.isSuccessful) {
        if (!isUpdate) {
          setNewFoodItem({
            category: "",
            amount: 0,
            convenienceFee: 0,
            extraDetails: "",
            measurement: "",
            name: "",
            images: [],
          });
        }

        setFiles([]);
      } else {
        setErrors((state) => ({
          ...state,
          ...res?.data?.errors,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    var id = searchParams.get("foodItemId");

    if (isUpdate && id?.length > 0) {
      setFoodItemId(id);
      getFoodItemAction(id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams && isUpdate]);

  useEffect(() => {
    isUpdate &&
      foodItem != null &&
      setNewFoodItem({
        amount: foodItem?.amount,
        category: foodItem?.category,
        convenienceFee: foodItem?.convenienceFee,
        extraDetails: foodItem?.extraDetails,
        images: [],
        measurement: foodItem?.measurement,
        name: foodItem?.name,
      });
  }, [isUpdate, foodItem]);

  return (
    <section className="mx-auto mb-[28px] w-[95%]">
      <header className="mb-[14px] flex items-center gap-3">
        <img src={activeModule} alt="active module" loading="lazy" />
        <h5 className="text-[18px] font-[500] capitalize text-brand">
          {isUpdate && foodItem
            ? `Update ${foodItem && foodItem?.name}`
            : "Create Food Item"}
        </h5>
      </header>
      <form className="flex gap-5" onSubmit={addUpdateFoodItem}>
        <div className="w-3/5">
          <InputField
            boxStyle="mb-[18px]"
            placeholder="Name"
            label="Name"
            name="name"
            isRequired
            value={newFoodItem?.name}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.Name[0]}
            onBlur={() => onErrorChange("Name", [])}
          />

          <SelectField
            boxStyle="mb-[18px]"
            selectStyle="!border-brand text-[#615353]"
            name="category"
            isRequired
            defaultName="Select Category"
            label="Category"
            defaultValue=""
            options={
              FOOD_CATEGORY?.length > 0
                ? [
                    ...FOOD_CATEGORY?.map((cat: string) => ({
                      name: cat,
                      value: cat,
                    })),
                  ]
                : []
            }
            value={newFoodItem?.category}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.Category[0]}
            onBlur={() => onErrorChange("Category", [])}
          />

          <InputField
            boxStyle="mb-[24px]"
            placeholder="Measurement"
            label="Measurement"
            name="measurement"
            isRequired
            value={newFoodItem?.measurement}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.Measurement[0]}
            onBlur={() => onErrorChange("Measurement", [])}
          />

          <div className="mb-[18px] flex items-center justify-between gap-3">
            <InputField
              boxStyle="w-1/2"
              label="Amount"
              placeholder="Amount"
              name="amount"
              value={newFoodItem?.amount}
              type="number"
              onChange={(e: any) => onFormChange(e)}
              error={errors?.Amount[0]}
              onBlur={() => onErrorChange("Amount", [])}
            />

            <InputField
              boxStyle="w-1/2"
              placeholder="Convenience Fee"
              label="Convenience Fee"
              name="convenienceFee"
              value={newFoodItem?.convenienceFee}
              type="number"
              onChange={(e: any) => onFormChange(e)}
              error={errors?.ConvenienceFee[0]}
              onBlur={() => onErrorChange("ConvenienceFee", [])}
            />
          </div>

          <TextField
            boxStyle="mb-[18px]"
            name="extraDetails"
            placeholder="Extra Details"
            label="Extra Details"
            isRequired
            rows={5}
            value={newFoodItem?.extraDetails}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.ExtraDetails[0]}
            onBlur={() => onErrorChange("ExtraDetails", [])}
          />

          <button
            disabled={isSuperAdmin}
            className={`${btn} w-full bg-brand text-white`}
          >
            Submit
          </button>
        </div>
        <div className="w-2/6">
          <>
            <FileUploadField
              multiple={true}
              boxStyle="w-3/5"
              label="Upload Pictures"
              name="signature"
              onChange={setFiles}
              showUploadedImages={false}
            />

            <div className="mt-[16px] grid grid-cols-2 gap-3 ">
              {files?.length > 0 &&
                files.map((file: any, index: number) => (
                  <div
                    key={file?.name + index}
                    className="relative overflow-hidden rounded-[5px] border border-brandLight"
                  >
                    <img src={URL.createObjectURL(file)} alt={`logo${index}`} />
                    <button
                      type="button"
                      className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                      onClick={() => removeImage(index)}
                    >
                      Delete
                    </button>
                  </div>
                ))}

              {isUpdate &&
                foodItem?.uploadedImageList?.length > 0 &&
                foodItem?.uploadedImageList?.map(
                  (image: string, index: number) => (
                    <div
                      key={index}
                      className="relative overflow-hidden rounded-[5px] border border-brandLight"
                    >
                      <img src={image} alt={`logo${index}`} />
                      <button
                        type="button"
                        className={`${actionbtn} !absolute bottom-0 !w-full !rounded-[5px] !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                        onClick={() => deleteImage(image)}
                      >
                        Delete
                      </button>
                    </div>
                  ),
                )}
            </div>
          </>
        </div>
      </form>
    </section>
  );
};

export default FoodItemForm;
