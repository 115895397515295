import { uploadImg } from "core/consts/images";
import { useEffect, useState } from "react";

function UploadField({
  boxStyle = "",
  name = "",
  onChange = () => {},
  label = "",
  value,
  isRequired = false,
}: {
  boxStyle?: string;
  name: string;
  isRequired?: boolean;
  onChange?: any;
  label?: string;
  value?: File | any;
}) {
  const [fileName, setFileName] = useState(value ? value?.name : "");
  const [inputError, setError] = useState("");
  const uploadField = document.getElementById(
    `upload-${name}`,
  ) as HTMLInputElement;

  const onFileChange = (e: any) => {
    const { name, files } = e?.target;

    setError("");

    if (files) {
      var uploadedFile: File = e.target?.files[0];

      if (uploadedFile !== null && uploadedFile?.type?.includes("image")) {
        setFileName(uploadedFile?.name);

        onChange((state: any) => ({
          ...state,
          [name]: uploadedFile,
        }));

        if (uploadField?.value) {
          uploadField.value = "";
        }
      } else {
        onChange((state: any) => ({
          ...state,
          [name]: "",
        }));

        setFileName("");

        setError("Only images of type: jpg,png or gif are allowed");
      }
    }
  };

  useEffect(() => {
    setFileName(value ? value?.name : "");
  }, [value]);

  return (
    <div className={`${boxStyle}`}>
      {label && label?.length > 0 && (
        <label
          htmlFor={`upload-${name}`}
          className="mb-2 block text-[14px] text-brandgray"
        >
          {label} {isRequired && <span className="text-red-500">*</span>}
        </label>
      )}{" "}
      <div>
        <label
          htmlFor={`upload-${name}`}
          className="mt-2 flex h-12 w-full items-center justify-between rounded-[5px] border-[1px] border-brandgray bg-white p-3 text-sm text-brandgray outline-none "
        >
          <span>{fileName?.length < 1 ? label : `${fileName}`}</span>
          <img src={uploadImg} alt="" />
        </label>
        <input
          type="file"
          id={`upload-${name}`}
          name={name}
          onChange={onFileChange}
          className="hidden"
        />
      </div>
      <span className="text-[14px] text-red-500">{inputError}</span>
    </div>
  );
}

export default UploadField;
