import { actionbtn } from "core/consts/styling";
import { useEffect, useState } from "react";
import Modal from "core/components/Modal";
import { addWhiteIcon, male, profileuser } from "core/consts/images";
import useTransportationStore from "core/services/stores/useTransportationStore";
import { TRANSPORTATION_AGENTS_TYPE } from "core/consts/systemConst";
import AgentsForm from "../../partials/AgentsForm";
import { useNavigate } from "react-router-dom";
import useUserStore from "core/services/stores/useUserStore";

const DeliveryAgent = () => {
  const navigate = useNavigate();
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const agents = useTransportationStore((store) => store.deliveryAgents);
  const getAgentAction = useTransportationStore((store) => store.getAgents);
  const setAgent = useTransportationStore((store) => store.setAgent);

  const [showViewModal, setShowViewModal] = useState(false);
  const [viewAgent, setViewAgent] = useState<any>(null);
  const [showForm, setShowForm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<any>(null);

  useEffect(() => {
    agents?.length < 1 &&
      getAgentAction(TRANSPORTATION_AGENTS_TYPE.deliveryAgent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: display image for document uploaded

  return (
    <>
      <section className="mx-auto w-[95%]">
        <div className="flex items-center justify-between gap-3">
          <header className="flex items-end gap-3">
            <img src={profileuser} loading="lazy" alt="" />
            <h5 className="text-[18px] font-[500] text-brand">
              Delivery Agents
            </h5>
          </header>
          <button
            onClick={() => {
              setSelectedAgent(null);
              setIsUpdate(false);
              setShowForm(true);
            }}
            disabled={isSuperAdmin}
            className="flex w-auto items-center justify-between rounded-[5px] bg-brand px-4 py-3 text-[14px] text-white shadow-md disabled:cursor-not-allowed"
          >
            <span>Add New Delivery Agent</span>
            <img src={addWhiteIcon} loading="lazy" alt="" />
          </button>
        </div>
        <main>
          {agents?.length > 0 ? (
            <div className="my-[24px] grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
              {agents?.map((agent) => (
                <div
                  key={agent?.agentId}
                  className="flex flex-col items-center rounded-[5px] border-[1px] border-brand bg-white p-5 shadow-md"
                >
                  <img src={male} loading="lazy" alt="" />
                  <p className="text-[14px] font-bold">
                    {agent?.firstName} {agent?.lastName}
                  </p>
                  <p className="mb-[18px] text-[12px] text-[#868686]">
                    {agent?.email}
                  </p>
                  <button
                    onClick={() => {
                      setViewAgent(agent);
                      setShowViewModal(true);
                    }}
                    className={`${actionbtn} mb-[10px] bg-[#F2EAF8]`}
                  >
                    View Agent
                  </button>
                  <button
                    onClick={() => {
                      setSelectedAgent(agent);
                      setIsUpdate(true);
                      setShowForm(true);
                    }}
                    disabled={isSuperAdmin}
                    className={`${actionbtn} mb-[15px] bg-[#DFA1F4]`}
                  >
                    Edit Agent
                  </button>
                  <button
                    onClick={() => {
                      setAgent(agent);

                      navigate(
                        `/transportation/delivery-agents/${agent?.agentId}`,
                      );
                    }}
                    className="text-[12px] text-brand disabled:cursor-not-allowed"
                  >
                    View Delivery History
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="my-[24px]">
              <p>There are no delivery agent at the moment</p>
            </div>
          )}
        </main>
      </section>

      {showViewModal && (
        <Modal
          header={`Delivery Agent`}
          onClose={() => {
            setShowViewModal(false);
            setViewAgent(null);
          }}
        >
          <div>
            <div
              key={viewAgent?.agentId}
              className="flex flex-col items-center"
            >
              <img src={male} alt="" loading="lazy" />
              <div className="my-[18px] text-center text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  First Name
                </span>
                <br />
                {viewAgent?.firstName}
              </div>
              <div className="mb-[18px] text-center text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  Last Name
                </span>
                <br />
                {viewAgent?.lastName}
              </div>
              <div className="mb-[18px] text-center text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  Email
                </span>
                <br />
                {viewAgent?.email}
              </div>
              <div className="mb-[18px] text-center text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  Contact Address
                </span>
                <br />
                {viewAgent?.contactAddress}
              </div>
              <div className="mb-[18px] text-center text-[14px] font-bold">
                <span className="text-[12px] font-[400] text-[#868686]">
                  Date Profiled
                </span>
                <br />
                {viewAgent?.dateCreated}
              </div>
            </div>
            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setShowViewModal(false);
                  setViewAgent(null);
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}

      <AgentsForm
        show={showForm}
        isUpdate={isUpdate}
        type={TRANSPORTATION_AGENTS_TYPE.deliveryAgent}
        onShow={setShowForm}
        setSelected={setSelectedAgent}
        selectedAgent={selectedAgent}
      />
    </>
  );
};

export default DeliveryAgent;
