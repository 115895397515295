import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import { downloadImg, male, users } from "core/consts/images";
import { actionbtn, navbtn } from "core/consts/styling";
import { exportToCSV } from "core/helpers/exportToExcel";
import { formatSimpleDate } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useLocationStore from "core/services/stores/useLocationStore";
import useReferralStore from "core/services/stores/useReferralStore";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const AgencyReferral = () => {
  const navigate = useNavigate();

  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);
  const agencyReferralList = useReferralStore(
    (store) => store.agencyReferralList,
  );
  const getAgencyReferralAction = useReferralStore(
    (store) => store.getAgencyReferral,
  );

  const [query, setQuery] = useState<ReferralQuery>({
    search: "",
    locationFilter: "",
    endDate: "",
    pageNumber: 1,
    pageSize: 20,
    startDate: "",
  });

  const onQueryChange = (e: any) => {
    const { name, value } = e.target;
    setQuery((state) => ({ ...state, [name]: value }));
  };

  const search = (e: any) => {
    e.preventDefault();
    getAgencyReferralAction(query);
  };

  const fetchMore = (next: boolean) => {
    switch (next) {
      case true:
        if (agencyReferralList?.pageNumber < agencyReferralList?.totalPage) {
          setQuery((state: any) => ({
            ...state,
            pageNumber: agencyReferralList?.pageNumber + 1,
          }));

          getAgencyReferralAction({
            ...query,
            pageNumber: agencyReferralList?.pageNumber + 1,
            pageSize: agencyReferralList?.pageSize,
          });
        }
        break;
      case false:
        if (agencyReferralList?.pageNumber > 1) {
          setQuery((state: any) => ({
            ...state,
            pageNumber: agencyReferralList?.pageNumber - 1,
          }));

          getAgencyReferralAction({
            ...query,
            pageNumber: agencyReferralList?.pageNumber - 1,
            pageSize: agencyReferralList?.pageSize,
          });
        }
        break;
      default:
        break;
    }
  };
  
  useEffect(() => {
    locations?.length < 1 && getLocationAction();

    agencyReferralList?.referers?.length < 1 && getAgencyReferralAction(query);

    setQuery((state: any) => ({
      ...state,
      pageNumber: agencyReferralList?.pageNumber,
      pageSize: agencyReferralList?.pageSize,
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mx-auto w-[95%]">
      <header className="mb-[28px] flex flex-col justify-start lg:flex-row lg:justify-between">
        <div className="flex flex-col items-start gap-3 font-[500] text-brand lg:flex-row">
          <img src={users} loading="lazy" alt="" />
          <h5 className="text-[18px] font-[500] text-brand">
            Referral Program / Agencies & Influencers
          </h5>
        </div>
        <div className="flex items-center gap-5">
          <Link
            className="font-bold text-brand underline hover:underline"
            to="/superadmin/referral-agencies"
          >
            Agencies & Influencers
          </Link>
          <Link
            className="text-brand hover:underline"
            to="/superadmin/referral-customers"
          >
            Customers
          </Link>
        </div>
      </header>
      <section>
        <form
          className="mb-[28px] flex items-end justify-between gap-2 overflow-x-scroll rounded-[5px] border border-t-[3px] border-brand bg-white p-3"
          onSubmit={search}
        >
          <InputField
            label="Search"
            inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
            name="search"
            value={query?.search}
            onChange={onQueryChange}
            boxStyle="min-w-[200px]"
          />
          
          <SelectField
            label="Select Location"
            boxStyle="min-w-[200px]"
            selectStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
            defaultName="Select your Location"
            defaultValue=""
            name="locationFilter"
            options={
              locations?.length > 0
                ? [
                    ...locations?.map((location) => ({
                      name: location?.name,
                      value: location?.name,
                    })),
                  ]
                : []
            }
            value={query?.locationFilter}
            onChange={onQueryChange}
          />

          <InputField
            label="Start Date"
            type="date"
            boxStyle="min-w-[200px]"
            inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
            name="startDate"
            value={query?.startDate}
            onChange={onQueryChange}
          />

          <InputField
            label="End Date"
            type="date"
            boxStyle="min-w-[200px]"
            inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
            name="endDate"
            value={query?.endDate}
            onChange={onQueryChange}
          />

          <div className="flex items-center gap-3">
            <button className="flex h-[50px] min-w-[160px] items-center justify-center gap-3 rounded-[5px] border border-brand bg-[#DFA1F4] p-3  px-8 text-white">
              <span className="text-[14px text-brand">Search</span>
            </button>

            <button
              type="button"
              disabled={agencyReferralList?.referers?.length < 1}
              className="flex h-[50px] min-w-[160px] items-center justify-between gap-3 rounded-[5px] bg-brand p-3 text-white disabled:cursor-not-allowed"
              onClick={() => {
                exportToCSV(agencyReferralList?.referers, "Agency Referrals");

                notification({
                  message: "You have successfully exported the data to excel",
                  type: "success",
                });
              }}
            >
              <span className="text-[14px]">Download</span>
              <img src={downloadImg} alt="" loading="lazy" />
            </button>
          </div>
        </form>
      </section>
      <section className="mb-[28px]">
        <div className="flex min-w-[600px] items-center justify-between gap-2 border-b-[3px] border-b-brand py-3 font-[500] text-brand">
          <div className="w-2/5"></div>
          <div className="w-1/5">
            <p>Sign Ups</p>
          </div>
          <div className="w-1/5">
            <p>Completed Transactions</p>
          </div>
          <div className="w-1/5">
            <p>Date</p>
          </div>
          <div className="w-[150px]"></div>
        </div>
        {agencyReferralList?.referers?.length > 0 ? (
          <>
            {agencyReferralList?.referers?.map((referral: AgencyReferer) => (
              <div
                key={referral?.referralCode}
                className="mb-5 flex min-w-[600px] items-center justify-between gap-2 rounded-[5px] border border-brand bg-white p-3"
              >
                <div className="w-2/5">
                  <div className="flex items-center gap-2">
                    <img
                      src={male}
                      alt=""
                      loading="lazy"
                      className="w-[35px]"
                    />
                    <div>
                      <p className="text-[14px] font-[500] capitalize text-[#3A3A3A]">
                        {referral?.referrerFullName}
                      </p>
                      <p className="text-[12px] text-[#7D7D7D]">
                        {referral?.referrerLocation}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-1/5">
                  <p>{referral?.sigups}</p>
                </div>
                <div className="w-1/5">
                  <p>{referral?.transactionCompleted}</p>
                </div>
                <div className="w-1/5">
                  {formatSimpleDate(referral?.recentReferralDate)}
                </div>
                <div className="w-[150px] min-w-[150px]">
                  <button
                    onClick={() => {
                      sessionStorage.setItem(
                        "referrer",
                        JSON.stringify(referral),
                      );
                      navigate(
                        `/superadmin/referral-agencies/${referral?.referralCode}`,
                      );
                    }}
                    className={`${actionbtn} bg-[#DFA1F4]`}
                  >
                    View Referrals
                  </button>
                </div>
              </div>
            ))}

            <div className="mt-[40px] flex items-center justify-between text-brand">
              <div className="flex items-center gap-2">
                <p className="text-[14px]">
                  <span className="font-[600]">
                    Page {agencyReferralList?.pageNumber}/
                  </span>
                  <span>{agencyReferralList?.totalPage}</span>
                </p>
                <span>|</span>
                <p className="text-[14px]">
                  Total: {agencyReferralList?.totalCount}
                </p>
              </div>
              <div>
                <button
                  className={`${navbtn} rounded-l-[5px] !px-5 disabled:cursor-not-allowed`}
                  onClick={() => fetchMore(false)}
                  disabled={agencyReferralList?.pageNumber === 1}
                >
                  Prev
                </button>

                <button
                  className={`${navbtn} hover:!bg-white hover:!text-brand`}
                  disabled
                >
                  {agencyReferralList?.pageNumber} of{" "}
                  {agencyReferralList?.totalPage}
                </button>
                <button
                  disabled={
                    agencyReferralList?.pageNumber ===
                    agencyReferralList?.totalPage
                  }
                  onClick={() => fetchMore(true)}
                  className={`${navbtn} rounded-r-[5px] !px-5 disabled:cursor-not-allowed`}
                >
                  Next
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="mb-5 flex items-center justify-between gap-2 rounded-[5px] border border-brand bg-white p-3">
            <div className="w-2/5">
              <div className="flex items-center gap-2">
                <div>
                  <p className="text-[14px] font-[500] text-[#3A3A3A]">
                    no referrals yet
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default AgencyReferral;
